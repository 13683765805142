import React, { createContext, useContext, PropsWithChildren } from "react";
import { createMachine, assign, DoneInvokeEvent } from "xstate";
import { useMachine } from "@xstate/react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

type Config = {
  keycloak?: {
    sso_url: string;
    realm: string;
    clientId: string;
  };
  keys?: {
    maps: string;
  };
  theme?: {
    sponsor: string;
    logo: {
      image: string;
      link: string;
    };
  };
  environment?: string;
  customFacilityEnabled?: boolean;
  ohfsRedirect?: boolean
  ohfsUrl?: string;
};

type ConfigContextOptions = {
  status: "loading" | "success" | "failure";
  config: Config;
};

let ConfigContext = createContext<ConfigContextOptions>({
  status: "loading",
  config: {},
});

type SuccessEvent = DoneInvokeEvent<Config>;

let ConfigMachine = createMachine<Config>(
  {
    initial: "loading",
    context: {},
    states: {
      loading: {
        invoke: {
          src: "fetchConfig",
          onDone: {
            actions: "setConfig",
            target: "success",
          },
          onError: "failure",
        },
      },
      success: {
        entry: ["installGoogle", "initializeSentry"],
      },
      failure: {},
    },
  },
  {
    actions: {
      //@ts-ignore
      setConfig: assign((_context, { data }: SuccessEvent) => data),
      installGoogle: (context, _event) => {
        let script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${context?.keys?.maps}&libraries=places`;
        document.head.appendChild(script);
      },
      initializeSentry: (context, _event) => {
        Sentry.init({
          dsn: "https://0d088dd1e9634e0493c2b743f3cd1465@o301889.ingest.sentry.io/6043621",
          integrations: [new Integrations.BrowserTracing()],
          tracesSampleRate: process.env.NODE_ENV === "development" ? 1.0 : 0.25,
          environment: context?.environment,
        });
      }
    },
    services: {
      async fetchConfig(_context, _event) {
        let response = await fetch("/config.json");
        let config = await response.json();
        return config;
      },
    },
  }
);

export function ConfigProvider({ children }: PropsWithChildren<{}>) {
  const [state] = useMachine(ConfigMachine);

  return (
    <ConfigContext.Provider
      //@ts-ignore
      value={{ config: state.context, status: state.value }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export function useConfig() {
  let config = useContext(ConfigContext);
  return {
    ...config,
  };
}
