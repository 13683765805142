import React, { useState } from "react";
import { isEmpty } from "../validators/isEmpty"
import { Snackbar } from "./Snackbar";

interface GraphQLError extends Error {
  graphQLErrors?: { code: string; type: string; message: string }[];
}

export interface GqlErrorCodeMessageMap {
  [code: string]: string;
}

interface ValidationErrorBarProps {
  error?: GraphQLError | null;
  className?: string;
  noActionButton?: boolean;
  mapErrorToMessage?: (
    error?: GraphQLError | null,
    gqlCodeMessageMap?: GqlErrorCodeMessageMap,
    renderNetworkError?: boolean
  ) => string | JSX.Element;
  gqlCodeMessageMap?: GqlErrorCodeMessageMap;
  renderNetworkError?: boolean;
  "aria-label"?: string;
  "data-testid"?: string;
}

export function FormErrorBar({
  error,
  className,
  noActionButton = false,
  gqlCodeMessageMap = {},
  renderNetworkError = false,
  mapErrorToMessage = mapGqlErrorToMessage,
  "aria-label": label = "Error",
  "data-testid": testId = FormErrorBar.name,
}: ValidationErrorBarProps) {
  let [isOpen, setIsOpen] = useState(true);
  if (!error) return null;
  return (
    <div
      className={className}
      role="alert"
      aria-label={label}
      aria-describedby="error-message"
    >
      <Snackbar
        open={isOpen}
        variant="error"
        message={mapErrorToMessage(
          error,
          gqlCodeMessageMap,
          renderNetworkError
        )}
        data-testid={testId}
        contentId="error-message"
        noActionButton={noActionButton}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </div>
  );
}

function mapGqlErrorToMessage(
  error?: GraphQLError | Error | any | null,
  gqlCodeMessageMap?: GqlErrorCodeMessageMap,
  renderNetworkError?: boolean
) {
  if (!error) return "";

  const networkErrorMsg = error.networkError?.result?.errors[0].message;
  const genericErrorMsg = "Something went wrong. Please try again later";
  const genericValidationErrorMsg =
    "The form has validation errors. Please address them and try again.";

  const errorItem = ((error as GraphQLError).graphQLErrors || [])!.find(
    (e) => e.type === "validation"
  )!;

  if (isEmpty(gqlCodeMessageMap) && errorItem) {
    return errorItem.message ? errorItem.message : genericValidationErrorMsg;
  }

  if (gqlCodeMessageMap && errorItem) {
    const message =
      gqlCodeMessageMap[errorItem.code] || genericValidationErrorMsg;
    return message;
  }

  if (error.type === "validation") {
    return genericValidationErrorMsg;
  }

  if (renderNetworkError && networkErrorMsg) {
    return networkErrorMsg;
  }

  return genericErrorMsg;
}
