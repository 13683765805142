import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { noop } from "utils/noop";
import { Facility } from "types/profile";
import { FacilityCreationForm } from "./FacilityCreationForm";

export interface FacilityCreationFormDialogProps {
  open?: boolean;
  submit: (facility: Facility) => Promise<any>;
  onError?: Callback;
  onCancel?: Callback;
  onSuccess?: Callback;
  withReason?: boolean;
  "data-testid"?: string;
}

export function FacilityCreationFormDialog({
  submit,
  open = false,
  onError = console.error,
  onCancel = noop,
  onSuccess = noop,
  withReason = false,
  "data-testid": testId = FacilityCreationFormDialog.name,
  ...otherProps
}: FacilityCreationFormDialogProps) {
  return (
    <Dialog
      open={open}
      aria-labelledby="form-title"
      maxWidth="xl"
      data-testid={testId}
      {...otherProps}
    >
      <DialogContent>
        <FacilityCreationForm
          open={open}
          submit={submit}
          onError={onError}
          onCancel={onCancel}
          onSuccess={onSuccess}
          withReason={withReason}
        />
      </DialogContent>
    </Dialog>
  );
}
