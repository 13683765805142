import * as React from "react";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { Phone } from "types/profile";
import { required } from "validators/required";
import { FormDialog } from "./FormDialog";
import { noop } from "utils/noop";
import { useForm, SubmitFn } from "hooks/useForm";
import { PhoneTypeSelectField } from "./PhoneTypeSelectField";
import { PhoneCountryCodeSelectField } from "./PhoneCountryCodeSelectField";
import {
  ChangeReasonFormDialog,
  useChangeReasonFormDialog,
} from "./ChangeReasonFormDialog";
import { maxLengthForValidator as maxLength } from "validators/maxLength";
import { validators } from "utils/forms";
import { produce } from "immer";

const PREFIX = "ContactPhoneForm";

const classes = {
  fieldset: `${PREFIX}-fieldset`,
};

const DialogRoot = styled("div")(({ theme }) => ({
  [`& .${classes.fieldset}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridColumnGap: theme.spacing(1),
  },
}));

interface Props {
  open?: boolean;
  entry?: Phone;
  submit?: SubmitFn;
  onCancel?: Callback;
  onSuccess?: Callback;
  withReason?: boolean;
  "data-testid"?: string;
}

export function ContactPhoneForm(props: Props) {
  let phone: Phone = props.entry ?? {};
  let reasonForm = useChangeReasonFormDialog({
    doSubmit: props.submit ?? noop,
    formHookSubmit,
    withReason: props.withReason ?? false,
  });
  let { id, fields, submit, reset, submitting, error } = useForm({
    id: "contact-phone-form",
    fields: {
      id: {
        name: "id",
        value: phone.id,
      },
      type: {
        id: "type",
        name: "type",
        value: phone.type,
        required: true,
        validate: required,
      },
      countryCode: {
        id: "countryCode",
        name: "countryCode",
        value: phone!.countryCode,
        required: true,
        validate: required,
      },
      number: {
        id: "number",
        name: "number",
        label: "Telephone number",
        value: phone!.number,
        required: true,
        validate: validators(required, maxLength(100, "number")),
      },
      ext: {
        id: "ext",
        label: "Ext.",
        name: "ext",
        value: phone!.ext,
        validate: maxLength(7, "number"),
      },
    },
    onReset: props.onCancel,
    submit: reasonForm.performSubmit,
    onError: console.error,
    onSuccess: props.onSuccess,
  });

  function formHookSubmit() {
    return submit();
  }

  if (error?.message.includes("phone number")) {
    fields = produce(fields, (draft) => {
      draft.number.error = true;
      draft.number.helperText = error?.message.includes("duplicate")
        ? "This number has already been used"
        : "Invalid phone number";
    });
  }

  if (error?.message.includes("extension")) {
    fields = produce(fields, (draft) => {
      draft.ext.error = true;
      draft.ext.helperText = "Extention isn't valid";
    });
  }

  return (
    <div>
      <FormDialog
        id={id}
        open={props.open}
        onCancel={reset}
        onSubmit={reasonForm.handleSubmitButtonClick}
        showProgressIndicator={submitting}
        title="Update Telephone Details"
        {...props}
      >
        <DialogRoot>
          <div className={classes.fieldset}>
            <PhoneTypeSelectField {...fields.type} />
            <PhoneCountryCodeSelectField {...fields.countryCode} />
            <TextField {...fields.number} />
            <TextField {...fields.ext} />
          </div>
        </DialogRoot>
      </FormDialog>
      <ChangeReasonFormDialog {...reasonForm.reasonDialog} />
    </div>
  );
}
