import React from "react";
import nameTitles from "../fixtures/nameTitles";

import {
  AutocompleteField,
  AutocompleteInstanceFieldProps,
} from "./AutocompleteField";

export function NameTitlesSelectField(props: AutocompleteInstanceFieldProps) {
  return (
    <AutocompleteField
      id="title"
      label="Title"
      name="nameTitle"
      options={nameTitles}
      {...props}
    />
  );
}
