import React from "react";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useManageInvitation } from "hooks/useDelegates";
import InvitationMessage from "components/InvitationMessage";

const PaperStyled = styled(Paper)(({ theme }) => ({
  background: theme.palette.error.main,
  color: theme.palette.common.white,
  padding: theme.spacing(2),
}));

interface DeclineInvitationProps {
  params: {
    token: string;
  };
}

export default function ExternalDeclineInvitation({
  params,
}: DeclineInvitationProps) {
  let { error, open, ...rest } = useManageInvitation({
    token: params.token,
    invitationType: "DECLINE",
  });

  return (
    <PaperStyled>
      <InvitationMessage {...rest} token={params.token} />
    </PaperStyled>
  );
}
