import React from "react";
import departmentTypes from "../fixtures/departmentTypes";

import {
  AutocompleteField,
  AutocompleteInstanceFieldProps,
} from "./AutocompleteField";

const options = departmentTypes.map((t) => ({ value: t.code, label: t.name }));

export function DepartmentTypeSelectField({
  "data-testid": testId = DepartmentTypeSelectField.name,
  ...props
}: AutocompleteInstanceFieldProps) {
  return (
    <AutocompleteField
      id="departmentType"
      name="departmentType"
      label="Department type"
      testId={testId}
      options={options}
      {...props}
    />
  );
}
