import Fade from "@mui/material/Fade";
import React from "react";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import { noop } from "utils/noop";
import { Form } from "components/Form";
import { email } from "validators/email";
import { useForm } from "hooks/useForm";
import { required } from "validators/required";
import { styled } from "@mui/material/styles";
import { ContactTypeSelectField } from "./ContactTypeSelectField";

const PREFIX = "DepartmentContactAdditionForm";

const classes = {
  heading: `${PREFIX}-heading`,
  submitButton: `${PREFIX}-submitButton`,
  indicatorButtonWrapper: `${PREFIX}-indicatorButtonWrapper`,
  indicatorIconWrapper: `${PREFIX}-indicatorIconWrapper`,
};

const FormStyled = styled(Form)(({ theme }) => {
  return {
    [`& .${classes.heading}`]: {
      fontSize: 20,
    },
    [`& .${classes.submitButton}`]: {
      flex: 1,
      marginTop: 8,
      height: 40,
    },
    [`& .${classes.indicatorButtonWrapper}`]: {
      position: "relative",
      display: "flex",
      width: 20,
      minWidth: 15,
    },
    [`& .${classes.indicatorIconWrapper}`]: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
});

export interface DepartmentContactAdditionFormProps {
  id?: string;
  title?: string;
  email?: string;
  emailFieldLabel?: string;
  emailFieldDisabled?: boolean;
  submitButtonLabel?: string;
  submit: (values: { type: number; email: string }) => Promise<any>;
  onError?: Callback;
  onSuccess?: Callback;
}

export function DepartmentContactAdditionForm({
  email: emailToAdd,
  id = "department-contact-self-addition-form",
  title = "Add a contact",
  emailFieldLabel = "Email address",
  emailFieldDisabled = false,
  submitButtonLabel = "Add email",
  onError = console.error,
  onSuccess = noop,
  submit: doSubmit,
  ...otherProps
}: DepartmentContactAdditionFormProps) {
  const { fields, submit, submitting, error } = useForm({
    id,
    fields: {
      type: {
        name: "type",
        label: "Contact type",
        required: true,
        validate: required,
      },
      email: {
        name: "email",
        label: emailFieldLabel,
        value: emailToAdd,
        required: true,
        validate: email,
        disabled: emailFieldDisabled,
      },
    },
    submit: doSubmit,
    onError,
    onSuccess,
  });

  return (
    <FormStyled
      id={id}
      error={error}
      onSubmit={submit}
      submitting={submitting}
      aria-label={title}
      noSubmitButton
      noCancelButton
      noActivityIndicator
      open
      {...otherProps}
    >
      <Typography component="h3" className={classes.heading} children={title} />
      <FormGroup row>
        <ContactTypeSelectField id={`${id}-type-field`} {...fields.type} />
        <TextField {...fields.email} />
        <div className={classes.indicatorButtonWrapper}>
          <Button
            className={classes.submitButton}
            variant="contained"
            disabled={!fields.type.value || !fields.email.value || submitting}
            onClick={submit}
          >
            {submitButtonLabel}
          </Button>

          {submitting && (
            <div className={classes.indicatorIconWrapper}>
              <Fade in>
                <CircularProgress size={20} />
              </Fade>
            </div>
          )}
        </div>
      </FormGroup>
    </FormStyled>
  );
}
