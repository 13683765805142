import React from "react";
import FormGroup from "@mui/material/FormGroup";

import { validators } from "utils/forms";
import { required } from "validators/required";
import { maxLengthForValidator as maxLength } from "validators/maxLength";
import { styled } from "@mui/material/styles";
import { FormDialog } from "./FormDialog";
import { Qualification } from "types/profile";
import { noop, asyncNoop } from "utils/noop";
import { YearSelectField } from "./YearSelectField";
import { useForm, SubmitFn } from "hooks/useForm";
import { AutocompleteField } from "./AutocompleteField";
import { CountrySelectField } from "./CountrySelectField";
import { UniversityAutocompleteField } from "./UniversityAutocompleteField";
import { QualificationAutocompleteField } from "./QualificationAutocompleteField";

import {
  ChangeReasonFormDialog,
  useChangeReasonFormDialog,
} from "./ChangeReasonFormDialog";
import { FormErrorBar } from "./FormErrorBar";

const PREFIX = "EducationForm";

const classes = {
  yearFormGroup: `${PREFIX}-yearFormGroup`,
  error: `${PREFIX}-error`,
};

const DialogRoot = styled("div")(({ theme }) => ({
  [`& .${classes.yearFormGroup}`]: {
    "& > *:not(:first-child)": {
      marginLeft: theme.spacing(3),
    },
  },

  [`& .${classes.error}`]: {
    marginTop: theme.spacing(1),
  },
}));

const defaultQualification = {} as Qualification;

interface Props {
  title?: string;
  open?: boolean;
  entry?: Qualification;
  testId?: string;
  submit?: SubmitFn;
  onCancel?: Callback;
  onSuccess?: Callback;
  withReason?: boolean;
  "data-testid"?: string;
}

export function EducationForm({
  open,
  entry,
  "data-testid": testId = EducationForm.name,
  title = "Update qualification",
  submit: doSubmit = asyncNoop,
  onCancel: doCancel = noop,
  onSuccess = noop,
  withReason = false,
  ...otherProps
}: Props) {
  const qualification = entry || defaultQualification;
  const handleSubmit = async (values: AnyObject, adminComment?: string) => {
    values.isMedicalDegree = ["true", true].includes(values.isMedicalDegree);
    return await doSubmit(values, adminComment);
  };

  const reasonForm = useChangeReasonFormDialog({
    doSubmit: handleSubmit,
    formHookSubmit,
    withReason,
  });

  const { id, fields, error, submit, reset, submitting } = useForm({
    id: "education-form",
    fields: {
      id: {
        name: "id",
        value: qualification.id,
      },
      countryCode: {
        name: "countryCode",
        value: qualification.countryCode,
        required: true,
        validate: required,
        dependantFields: ["university"],
      },
      university: {
        name: "university",
        label: "University",
        value: qualification.university,
        required: true,
        validate: validators(required, maxLength(200)),
      },
      qualification: {
        name: "qualification",
        label: "Degree or certificate",
        value: qualification.qualification,
        required: true,
        validate: required,
      },
      yearStarted: {
        id: "yearStarted",
        name: "yearStarted",
        label: "Year started",
        defaultValue: undefined,
        value: qualification.yearStarted ? qualification.yearStarted.toString() : undefined,
        required: true,
        validate: required,
      },
      yearFinished: {
        id: "yearFinished",
        name: "yearFinished",
        label: "Year finished",
        value: qualification.yearFinished ? qualification.yearFinished.toString() : undefined,
        required: true,
        validate: required,
      },
      isMedicalDegree: {
        id: "isMedicalDegree",
        name: "isMedicalDegree",
        label: "Is this a medical degree?",
        value: qualification.isMedicalDegree,
        required: true,
        validate: required,
      },
    },
    onReset: doCancel,
    submit: reasonForm.performSubmit,
    onError: console.error,
    onSuccess,
  });

  function formHookSubmit() {
    return submit();
  }

  return (
    <div>
      <FormDialog
        id={id}
        open={open}
        title={title}
        onCancel={reset}
        onSubmit={reasonForm.handleSubmitButtonClick}
        maxWidth="sm"
        data-testid={testId}
        showProgressIndicator={submitting}
        {...otherProps}
      >
        <DialogRoot>
          <FormGroup>
            <CountrySelectField
              data-testid={`${testId}CountryField`}
              {...fields.countryCode}
            />

            <UniversityAutocompleteField
              data-testid={`${testId}UniversityField`}
              countryCode={fields.countryCode.value}
              fullWidth
              {...fields.university}
            />

            <QualificationAutocompleteField
              data-testid={`${testId}QualificationField`}
              fullWidth
              {...fields.qualification}
            />

            <FormGroup row className={classes.yearFormGroup}>
              <YearSelectField
                data-testid={`${testId}YearStartedField`}
                {...fields.yearStarted}
                onChange={(event: any) => {
                  if (
                    Number(event.target.value) >
                    Number(fields.yearFinished.value)
                  ) {
                    fields.yearFinished.onChange({ target: { value: "" } });
                  }
                  fields.yearStarted.onChange(event);
                }}
              />

              <YearSelectField
                startYear={fields.yearStarted.value}
                data-testid={`${testId}YearFinishedField`}
                {...fields.yearFinished}
              />
            </FormGroup>

            <AutocompleteField
              data-testid={`${testId}IsMedicalDegreeField`}
              options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              {...fields.isMedicalDegree}
            />
          </FormGroup>

          <FormErrorBar
            className={classes.error}
            error={error}
            mapErrorToMessage={(error) => {
              //@ts-ignore
              if (error?.type === "validation") {
                return "The form has validation errors. Please address them and try again.";
              }
              if (error?.graphQLErrors?.[0].type === "validation") {
                if (error?.message.includes("duplicated")) {
                  return "This qualification already exisits.";
                } else {
                  return "The form has validation errors. Please address them and try again.";
                }
              }
              return "Something went wrong. Please try again later";
            }}
          />
        </DialogRoot>
      </FormDialog>
      <ChangeReasonFormDialog {...reasonForm.reasonDialog} />
    </div>
  );
}
