//@ts-nocheck
import { Machine, assign, Interpreter, sendParent, send, spawn } from "xstate";
import { assocPath } from "rambda";
import { useActor } from "@xstate/react";
import { maxLength } from "validators/maxLength";
import { isEmail } from "validators/email";
import { isEmpty } from "../validators/isEmpty";
import { validator } from "./utils";

type SetValueEvent = { type: "SET_VALUE"; name: string; value: string };
type SubmitEvent = { type: "SUBMIT" };
type ContactUsFormEvents = SetValueEvent | SubmitEvent;

interface Field {
  value: string;
  helperText: string;
}

interface ContactUsMachineContext {
  email: Field;
  name: Field;
  message: Field;
  phone: Field;
  source: string;
  error: string | null;
}

export let ContactUsFormMachine = Machine<
  ContactUsMachineContext,
  ContactUsFormEvents
>(
  {
    initial: "idle",
    context: {
      source: "",
      email: {
        helperText: "",
        value: "",
      },
      name: {
        helperText: "",
        value: "",
      },
      message: {
        helperText: "",
        value: "",
      },
      phone: {
        helperText: "",
        value: "",
      },
      error: null,
    },
    states: {
      idle: {
        on: {
          SET_USER: {
            actions: "setUser",
          },
          SUBMIT: "validate",
          SET_VALUE: {
            actions: "setValue",
          },
        },
      },
      validate: {
        entry: "resetValidationMessages",
        invoke: {
          src: "validation",
          onDone: "submitting",
          onError: {
            actions: ["setValidationErrors"],
            target: "idle",
          },
        },
      },
      submitting: {
        entry: "submit",
        on: {
          SUCCESS: "idle",
          ERROR: "idle",
        },
      },
    },
  },
  {
    guards: {},
    actions: {
      setValue: assign(
        (context: ContactUsMachineContext, event: SetValueEvent) =>
          assocPath([event.name, "value"], event.value, context)
      ),
      setUser: assign({
        email(context, event) {
          return assocPath(["value"], event.user.email, context.email);
        },
        name(context, event) {
          return assocPath(["value"], event.user.name, context.name);
        },
      }),
      submit: sendParent("SUBMIT"),
      setValidationErrors: assign((context, event) => {
        for (let [key, message] of event.data) {
          context[key].helperText = message;
        }

        return context;
      }),
      resetValidationMessages: assign((context, event) => {
        for (let key of ["name", "email", "message", "phone"]) {
          context[key].helperText = "";
        }

        return context;
      }),
    },
    services: {
      async validation(context, event) {
        let { hasErrors, errors } = validator(
          {
            key: "name",
            message: "Should not be more than 200 characters long",
            predicate: !maxLength(200)(context.name.value),
          },
          {
            key: "name",
            message: "This field is required",
            predicate: isEmpty(context.name.value),
          },
          {
            key: "email",
            message: "Should not be more than 120 characters long",
            predicate: !maxLength(120)(context.email.value),
          },
          {
            key: "email",
            message: "This field is required",
            predicate: isEmpty(context.email.value),
          },
          {
            key: "message",
            message: "Should not be more than 1000 characters long",
            predicate: !maxLength(1000)(context.message.value),
          },
          {
            key: "message",
            message: "This field is required",
            predicate: isEmpty(context.message.value),
          },
          {
            key: "phone",
            message: "Should not be more than 17 characters long",
            predicate: !isEmpty(context.phone.value)
              ? !maxLength(17)(context.phone.value)
              : false,
          },
          {
            key: "email",
            message: "Not a valid email address",
            predicate: !isEmpty(context.email.value)
              ? !isEmail(context.email.value)
              : false,
          }
        );

        if (hasErrors) {
          return Promise.reject(errors);
        }
      },
    },
  }
);

export type ContactFormMachineService = Interpreter<ContactUsMachineContext>;

export function useContactUsForm(
  service: Interpreter<ContactUsMachineContext>
) {
  let [state, send] = useActor(service);

  return {
    ...state.context,
    setValue(name, value) {
      return send({
        type: "SET_VALUE",
        name: name,
        value: value,
      } as SetValueEvent);
    },
    onChange(event: React.ChangeEvent<HTMLInputElement>) {
      return send({
        type: "SET_VALUE",
        name: event.target.name,
        value: event.target.value,
      } as SetValueEvent);
    },
    handleSubmit(event: React.FormEvent) {
      event.preventDefault();
      event.stopPropagation();
      send({ type: "SUBMIT" });
    },
  };
}

export function contactFormActions(to: string) {
  return {
    setUser: send(
      (context, { data }) => {
        return { type: "SET_USER", user: data };
      },
      { to }
    ),
    success: send(
      {
        type: "SUCCESS",
      },
      { to }
    ),
    error: send(
      {
        type: "ERROR",
      },
      { to }
    ),
    spawn(config: any = {}, context?: Partial<ContactUsMachineContext>) {
      let machine = ContactUsFormMachine.withConfig(config);
      if (context) {
        machine = ContactUsFormMachine.withContext(context);
      }
      return spawn(machine, { name: to, sync: true, ...config });
    },
  };
}
