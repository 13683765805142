let LicenseTypes = [
  { label: "Medical Doctor", value: "Medical Doctor" },
  { label: "Physician Assistant", value: "Physician Assistant" },
  { label: "Other Licence to Practice", value: "Other Licence to Practice" },
  {
    label: "Certification (not licence to practice)",
    value: "Certification (not licence to practice)",
  },
];

export default LicenseTypes;
