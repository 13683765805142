//@ts-nocheck
import { Actor, Machine, assign } from "xstate";
import { contactFormActions } from "./useContactUsForm";
import {
  NotificationMachineContext,
  NotificationMachineEvents,
  createNotificationActions
} from "../machines/notification";
import { useActor, useMachine } from "@xstate/react";
import { useMutation, useApolloClient } from "@apollo/client";
import gql from "graphql-tag";
import { GET_USER } from "./useUser";

export interface ContactUsMachineContext {
  source: "external" | "internal";
  notificationRef: Actor | null;
  formRef: Actor | null;
}

export let SEND_CONTACT_US_MESSAGE = gql `
  mutation ContactUs(
    $name: NonBlankString!
    $email: Email!
    $facilityId: String
    $message: NonBlankString!
    $phone: String
    $source: Source!
  ) {
    contactUs(
      name: $name
      email: $email
      message: $message
      phone: $phone
      source: $source
      facilityId: $facilityId
    ) {
      status
    }
  }
`;

let formActions = contactFormActions("form");
let notificationActions = createNotificationActions("notification");

let actions = {
  initialise: assign({
    formRef: (_context, _event) => formActions.spawn(),
    notificationRef: (_context, _event) => notificationActions.spawn(),
  }),
  setUser: formActions.setUser,
  notifyError: notificationActions.notify("error", "Something went wrong"),
  notifySuccess: notificationActions.notify(
    "success",
    "Thank you, your message has been sent to our support team. Please allow three working days for a response",
  ),
  sendSuccess: formActions.success,
  sendError: formActions.error,
};

export const contactUsMachine = Machine<ContactUsMachineContext>(
  {
    initial: "initialise",
    context: {
      source: "internal",
      notificationRef: null,
      formRef: null,
    },
    states: {
      initialise: {
        entry: "initialise",
        after: {
          0: "load",
        },
      },
      load: {
        invoke: {
          src: "getUser",
          onDone: {
            actions: "setUser",
            target: "idle",
          },
          onError: {
            target: "idle",
          },
        },
      },
      idle: {
        on: {
          SUBMIT: "submitting",
        },
      },
      submitting: {
        invoke: {
          src: "submit",
          onDone: {
            actions: ["notifySuccess", "sendSuccess"],
            target: "idle",
          },
          onError: {
            actions: ["notifyError", "sendError", console.log],
            target: "idle",
          },
        },
      },
    },
  },
  {
    actions,
  },
);

export function useContactUs(source: "external" | "internal" = "internal") {
  let [contactUs] = useMutation(SEND_CONTACT_US_MESSAGE);
  let client = useApolloClient();
  let [state] = useMachine(contactUsMachine, {
    deferEvents: true,
    devTools: true,
    context: {
      source,
    },
    services: {
      async getUser() {
        let { data } = await client.query({ query: GET_USER });
        return {
          email: data.profile.contactEmail || data.profile.email,
          name: `${data.profile.user.firstName} ${data.profile.user.lastName}`,
        };
      },
      async submit(context, _event) {
        let { data } = await contactUs({
          variables: {
            source: context.source,
            message: context.formRef.state.context.message.value,
            email: context.formRef.state.context.email.value,
            phone: context.formRef.state.context.phone.value,
            name: context.formRef.state.context.name.value,
          },
        });
        return data;
      },
    },
  });
  let [notification, sendToNotification] = useActor<
    NotificationMachineContext,
    NotificationMachineEvents
  >(state.context.notificationRef);

  return {
    state: state.value,
    matches: state.matches,
    notification: {
      open: notification.matches("open"),
      close: () => sendToNotification("CLOSE_NOTIFICATION"),
      variant: notification.context.variant,
      message: notification.context.message,
    },
    services: {
      form: state.context.formRef,
    },
  };
}
