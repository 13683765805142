import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

export function createApolloClient() {
  const httpLink = createHttpLink({
    uri: "/graphql/",
  });

  const authLink = setContext((_, { headers = {}, coreData = false }) => {
    let token = localStorage.getItem("authToken");

    if (token && !coreData) {
      headers["authorization"] = `Bearer ${token}`;
    }

    return {
      headers,
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
}

export function createTestApolloClient() {
  const httpLink = createHttpLink({
    uri: "/graphql/",
  });

  return new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
}
