import gql from "graphql-tag";
import React from "react";

import { useQuery } from "@apollo/client";
import { ErrorBar } from "components/ErrorBar";
import { ContactType } from "types/facility";
import { LoadingIndicator } from "components/LoadingIndicator";

export const GET_CONTACT_TYPES = gql`
  query GetContactCard {
    contactTypes {
      value
      label
    }
  }
`;

interface ContactTypesProviderProps {
  children?: ReactNodes;
}

export function ContactTypesProvider({ children }: ContactTypesProviderProps) {
  const { loading, error } = useQuery(GET_CONTACT_TYPES);

  if (loading)
    return (
      <div aria-busy="true">
        <LoadingIndicator />
      </div>
    );
  if (error) {
    return (
      <ErrorBar message="There was an error retrieving the contact types. Please try again later" />
    );
  }

  return <>{children}</>;
}

export function useContactTypes() {

  const { data } = useQuery(GET_CONTACT_TYPES);

  if (!(data && data.contactTypes)) {
    throw new Error(
      `'${useContactTypes.name}' must be used in the context of a '${ContactTypesProvider.name}'`
    );
  }

  const contactTypes = data.contactTypes as ContactType[];

  function getContactTypeLabel(typeCode: number) {
    const type = contactTypes.find(type => type.value === typeCode);
    if (!type?.label) {
      throw new Error(`There is no contact type with a value of ${typeCode}`);
    }
    return type.label;
  }

  return {
    contactTypes: contactTypes,
    getContactTypeLabel
  };
}
