import React from "react";
import {
  Screen,
  ScreenSection,
  ScreenSectionTitle,
} from "../components/Screen";
import { InvitationType } from "machines/Invitation";
import { DelegatesCard } from "components/DelegatesCard";
import { DelegatesForCard } from "components/DelegatesForCard";
import { ManageInvitation } from "components/ManageInvitation";
import { useDelegates, DelegateType } from "hooks/useDelegates";

interface DelegatesProps {
  token?: string;
  invitationType?: InvitationType;
}

export default function Delegates({ token, invitationType }: DelegatesProps) {
  const {
    delegates,
    delegatesFor,
    inviteDelegate,
    invitationData,
    onInvitationSuccess,
    onRequestSuccess,
    onRequestError,
    deleteDelegate,
    invitationRef,
  } = useDelegates({ token, invitationType });

  return (
    <Screen data-testid={Delegates.name}>
      {invitationRef && <ManageInvitation invitationRef={invitationRef} />}
      <ScreenSection>
        <ScreenSectionTitle children="Delegates" />
        <DelegatesCard
          delegates={delegates}
          inviteDelegate={(data: any) =>
            inviteDelegate({
              email: data.email,
              delegateType: "DELEGATES" as DelegateType,
            })
          }
          deleteDelegate={(data: any) =>
            deleteDelegate({
              delegate: data,
              delegateType: "DELEGATES" as DelegateType,
              adminComment: null,
            })
          }
          onRequestSuccess={(data: any) =>
            onRequestSuccess({
              data,
              delegateType: "DELEGATES" as DelegateType,
            })
          }
          onInvitationSuccess={(invitationData: any) =>
            onInvitationSuccess({
              invitationData,
              delegateType: "DELEGATES" as DelegateType,
            })
          }
          onRequestError={onRequestError}
        />
        <DelegatesForCard
          delegates={delegatesFor}
          inviteDelegate={(data: any) =>
            inviteDelegate({
              email: data.email,
              delegateType: "DELEGATE_FOR" as DelegateType,
            })
          }
          deleteDelegate={(data: any) =>
            deleteDelegate({
              delegate: data,
              delegateType: "DELEGATE_FOR" as DelegateType,
              adminComment: null,
            })
          }
          onRequestSuccess={(data: any) =>
            onRequestSuccess({
              data,
              delegateType: "DELEGATE_FOR" as DelegateType,
            })
          }
          onInvitationSuccess={(invitationData: any) =>
            onInvitationSuccess({
              invitationData,
              delegateType: "DELEGATE_FOR" as DelegateType,
            })
          }
          invitationData={invitationData}
          onRequestError={onRequestError}
        />
      </ScreenSection>
    </Screen>
  );
}
