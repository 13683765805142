import React from "react";
import { Table, TableBody, TableHead } from "@mui/material";
import studyComments from "fixtures/studyComments";

import { styled } from "@mui/material/styles";
import { formattedDate } from "utils/date";
import { TableHeadRow, TableBodyRow, TableCell, TableHeadCell } from "./Table";

interface StudyStatistics {
  id: number;
  role: string;
  title: string | null;
  primaryIndication?: string[] | null;
  nctNumber: string;
  sponsor: string;
  status: string;
  phase: string;
  siteOpenDate: string;
  enrolled?: string | null;
  enrolledMonths?: string | null;
  patientsPspm?: string | null;
  screenFail?: string | null;
  complete?: string | null;
  medianEnrolled?: string | null;
  medianEnrolledMonths?: string | null;
  medianPatientsPspm?: string | null;
  medianScreenFail?: string | null;
  medianComplete?: string | null;
  comment?: string | null;
}

const Root = styled("div")(({ theme }) => ({
  "& > *:not(:first-child)": {
    marginTop: theme.spacing(2),
  },
}));

const SplitPant = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  columnGap: theme.spacing(2),
}));

interface StudyStatsProps {
  entry: StudyStatistics;
  "data-testid"?: string | null;
  "aria-hidden"?: boolean;
}

export function StudyStats({
  entry = {} as StudyStatistics,
  "aria-hidden": ariaHidden = false,
  "data-testid": testId = StudyStats.name,
  ...otherProps
}: StudyStatsProps) {
  return (
    <Root aria-hidden={ariaHidden} data-testid={testId} {...otherProps}>
      {entry.title && (
        <div data-testid={`${testId}TitleSection`}>
          <Table>
            <TableHead>
              <TableHeadRow>
                <TableHeadCell>{entry.nctNumber}</TableHeadCell>
              </TableHeadRow>
            </TableHead>
            <TableBody>
              <TableBodyRow>
                <TableCell variant="body" data-testid={`${testId}TitleCell`}>
                  {entry.title}
                </TableCell>
              </TableBodyRow>
            </TableBody>
          </Table>
        </div>
      )}

      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell>Sponsor</TableHeadCell>
            <TableHeadCell>Primary indication</TableHeadCell>
            <TableHeadCell>Phase</TableHeadCell>
            <TableHeadCell>Site OPN</TableHeadCell>
            <TableHeadCell>Role</TableHeadCell>
            <TableHeadCell>Status</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <TableBody>
          <TableBodyRow data-testid={`${testId}BodyRow`}>
            <TableCell data-testid={`${testId}SponsorCell`}>
              {entry.sponsor}
            </TableCell>
            <TableCell data-testid={`${testId}PrimaryIndicationCell`}>
              {(entry.primaryIndication || []).join(", ")}
            </TableCell>
            <TableCell data-testid={`${testId}PhaseCell`}>
              {entry.phase}
            </TableCell>
            <TableCell data-testid={`${testId}SiteOpenDateCell`}>
              {formattedDate(entry.siteOpenDate)}
            </TableCell>
            <TableCell data-testid={`${testId}EntryRoleCell`}>
              {entry.role}
            </TableCell>
            <TableCell data-testid={`${testId}StatusCell`}>
              {entry.status}
            </TableCell>
          </TableBodyRow>
        </TableBody>
      </Table>

      <SplitPant>
        <Table>
          <TableBody>
            <TableBodyRow>
              <TableHeadCell>#Enrolment</TableHeadCell>
              <TableCell variant="body" data-testid={`${testId}EnrolmentCell`}>
                {numberValue(entry.enrolled)}
              </TableCell>
            </TableBodyRow>
            <TableBodyRow>
              <TableHeadCell>Enrolment months</TableHeadCell>
              <TableCell
                variant="body"
                data-testid={`${testId}EnrolmentMonthsCell`}
              >
                {numberValue(entry.enrolledMonths)}
              </TableCell>
            </TableBodyRow>
            <TableBodyRow>
              <TableHeadCell>Patients per site per month</TableHeadCell>
              <TableCell
                variant="body"
                data-testid={`${testId}PatientsPSPMCell`}
              >
                {numberValue(entry.patientsPspm)}
              </TableCell>
            </TableBodyRow>
            <TableBodyRow>
              <TableHeadCell>% screen fail</TableHeadCell>
              <TableCell variant="body" data-testid={`${testId}ScreenFailCell`}>
                {numberValue(entry.screenFail)}
              </TableCell>
            </TableBodyRow>
            <TableBodyRow>
              <TableHeadCell>% complete</TableHeadCell>
              <TableCell variant="body" data-testid={`${testId}CompleteCell`}>
                {numberValue(entry.complete)}
              </TableCell>
            </TableBodyRow>
          </TableBody>
        </Table>
        <Table>
          <TableBody>
            <TableBodyRow>
              <TableHeadCell>Median #Enrolment</TableHeadCell>
              <TableCell
                variant="body"
                data-testid={`${testId}MedianEnrolmentCell`}
              >
                {numberValue(entry.medianEnrolled)}
              </TableCell>
            </TableBodyRow>
            <TableBodyRow>
              <TableHeadCell>Median Enrolment months</TableHeadCell>
              <TableCell
                variant="body"
                data-testid={`${testId}MedianEnrolmentMonthsCell`}
              >
                {numberValue(entry.medianEnrolledMonths)}
              </TableCell>
            </TableBodyRow>
            <TableBodyRow>
              <TableHeadCell>Median Patients per site per month</TableHeadCell>
              <TableCell
                variant="body"
                data-testid={`${testId}MedianPatientsPSPMCell`}
              >
                {numberValue(entry.medianPatientsPspm)}
              </TableCell>
            </TableBodyRow>
            <TableBodyRow>
              <TableHeadCell>Median % screen fail</TableHeadCell>
              <TableCell
                variant="body"
                data-testid={`${testId}MedianScreenFailCell`}
              >
                {numberValue(entry.medianScreenFail)}
              </TableCell>
            </TableBodyRow>
            <TableBodyRow>
              <TableHeadCell>Median % complete</TableHeadCell>
              <TableCell
                variant="body"
                data-testid={`${testId}MedianCompleteCell`}
              >
                {numberValue(entry.medianComplete)}
              </TableCell>
            </TableBodyRow>
          </TableBody>
        </Table>
      </SplitPant>

      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell>Your comments</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <TableBody>
          <TableBodyRow>
            <TableCell variant="body" data-testid={`${testId}CommentCell`}>
              {commentLabel(entry.comment)}
            </TableCell>
          </TableBodyRow>
        </TableBody>
      </Table>
    </Root>
  );
}

export function commentLabel(value: string | undefined | null) {
  if (!value) return "-";
  const comment = studyComments.find((s) => s.value === value);
  if (!comment)
    throw new Error(`There is no study comment with a value of '${value}'`);
  return comment.label;
}

export function numberValue(value: string | undefined | null) {
  if (!value) return "-";
  return roundNum(value);
}

function roundNum(value?: string) {
  if (!value) return;
  return (Math.round(parseFloat(value) * 10) / 10).toString();
}
