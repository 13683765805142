import React from "react";
import { Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CardStyled = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  background: "#52a552",
  color: theme.palette.common.white,
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  gridGap: theme.spacing(1),
}));

export function OptedInConfirmation() {
  return (
    <CardStyled>
      <CheckCircleIcon fontSize="large" />
      <div>
        <Typography sx={{ color: "common.white" }} variant="h4">
          You have been successfully opted-in to the Investigator Databank
        </Typography>
        <Typography variant="body1">
          Your information can now be shared with the participating member
          companies, and you can view and comment on your study participation
          history with these companies in your profile. All you need to do now
          is to complete your profile below.
        </Typography>
      </div>
    </CardStyled>
  );
}
