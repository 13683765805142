import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { noop } from "utils/noop";
import { StudyByManualForm, StudyByManualFormProps } from "./StudyByManualForm";

export function StudyByManualFormDialog({
  open,
  title,
  submit,
  entry = {} as any,
  onError,
  onCancel = noop,
  onSuccess = noop,
  withReason = false,
  "data-testid": testId = StudyByManualFormDialog.name,
  ...otherProps
}: StudyByManualFormProps) {
  if (!open) return null;

  return (
    <Dialog open={open} data-testid={testId} maxWidth="xl" {...otherProps}>
      <DialogContent>
        <StudyByManualForm
          open={open}
          title={title}
          entry={entry}
          onCancel={onCancel}
          onError={onError}
          onSuccess={onSuccess}
          withReason={withReason}
          submit={submit}
        />
      </DialogContent>
    </Dialog>
  );
}
