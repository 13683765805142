import React from "react";
import Typography from "@mui/material/Typography";

import { styled } from "@mui/material/styles";
import { Facility, FacilityProfile } from "types/facility";
import { Link } from "wouter";

const Root = styled("div")(({ theme }) => ({
  "& > *:not(:first-child)": {
    marginTop: theme.spacing(2),
  },
}));

interface FacilityProfileHeadingTextProps {
  facilityProfile: FacilityProfile;
}

function constructAddressLine(details: Facility) {
  return ["street", "city", "region", "zipCode", "countryCode"]
    .filter((part) => Boolean((details as any)[part]))
    .map((part) => (details as any)[part])
    .join(", ");
}

export function FacilityProfileHeadingText({
  facilityProfile,
}: FacilityProfileHeadingTextProps) {
  return (
    <Root>
      <Typography variant="h5" color="textPrimary">
        {facilityProfile.facilityDetails.name}
      </Typography>
      <Typography variant="subtitle2" color="textPrimary">
        {constructAddressLine(facilityProfile.facilityDetails)}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        If you spot any problems with this profile including an incorrect
        address, please <Link href="/contact-us">contact us</Link>
      </Typography>
      <Typography variant="subtitle2" color="textPrimary">
        Sharing this information will help sponsors match your facility to the
        right trials.
      </Typography>
    </Root>
  );
}
