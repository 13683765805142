import React from "react";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import { useEffect } from "react";
import { FormField } from "./FormField";
import { useForm, Field } from "hooks/useForm";
import { asyncNoop, noop } from "utils/noop";
import { SponsorExperience } from "types/facility";
import { FormCheckboxField } from "./FormCheckboxField";

const defaultValue = {} as SponsorExperience;

const FormContent = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridRowGap: theme.spacing(1.5),
}));

interface SponsorExperienceFieldProps {
  label?: string;
  error?: boolean;
  form: string;
  helperText?: string;
  onChange: (experience: SponsorExperience) => void;
  value?: SponsorExperience;
  [key: string]: any;
}

export function SponsorExperienceField({
  label = "Sponsor experience",
  error = false,
  form,
  value = defaultValue,
  helperText,
  onChange,
  ...otherProps
}: SponsorExperienceFieldProps) {
  const { fields, setField } = useForm({
    id: form,
    fields: {
      industry: {
        type: "checkbox",
        name: "industry",
        label: "Industry",
        value: value.industry,
      },
      investigatorInitiated: {
        type: "checkbox",
        name: "investigatorInitiated",
        label: "Investigator",
        value: value.investigatorInitiated,
      },
      academic: {
        type: "checkbox",
        name: "academic",
        label: "Academic",
        value: value.academic,
      },
      government: {
        type: "checkbox",
        name: "government",
        label: "Government funded",
        value: value.government,
      },
      other: {
        type: "checkbox",
        name: "other",
        label: "Other",
        value: value.other,
      },
    },
    submit: asyncNoop,
    onError: noop,
    onChange: (fields: any) => {
      onChange({
        industry: asBoolOrNull(fields.industry.value),
        investigatorInitiated: asBoolOrNull(fields.investigatorInitiated.value),
        academic: asBoolOrNull(fields.academic.value),
        government: asBoolOrNull(fields.government.value),
        other: asBoolOrNull(fields.other.value),
      });
    },
  });

  useEffect(() => {
    for (const key of Object.keys(value)) {
      // @ts-ignore
      setField(key, value[key] || "");
    }
  }, [JSON.stringify(value)]); // eslint-disable-line

  return (
    <FormField
      error={error}
      label={label}
      helperText={helperText}
      {...otherProps}
    >
      <FormContent>
        <Typography variant="subtitle1" color="textSecondary">
          Please tell us in which clinical trial phases your site has experience
          (select all that apply)
        </Typography>
        <FormGroup row>
          <CheckboxField {...fields.industry} />
          <CheckboxField {...fields.investigatorInitiated} />
          <CheckboxField {...fields.academic} />
          <CheckboxField {...fields.government} />
          <CheckboxField {...fields.other} />
        </FormGroup>
      </FormContent>
    </FormField>
  );
}

function CheckboxField(props: Field) {
  return (
    <FormCheckboxField
      sx={{
        root: { mr: 2 },
        checkbox: { pr: 1 },
      }}
      {...props}
    />
  );
}

function asBoolOrNull(value?: boolean) {
  if (typeof value === "boolean") {
    return value;
  }
  return null;
}
