//@ts-nocheck
import React from "react";
import { noop } from "utils/noop";
import {
  TextField,
  TextFieldProps,
  Autocomplete,
  AutocompleteProps,
} from "@mui/material";

interface Option {
  label: string;
  value: string;
}

interface OnChangeEvent {
  target: {
    name?: string;
    value: any;
  };
}

export type AutocompleteInstanceFieldProps = Omit<
  AutocompleteFieldProps,
  "options" | "getOptionLabel" | "getOptionValue"
> & {};

export type AutocompleteFieldProps = Partial<
  AutocompleteProps<any, any, any, any>
> & {
  value?: any;
  name?: string;
  label?: string;
  error?: boolean;
  required?: boolean;
  options: Option[] | any[];
  helperText?: string;
  placeholder?: string;
  textFieldProps?: Partial<TextFieldProps>;
  valueToDispatchWhenNull?: any;
  getOptionValue?: (option: any) => any;
  onChange?: (event: OnChangeEvent) => void;
  [key: string]: any;
};

export function AutocompleteField({
  name,
  label,
  error,
  value,
  options = [],
  required,
  helperText,
  placeholder = "Select...",
  textFieldProps = {},
  valueToDispatchWhenNull = "",
  onChange = noop,
  getOptionLabel = (option) => option.label,
  getOptionValue = (option) => option.value,
  ...otherProps
}: AutocompleteFieldProps) {
  let suppliedValue;

  function findOptionByValue(value: any) {
    return options.find((option) => {
      const optionValue = getOptionValue(option);
      return value === optionValue;
    });
  }

  // This check exists to ensure that when the value is
  // undefined, the field is treated as `uncontrolled`,
  // otherwise, every time an option is manually selected,
  // the null `value` will override it. So we need the
  // value to remain is `undefined` until an actual `value`
  // prop is supplied, so that it now becomes `controlled`
  if (value !== undefined) {
    suppliedValue = findOptionByValue(value) || null;
  }

  return (
    <Autocomplete
      value={suppliedValue}
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={(event: any, option: any) => {
        const value = option ? getOptionValue(option) : valueToDispatchWhenNull;
        onChange({ target: { name: name, value } });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          label={label}
          error={error}
          required={required}
          helperText={helperText}
          placeholder={placeholder}
          {...textFieldProps}
        />
      )}
      {...otherProps}
    />
  );
}
