import { Field } from "hooks/useForm";

const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

export const INVALID_EMAIL_ERROR = "Invalid email";

export function email(field: Field) {
  if (
    typeof field.value === "string" &&
    field.value.trim() !== "" &&
    !field.value.match(emailRegex)
  ) {
    return INVALID_EMAIL_ERROR;
  }
}

const emailRegexExtended = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isEmail(email: string): boolean {
  return emailRegexExtended.test(email);
}
