import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  Alert,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import { noop } from "utils/noop";
import { Training } from "types/profile";
import { formattedDate } from "utils/date";

import { isCompleteTraining, hasIncompleteTrainings } from "utils/trainings";

import {
  TablePane,
  TableHeadRow,
  TableBodyRow,
  TableActionsCell,
  TableEditActionButton,
  TableDeleteActionButton,
  TablePaneFooter,
  TablePaneButton,
} from "./Table";

interface Props {
  testId?: string;
  entries?: Training[];
  onCreateButtonClick?: Callback;
  onUpdateButtonClick?: Callback;
  onDeleteButtonClick?: Callback;
  onDownloadButtonClick?: Callback;
  "data-testid"?: string;
}

export function TrainingTable({
  entries = [],
  "data-testid": testId = TrainingTable.name,
  onCreateButtonClick = noop,
  onUpdateButtonClick = noop,
  onDeleteButtonClick = noop,
  onDownloadButtonClick = noop,
}: Props) {
  return (
    <TablePane>
      {hasIncompleteTrainings(entries) && (
        <Alert sx={{ mb: 2 }} severity="warning">
          Please provide missing information for the entries indicated below
        </Alert>
      )}
      <Table data-testid={testId}>
        <TableHead>
          <TableHeadRow>
            <TableCell>Type</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Started</TableCell>
            <TableCell>Completed</TableCell>
            <TableCell>Certificate</TableCell>
            <TableCell />
            <TableCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {entries.map((entry) => (
            <TableBodyRow key={entry.pk} data-testid={`${testId}BodyRow`}>
              <TableCell>GCP</TableCell>
              <TableCell>{entry.courseProvider}</TableCell>
              <TableCell>{formattedDate(entry.startDate)}</TableCell>
              <TableCell>{formattedDate(entry.completionDate)}</TableCell>
              <TableCell>{entry.certificateFilename}</TableCell>
              <TableCell>
                {!isCompleteTraining(entry) && (
                  <WarningIcon
                    sx={{ color: "warning.main" }}
                    aria-label="Warning"
                    titleAccess="This experience entry is incomplete"
                  />
                )}
              </TableCell>
              <TableActionsCell>
                {entry.certificateFilename && (
                  <IconButton
                    aria-label="help"
                    size="small"
                    color="primary"
                    data-testid={`${testId}DownloadButton`}
                    onClick={() => onDownloadButtonClick(entry)}
                  >
                    <CloudDownloadIcon fontSize="inherit" />
                  </IconButton>
                )}

                <TableEditActionButton
                  onClick={() => onUpdateButtonClick(entry)}
                  data-testid={`${testId}UpdateEntryButton`}
                />
                <TableDeleteActionButton
                  onClick={() => onDeleteButtonClick(entry)}
                  data-testid={`${testId}DeleteEntryButton`}
                />
              </TableActionsCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
      <TablePaneFooter>
        <TablePaneButton
          icon={AddCircleIcon}
          label="Add more GCP training"
          onClick={onCreateButtonClick}
          data-testid={`${testId}CreateEntryButton`}
        />
      </TablePaneFooter>
    </TablePane>
  );
}
