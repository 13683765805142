import React from "react";
import FormGroup from "@mui/material/FormGroup";

import { FormDialog } from "./FormDialog";
import { Facility } from "types/profile";
import { noop, asyncNoop } from "utils/noop";
import { useForm, SubmitFn } from "hooks/useForm";
import { FieldPrimaryLocationSelect } from "./FieldPrimaryLocationSelect";

import {
  useChangeReasonFormDialog,
  ChangeReasonFormDialog,
} from "./ChangeReasonFormDialog";
import { clone } from "rambda";

interface Props {
  title?: string;
  open?: boolean;
  facilities: Facility[];
  testId?: string;
  submit?: SubmitFn;
  onCancel?: Callback;
  onSuccess?: Callback;
  withReason?: boolean;
  flagIncompleteFields?: boolean;
}

export function FacilityPrimaryLocationDialog({
  open,
  facilities,
  title = "Select Primary Location",
  submit: doSubmit = asyncNoop,
  onCancel: doCancel = noop,
  withReason = false,
  onSuccess = noop,
  flagIncompleteFields = false,
  ...otherProps
}: Props) {
  const handleSubmit = async (
    { primaryLocation }: AnyObject,
    adminComment?: string
  ) => {
    let selectedLocation: any = {};
    let clonedFacilties = clone(facilities);

    for (let facility of clonedFacilties) {
      if (
        primaryLocation.facilityId === facility.id &&
        primaryLocation.name === facility.name
      ) {
        facility.primaryLocation = true;
        selectedLocation = facility;
      } else if (
        primaryLocation.facilityId === facility.id &&
        facility.departments.length > 0
      ) {
        for (let index in facility.departments) {
          let department = facility.departments[index];
          if (department.name === primaryLocation.name) {
            facility.departments[index].primaryLocation = true;
            selectedLocation = facility;
          }
        }
      }
    }

    if (primaryLocation.name === "") {
      for (let facility of clonedFacilties) {
        if (facility.primaryLocation) {
          facility.primaryLocation = false;
          selectedLocation = facility;
        } else if (facility.departments.length > 0) {
          for (let index in facility.departments) {
            let department = facility.departments[index];
            if (department.primaryLocation) {
              facility.departments[index].primaryLocation = false;
              selectedLocation = facility;
            }
          }
        }
      }
    }

    if (!selectedLocation.id) {
      selectedLocation = clonedFacilties[0];
      selectedLocation.primaryLocation = false;
    }

    return await doSubmit(
      {
        goldenFacilityPk: selectedLocation.id,
        primaryLocation: selectedLocation.primaryLocation ? true : false,
        departments: selectedLocation.departments,
      },
      adminComment
    );
  };

  const reasonForm = useChangeReasonFormDialog({
    doSubmit: handleSubmit,
    formHookSubmit,
    withReason,
  });

  function getPrimaryLocationValue(facilities: Facility[]) {
    let selectedLocation: { name: string; facilityId: number | null } = {
      name: "",
      facilityId: null,
    };

    for (let facility of facilities) {
      if (facility.primaryLocation) {
        selectedLocation = { name: facility.name, facilityId: facility.id };
      } else if (facility.departments.length > 0) {
        for (let department of facility.departments) {
          if (department.primaryLocation) {
            selectedLocation = {
              name: department.name,
              facilityId: facility.id,
            };
          }
        }
      }
    }

    return selectedLocation;
  }

  const { id, fields, submit, reset, submitting } = useForm({
    id: "primary-location-form",
    fields: {
      primaryLocation: {
        id: "primaryLocation",
        name: "primaryLocation",
        value: getPrimaryLocationValue(facilities),
        required: true,
      },
    },
    onReset: doCancel,
    submit: reasonForm.performSubmit,
    onError: console.error,
    onSuccess,
  });

  function formHookSubmit() {
    return submit();
  }

  return (
    <div>
      <FormDialog
        id={id}
        open={open}
        title={title}
        onCancel={reset}
        onSubmit={reasonForm.handleSubmitButtonClick}
        maxWidth="sm"
        showProgressIndicator={submitting}
        {...otherProps}
      >
        <FormGroup>
          <FieldPrimaryLocationSelect
            {...fields.primaryLocation}
            facilities={facilities}
          />
        </FormGroup>
      </FormDialog>
      <ChangeReasonFormDialog {...reasonForm.reasonDialog} />
    </div>
  );
}
