import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  Link as MuiLink,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Link } from "wouter";
import { noop } from "utils/noop";
import { Delegate, DelegateStatusType, DelegateType } from "hooks/useDelegates";
import { formatUserName } from "utils/user";
import { StatusIcon } from "components/StatusIcon";
import { useAuth } from "hooks/useAuth";
import {
  TablePane,
  TableHeadRow,
  TableBodyRow,
  TableActionsCell,
  TableDeleteActionButton,
  TablePaneFooter,
  TablePaneButton,
} from "./Table";

interface Props {
  testId?: string;
  entries?: Delegate[];
  delegateType: DelegateType;
  maxEntries?: number;
  onCreateButtonClick?: Callback;
  onDeleteButtonClick?: Callback;
  "data-testid"?: string;
}

export function DelegatesTable({
  entries = [],
  "data-testid": testId = DelegatesTable.name,
  onCreateButtonClick = noop,
  onDeleteButtonClick = noop,
  delegateType,
}: Props) {
  const { isAdmin } = useAuth();
  function delegateName(delegateType: DelegateType, delegate: Delegate) {
    if (
      delegateType === "DELEGATES" &&
      delegate.hasProfile &&
      delegate.status === "ACTIVE"
    ) {
      return (
        <Link
          href={
            isAdmin
              ? `/admin/profile/${delegate.pk}`
              : `/delegates/profile/${delegate.pk}`
          }
        >
          <MuiLink sx={{ color: "primary.main", textDecoration: "none" }}>
            {formatUserName(delegate)}
          </MuiLink>
        </Link>
      );
    }
    return formatUserName(delegate);
  }

  return (
    <TablePane>
      <Table data-testid={testId}>
        <TableHead>
          <TableHeadRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {entries.map((entry) => (
            <TableBodyRow key={entry.email} data-testid={`${testId}BodyRow`}>
              <TableCell>{delegateName(delegateType, entry)}</TableCell>
              <TableCell>{entry.email}</TableCell>
              <TableCell>
                <StatusIcon status={entry.status as DelegateStatusType} />
              </TableCell>
              <TableActionsCell>
                <TableDeleteActionButton
                  onClick={() => onDeleteButtonClick(entry)}
                  data-testid={`${testId}DeleteEntryButton`}
                />
              </TableActionsCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
      <TablePaneFooter>
        <TablePaneButton
          icon={AddCircleIcon}
          label="Invite another Delegate"
          onClick={onCreateButtonClick}
          data-testid={`${testId}CreateEntryButton`}
        />
      </TablePaneFooter>
    </TablePane>
  );
}
