import React from "react";

import { Card } from "components/Card";
import { DelegateForm } from "./DelegateForm";
import { DelegatesTable } from "./DelegatesTable";
import { DeletionDialog } from "./DeletionDialog";
import { useEditableData } from "hooks/useEditableData";
import { useAuth } from "hooks/useAuth";

export function DelegatesCard(props: any) {
  const {
    submitting,
    delegates,
    deleteDelegate,
    inviteDelegate,
    onRequestError,
    onInvitationSuccess,
  } = props;

  const { details, creationForm, deletionDialog } = useEditableData({
    create: inviteDelegate,
    delete: deleteDelegate,
  });

  const { isAdmin } = useAuth();

  return (
    <Card
      id="delegates-card"
      heading="People you are a delegate for"
      subHeading="You have permission to view and make changes to the following profile owners information."
      data-testid={DelegatesCard.name}
      noExpandIcon
      expanded
    >
      <DelegatesTable
        delegateType="DELEGATES"
        entries={delegates}
        {...details}
      />

      {creationForm.open && (
        <DelegateForm
          title="Request permission to delegate for another person"
          data-testid="DelegateForm"
          submitting={submitting}
          onRequestSuccess={onInvitationSuccess}
          onRequestError={onRequestError}
          withReason={isAdmin}
          {...creationForm}
        />
      )}

      {deletionDialog.open && (
        <DeletionDialog withReason={isAdmin} {...deletionDialog} />
      )}
    </Card>
  );
}
