import React from "react";
import { TextField, TextFieldProps } from "@mui/material";

export type TextFieldWithWarningProps = TextFieldProps & {
  warn?: boolean;
};

export function TextFieldWithWarning({
  warn = false,
  ...otherProps
}: TextFieldWithWarningProps) {
  const warningProps = (
    warn
      ? {
          InputProps: {
            sx: {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "warning.main",
              },
            },
          },
          InputLabelProps: {
            sx: {
              color: "warning.main",
            },
          },
        }
      : {}
  ) as any;

  return <TextField {...otherProps} {...warningProps} />;
}
