import gql from "graphql-tag";
import { useAuth } from "hooks/useAuth";
import { useMachine } from "@xstate/react";
import { useApolloClient } from "@apollo/client";
import {
  StateValue,
  createMachine,
  assign,
  spawn,
  send,
} from "xstate";
import {
  BasicDetailsFormMachineService,
  BasicDetailsMachine,
} from "hooks/useBasicDetailForm";
import {
  PrimaryFacilityFormMachine,
  PrimaryFacilityFormMachineService,
} from "hooks/usePrimaryFacilityForm";
import {
  AccountDetailsFormMachine,
  AccountDetailsFormMachineService,
} from "hooks/useAccountDetailsForm";
import { produce } from "immer";
import { useConfig } from "providers/Config";
import { isEmpty, pick } from "rambda";

type TCreateProfileVariables = {
  email: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  password: string;
  isInvestigator: boolean | string;
  specialty: string;
  goldenFacilityPk?: number;
  customFacilityName?: string;
  customFacilityAddress?: string;
  customFacilityPostalCode?: string;
  facilityCountryCode: string | null;
  facilityState?: string | null;
  domain: string;
};

type TCreateProfileResponse = {
  data?: {
    checkUserAccount: ExistingData;
  };
};

type TRequestError = {
  message: string;
};

const CheckUserMutation = gql`
  mutation CheckUserAccount(
    $countryCode: CountryCode!
    $email: String_max_100!
    $firstName: String_max_50!
    $lastName: String_max_50!
  ) {
    checkUserAccount(
      countryCode: $countryCode
      email: $email
      firstName: $firstName
      lastName: $lastName
    ) {
      hasProfile
      hasSso
      optInStatus
      existingEmail
    }
  }
`;

const RegistrationProgress = gql`
  mutation RegistrationProgress(
    $email: String_max_100!
    $data: RegistrationFormData!
  ) {
    registrationProgress(
      email: $email
      data: $data
    ) {
      status
    }
  }
`;

const CreateProfileMutation = gql`
  mutation createProfile(
    $email: String_max_100!
    $password: String
    $countryCode: CountryCode!
    $firstName: String_max_50!
    $lastName: String_max_50!
    $specialty: String
    $isInvestigator: Boolean!
    $goldenFacilityPk: Int
    $customFacilityAddress: String
    $customFacilityName: String
    $customFacilityPostalCode: String
    $facilityCountryCode: CountryCode
    $facilityState: String
    $domain: String
  ) {
    createProfile(
      password: $password
      email: $email
      firstName: $firstName
      lastName: $lastName
      isInvestigator: $isInvestigator
      specialty: $specialty
      goldenFacilityPk: $goldenFacilityPk
      countryCode: $countryCode
      customFacilityAddress: $customFacilityAddress
      customFacilityName: $customFacilityName
      customFacilityPostalCode: $customFacilityPostalCode
      facilityCountryCode: $facilityCountryCode
      facilityState: $facilityState
      domain: $domain
    ) {
      status
      email
      goldenPersonPk
    }
  }
`;

export type RegistrationDetails = {
  subspecialty: string;
  message: string | undefined;
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
  password: string;
  isInvestigator: boolean;
  specialty: string;
  primaryFacility: PrimaryFacility;
};

type Facility = {
  id: number;
  name: string;
  countryCode: string;
  city: string;
  region: string;
  zipCode: string;
  street: string;
  departments: string | null;
};

type PrimaryFacility = {
  countryCode: string;
  state?: string;
  facility?: Facility;
  customFacility?: string;
  customFacilityAddress?: string;
  customFacilityPostalCode?: string;
};

export type ExistingData = {
  hasProfile: boolean;
  hasSso: boolean;
  optInStatus: string;
  existingEmail: string | null;
};

type LoggedInUser = {
  email: string;
  first_name: string;
  last_name: string;
  sub: string;
  username: string;
};

interface RegistrationContext {
  registrationDetails: RegistrationDetails;
  existingData: ExistingData;
  basicDetailsFormRef: BasicDetailsFormMachineService;
  primaryFacilityFormRef: PrimaryFacilityFormMachineService;
  loginDetailsFormRef: AccountDetailsFormMachineService;
  error: string | undefined;
  customFacilityEnabled: boolean;
  loggedInUser: LoggedInUser;
}

type RegistrationEvent =
  | { type: "NEXT" }
  | {
      type: "VALIDATED";
      data: RegistrationDetails;
    }
  | { type: "BACK" }
  | { type: "REGISTER" }
  | {
      type: "done.invoke.checkUserInfo";
      data: TCreateProfileResponse;
    }
  | {
      type: "error.platform.checkUserInfo" | "error.platform.(machine).creatingProfile:invocation[0]";
      data: TRequestError;
    }
  | {
      type: "xstate.init";
    };


type CheckUserInfoService = {
  data: {
     data?: {
       checkUserAccount?: ExistingData
     }
   }
}
type CreateProfileService = {
  data: {
    createProfile?: ExistingData
  }
}
type RegistrationService = {
  checkUserInfo: CheckUserInfoService
  createProfile: CreateProfileService
}

export const registrationMachine = createMachine(
  {
    initial: "initialize",
    tsTypes: {} as import("./useRegistration.typegen").Typegen0,
    schema: {
      context: {} as RegistrationContext,
      events: {} as RegistrationEvent,
      services: {} as RegistrationService
    },
    states: {
      initialize: {
        entry: ["initializeServices"],
        always: "step1",
      },
      step1: {
        entry: "clearError",
        on: {
          NEXT: {
            actions: "validateBasicDetailsForm",
          },
          VALIDATED: {
            target: "step2",
            actions: ["setBasicDetails", "sendProgress", ],
          },
        },
      },
      step2: {
        on: {
          NEXT: {
            actions: "validatePrimaryFacilityForm",
          },
          BACK: { target: "step1" },
          VALIDATED: {
            target: "checkingUserInfo",
            actions: ["setPrimaryFacility", "sendProgress", ],
          },
        },
      },
      checkingUserInfo: {
        entry: "clearError",
        invoke: {
          id: "checkUserInfo",
          src: "checkUserInfo",
          onDone: [
            {
              target: "profileFound",
              actions: "setExistingData",
              cond: (_context, { data }) =>
                data.data.checkUserAccount.hasProfile,
            },
            { target: "step3", actions: "setExistingData" },
          ],
          onError: { target: "step2", actions: "setError" },
        },
      },
      profileFound: {
        type: "final",
      },
      registrationProfileFound: {
        type: "final",
      },
      step3: {
        //@ts-ignore
        on: {
          //TODO: Requires fix for typings, may be resolved in newer releases.
          NEXT: {
            actions: send(
              (context: RegistrationContext) => ({
                hasSso: context.existingData?.hasSso,
                type: "VALIDATE",
              }),
              {
                to: "loginDetailsForm",
              }
            ),
          },
          BACK: { target: "step2"},
          VALIDATED: {
            target: "creatingProfile",
            actions: ["setAccountDetails", "sendProgress", ],
          },
        },
      },
      creatingProfile: {
        entry: "clearError",
        invoke: {
          src: "createProfile",
          onDone: [
            {
              target: "registrationProfileFound",
              actions: "setExistingProfile",
              cond: (_context, result) => {
                return result.data?.data?.createProfile?.status === false;
              }
            },
            { target: "step3", actions: "profileCreated" }],
          onError: { target: "step3", actions: "setError"},
        },
      },
      profileCreated: {
        type: "final",
      },
    },
  },
  {
    actions: {
      initializeServices: assign((context) => {
        return produce(context, (newContext) => {
          if (context.loggedInUser) {
            newContext.basicDetailsFormRef = spawn(
              BasicDetailsMachine.withContext({
                ...BasicDetailsMachine.context,
                blockUserDetails: true,
                email: {
                  ...BasicDetailsMachine.context.email,
                  value: context.loggedInUser?.email,
                },
                firstName: {
                  ...BasicDetailsMachine.context.firstName,
                  value: context.loggedInUser?.first_name,
                },
                lastName: {
                  ...BasicDetailsMachine.context.lastName,
                  value: context.loggedInUser?.last_name,
                },
              }),
              "basicDetailsForm"
            );
          }
          newContext.basicDetailsFormRef = spawn(BasicDetailsMachine, "basicDetailsForm");
          newContext.primaryFacilityFormRef = spawn(
              PrimaryFacilityFormMachine.withContext({
                ...PrimaryFacilityFormMachine.context,
                customFacilityEnabled: context.customFacilityEnabled,
              }),
              "primaryFacilityForm"
          )
          newContext.loginDetailsFormRef = spawn(AccountDetailsFormMachine, "loginDetailsForm")
        })}),
      validateBasicDetailsForm: send(
        { type: "VALIDATE" },
        { to: "basicDetailsForm" }
      ),
      validatePrimaryFacilityForm: send(
        { type: "VALIDATE" },
        { to: "primaryFacilityForm" }
      ),
      setBasicDetails: assign((context, event) => {
        if (event.type !== "VALIDATED") return context;
        return produce(context, (newContext) => {
          newContext.registrationDetails = event.data;
        });
      }),
      setExistingData: assign((context, event) => {
        if (event.type !== "done.invoke.checkUserInfo"
          && event.type !== "error.platform.createProfile") return context;
        return produce(context, (newContext) => {
          if(event.data?.data?.checkUserAccount){
            newContext.existingData = event.data?.data?.checkUserAccount;
          }
        });
      }),
      setExistingProfile: assign((context, event) => {
        if (event.type !== "done.invoke.(machine).creatingProfile:invocation[0]" &&
        event.type !== "error.platform.createProfile"
        ) return context;
        return produce(context, (newContext) => {
          if(newContext.existingData){
            newContext.existingData.hasProfile = true;
            newContext.existingData.hasSso = true;
            newContext.existingData.existingEmail = event.data.createProfile?.existingEmail ?? null;
          }
        });
      }),
      setPrimaryFacility: assign((context, event) => {
        if (event.type !== "VALIDATED") return context;
        return produce(context, (newContext) => {
          newContext.registrationDetails.primaryFacility = event.data;
        });
      }),
      setAccountDetails: assign((context, event) => {
        if (event.type !== "VALIDATED") return context;
        return produce(context, (newContext) => {
          newContext["registrationDetails"].isInvestigator = event.data.isInvestigator;
          newContext["registrationDetails"].specialty =
            event.data.subspecialty || event.data.specialty;
          newContext["registrationDetails"].password = event.data.password;
        });
      }),
      setError: assign((context, event) => {
        if (event.type !== "error.platform.checkUserInfo"
          && event.type !== "error.platform.(machine).creatingProfile:invocation[0]" ) return context;
        return produce(context, (newContext) => {
          newContext["error"] = event.data.message;
        });
      }),
      clearError: assign((_context, _event) => ({
        error: undefined,
      })),
    },
  }
);

type TRegistrationHook = {
  data: RegistrationContext;
  error: string | undefined;
  currentStep: number;
  hasProfile: boolean;
  hasSso: boolean;
  customFacilityEnabled: boolean;
  basicDetailsFormRef: BasicDetailsFormMachineService;
  primaryFacilityFormRef: PrimaryFacilityFormMachineService;
  loginDetailsFormRef: AccountDetailsFormMachineService;
  loading: boolean;
  blocked: boolean;
  next(): void;
  back(): void;
};

export function useRegistration(): TRegistrationHook {
  const auth = useAuth();
  const { config } = useConfig();
  const client = useApolloClient();
  const [state, send] = useMachine(registrationMachine, {
    context: {
      ...registrationMachine.context,
      customFacilityEnabled: config.customFacilityEnabled,
      loggedInUser: isEmpty(auth.ssoUser) ? null : auth.ssoUser,
    },
    actions: {
      profileCreated(context) {
        const urlRedirection = !context.registrationDetails.isInvestigator
          ? window.location.search
            ? new URL(window.location.href).searchParams.get("redirect")
            : "/"
          : `/optin${window.location.search}`;

        auth.login && auth.login(urlRedirection);
      },
      sendProgress(context, event) { //TODO: remove this provisional action once investigation is finished
        if(event.type !== "VALIDATED") return context;
        const nonEmptyValues = Object.entries(event.data).filter(([_key, value]) => value !== "");
        client.mutate({
          mutation: RegistrationProgress,
          variables: {
            email: context.registrationDetails.email,
            data: {
              ...pick([
                "firstName", "lastName", "email", "countryCode",
                "password", "isInvestigator", "specialty", "facilityCountryCode",
                "facilityState", "goldenFacilityPk", "facility",
              ], Object.fromEntries(nonEmptyValues)),
            },
          }
        }).catch(_error => {
          console.info("Error on tracking step");
        })
      }
    },
    services: {
      checkUserInfo: (context, _event) => {
        type TVariables = {
          email: string
          firstName: string
          lastName: string
          countryCode: string
          goldenFacilityPk?: number
        }
        const variables: TVariables = {
          email: context.registrationDetails.email,
          firstName: context.registrationDetails.firstName,
          lastName: context.registrationDetails.lastName,
          countryCode: context.registrationDetails.countryCode,
        };
        if (context.registrationDetails.primaryFacility.facility) {
          variables.goldenFacilityPk =
            context.registrationDetails.primaryFacility.facility.id;
        }
        return client
          .mutate({
            mutation: CheckUserMutation,
            variables,
          })
          .then((result) =>
            result.errors
              ? Promise.reject(result.errors)
              : Promise.resolve(result)
          );
      },
      //@ts-ignore
      createProfile: async (context, _event) => {
        let variables: TCreateProfileVariables = {
          email: context.registrationDetails.email,
          firstName: context.registrationDetails.firstName,
          lastName: context.registrationDetails.lastName,
          countryCode: context.registrationDetails.countryCode,
          password: context.registrationDetails.password,
          isInvestigator: context.registrationDetails.isInvestigator,
          specialty: context.registrationDetails.specialty,
          facilityCountryCode:
            context.registrationDetails.primaryFacility.countryCode || null,
          facilityState: context.registrationDetails.primaryFacility.state || null,
          domain: config.theme?.sponsor || "Drugdev",
        };
        if (context.registrationDetails.primaryFacility.facility) {
          variables.goldenFacilityPk =
            context.registrationDetails.primaryFacility.facility.id;
        } else {
          variables.customFacilityName =
            context.registrationDetails.primaryFacility.customFacility;
          variables.customFacilityAddress =
            context.registrationDetails.primaryFacility.customFacilityAddress;
          variables.customFacilityPostalCode =
            context.registrationDetails.primaryFacility.customFacilityPostalCode;
        }

        return client.mutate({
          mutation: CreateProfileMutation,
          variables,
        }).then((result) => {
          return Promise.resolve(result)
        }).catch((_error) => {
            return Promise.reject({ message: "Something went wrong. If the issue persists please contact us"});
        })
      },
    },
  });

  function getCurrentStep(value: StateValue): number {
    switch (value) {
      case "step1":
        return 0;
      case "step2":
      case "profileFound":
      case "checkingUserInfo":
        return 1;
      case "step3":
      case "creatingProfile":
      case "registrationProfileFound":
        return 2;
      default:
        return 0;
    }
  }

  return {
    data: state.context,
    error: state.context.error,
    currentStep: getCurrentStep(state.value),
    hasProfile: state.matches("profileFound"),
    hasSso: Boolean(state.context?.existingData?.hasSso),
    customFacilityEnabled: state.context.customFacilityEnabled,
    basicDetailsFormRef: state.context.basicDetailsFormRef,
    primaryFacilityFormRef: state.context.primaryFacilityFormRef,
    loginDetailsFormRef: state.context.loginDetailsFormRef,
    loading: ["checkingUserInfo", "creatingProfile"].some(state.matches),
    blocked: ["profileFound", "registrationProfileFound"].some(state.matches),
    next() {
      send("NEXT");
    },
    back() {
      send("BACK");
    },
  };
}
