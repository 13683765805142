import React from "react";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import StarIcon from "@mui/icons-material/Star";

import { Facility } from "types/profile";
import {
  isPrimaryLocationSelected,
  isNonePrimaryLocationSelected,
} from "utils/facilities";
import { styled } from "@mui/material/styles";
import { FacilityNameCell } from "./FacilityNameCell";
import { FacilityDepartmentTable } from "./FacilityDepartmentTable";

const PREFIX = "FacilityList";

const classes = {
  root: `${PREFIX}-root`,
  actions: `${PREFIX}-actions`,
  primaryLocationButtonWrapper: `${PREFIX}-primaryLocationButtonWrapper`,
  primaryLocationButton: `${PREFIX}-primaryLocationButton`,
  starIcon: `${PREFIX}-starIcon`,
};

const Root = styled("div")<{ entries: Facility[] }>(({ theme, entries }) => {
  return {
    [`&.${classes.root}`]: {
      "& > *:not(:first-child)": {
        marginTop: theme.spacing(6),
      },
    },
    [`& .${classes.actions}`]: {
      display: "grid",
      gridTemplateColumns: "auto auto",
      justifyContent: "flex-end",
    },
    [`& .${classes.primaryLocationButtonWrapper}`]: {
      display: "grid",
      gridTemplateColumns: "auto auto",
      alignItems: "center",
      marginRight: theme.spacing(1),
    },
    [`& .${classes.primaryLocationButton}`]: {
      border: `${
        !isPrimaryLocationSelected(entries) &&
        isNonePrimaryLocationSelected(entries)
          ? `2px solid ${theme.palette.warning.main}`
          : "none"
      }`,
    },
    [`& .${classes.starIcon}`]: {
      fill: theme.palette.success.main,
      marginRight: theme.spacing(0.5),
    },
  };
});

interface Props {
  userPk?: number;
  testId?: string;
  entries: Facility[];
  userEntries: Facility[];
  onCreateButtonClick: Callback;
  onUpdateButtonClick: Callback;
  onDeleteButtonClick: Callback;
  onCreateDepartmentButtonClick: Callback;
  onUpdateDepartmentButtonClick: Callback;
  onDeleteDepartmentButtonClick: Callback;
  onCreatePrimaryLocationButtonClick: Callback;
  [key: string]: any;
}

export function FacilityList({
  userPk,
  entries,
  userEntries,
  onCreateButtonClick,
  onUpdateButtonClick,
  onDeleteButtonClick,
  onCreateDepartmentButtonClick,
  onUpdateDepartmentButtonClick,
  onDeleteDepartmentButtonClick,
  onCreatePrimaryLocationButtonClick,
  ...otherProps
}: Props) {
  return (
    <Root
      role="list"
      aria-label="Facilities list"
      className={classes.root}
      entries={entries}
      {...otherProps}
    >
      {entries.map((entry) => {
        const facility = userEntries.find((e) => e.id === entry.id);
        return (
          <div role="listitem" key={entry.id}>
            <FacilityNameCell
              userPk={userPk}
              entry={entry}
              userEntry={facility}
              onUpdateButtonClick={onUpdateButtonClick}
              onDeleteButtonClick={onDeleteButtonClick}
              role="heading"
              aria-level={2}
            />

            <Divider />

            <FacilityDepartmentTable
              userPk={userPk}
              entries={entry.departments}
              userEntries={facility ? facility.departments : []}
              facility={entry}
              showTable={!!entry.departments.length}
              onCreateButtonClick={onCreateDepartmentButtonClick}
              onUpdateButtonClick={onUpdateDepartmentButtonClick}
              onDeleteButtonClick={onDeleteDepartmentButtonClick}
            />
          </div>
        );
      })}
      <div className={classes.actions}>
        {entries.length ? (
          <div className={classes.primaryLocationButtonWrapper}>
            <StarIcon className={classes.starIcon} />
            <Button
              className={classes.primaryLocationButton}
              onClick={onCreatePrimaryLocationButtonClick}
            >
              {isPrimaryLocationSelected(entries)
                ? `Change Primary Location`
                : `Select Primary Location`}
            </Button>
          </div>
        ) : null}
        <Button
          variant="contained"
          onClick={onCreateButtonClick}
          data-testid="AddFacilityButton"
        >
          {entries.length === 0 ? "Add a Facility" : "Add Another Facility"}
        </Button>
      </div>
    </Root>
  );
}
