import React, { useState, ReactElement, ReactNode } from "react";
import { Facility } from "types/profile";
import { Box, Paper, styled, Typography } from "@mui/material";
import { useManuallyGetFacilities } from "hooks/useTypeaheadQueries";

import {
  AsyncAutocompleteField,
  AsyncAutocompleteFieldProps,
} from "./AsyncAutocompleteField";

const SubmitNewFacilityItem = styled(Typography)(({ theme }) => ({
  padding: `0 ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`,
  color: theme.palette.grey[800],
}));

const RequestFacility = styled("span")(({ theme }) => ({
  textDecoration: "underline",
  color: theme.palette.primary.main,
  cursor: "pointer",
}));

export interface FacilityAutocompleteFieldProps
  extends Omit<
    AsyncAutocompleteFieldProps,
    "fetchOptions" | "additionalQueryArgs"
  > {
  countryCode: string;
  region?: string;
  submitUnknownFacility?: boolean;
  handleSelectUnknownValue?: () => void;
  onChange?: (value: string) => void;
}

export interface UnknownFacilityComponentProps extends ReactElement {
  event: any;
  handleClick: () => void;
}

export function FacilityAutocompleteField({
  name = "facilityName",
  label = "Institution",
  onQueryError = console.error,
  getOptionLabel = (option: any) => option.name || "",
  getOptionValue = (option: any) => option,
  countryCode,
  region,
  submitUnknownFacility,
  handleSelectUnknownValue,
  handleOnChange,
  customFacilityEnabled = true, // for customFacilityEnabled
  ...otherProps
}: FacilityAutocompleteFieldProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getFacilities = useManuallyGetFacilities();

  function fetchOptions(q: string) {
    return getFacilities(q, countryCode, region);
  }

  const unknownFacilityOptions = submitUnknownFacility
    ? {
        open: isOpen,
        onOpen: () => setIsOpen(true),
        onClose: () => setIsOpen(false),
      }
    : {};

  // Replaces the the standard Paper component with a custom component that allows us to add additional elements within the Paper component
  const AddUnknownFacility = (event: any): ReactNode => {
    let availableChildren = event?.children?.filter(
      (item: any) => item !== null
    );
    if (
      availableChildren &&
      availableChildren[0].props?.children === "No options" &&
      handleSelectUnknownValue
    ) {
      return (
        <Paper
          onMouseDown={(event) => event.preventDefault()}
          onClick={() => setIsOpen(false)}
        >
          {availableChildren}
          <SubmitNewFacilityItem variant="subtitle1">
            Can't find the Facility you're looking for?{" "}
            {customFacilityEnabled ? (
              <RequestFacility onClick={handleSelectUnknownValue}>
                Enter a facility name
              </RequestFacility>
            ) : (
              "You will be able to add it after you register."
            )}
          </SubmitNewFacilityItem>
        </Paper>
      );
    }
    return <Paper>{availableChildren}</Paper>;
  };

  return (
    <AsyncAutocompleteField
      name={name}
      label={label}
      fetchOptions={fetchOptions}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      AutocompleteProps={{
        ...unknownFacilityOptions,
        renderOption: (props: any, facility: Facility) => {
          return (
            <li {...props}>
              <OptionRenderer facility={facility} />
            </li>
          );
        },
      }}
      PaperComponent={AddUnknownFacility}
      onChange={handleOnChange}
      {...otherProps}
    />
  );
}

interface OptionRendererProps {
  facility: Facility;
}

function OptionRenderer({ facility }: OptionRendererProps) {
  return (
    <Box
      sx={{
        pt: 0.2,
        pb: 0.2,
      }}
    >
      <div>{facility.name}</div>
      <Box
        sx={{
          opacity: 0.6,
          fontSize: 14,
        }}
      >
        {getFacilityAddressAsString(facility)}
      </Box>
    </Box>
  );
}

function getFacilityAddressAsString(facility: Facility) {
  return [
    facility.street,
    facility.countryCode,
    facility.region,
    facility.zipCode,
    facility.countryCode,
  ]
    .filter((f) => !!f)
    .join(", ");
}
