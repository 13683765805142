import React from "react";
import {AppBar as Bar, Container} from '@mui/material';
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "wouter";

import { useAuth } from "../hooks/useAuth";
import { ProfileDropDown, User } from "components/ProfileDropDownMenu";
import { ROLES } from "routes";

export interface MenuLink {
  link: string
  title:string
  key?: number
  blank?: boolean
  selected?: boolean
  ariaLabel?: string
}

const NavListItem = styled("li")(() => ({
  display: "flex",
  "& > .active": {
    textDecoration: "none",
    color: "#ffffff",
    background: "rgba(255, 255, 255, 0.25)",
  },
}));

const NavListItemLink = styled(Link)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 18px 2px 18px",
  fontFamily: '"Proxima Nova", "Noto Sans", Helvetica, Arial, sans-serif',
  fontWeight: "normal",
  fontSize: 16,
  color: "#ffffff",
  textDecoration: "none",
  "&:active, &:visited": {
    color: "#ffffff",
  },
  "&:hover": {
    textDecoration: "none",
    color: "#ffffff",
    background: "rgba(255, 255, 255, 0.25)",
  },
}));


function NavItem({item, pathname}: {
  item: MenuLink,
  pathname?: string
}) {
  return (
    <NavListItem>
      <NavListItemLink aria-label={item?.ariaLabel} className={item.selected ? 'active' : ''} to={item.link}>
        {item.title}
      </NavListItemLink>
     </NavListItem>
  );
}

const NavBar = styled(Bar)(() => ({
  height: 55,
  zIndex: 2,
  background: "linear-gradient(to right, rgb(37, 0, 86), rgb(0, 118, 174))",
}));

const NavBarContainer = styled(Container)(() => ({
  display: "flex",
  height: '100%',
  justifyContent: "space-between",
  paddingLeft: 24,
  paddingRight: 0,
  alignItems: "center",
  width: '100%',
  color: "#ffffff",
}));

const NavContentArea = styled('div')(() => ({
  display: "flex",
  alignItems: "center",
  height: "100%",
  color: "#ffffff",
}));

const LogoContainer = styled('div')(() => ({
  paddingRight: 36,
  marginRight: 18,

  "&.nav-divide": {
    borderRight: "1px solid #ffffff",
  }
}));

const Logo = styled('div')(() => ({
  position: "relative",
  fontFamily: '"Proxima Nova", "Noto Sans", Helvetica, Arial, sans-serif',
  fontWeight: 500,
  fontSize: 16,
  color: "white",
  margin: 0,
  zIndex: 2,
  whiteSpace: "nowrap",

  "&:hover, &:active, &:visited, &:focus": {
    textDecoration: "none",
    color: "#ffffff",
  },

  "& .supporting-text": {
    fontWeight: 700,
    marginLeft: 12,
  }
}));

const NavList = styled('ul')(() => ({
  display: "flex",
  height: "100%",
  listStyle: "none",
  padding: 0,
  margin: 0,
}))


export function AppBar({showNav, menuItems}: {
  showNav: boolean,
  menuItems?: MenuLink[]
}) {
  let [_location] = useLocation();
  let { logout, isLoggedIn, ssoUser, user } = useAuth();
  const loggedInUser: User = user?.role === ROLES.ADMIN ? { firstName: ssoUser?.first_name, lastName: ssoUser?.last_name, email: ssoUser?.email, role: user?.role} : user
  return (
    <NavBar position="fixed" elevation={0}>
      <NavBarContainer maxWidth={false} disableGutters={true}>
        <NavContentArea>
          <LogoContainer className={showNav ? "nav-divide" : ''}>
              <Logo>
                IQVIA <span className="supporting-text">Profiles</span>
              </Logo>
          </LogoContainer>
            {showNav && <NavList>
              {(menuItems && menuItems.length) && menuItems.map((item, key) => <NavItem item={item} key={key} pathname={_location} />)}
            </NavList>
            }
        </NavContentArea>
        <NavContentArea>
          {isLoggedIn && <ProfileDropDown handleLogOut={logout} pathname={_location} user={loggedInUser}/>}
          </NavContentArea>
      </NavBarContainer>
    </NavBar>
  );
}
