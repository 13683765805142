import React from "react";
import FormGroup from "@mui/material/FormGroup";

import { useForm, Field } from "hooks/useForm";
import { useEffect } from "react";
import { FormField } from "./FormField";
import { styled } from "@mui/material/styles";
import { ChipListInput } from "./ChipListInput";
import { asyncNoop, noop } from "utils/noop";
import { FormCheckboxField } from "./FormCheckboxField";
import { FacilityDiagnostics } from "types/facility";

const defaultValue = {} as FacilityDiagnostics;

const CheckboxesWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  marginBottom: theme.spacing(3),
}));

const FormGroupStyled = styled(FormGroup)(({ theme }) => ({
  flex: 1,
}));

interface EquipmentDiagnosticsFieldProps {
  form: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  onChange: Callback;
  value?: FacilityDiagnostics;
  [key: string]: any;
}

export function EquipmentDiagnosticsField({
  form,
  label = "Which of the following diagnostic equipment is available for use in clinical research?",
  error = false,
  value = defaultValue,
  helperText,
  onChange,
  ...otherProps
}: EquipmentDiagnosticsFieldProps) {
  const { fields, setField } = useForm({
    id: form,
    fields: {
      ct: {
        type: "checkbox",
        name: "ct",
        label: "CT",
        value: !!value.ct,
      },
      dxa: {
        type: "checkbox",
        name: "dxa",
        label: "DXA",
        value: !!value.dxa,
      },
      echo: {
        type: "checkbox",
        name: "echo",
        label: "ECHO",
        value: !!value.echo,
      },
      ecg: {
        type: "checkbox",
        name: "ecg",
        label: "ECG/EKG",
        value: !!value.ecg,
      },
      flro: {
        type: "checkbox",
        name: "flro",
        label: "Fluoroscopy",
        value: !!value.flro,
      },
      mra: {
        type: "checkbox",
        name: "mra",
        label: "Magnetic Resonance Angiography (MRA)",
        value: !!value.mra,
      },
      mri: {
        type: "checkbox",
        name: "mri",
        label: "Magnetic Resonance Imaging (MRI)",
        value: !!value.mri,
      },
      mrs: {
        type: "checkbox",
        name: "mrs",
        label: "Magnetic Resonance Spectroscopy (MRS)",
        value: !!value.mrs,
      },
      mammo: {
        type: "checkbox",
        name: "mammo",
        label: "Mammography",
        value: !!value.mammo,
      },
      nmed: {
        type: "checkbox",
        name: "nmed",
        label: "Nuclear Medicine",
        value: !!value.nmed,
      },
      pet: {
        type: "checkbox",
        name: "pet",
        label: "PET",
        value: !!value.pet,
      },
      spirometry: {
        type: "checkbox",
        name: "spirometry",
        label: "Spirometry",
        value: !!value.spirometry,
      },
      ultrasound: {
        type: "checkbox",
        name: "ultrasound",
        label: "Ultrasound",
        value: !!value.ultrasound,
      },
      xray: {
        type: "checkbox",
        name: "xray",
        label: "X-Ray",
        value: !!value.xray,
      },
      other: {
        name: "other",
        label: "Other diagnostic equipment",
        value: value.other,
        clearOnBlur: true,
      },
    },
    submit: asyncNoop,
    onError: noop,
    onChange: (fields: any) => {
      const other = (Array.isArray(fields.other.value)
        ? fields.other.value
        : fields.other.value.split(",").map((val: string) => val.trim())
      ).filter((item: string) => item !== "");

      onChange({
        ct: fields.ct.value,
        dxa: fields.dxa.value,
        echo: fields.echo.value,
        ecg: fields.ecg.value,
        flro: fields.flro.value,
        mra: fields.mra.value,
        mri: fields.mri.value,
        mrs: fields.mrs.value,
        mammo: fields.mammo.value,
        nmed: fields.nmed.value,
        pet: fields.pet.value,
        spirometry: fields.spirometry.value,
        ultrasound: fields.ultrasound.value,
        xray: fields.xray.value,
        other,
      });
    },
  });

  useEffect(() => {
    for (const name of Object.keys(value)) {
      if (name === "other") {
        setField(name, value[name] || "");
      } else {
        //@ts-ignore
        setField(name, !!value[name]);
      }
    }
  }, [setField, JSON.stringify(value)]); // eslint-disable-line

  return (
    <FormField
      error={error}
      label={label}
      helperText={helperText}
      helperTextId="equipment-diagnostics"
      {...otherProps}
    >
      <CheckboxesWrapper>
        <FormGroupStyled>
          <CheckboxField {...fields.ct} />
          <CheckboxField {...fields.dxa} />
          <CheckboxField {...fields.echo} />
          <CheckboxField {...fields.ecg} />
          <CheckboxField {...fields.flro} />
          <CheckboxField {...fields.mra} />
          <CheckboxField {...fields.mri} />
        </FormGroupStyled>

        <FormGroupStyled>
          <CheckboxField {...fields.mrs} />
          <CheckboxField {...fields.mammo} />
          <CheckboxField {...fields.nmed} />
          <CheckboxField {...fields.pet} />
          <CheckboxField {...fields.spirometry} />
          <CheckboxField {...fields.ultrasound} />
          <CheckboxField {...fields.xray} />
        </FormGroupStyled>
      </CheckboxesWrapper>

      <ChipListInput {...fields.other} error={error} />
    </FormField>
  );
}

function CheckboxField(props: Field) {
  return (
    <FormCheckboxField
      sx={{
        root: { mt: 1 },
        checkbox: { pr: 1 },
      }}
      {...props}
    />
  );
}
