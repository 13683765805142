import React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const YEAR = new Date().getFullYear();

const FooterContainer = styled("footer")(() => ({
  display: 'flex',
  padding: '11px 36px 11px 24px',
  background: '#F8F9FB',
  justifyContent: "center",
  width: '100%',
  marginTop: 40,
  fontFamily: '"Proxima Nova", "Noto Sans", Helvetica, Arial, sans-serif',
  fontWeight: "normal",
  fontSize: 14,
  borderTop: '1px solid #F2F2F2'
}));

const LogoSvg = styled("img")(() => ({
  display: 'flex',
  alignItems: 'center',
  width: 100,
  height: 38,
  marginRight: 12
}));

const FooterInner = styled("div")(() => ({
  display: 'flex',
  justifyContent: "space-between",
  width: '100%',
}));

const LeftContent = styled("div")(() => ({
  display: 'flex',
  alignItems: "center"
}))

const RightContent = styled("div")(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}))

const List = styled("ul")(() => ({
  display: "flex",
  listStyle: "none",
  margin: 0,
  padding: 0,
  color: '#0768FD',

  "& li" :{
    margin: '0 12px',
  },

  "& a": {
    textDecoration: "none",
    color: "inherit",
  },
  "& a:hover": {
    textDecoration: "underline",
  },
}));

const Copyright = styled("p")(() => ({
  margin: 0,
  color: '#595959'
}))

const IconLink = styled("a")(() => ({
  display: 'inline-flex',
  alignItems: 'center',

  '& img': {
    width: 30,
    height: 14,
    marginLeft: 5
  }
}))

export function Footer() {
  return (
    <FooterContainer>
      <FooterInner>
        <LeftContent>
          <div>
            <LogoSvg src="/logo.svg" alt="IQVIA logo" />
          </div>
          <List>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://static.clintech.iqvia.com/terms/"
              >
                <Typography variant="body2">Terms of Use</Typography>
              </a>
            </li>
             <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://static.clintech.iqvia.com/privacy/"
              >
                <Typography variant="body2">Privacy</Typography>
              </a>
            </li>
            <li>
              <IconLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.iqvia.com/about-us/privacy/ccpa/do-not-sell"
              >
                <Typography variant="body2">Your Privacy Choices</Typography>
                <img src="/privacy-choices-icon.svg" alt="Your Privacy Choices logo" />
              </IconLink>
            </li>
          </List>
        </LeftContent>
        <RightContent>
          <Copyright>
            &copy; {YEAR} IQVIA Inc
          </Copyright>
        </RightContent>
      </FooterInner>
    </FooterContainer>
  );
}
