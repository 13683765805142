import React from "react";

import { Card } from "components/Card";
import { values } from "rambda";
import { DepartmentEquipmentForm } from "./DepartmentEquipmentForm";
import { FacilityEquipment, FacilityTreatment } from "types/facility";
import { CardMachine, ManagedCardProps, useCard } from "hooks/useCard";

import {
  Profile,
  DepartmentProfileHookData,
} from "hooks/useFacilityDepartmentProfile";

interface DepartmentEquipmentCardProps extends ManagedCardProps {
  profile: Profile;
  departmentPk?: number;
  updateEquipmentDetails: (equipment: FacilityEquipment) => Promise<any>;
}

export const DepartmentEquipmentCardMachine = CardMachine({ validateCard });

export function DepartmentEquipmentCard({
  id = "equipment-card",
  service,
  profile,
  departmentPk = 0,
  updateEquipmentDetails,
  ...otherProps
}: DepartmentEquipmentCardProps) {
  const { card } = useCard({ service });
  return (
    <Card
      id={id}
      heading={formatCardHeading(profile)}
      {...card}
      {...otherProps}
    >
      <DepartmentEquipmentForm
        entry={profile.equipment}
        submit={updateEquipmentDetails}
        forFacility={!departmentPk}
      />
    </Card>
  );
}

function formatCardHeading(profile: Profile) {
  if (profile.departmentDetails) {
    return `Equipment Details: ${profile.departmentDetails.departmentName} - ${profile.facilityDetails.name}`;
  } else {
    return `Equipment Details: ${profile.facilityDetails.name}`;
  }
}

function validateCard(data?: DepartmentProfileHookData) {
  const equipment = data?.profile.equipment;
  const freezers = {
    ...Object(equipment?.equipmentDetails.freezers || {}),
  };
  const treatment = { ...(equipment?.treatment || ({} as FacilityTreatment)) };

  if (treatment.pkPdCapability === false) {
    delete treatment.pkPdOutsideHours;
  }

  const isComplete = Boolean(
    equipment &&
      values(equipment?.diagnostics).some(isTruthyBoolOrArray) &&
      values(freezers).some(isBoolOrArray) &&
      values(treatment).some(isBoolOrArray)
  );

  if (!isComplete) {
    return "Please tell us more about your equipment";
  }
}

function isBoolOrArray(value: any) {
  return [true, false].includes(value) || Array.isArray(value);
}

function isTruthyBoolOrArray(value: any) {
  return value === true || (Array.isArray(value) && value.length);
}
