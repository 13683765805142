import { Facility } from "types/profile";

export function isPrimaryLocationSelected(facilities: Facility[]) {
  return facilities.some((facility) => {
    if (facility.primaryLocation) {
      return true;
    } else if (facility.departments.length > 0) {
      for (let department of facility.departments) {
        if (department.primaryLocation) {
          return true;
        }
      }
    }
    return false;
  });
}

export function isNonePrimaryLocationSelected(facilities: Facility[]) {
  return facilities.some((facility) => {
    if (facility.primaryLocation === null) {
      return true;
    } else if (facility.departments.length > 0) {
      for (let department of facility.departments) {
        if (department.primaryLocation === null) {
          return true;
        }
      }
    }
    return false;
  });
}
