import React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
const PREFIX = "ProfileTrialSponsorsBox";

const classes = {
  trialSponsors: `${PREFIX}-trialSponsors`,
  trialSponsorsImages: `${PREFIX}-trialSponsorsImages`,
  trialSponsorsText: `${PREFIX}-trialSponsorsText`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.trialSponsors}`]: {
    display: "grid",
    gridTemplateRows: "1fr 1fr",
    height: "100%",
  },

  [`& .${classes.trialSponsorsImages}`]: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: theme.spacing(2),
    alignItems: "end",
    "& img": {
      maxWidth: "100%",
    },
  },

  [`& .${classes.trialSponsorsText}`]: {
    textAlign: "center",
    alignSelf: "center",
  },
}));

export function ProfileTrialSponsorsBox() {
  return (
    <Root className={classes.trialSponsors}>
      <div className={classes.trialSponsorsText}>
        <Typography variant="body1">Increase your visibility to</Typography>
        <Typography variant="body1">
          <b>Clinical Trial Sponsors and CROs</b>
        </Typography>
        <Typography variant="body1">
          responsible for delivering over a third of
        </Typography>
        <Typography variant="body1">
          global clinical trials since 2008
        </Typography>
      </div>
      <div className={classes.trialSponsorsImages}>
        <img alt="" src="/clinical-trail-sponsors-1.jpg" />
        <img alt="" src="/clinical-trail-sponsors-2.jpg" />
      </div>
    </Root>
  );
}
