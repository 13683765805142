import React from "react";
import MuiLink from "@mui/material/Link";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";

import { styled } from "@mui/material/styles";
import { useForm, Field } from "hooks/useForm";
import { asyncNoop, noop } from "utils/noop";
import { FormCheckboxField } from "./FormCheckboxField";
import { Link as WouterLink } from "wouter";
import { PopulationKeyValue } from "types/facility";

import {
  fieldsToPopulationKeyValueArray,
  toPopulationValuesObj,
} from "utils/department";

const defaultValue = [] as PopulationKeyValue[];

const Root = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridRowGap: theme.spacing(2),
}));

const CheckboxesWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridRowGap: theme.spacing(4),
  gridColumnGap: theme.spacing(2),
}));

interface PatientPopulationEthnicitiesFieldProps {
  value?: PopulationKeyValue[];
  onChange: Callback;
  form: string;
  [key: string]: any;
}

export function PatientPopulationEthnicitiesFieldUSA({
  value = defaultValue,
  form,
  onChange,
}: PatientPopulationEthnicitiesFieldProps) {
  const values = toPopulationValuesObj(value);
  const { fields } = useForm({
    id: form,
    fields: {
      american_indian_or_alaska_native: {
        type: "checkbox",
        name: "american_indian_or_alaska_native",
        label: "American Indian or Alaska Native",
        value: values.american_indian_or_alaska_native,
      },
      asian: {
        type: "checkbox",
        name: "asian",
        label: "Asian",
        value: values.asian,
      },
      black_or_african_american: {
        type: "checkbox",
        name: "black_or_african_american",
        label: "Black or African American",
        value: values.black_or_african_american,
      },
      native_hawaiian_or_other_pacific_islander: {
        type: "checkbox",
        name: "native_hawaiian_or_other_pacific_islander",
        label: "Native Hawaiian or Other Pacific Islander",
        value: values.native_hawaiian_or_other_pacific_islander,
      },
      hispanic_or_latino: {
        type: "checkbox",
        name: "hispanic_or_latino",
        label: "Hispanic or Latino",
        value: values.hispanic_or_latino,
      },
      caucasian: {
        type: "checkbox",
        name: "caucasian",
        label: "Caucasian",
        value: values.caucasian,
      },
    },
    submit: asyncNoop,
    onError: noop,
    onChange: (fields: any) => {
      onChange(fieldsToPopulationKeyValueArray(fields));
    },
  });

  return (
    <Root>
      <Typography variant="h6">
        Does your site have access to patients from any of the following
        population groups / geographic backgrounds for enrolment in clinical
        trials? (select all those that make up 25% or more of your population):
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        These data are being collected to support sponsors in ensuring
        representation from diverse populations in their clinical trials, and to
        support enrolment of specific patient groups for targeted trials. Need
        help?{" "}
        <WouterLink href="/contact-us">
          <MuiLink underline="hover">Contact us</MuiLink>
        </WouterLink>
        .
      </Typography>

      <CheckboxesWrapper>
        <FormGroup>
          <CheckboxField {...fields.american_indian_or_alaska_native} />
          <CheckboxField {...fields.asian} />
        </FormGroup>

        <FormGroup>
          <CheckboxField {...fields.black_or_african_american} />
          <CheckboxField
            {...fields.native_hawaiian_or_other_pacific_islander}
          />
        </FormGroup>

        <FormGroup>
          <CheckboxField {...fields.hispanic_or_latino} />
          <CheckboxField {...fields.caucasian} />
        </FormGroup>
      </CheckboxesWrapper>
    </Root>
  );
}

function CheckboxField(props: Field) {
  return (
    <FormCheckboxField
      sx={{ root: { mt: 1 }, checkbox: { pr: 1 } }}
      {...props}
    />
  );
}
