import React from "react";
import { useManuallyGetFacilities } from "hooks/useTypeaheadQueries";

import {
  AsyncAutocompleteField,
  AsyncAutocompleteFieldProps,
} from "./AsyncAutocompleteField";

export interface FacilityNameAutocompleteFieldProps
  extends Omit<
    AsyncAutocompleteFieldProps,
    "fetchOptions" | "additionalQueryArgs"
  > {
  countryCode: string;
}

export function FacilityNameAutocompleteField({
  name = "facilityName",
  label = "Institution",
  onQueryError = console.error,
  countryCode,
  ...otherProps
}: FacilityNameAutocompleteFieldProps) {
  const getFacilities = useManuallyGetFacilities();

  function fetchOptions(q: string) {
    return getFacilities(q, countryCode);
  }

  return (
    <AsyncAutocompleteField
      name={name}
      label={label}
      fetchOptions={fetchOptions}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return option.name;
      }}
      getOptionValue={(option) => option}
      freeSolo
      {...otherProps}
    />
  );
}
