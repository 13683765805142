import "react-app-polyfill/ie11";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import { ConfigProvider } from "./providers/Config";
import { MaintenanceModeProvider } from "providers/MaintenanceMode";
import theme from "./theme";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";




ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MaintenanceModeProvider>
        <ConfigProvider>
          <App />
        </ConfigProvider>
      </MaintenanceModeProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById("root")
);
