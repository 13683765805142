import { Licence, GQLLicence } from "types/profile";

export function isCompleteLicence(licence: Licence | GQLLicence) {
  return Boolean(
    licence.countryCode &&
    licence.type &&
    licence.issuer &&
    licence.issueDate &&
    licence.number
  );
}

export function hasIncompleteLicences(licences: Licence[] | GQLLicence[]) {
  return licences.some((licence: any) => {
    return !isCompleteLicence(licence);
  });
}
