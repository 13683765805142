import React from "react";
import { Suspend } from "components/Suspend";
import { CoreDataProvider } from "hooks/useCoreData";
import { useProfile } from "hooks/useProfile";
import { FacilityAffiliationsCard } from "components/FacilityAffiliationsCard";
import { Screen, ScreenSection, ScreenSectionTitle } from "components/Screen";

export default function Facilities() {
  const { cards, loading } = useProfile();
  return (
    <CoreDataProvider>
      <Suspend when={loading}>
        <Screen>
          <ScreenSection>
            <ScreenSectionTitle children="Facilities You Are Affiliated with" />
            <FacilityAffiliationsCard
              {...cards.facility}
              expanded
              noExpandIcon
            />
          </ScreenSection>
        </Screen>
      </Suspend>
    </CoreDataProvider>
  );
}
