import React, { useState } from "react";
import MuiLink from "@mui/material/Link";
import StarIcon from "@mui/icons-material/Star";
import AccountIcon from "@mui/icons-material/AccountBalance";

import { Facility } from "types/profile";
import { styled } from "@mui/material/styles";
import { Link as WouterLink } from "wouter";
import { TableEditActionButton, TableDeleteActionButton } from "./Table";
import { useAuth } from "hooks/useAuth";
import { DelegateFacilityMessage } from "./DelegateFacilityMessage";

const PREFIX = "FacilityNameCell";

const classes = {
  root: `${PREFIX}-root`,
  text: `${PREFIX}-text`,
  link: `${PREFIX}-link`,
  accountIcon: `${PREFIX}-accountIcon`,
  starIcon: `${PREFIX}-starIcon`,
  actions: `${PREFIX}-actions`,
};

const Root = styled("div")(({ theme }) => {
  return {
    [`&.${classes.root}`]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    [`& .${classes.text}`]: {
      display: "flex",
      alignItems: "center",
      fontSize: theme.typography.subtitle1.fontSize,
      "& > :not(:first-of-type)": {
        marginLeft: theme.spacing(1),
      },
    },
    [`& .${classes.link}`]: {
      cursor: "pointer",
    },
    [`& .${classes.accountIcon}`]: {
      fill: theme.palette.grey[500],
    },
    [`& .${classes.starIcon}`]: {
      fill: theme.palette.success.main,
    },
    [`& .${classes.actions}`]: {
      "& > :not(:first-of-type)": {
        marginLeft: theme.spacing(1),
      },
    },
  };
});

export interface FacilityNameCellProps {
  userPk?: number;
  testId?: string;
  entry: Facility;
  userEntry?: Facility;
  onUpdateButtonClick: Callback;
  onDeleteButtonClick: Callback;
  "data-testid"?: string;
  [key: string]: any;
}

export function FacilityNameCell({
  userPk,
  entry,
  userEntry,
  onDeleteButtonClick,
  onUpdateButtonClick,
  ...otherProps
}: FacilityNameCellProps) {
  const { isAdmin, isDelegate } = useAuth();
  const [dialogOpen, setDialogOpen] = useState(false);
  const facilityUrl = isAdmin
    ? `/admin/profile/${userPk}/facilities/${entry.id}`
    : `/facilities/${entry.id}`;

  const ShowDelegateMessage = () => {
    setDialogOpen(!dialogOpen);
  };

  return (
    <Root className={classes.root} {...otherProps}>
      <div className={classes.text}>
        {entry.primaryLocation && <StarIcon className={classes.starIcon} />}
        <AccountIcon className={classes.accountIcon} />
        {isDelegate ? (
          <MuiLink
            className={classes.link}
            onClick={ShowDelegateMessage}
            underline="hover"
          >
            {entry.name}
          </MuiLink>
        ) : (
          <WouterLink href={facilityUrl}>
            <MuiLink underline="hover">{entry.name}</MuiLink>
          </WouterLink>
        )}
      </div>
      <div className={classes.actions}>
        {isDelegate ? (
          <MuiLink
            className={classes.link}
            onClick={ShowDelegateMessage}
            underline="hover"
          >
            <TableEditActionButton />
          </MuiLink>
        ) : (
          <WouterLink href={facilityUrl}>
            <TableEditActionButton />
          </WouterLink>
        )}
        <TableDeleteActionButton onClick={() => onDeleteButtonClick(entry)} />
      </div>
      {isDelegate && (
        <DelegateFacilityMessage
          open={dialogOpen}
          entry={entry}
          type="facility"
          disabled={!userEntry}
          onCancel={ShowDelegateMessage}
        />
      )}
    </Root>
  );
}
