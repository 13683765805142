import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const GET_USER = gql`
  query getUser {
    profile {
      contactEmail
      email
      user {
        firstName
        lastName
      }
    }
  }
`;

export function useUser() {
  let { data, loading, error } = useQuery(GET_USER);
  return {
    loading,
    error,
    user:
      loading || error
        ? {}
        : {
            email: data.profile.contactEmail || data.profile.email,
            name: `${data.profile.user.firstName} ${data.profile.user.lastName}`,
          },
  };
}
