import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import WarningIcon from "@mui/icons-material/Warning";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { noop } from "utils/noop";
import { Alert } from "@mui/material";
import { Experience } from "types/profile";

import {
  isCompleteExperience,
  hasIncompleteExperiences,
} from "utils/experience";

import {
  TablePane,
  TableHeadRow,
  TableBodyRow,
  TableActionsCell,
  TableEditActionButton,
  TableDeleteActionButton,
  TablePaneFooter,
  TablePaneButton,
} from "./Table";

interface Props {
  testId?: string;
  entries?: Experience[];
  maxEntries?: number;
  onCreateButtonClick?: Callback;
  onUpdateButtonClick?: Callback;
  onDeleteButtonClick?: Callback;
  "data-testid"?: string;
}

export function ExperienceTable({
  entries = [],
  "data-testid": testId = ExperienceTable.name,
  onCreateButtonClick = noop,
  onUpdateButtonClick = noop,
  onDeleteButtonClick = noop,
}: Props) {
  return (
    <TablePane>
      {hasIncompleteExperiences(entries) && (
        <Alert sx={{ mb: 2 }} severity="warning">
          Please provide missing information for the entries indicated below
        </Alert>
      )}

      <Table arial-label="professionalExperienceTable" data-testid={testId}>
        <TableHead>
          <TableHeadRow>
            <TableCell>Institution</TableCell>
            <TableCell>Job title</TableCell>
            <TableCell>From</TableCell>
            <TableCell>To</TableCell>
            <TableCell />
            <TableCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {entries.map((entry) => (
            <TableBodyRow key={entry.id} data-testid={`${testId}BodyRow`}>
              <TableCell>{entry.facilityName || "-"}</TableCell>
              <TableCell>{entry.jobTitle || "-"}</TableCell>
              <TableCell>{entry.yearStarted || "-"}</TableCell>
              <TableCell>{entry.yearFinished || "-"}</TableCell>
              <TableCell>
                {!isCompleteExperience(entry) && (
                  <WarningIcon
                    sx={{ color: "warning.main" }}
                    aria-label="Warning"
                    titleAccess="This experience entry is incomplete"
                  />
                )}
              </TableCell>
              <TableActionsCell>
                <TableEditActionButton
                  onClick={() => onUpdateButtonClick(entry)}
                  data-testid={`${testId}UpdateEntryButton`}
                />
                <TableDeleteActionButton
                  onClick={() => onDeleteButtonClick(entry)}
                  data-testid={`${testId}DeleteEntryButton`}
                />
              </TableActionsCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
      <TablePaneFooter>
        <TablePaneButton
          icon={AddCircleIcon}
          arial-label="addMoreProfessionlExperience"
          label="Add more professional experience"
          onClick={onCreateButtonClick}
          data-testid={`${testId}CreateEntryButton`}
        />
      </TablePaneFooter>
    </TablePane>
  );
}
