import React from "react";
import Link from "@mui/material/Link";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";

import { Button } from "@mui/material";
import { useEffect } from "react";
import { FieldInfo } from "./FieldInfo";
import { FormField } from "./FormField";
import { styled } from "@mui/material/styles";
import { asyncNoop, noop } from "utils/noop";
import { FormCheckboxField } from "./FormCheckboxField";
import { useForm, Field, Fields } from "hooks/useForm";
import { TherapeuticArea, LovItem } from "types/facility";

const PREFIX = "TherapeuticAreasField";

const classes = {
  root: `${PREFIX}-root`,
  formGroup: `${PREFIX}-formGroup`,
  checkbox: `${PREFIX}-checkbox`,
  fieldInfo: `${PREFIX}-fieldInfo`,
  selectAllButtonsContainer: `${PREFIX}-selectAllButtonsContainer`,
};

const Root = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridRowGap: theme.spacing(2),

  [`& .${classes.formGroup}`]: {
    display: "grid",
  },

  [`& .${classes.checkbox}`]: {
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.selectAllButtonsContainer}`]: {
    display: "grid",
    gridTemplateColumns: "120px 120px",
    gridColumnGap: theme.spacing(1),
  },
}));

interface TherapeuticAreasFieldProps {
  label?: string;
  error?: boolean;
  form: string;
  helperText?: string;
  onChange: (areas: TherapeuticArea[]) => void;
  value?: TherapeuticArea[];
  areas: LovItem[];
  [key: string]: any;
}

export function TherapeuticAreasField({
  label = "Therapeutic areas",
  error = false,
  value = [],
  form,
  helperText,
  onChange,
  areas,
  ...otherProps
}: TherapeuticAreasFieldProps) {
  const $fields = areas.reduce((acc, area) => {
    acc[area.code] = {
      type: "checkbox",
      name: area.code,
      label: area.name,
      value: value.find((v: TherapeuticArea) => v.code === area.code)!.value,
    };
    return acc;
  }, {} as Fields);

  const { fields, setField } = useForm({
    id: form,
    fields: $fields,
    submit: asyncNoop,
    onError: noop,
    onChange: (fields: any) => {
      const values = Object.keys(fields).map((key) => {
        return {
          code: key,
          value: fields[key].value,
        };
      });
      onChange(values);
    },
  });

  useEffect(() => {
    for (const item of value) {
      setField(item.code, item.value);
    }
  }, [setField, valueCacheKey(value)]); // eslint-disable-line

  function selectAll() {
    for (let field of Object.values(fields)) {
      field.onChange(true);
    }
  }

  function deselectAll() {
    for (let field of Object.values(fields)) {
      field.onChange(false);
    }
  }

  function valueCacheKey(value: TherapeuticArea[]) {
    return value.reduce((acc, item) => {
      return `${acc}|${item.code}:${item.value}`;
    }, "");
  }

  function CheckboxField(props: Field) {
    return <FormCheckboxField sx={{ checkbox: { pr: 1 } }} {...props} />;
  }

  return (
    <FormField
      error={error}
      label={label}
      helperText={helperText}
      {...otherProps}
    >
      <Root className={classes.root}>
        <Typography variant="h6">
          The therapeutic areas below use MeSH terms. MeSH stands for Medical
          Subject Headings, and is the US National Library of Medicine's
          controlled list of terms. This has been used to standardise therapy
          areas, for more information visit{" "}
          <Link
            href="https://www.nlm.nih.gov/mesh"
            target="_blank"
            underline="hover"
          >
            www.nlm.nih.gov/mesh
          </Link>
        </Typography>
        <div className={classes.selectAllButtonsContainer}>
          <Button variant="outlined" onClick={selectAll}>
            Select All
          </Button>
          <Button variant="outlined" onClick={deselectAll}>
            Deselect All
          </Button>
        </div>

        <FormGroup className={classes.formGroup}>
          {areas.map((area) => (
            <FieldInfo
              key={area.code}
              sx={{
                borderBottom: 1,
                borderColor: "grey.300",
                pt: 1,
                pb: 1,
              }}
              info={area.description}
            >
              <CheckboxField {...fields[area.code]} />
            </FieldInfo>
          ))}
        </FormGroup>
      </Root>
    </FormField>
  );
}
