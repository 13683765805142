import React from "react";
import Keycloak from "keycloak-js";

import { Routes } from "routes";
import { useConfig } from "providers/Config";
import { AuthRoutes } from "./routes/auth";
import { AuthProvider } from "hooks/useAuth";
import { ApolloProvider } from "@apollo/client";
import { createApolloClient } from "utils/apollo";

export default function App() {
  let { config, status } = useConfig();

  switch (status) {
    case "success":
      let apolloClient = createApolloClient();
      let keycloakClient = Keycloak(config.keycloak);
      return (
        <>
          <ApolloProvider client={apolloClient}>
            <AuthProvider client={keycloakClient}>
              <AuthRoutes>
                <Routes />
              </AuthRoutes>
            </AuthProvider>
          </ApolloProvider>
        </>
      );
    case "loading":
      return <div></div>;
    case "failure":
    default:
      return (
        <div>
          <p>
            Unable to load, try refreshing. If the issue persists please contact
            profile@drugdev.com.
          </p>
        </div>
      );
  }
}
