import { assign, sendParent, createMachine, ActorRefFrom } from "xstate";
import { assocPath } from "rambda";
import { useActor } from "@xstate/react";
import { validator } from "./utils";
import { isEmpty } from "../validators/isEmpty";
import { produce } from "immer"

export enum PasswordValidation {
  "MIN_CHARACTERS" = "Password must be at least 8 characters long",
  "SPECIAL_CHARACTERS" = "Password must contain one special character",
  "LOWER_CASE_CHARACTERS" = "Password must contain at least 1 lowercase letter",
  "DIGIT_CHARACTER" = "Password must contain at least 1 digit",
  "REQUIRED" = "This field is required",
}

type SetValueEvent = { type: "SET_VALUE"; name: string; value: string };
type ValidateEvent = {
  type: "VALIDATE";
  name: string;
  data: any;
  hasSso?: boolean;
};
type ValidatedEvent = {
  type: "VALIDATED";
  name: string;
  data: any;
  hasSso?: boolean;
};
type ValidateResponse = {
  type: "error.platform.validation";
  data: string[];
};
type AccountDetailsFormEvents =
  | SetValueEvent
  | ValidateEvent
  | ValidatedEvent
  | ValidateResponse;

interface Field {
  value: string | undefined;
  helperText?: string;
}
export interface AccountDetailsFormMachineContext {
  hasSso: boolean;
  investigator: Field;
  specialty: Field;
  subspecialty: Field;
  password: Field;
}

export interface AccountDetailsFormFinalValues {
  isInvestigator: boolean;
  specialty: string;
  subspecialty: string;
  password: string;
}

let defaultContext: AccountDetailsFormMachineContext = {
  hasSso: false,
  investigator: {
    value: undefined,
  },
  specialty: {
    helperText: "",
    value: "",
  },
  subspecialty: {
    helperText: "",
    value: "",
  },
  password: {
    helperText: "",
    value: "",
  },
};

const getFinalValues = (context: AccountDetailsFormMachineContext)  => {
  return ({
    isInvestigator: context.investigator.value === "yes" ? true : false,
    specialty: context.specialty.value,
    subspecialty:  context.specialty.value,
    password:  context.password.value,
  }) as AccountDetailsFormFinalValues;
};

export let AccountDetailsFormMachine = createMachine(
  {
    schema: {
      context: {} as AccountDetailsFormMachineContext,
      events: {} as AccountDetailsFormEvents,
    },
    initial: "idle",
    context: defaultContext,
    states: {
      idle: {
        on: {
          SET_VALUE: {
            actions: "setValue",
          },
          VALIDATE: "validate",
        },
      },
      validate: {
        entry: ["resetValidationMessages"],
        invoke: [
          {
            id: "validation",
            src: "validation",
            onDone: [
              {
                actions: ["sendDataToParent"],
                target: "idle",
              },
            ],
            onError: [
              {
                actions: "setValidationErrors",
                target: "idle",
              },
            ],
          },
        ],
      },
    },
  },
  {
    actions: {
      setValue: assign((context, event) =>
        assocPath(
          [(event as SetValueEvent).name, "value"],
          (event as SetValueEvent).value,
          context
        )
      ),
      setValidationErrors: assign((context, event) => {
        if (event.type !== "error.platform.validation") return context;
        return produce(context, (newContext) => {
          for (let key of event.data) {
            let fieldName = key[0] as keyof AccountDetailsFormMachineContext;
            let field = newContext[fieldName] as Field;
            field.helperText = key[1];
          }
        });
      }),
      resetValidationMessages: assign((context, event) => {
        if (event.type !== "error.platform.validation") return context;
        return produce(context, (newContext) => {
          for (let key of event.data) {
            let fieldName = key[0] as keyof AccountDetailsFormMachineContext;
            let field = newContext[fieldName] as Field;
            field.helperText = "";
          }
        });
      }),
      sendDataToParent: sendParent((context) => {
        return {
          type: "VALIDATED",
          data: getFinalValues(context),
        } as ValidatedEvent;
      }),
    },
    services: {
      async validation(context, event) {
        if (event.type !== "VALIDATE") return;
        let { hasErrors, errors } = validator(
          {
            key: "investigator",
            message: "This field is required",
            predicate: isEmpty(context.investigator.value),
          },
          {
            key: "specialty",
            message: "This field is required",
            predicate:
              isEmpty(context.specialty.value) &&
              context.investigator.value === "yes",
          },
          {
            key: "password",
            message: PasswordValidation.MIN_CHARACTERS,
            predicate:
              event.hasSso === false &&
              (context.password.value?.length ?? 0) < 8,
          },
          {
            key: "password",
            message: PasswordValidation.SPECIAL_CHARACTERS,
            predicate:
              event.hasSso === false &&
              !/[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
                context.password.value ?? ""
              ),
          },
          {
            key: "password",
            message: PasswordValidation.LOWER_CASE_CHARACTERS,
            predicate:
              event.hasSso === false &&
              !/[a-z]/.test(context.password.value ?? ""),
          },
          {
            key: "password",
            message: PasswordValidation.DIGIT_CHARACTER,
            predicate:
              event.hasSso === false &&
              !/[0-9]/.test(context.password.value ?? ""),
          },
          {
            key: "password",
            message: PasswordValidation.REQUIRED,
            predicate:
              event.hasSso === false && isEmpty(context.password.value),
          }
        );
        if (hasErrors) {
          return Promise.reject(errors);
        }
      },
    },
  }
);

export type AccountDetailsFormMachineService = ActorRefFrom<typeof AccountDetailsFormMachine>;

export function useAccountDetailsForm(
  service: AccountDetailsFormMachineService
) {
  let [state, send] = useActor(service);
  return {
    ...(state?.context ?? defaultContext),
    setValue(name: string, value: string | boolean) {
      return send({
        type: "SET_VALUE",
        name: name,
        value: value,
      } as SetValueEvent);
    },
    onChange(event: React.ChangeEvent<HTMLInputElement>) {
      return send({
        type: "SET_VALUE",
        name: event.target.name,
        value: event.target.value,
      } as SetValueEvent);
    },
  };
}
