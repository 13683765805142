import React from "react";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { ProgressBar } from "./ProgressBar";

import { Paper, Typography, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const PaperStyled = styled(Paper)<{ progress: number }>(
  ({ theme, progress }) => {
    return {
      padding: theme.spacing(2),
      height: "100%",
      paddingBottom: `${
        progress === 100 ? theme.spacing(0) : theme.spacing(2)
      }`,
      "& p": {
        color: theme.palette.grey[800],
      },
      "& b": {
        color: theme.palette.grey[900],
      },
    };
  }
);

const ProfileCompletionText = styled("p")(({ theme }) => ({
  marginTop: "0",
  fontSize: "0.875rem",
}));

const ProgressTitle = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr auto",
}));

const OptInStatus = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "auto auto",
  alignItems: "center",
  gridGap: "2px",
}));

interface ProfileProgressProps {
  optedIn?: Boolean;
  cvProgress: number;
  basicProgress: number;
  onClickShowMissing(): void;
}

export function ProfileProgress({
  optedIn,
  cvProgress,
  basicProgress,
  onClickShowMissing,
}: ProfileProgressProps) {
  return (
    <PaperStyled progress={basicProgress}>
      <ProgressTitle>
        <Typography variant="h5">Profile Status</Typography>
        {optedIn && (
          <OptInStatus aria-label="Opted-In success">
            <CheckCircle sx={{ color: "success.main" }} />
            <span>Opted-in</span>
          </OptInStatus>
        )}
      </ProgressTitle>

      {cvProgress !== 100 && (
        <BasicProgress
          progress={basicProgress}
          onClickShowMissing={onClickShowMissing}
        />
      )}

      {basicProgress === 100 && cvProgress !== 100 && (
        <CVProgress progress={cvProgress} />
      )}

      {basicProgress === 100 && cvProgress === 100 && <CompleteProgress />}
    </PaperStyled>
  );
}

interface BasicProgressProps {
  progress: number;
  onClickShowMissing(): void;
}

function BasicProgress({ progress, onClickShowMissing }: BasicProgressProps) {
  return (
    <>
      <ProfileCompletionText>
        {progress === 100 ? (
          <>
            Your basic profile is <b>100%</b> complete and is now visible to
            study sponsors.
          </>
        ) : (
          <>
            Your basic profile is <b>{progress}%</b> complete
          </>
        )}
      </ProfileCompletionText>
      {progress !== 100 && (
        <>
          <ProgressBar progress={progress} />
          <Typography variant="body1">
            Completing your basic profile,{" "}
            <b>especially your Facility Affiliations</b>, will help sponsors
            better match you to potential study opportunities and save time
            during study start-up.
          </Typography>

          <Box sx={{ textAlign: "right" }}>
            <Button
              onClick={onClickShowMissing}
              color="secondary"
              variant="contained"
            >
              Show me what is missing
            </Button>
          </Box>
        </>
      )}
    </>
  );
}

interface CVProgressProps {
  progress: number;
}

function CVProgress({ progress }: CVProgressProps) {
  return (
    <>
      <p>
        Your CV information is <b>{progress}%</b> complete
      </p>
      <ProgressBar progress={progress} />
      <Typography variant="body1">
        With a few extra bits of information you can create an industry standard
        CV that will automatically be available to multiple sponsors to save
        time in study start-up.
      </Typography>
    </>
  );
}

function CompleteProgress() {
  return (
    <>
      <ProfileCompletionText>Your profile is complete</ProfileCompletionText>
      <ProgressBar progress={100} />
    </>
  );
}
