import React from "react";
import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";
import { AppBar } from "./AppBar";
import { Footer } from "./Footer";

interface Props {
  children: ReactNodes;
}

const Main = styled("main")(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 auto'
}));

const Content = styled("div")(({ theme }) => ({
  display: 'flex',
  flex: '1 0 auto',
  marginTop: theme.spacing(10),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  justifyContent: "center"
}));

export function RegistrationLayout({ children }: Props) {
  return (
    <>
      <AppBar showNav={false} />
      <Main>
        <Content>
          <Container>{children}</Container>
        </Content>
      </Main>
      <Footer />
    </>
  );
}
