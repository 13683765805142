import React, { FunctionComponent, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import { MedicalSpecialtySelectField } from "./MedicalSpecialtySelectField";
import { MedicalSubspecialtySelectField } from "./MedicalSubspecialtySelectField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LoginLink } from "./LoginLink";
import { useAuth } from "hooks/useAuth";

import {
  AccountDetailsFormMachineService,
  PasswordValidation,
  useAccountDetailsForm,
} from "hooks/useAccountDetailsForm";
import { ExistingData, RegistrationDetails } from "hooks/useRegistration";
import { Tooltip } from "components/Tooltip";

const Root = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  width: "100%",
  alignItems: "center",
  color: theme.palette.grey[800],

  [`.MuiFormHelperText-root`]: {
    marginLeft: 0,
  },
  [`.MuiAutocomplete-root, .MuiFormControl-root`]: {
    marginTop: 0,
    width: "100%",
  },

  [`.MuiFormControl-root`]: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2.5),
  },

  [`.MuiFormLabel-root`]: {
    color: theme.color.main,
  },
}));

const RadioGroupStyled = styled(RadioGroup)(() => ({
  display: "flex",
  width: "100%",
  flexDirection: "row",
}));

const TooltipWrapperStyled = styled("div")(() => ({
  position: "absolute",
  right: -10,
  top: 0,
  height: 40,
  width: 40,
}));

const TooltipValidationList = styled("ul")(({theme}) => ({
  margin: `0 ${theme.spacing(2)} 0 ${theme.spacing(3)}`,
  padding: 0
}));

const InfoTypographyStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[800],
  lineHeight: 1.25,
  marginBottom: theme.spacing(3),
}));

interface Props {
  service: AccountDetailsFormMachineService;
  className?: string;
  existingData: ExistingData;
  registrationDetails: RegistrationDetails;
}

export const RegisterAccountDetailsForm: FunctionComponent<Props> = ({
  service,
  className,
  existingData,
  registrationDetails,
}) => {
  const auth = useAuth();
  let [showPassword, setShowPassword] = useState<boolean>(false);
  const { investigator, specialty, subspecialty, password, onChange } =
    useAccountDetailsForm(service);

  const validationMessages = Object.values(PasswordValidation).filter(
    (item) => item !== PasswordValidation.REQUIRED
  );

  return (
    <Root
      className={`${className}`}
      id="register-account-details-form"
      aria-label="register account details form"
      data-testid="PrimaryFacility"
      noValidate={true}
    >
      <Box sx={{ mt: 4 }} width="100%">
        <Box width="100%">
          <FormControl
            component="fieldset"
            error={!!investigator?.helperText?.length}
          >
            <FormLabel component="legend">Are you an Investigator?*</FormLabel>
            <RadioGroupStyled
              id="account-details-form-investigator"
              aria-label="Select if you are an investigator or not"
              name="investigator"
              onChange={onChange}
              value={investigator}
            >
              <FormControlLabel
                value="yes"
                control={
                  <Radio
                    checked={investigator.value === "yes"}
                    color="secondary"
                    required={true}
                    size="small"
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={
                  <Radio
                    checked={investigator.value === "no"}
                    color="secondary"
                    required={true}
                    size="small"
                  />
                }
                label="No"
              />
            </RadioGroupStyled>
            <FormHelperText>{investigator.helperText}</FormHelperText>
          </FormControl>
        </Box>
        {investigator.value === "yes" && (
          <Box sx={{ mb: 2 }}>
            <Box sx={{ pl: 3 }}>
              <MedicalSpecialtySelectField
                id="account-details-form-specialty"
                aria-label="Search by speciality"
                label="Specialty*"
                onChange={onChange}
                error={
                  !!(specialty.helperText && specialty.helperText.length !== 0)
                }
                helperText={
                  specialty.helperText && specialty.helperText.length !== 0
                    ? specialty.helperText
                    : ""
                }
                value={specialty.value}
              />
            </Box>
            <Box sx={{ pl: 5 }}>
              <MedicalSubspecialtySelectField
                id="account-details-form-subspecialty"
                aria-label="Search by subspeciality"
                specialty={specialty.value}
                onChange={onChange}
                error={
                  !!(
                    subspecialty.helperText &&
                    subspecialty.helperText.length !== 0
                  )
                }
                helperText={
                  subspecialty.helperText &&
                  subspecialty.helperText.length !== 0
                    ? subspecialty.helperText
                    : ""
                }
                value={subspecialty.value}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{ mb: 4 }} width="100%">
        <Box width="100%">
          <FormLabel component="legend">Login Details</FormLabel>
          {!existingData.hasProfile && existingData.hasSso && (
            <>
              <Alert sx={{ mt: 2, mb: 2 }} severity="success">
                Great news - we have found profile login details for you.
              </Alert>
              <InfoTypographyStyled>
                Your login email is {registrationDetails.email}.
              </InfoTypographyStyled>
              <InfoTypographyStyled>
                Please use the password associated with your existing IQVIA
                account.
              </InfoTypographyStyled>
            </>
          )}
          {!existingData.hasSso && (
            <>
              <Box sx={{ position: "relative", pr: 4, mt: 2.5 }} width="100%">
                <TextField
                  id="account-details-form-email"
                  aria-label="Enter email"
                  name="email"
                  label="Email"
                  fullWidth={true}
                  onChange={onChange}
                  value={registrationDetails.email}
                  disabled={true}
                />
              </Box>
              <Box sx={{ position: "relative", pr: 4 }} width="100%">
                <TextField
                  id="account-details-form-password"
                  aria-label="Enter password"
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  fullWidth={true}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                  onChange={onChange}
                  error={
                    !!(password.helperText && password.helperText.length !== 0)
                  }
                  helperText={
                    password.helperText && password.helperText.length !== 0
                      ? password.helperText
                      : ""
                  }
                  value={password.value}
                />

                <TooltipWrapperStyled>
                  <Tooltip
                    title={
                      <TooltipValidationList>
                        {validationMessages.map((validation, key) => (
                          <li key={`validation-${key}`}>{validation}</li>
                        ))}
                      </TooltipValidationList>
                    }
                  />
                </TooltipWrapperStyled>
              </Box>
            </>
          )}
          {existingData.hasProfile && (
            <Alert sx={{ mt: 2, mb: 2 }} severity="success">
              {`Great news! We have found profile login details for you. It looks like you
                previously registered with ${existingData.existingEmail}. `}
              <LoginLink onClick={() => auth?.login?.()}>log in</LoginLink>
            </Alert>
          )}
        </Box>
      </Box>
      <InfoTypographyStyled>
        {"By registering for the IQVIA network, you agree to our "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.drugdev.com/terms-conditions/"
        >
          Terms &amp; Conditions
        </a>
        {" and "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.drugdev.com/privacy-policy/"
        >
          Privacy Policy.
        </a>
      </InfoTypographyStyled>
    </Root>
  );
};
