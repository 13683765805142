import React from "react";
import { Typography, Button } from "@mui/material";
import { Link } from "wouter";

export default function InvitationMessage({
  error,
  errorMessage,
  invitationType,
  delegateData,
  acceptDelegate,
  delegateType,
  token,
}: any) {
  let title, textMessage;

  function InvitationButton() {
    if (token) {
      return (
        <>
          <Link href={`/delegates/accept/${token}`}>
            <Button
              sx={{ mt: "20px", color: "white" }}
              aria-label="Accept invitation"
            >
              Accept Invitation
            </Button>
          </Link>
        </>
      );
    }
    return (
      <Button sx={{ mt: "20px", color: "white" }} onClick={acceptDelegate}>
        ACCEPT DELEGATE
      </Button>
    );
  }

  if (error) {
    textMessage = `There was an error ${
      invitationType === "ACCEPT" ? "accepting" : "declining"
    } your invitation: ${errorMessage}`;
  } else {
    if (invitationType === "ACCEPT") {
      title = "Invitation accepted";
      textMessage =
        delegateType === "DELEGATES"
          ? `Permission to allow ${delegateData?.email} to view and edit your account accepted`
          : `Invitation to delegate for ${delegateData?.email} accepted`;
    } else {
      title = "Invitation declined";
      textMessage =
        delegateType === "DELEGATES"
          ? `You have declined the invitation to be a delegate for ${delegateData?.email}.
        We will inform the profile owner of your decision.`
          : `You have declined to give permission to ${delegateData?.email} to view and edit your profile.
        We will inform the profile owner of your decision.`;
    }
  }

  return (
    <div>
      {title && (
        <Typography variant="body1" sx={{ fontSize: "1.1rem" }}>
          {title}
        </Typography>
      )}
      <Typography variant="body1">{textMessage}</Typography>
      {!error && invitationType === "DECLINE" && (
        <div>
          <Typography variant="body1">Changed your mind?</Typography>
          <InvitationButton />
        </div>
      )}
    </div>
  );
}
