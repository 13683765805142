import React from "react";
import { required } from "validators/required";
import { FormDialog } from "./FormDialog";
import { noop, asyncNoop } from "utils/noop";
import { useForm, SubmitFn } from "hooks/useForm";
import { IndicationsAutocompleteField } from "./IndicationsAutocompleteField";

import {
  useChangeReasonFormDialog,
  ChangeReasonFormDialog,
} from "./ChangeReasonFormDialog";

interface Props {
  title?: string;
  open?: boolean;
  entry?: string;
  testId?: string;
  submit?: SubmitFn;
  onCancel?: Callback;
  onSuccess?: Callback;
  withReason?: boolean;
  "data-testid"?: string;
}

export function ResearchIndicationForm({
  open,
  entry,
  "data-testid": testId = ResearchIndicationForm.name,
  title = "Update indication",
  submit: doSubmit = asyncNoop,
  onCancel: doCancel = noop,
  onSuccess = noop,
  withReason = false,
  ...otherProps
}: Props) {
  const reasonForm = useChangeReasonFormDialog({
    doSubmit,
    formHookSubmit,
    withReason,
  });
  const { id, fields, submit, reset, submitting, error } = useForm({
    id: "research-indications-form",
    fields: {
      id: {
        name: "id",
        value: entry,
      },
      indication: {
        name: "indication",
        label: "Indication",
        value: entry,
        required: true,
        fullWidth: true,
        validate: required,
      },
    },
    onReset: doCancel,
    submit: reasonForm.performSubmit,
    onError: console.error,
    onSuccess,
  });

  function formHookSubmit() {
    return submit();
  }

  const indicationsField = {} as AnyObject;

  if (error) {
    indicationsField.error = true;
    if ((error as any)?.hasOwnProperty("graphQLErrors")) {
      indicationsField.helperText = (error as any)?.graphQLErrors[0].message;
    }
  }

  return (
    <>
      <FormDialog
        id={id}
        open={open}
        title={title}
        onCancel={reset}
        onSubmit={reasonForm.handleSubmitButtonClick}
        maxWidth="sm"
        data-testid={testId}
        showProgressIndicator={submitting}
        {...otherProps}
      >
        <IndicationsAutocompleteField
          data-testid={`${testId}IndicationField`}
          {...fields.indication}
          {...indicationsField}
        />
      </FormDialog>
      <ChangeReasonFormDialog {...reasonForm.reasonDialog} />
    </>
  );
}
