import gql from "graphql-tag";
import { useApolloClient } from "@apollo/client";
import { useMachine, useActor } from "@xstate/react";
import {
  createMachine,
  assign,
  spawn,
  send,
  sendParent,
  ActorRefFrom,
} from "xstate";

// TYPES ------------------------------------------------------------------

export interface Delegate {
  pk: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  hasProfile?: boolean;
  withResponse?: boolean;
}

export type DelegateType = "DELEGATES" | "DELEGATE_FOR";

export type DelegateStatusType = "INVITED" | "DECLINED" | "ACTIVE";

export interface InvitationMachineContext {
  invitationType: string;
  error?: string;
  token: string;
  delegateType?: DelegateType;
  data?: {
    email: string;
    firstName: string;
    lastName: string;
    hasProfile: boolean;
    status: DelegateStatusType;
  };
  responding?: boolean;
}

export type InvitationMachineEvents =
  | { type: "CLOSE_MESSAGE"; data: any }
  | { type: "ACCEPT_INVITATION"; data: any }
  | { type: "DECLINE_INVITATION"; data: any }
  | { type: "SUCCESS"; data: any }
  | { type: "CLEAN_ERRORS"; data: any }
  | { type: "FAILURE"; data: any };

export type InvitationType = "ACCEPT" | "DECLINE";

// STATE MACHINES ----------------------------------------------------------

const invitationMachine = createMachine<
  InvitationMachineContext,
  InvitationMachineEvents,
  any
>(
  {
    initial: "initializing",
    id: "invitation",
    context: {
      token: "",
      invitationType: "DECLINE",
      error: "",
    },
    states: {
      initializing: {
        always: [
          { target: "accepting", cond: "invitationTypeAccept" },
          { target: "declining" },
        ],
      },
      accepting: {
        invoke: {
          src: "acceptDelegate",
          onDone: { target: "responded", actions: ["setData"] },
          onError: { target: "failure", actions: ["setError"] },
        },
      },
      declining: {
        invoke: {
          src: "declineDelegate",
          onDone: { target: "responded", actions: ["setData"] },
          onError: { target: "failure", actions: ["setError"] },
        },
      },
      responded: {
        entry: sendParent("GET_DELEGATES", { delay: 1000 }),
        on: {
          DECLINE_INVITATION: "declining",
          ACCEPT_INVITATION: "accepting",
          CLOSE_MESSAGE: "idle",
        },
        after: {
          20000: "idle",
        },
      },
      failure: {
        on: {
          DECLINE_INVITATION: "declining",
          ACCEPT_INVITATION: "accepting",
          CLOSE_MESSAGE: "idle",
          CLEAN_ERRORS: "idle",
        },
      },
      idle: {
        entry: "cleanErrors",
        on: {
          DECLINE_INVITATION: "declining",
          ACCEPT_INVITATION: "accepting",
        },
      },
    },
  },
  {
    actions: {
      setError: assign((_, event) => ({
        error: event.data.graphQLErrors
          ? event.data.graphQLErrors[0].message
          : event.data.message,
      })),
      setData: assign((_, event) => {
        return {
          error: "",
          delegateType: event.data.data.delegateType,
          data: event.data.data.invitation.data,
          invitationType:
            event.type.includes("done.invoke.invitation.declining") ||
            event.type.includes("done.invoke.declineDelegate")
              ? "DECLINE"
              : "ACCEPT",
        };
      }),
      cleanErrors: assign((_, _event) => ({
        error: "",
      })),
    },
    guards: {
      invitationTypeAccept: (context, _) => {
        return context.invitationType === "ACCEPT";
      },
    },
  }
);

const delegatesMachine = createMachine(
  {
    initial: "initializing",
    id: "delegates",
    context: {
      delegates: [],
      delegatesFor: [],
      isAdmin: false,
      userPk: null,
      withResponse: false,
      invitationData: null, // will hold data of the recently invited user
      error: "", //maybe better to communicate with notification machine,
      responding: false,
      invitationRef: null,
    },
    states: {
      initializing: {
        always: [
          { target: "manageInvitation", cond: "responding" },
          { target: "gettingDelegates" },
        ],
      },
      manageInvitation: {
        entry: ["init", send("DONE")],
        on: {
          DONE: "gettingDelegates",
        },
      },
      gettingDelegates: {
        //@ts-ignore
        entry: send("CLEAN_ERRORS", { to: (context) => context.invitationRef }),
        invoke: [
          {
            src: "getDelegates",
            onDone: { target: "idle", actions: ["setDelegatesData"] },
            onError: { target: "failure", actions: ["setError"] },
          },
        ],
        on: {
          GET_DELEGATES: { target: "gettingDelegates", internal: false },
        },
      },
      sendingInvitation: {
        on: {
          SUCCESS: { target: "gettingDelegates", actions: "setInvitationData" },
          ERROR: "failure",
        },
      },
      deletingDelegate: {
        invoke: [
          {
            src: "deleteDelegate",
            onDone: { target: "idle", actions: ["updateDelegatesData"] },
            onError: { target: "failure", actions: ["setError"] },
          },
        ],
      },
      idle: {
        on: {
          GET_DELEGATES: "gettingDelegates",
          INVITE_DELEGATE: "sendingInvitation",
          DELETE_DELEGATE: "deletingDelegate",
        },
      },
      failure: {
        on: {
          GET_DELEGATES: "gettingDelegates",
          INVITE_DELEGATE: "sendingInvitation",
          DELETE_DELEGATE: "deletingDelegate",
        },
      },
    },
  },
  {
    guards: {
      responding: (context, _) => {
        return context.responding;
      },
    },
  }
);

// QUERIES ----------------------------------------------------------------

export const GET_ALL_DELEGATES = gql`
  query GetDelegates {
    delegates {
      firstName
      lastName
      email
      status
      hasProfile
      pk
    }
    delegatesFor {
      firstName
      lastName
      email
      status
      hasProfile
      pk
    }
  }
`;

export const GET_ALL_DELEGATES_ADMIN = gql`
  query GetDelegatesAdmin($userPk: Int!) {
    delegates: delegatesAdmin(userPk: $userPk) {
      firstName
      lastName
      email
      status
      hasProfile
      pk
    }
    delegatesFor: delegatesForAdmin(userPk: $userPk) {
      firstName
      lastName
      email
      status
      hasProfile
      pk
    }
  }
`;

export const GET_DELEGATES = gql`
  query GetDelegates {
    delegates {
      firstName
      lastName
      email
      status
      hasProfile
      pk
    }
  }
`;

export const GET_DELEGATES_FOR = gql`
  query GetDelegatesFor {
    delegates: delegatesFor {
      firstName
      lastName
      email
      status
      hasProfile
      pk
    }
  }
`;

export const INVITE_DELEGATE = gql`
  mutation InviteDelegate($email: Email!, $delegateType: DelegateType!) {
    inviteDelegate(email: $email, delegateType: $delegateType) {
      data {
        status
        firstName
        lastName
        email
        hasProfile
      }
    }
  }
`;

export const INVITE_DELEGATE_ADMIN = gql`
  mutation inviteDelegateAdmin(
    $email: Email!
    $delegateType: DelegateType!
    $userPk: Int!
    $adminComment: String!
  ) {
    inviteDelegate: inviteDelegateAdmin(
      email: $email
      delegateType: $delegateType
      userPk: $userPk
      adminComment: $adminComment
    ) {
      data {
        status
        firstName
        lastName
        email
        hasProfile
      }
    }
  }
`;

export const UPDATE_DELEGATES = gql`
  mutation UpdateDelegates($delegates: [Email]!, $delegateType: DelegateType!) {
    updateDelegates(delegates: $delegates, delegateType: $delegateType) {
      data {
        status
        firstName
        lastName
        email
        hasProfile
      }
    }
  }
`;

export const UPDATE_DELEGATES_ADMIN = gql`
  mutation UpdateDelegatesAdmin(
    $delegates: [Email]!
    $delegateType: DelegateType!
    $userPk: Int!
    $adminComment: String
  ) {
    updateDelegates: updateDelegatesAdmin(
      delegates: $delegates
      delegateType: $delegateType
      userPk: $userPk
      adminComment: $adminComment
    ) {
      data {
        status
        firstName
        lastName
        email
        hasProfile
      }
    }
  }
`;

export const ACCEPT_DELEGATE = gql`
  mutation AcceptDelegate($token: String!) {
    invitation: acceptDelegate(token: $token) {
      data {
        pk
        firstName
        lastName
        email
        status
        hasProfile
      }
      delegateType
    }
  }
`;

export const DECLINE_DELEGATE = gql`
  mutation DeclineDelegate($token: String!) {
    invitation: declineDelegate(token: $token) {
      delegateType
      data {
        pk
        firstName
        lastName
        email
        status
        hasProfile
      }
    }
  }
`;

// MAIN HOOK --------------------------------------------------------------

interface DelegatesHookResult {
  loading: boolean;
  submitting: boolean;
  delegates: Delegate[];
  delegatesFor: Delegate[];
  invitationData: any;
  invitationRef?: any;
  deleteDelegate: (props: any) => void;
  inviteDelegate: (props: any) => Promise<any>;
  onRequestSuccess: (props: any) => void;
  onRequestError: (error: string) => void;
  onInvitationSuccess: (invitationData: any) => void;
}

export function useDelegates({
  isAdmin = false,
  userPk = null,
  token = null,
  invitationType = null,
}: any): DelegatesHookResult {
  const client = useApolloClient();

  const extendedInvitationMachine = invitationMachine.withConfig({
    services: {
      declineDelegate: (context) => {
        return client
          .mutate({
            mutation: DECLINE_DELEGATE,
            variables: {
              token: context.token,
            },
            fetchPolicy: "no-cache",
          })
          .then((result) =>
            result.errors
              ? Promise.reject(result.errors)
              : Promise.resolve(result)
          );
      },
      acceptDelegate: (context) => {
        return client.mutate({
          mutation: ACCEPT_DELEGATE,
          variables: {
            token: context.token,
          },
          fetchPolicy: "no-cache",
        });
      },
    },
  });

  let [state, send] = useMachine(delegatesMachine, {
    context: {
      isAdmin,
      userPk,
      responding: !!token,
    },
    actions: {
      setDelegatesData: assign((_, event) => {
        return {
          error: "",
          delegates: event.data.data.delegates,
          delegatesFor: event.data.data.delegatesFor,
        };
      }),
      updateDelegatesData: assign((_, event) => {
        return {
          error: "",
          [event.data.delegateType === "DELEGATES"
            ? "delegates"
            : "delegatesFor"]: event.data.data,
        };
      }),
      setError: assign((_, event) => {
        return {
          error: event.data,
        };
      }),
      setInvitationData: assign((_, event) => ({
        invitationData: {
          ...event.invitationData,
          delegateType: event.delegateType,
        },
      })),
      //@ts-ignore
      init: assign((_) => ({
        invitationRef: spawn(
          extendedInvitationMachine.withContext({ token, invitationType })
        ),
      })),
    },
    services: {
      getDelegates: (context, _) => {
        return isAdmin
          ? client.query({
              query: GET_ALL_DELEGATES_ADMIN,
              variables: {
                userPk: context.userPk,
              },
              fetchPolicy: "network-only",
            })
          : client.query({
              query: GET_ALL_DELEGATES,
              fetchPolicy: "network-only",
            });
      },
      deleteDelegate: (context, { delegate, delegateType, adminComment }) => {
        const delegates =
          delegateType === "DELEGATE_FOR"
            ? context.delegatesFor
            : context.delegates;

        const variables = {
          delegates: delegates
            // @ts-ignore
            .filter((d) => d.email !== delegate.email)
            //@ts-ignore
            .map((e) => e.email),
          delegateType,
        };
        if (isAdmin) {
          //@ts-ignore
          variables.userPk = state.context.userPk;
          //@ts-ignore
          variables.adminComment = adminComment;
        }
        return client
          .mutate({
            mutation: isAdmin ? UPDATE_DELEGATES_ADMIN : UPDATE_DELEGATES,
            variables,
            fetchPolicy: "no-cache",
          })
          .then((result) =>
            result.errors
              ? Promise.reject(result.errors)
              : Promise.resolve({
                  delegateType,
                  data: result.data.updateDelegates.data,
                })
          );
      },
    },
  });

  return {
    loading:
      state.matches("gettingDelegates") ||
      state.matches("initializing") ||
      state.matches("manageInvitation"),
    submitting:
      state.matches("sendingInvitation") || state.matches("deletingDelegate"),
    delegates: state.context.delegates,
    delegatesFor: state.context.delegatesFor,
    invitationData: state.context.invitationData,
    invitationRef: state.context.invitationRef,
    inviteDelegate({ email, delegateType, adminComment }) {
      const variables = {
        email,
        delegateType,
      };

      if (isAdmin) {
        // @ts-expect-error
        variables.userPk = state.context.userPk;
        // @ts-expect-error
        variables.adminComment = adminComment;
      }

      send("INVITE_DELEGATE");
      return client
        .mutate({
          mutation: isAdmin ? INVITE_DELEGATE_ADMIN : INVITE_DELEGATE,
          variables,
          fetchPolicy: "no-cache",
        })
        .then((result) =>
          result.errors
            ? Promise.reject(result.errors)
            : Promise.resolve(result.data.inviteDelegate.data)
        );
    },
    async deleteDelegate({ delegate, delegateType, adminComment }: any) {
      send({ type: "DELETE_DELEGATE", delegate, delegateType, adminComment });
      return Promise.resolve();
    },
    onInvitationSuccess({ invitationData, delegateType }) {
      send({ type: "SUCCESS", invitationData, delegateType });
    },
    onRequestSuccess({ data, delegateType }) {
      send({ type: "SUCCESS", data, delegateType });
    },
    onRequestError(error) {
      send({ type: "ERROR", error });
    },
  };
}

export type InvitationMachineService = {
  invitationRef: ActorRefFrom<typeof invitationMachine>;
};

export function useInvitationService({
  invitationRef,
}: InvitationMachineService) {
  const [state, send] = useActor(invitationRef);

  return {
    loading: state.matches("declining"),
    error: state.matches("failure"),
    open: state.matches("responded") || state.matches("failure"),
    invitationType: state.context.invitationType,
    errorMessage: state.context.error,
    delegateData: state.context.data,
    delegateType: state.context.delegateType,
    acceptDelegate() {
      send("ACCEPT_INVITATION");
    },
    closeMessage() {
      send("CLOSE_MESSAGE");
    },
  };
}

export interface ManageInvitationProps {
  token: string;
  invitationType: InvitationType;
}

export function useManageInvitation({
  token,
  invitationType,
}: ManageInvitationProps) {
  const client = useApolloClient();
  let [state, send] = useMachine(invitationMachine, {
    context: {
      token,
      invitationType,
    },
    services: {
      declineDelegate: (context) => {
        return client.mutate({
          mutation: DECLINE_DELEGATE,
          variables: {
            token: context.token,
          },
          fetchPolicy: "no-cache",
        });
      },
      acceptDelegate: (context) => {
        return client.mutate({
          mutation: ACCEPT_DELEGATE,
          variables: {
            token: context.token,
          },
          fetchPolicy: "no-cache",
        });
      },
    },
  });

  return {
    loading: state.matches("declining"),
    error: state.matches("failure"),
    open: state.matches("responded") || state.matches("failure"),
    invitationType: state.context.invitationType,
    errorMessage: state.context.error,
    delegateData: state.context.data,
    delegateType: state.context.delegateType,
    acceptDelegate() {
      send("ACCEPT_INVITATION");
    },
    closeMessage() {
      send("CLOSE_MESSAGE");
    },
  };
}
