import Link from "@mui/material/Link";
import React from "react";
import TextField from "@mui/material/TextField";
import DialogContentText from "@mui/material/DialogContentText";

import { User } from "types/profile";
import { required } from "validators/required";
import { maxLengthForValidator as maxLength } from "validators/maxLength";
import { FormDialog } from "./FormDialog";
import { Typography } from "@mui/material";
import { validators } from "utils/forms";
import { styled } from "@mui/material/styles";
import { FormFieldset } from "./FormFieldset";
import { noop, asyncNoop } from "utils/noop";
import { useForm, SubmitFn } from "hooks/useForm";
import { roleLabelOf, RoleType } from "utils/role";
import { NameTitlesSelectField } from "./NameTitlesSelectField";
import { NameSuffixesSelectField } from "./NameSuffixSelectField";
import { SiteStaffJobTitleSelectField } from "./SiteStaffJobTitleSelectField";
import { FormRadioButtonGroup } from "components/FormRadioButtonGroup";
import { FormErrorBar } from "./FormErrorBar";

import {
  ChangeReasonFormDialog,
  useChangeReasonFormDialog,
} from "./ChangeReasonFormDialog";

const PREFIX = "ProfileUserForm";

const classes = {
  fieldset: `${PREFIX}-fieldset`,
  dialogText: `${PREFIX}-dialogText`,
  jobQuestionText: `${PREFIX}-jobQuestionText`,
  jobTypeWrapper: `${PREFIX}-jobTypeWrapper`,
  radioContainer: `${PREFIX}-radioContainer`,
  error: `${PREFIX}-error`,
};

const DialogRoot = styled("div")(({ theme }) => ({
  [`& .${classes.fieldset}`]: {
    display: "flex",
    justifyContent: "space-between",
    "& > *:not(:first-child)": {
      marginLeft: theme.spacing(1),
    },
  },

  [`& .${classes.dialogText}`]: {
    margin: 0,
  },

  [`& .${classes.jobQuestionText}`]: {
    fontWeight: 400,
  },

  [`& .${classes.jobTypeWrapper}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(1),
  },

  [`& .${classes.radioContainer}`]: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.error}`]: {
    marginTop: theme.spacing(2),
  },
}));

interface Props {
  entry: User;
  open?: boolean;
  submit: SubmitFn;
  onCancel?: Callback;
  onSuccess?: Callback;
  withReason?: boolean;
}

export function ProfileUserForm({
  open,
  entry: user,
  submit: doSubmit = asyncNoop,
  onCancel: doCancel = noop,
  onSuccess = noop,
  withReason = false,
  ...otherProps
}: Props) {
  const isInvestigator = user.role === RoleType.Investigator;
  const reasonForm = useChangeReasonFormDialog({
    doSubmit,
    formHookSubmit,
    withReason,
  });
  const { id, fields, submit, reset, submitting, error } = useForm({
    id: "profile-user-form",
    fields: {
      nameTitle: {
        id: "nameTitle",
        name: "nameTitle",
        value: user.nameTitle,
        validate: maxLength(50),
      },
      firstName: {
        id: "firstName",
        label: "First name",
        name: "firstName",
        value: user.firstName,
        required: true,
        disabled: withReason ? false : true,
        validate: validators(required, maxLength(200)),
      },
      middleName: {
        id: "middleName",
        name: "middleName",
        label: "Middle name",
        value: user.middleName,
        validate: maxLength(100),
      },
      lastName: {
        id: "lastName",
        label: "Surname",
        name: "lastName",
        value: user.lastName,
        required: true,
        disabled: withReason ? false : true,
        validate: validators(required, maxLength(200)),
      },
      nameSuffix: {
        id: "nameSuffix",
        name: "nameSuffix",
        value: user.nameSuffix,
        validate: maxLength(50),
      },
      jobTitle: {
        id: "jobTitle",
        name: "jobTitle",
        label: "Job function",
        value: user.jobTitle,
      },
      role: {
        id: "role",
        name: "role",
        label: "",
        value: user.role,
        options: [
          { value: "INVESTIGATOR", label: "Investigator" },
          { value: "SITE_STAFF", label: "Site staff" },
        ],
        "aria-label": "user role",
      },
    },
    onReset: doCancel,
    submit: (values: any) => {
      if (values.role === RoleType.Investigator) {
        values.jobTitle = null;
      }
      if(values.middleName === "") {
        values.middleName = null;
      }
      if(values.nameTitle === "") {
        values.nameTitle = null;
      }
      if(values.nameSuffix === "") {
        values.nameSuffix = null;
      }
      return reasonForm.performSubmit(values);
    },
    onError: console.error,
    onSuccess,
  });

  function formHookSubmit() {
    return submit();
  }

  function getJobRoleFieldLegend() {
    const jobRoleLegend = `Job Role (${roleLabelOf(user.role)})`;
    if (!isInvestigator) {
      return `${jobRoleLegend} & job function`;
    }
    return jobRoleLegend;
  }

  const RoleMessage = () => (
    <DialogContentText className={classes.dialogText}>
      {isInvestigator
        ? "You are registered as an investigator. "
        : "You are registered as a site staff. "}
      If this is incorrect please{" "}
      <Link href="/contact-us" underline="hover">
        contact us
      </Link>
      .
    </DialogContentText>
  );

  return (
    <div>
      <FormDialog
        id={id}
        open={open}
        onCancel={reset}
        onSubmit={reasonForm.handleSubmitButtonClick}
        data-testid={ProfileUserForm.name}
        showProgressIndicator={submitting}
        title="Update User Details"
        {...otherProps}
      >
        <DialogRoot>
          <FormFieldset legend="Name" disabled={submitting}>
            <div className={classes.fieldset}>
              <NameTitlesSelectField {...fields.nameTitle} />
              <TextField {...fields.firstName} />
              <TextField {...fields.middleName} />
              <TextField {...fields.lastName} />
              <NameSuffixesSelectField {...fields.nameSuffix} />
            </div>
            {!withReason && (
              <DialogContentText className={classes.dialogText}>
                If you need to change your profile name please{" "}
                <Link href="/contact-us" underline="hover">
                  contact us
                </Link>
                .
              </DialogContentText>
            )}
          </FormFieldset>

          <FormFieldset
            legend={getJobRoleFieldLegend()}
            sx={{
              mt: 2,
              pb: 1,
            }}
          >
            {withReason ? (
              <FormRadioButtonGroup {...fields.role} />
            ) : (
              <RoleMessage />
            )}
            {fields.role.value !== "INVESTIGATOR" && (
              <div className={classes.jobTypeWrapper}>
                <Typography
                  className={classes.jobQuestionText}
                  variant="subtitle2"
                  color="textPrimary"
                >
                  Which of the following best describes your job function?
                </Typography>
                <SiteStaffJobTitleSelectField {...fields.jobTitle} />
              </div>
            )}
          </FormFieldset>
          {!!error && <FormErrorBar error={error} className={classes.error} />}
        </DialogRoot>
      </FormDialog>
      <ChangeReasonFormDialog {...reasonForm.reasonDialog} />
    </div>
  );
}
