import React from "react";

import { Card } from "components/Card";
import { MutatorFn } from "hooks/useCards";
import { EducationForm } from "./EducationForm";
import { DeletionDialog } from "./DeletionDialog";
import { EducationTable } from "./EducationTable";
import { useEditableData } from "hooks/useEditableData";
import { Qualification, Profile } from "types/profile";
import { useCard, ManagedCardProps, CardMachine } from "hooks/useCard";

export const EducationCardMachine = CardMachine({ validateCard });

interface EducationCardProps extends ManagedCardProps {
  userPk?: number;
  isAdmin?: boolean;
  qualifications: Qualification[];
  createQualification: MutatorFn;
  updateQualification: MutatorFn;
  deleteQualification: MutatorFn;
}

export function EducationCard({
  id = "education-card",
  userPk,
  isAdmin = false,
  service,
  qualifications,
  createQualification,
  updateQualification,
  deleteQualification,
  ...otherProps
}: EducationCardProps) {
  const withReason = isAdmin;
  const { card } = useCard({ service });
  const { details, creationForm, updateForm, deletionDialog } = useEditableData(
    {
      create: createQualification,
      update: updateQualification,
      delete: deleteQualification,
    }
  );

  return (
    <Card
      id={id}
      heading="Education"
      arial-label="education"
      data-testid={EducationCard.name}
      {...card}
      {...otherProps}
    >
      <EducationTable entries={qualifications} {...details} />

      {creationForm.open && (
        <EducationForm
          title="Add qualification"
          withReason={withReason}
          data-testid="EducationCreationForm"
          {...creationForm}
        />
      )}

      {updateForm.open && (
        <EducationForm
          title="Update qualification"
          withReason={withReason}
          data-testid="EducationUpdateForm"
          {...updateForm}
        />
      )}

      {deletionDialog.open && (
        <DeletionDialog withReason={withReason} {...deletionDialog} />
      )}
    </Card>
  );
}

export function validateCard({ qualifications }: Profile) {
  if (!qualifications.length) {
    return "Indicate your initial university education as well your other qualifications (e.g. PhD)";
  }
}
