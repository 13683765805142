export enum RoleType {
  Investigator = "INVESTIGATOR",
  SiteStaff = "SITE_STAFF",
  Admin = "ADMIN"
}

const roleLabel = {
  [RoleType.Investigator]: "Investigator",
  [RoleType.SiteStaff]: "Site staff",
  [RoleType.Admin]: "Admin"
};

export function roleLabelOf(type: string) {
  return (roleLabel as any)[type];
}
