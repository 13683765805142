import React from "react";
import Typography from "@mui/material/Typography";

import { Card } from "components/Card";
import { StudyForm } from "./StudyForm";
import { asyncNoop } from "utils/noop";
import { MutatorFn } from "hooks/useCards";
import { StudiesTable } from "./StudiesTable";
import { Study, Profile } from "types/profile";
import { DeletionDialog } from "./DeletionDialog";
import { useEditableData } from "hooks/useEditableData";
import { StudyByNctFormDialog } from "./StudyByNctFormDialog";
import { StudyIdentifierGetter } from "hooks/useProfile";
import { StudyByManualFormDialog } from "./StudyByManualFormDialog";
import { CardMachine, ManagedCardProps, useCard } from "hooks/useCard";

export const StudiesCardMachine = CardMachine({ validateCard });

interface StudiesCardProps extends ManagedCardProps {
  userPk?: number;
  isAdmin?: boolean;
  studies: Study[];
  createStudy: MutatorFn;
  updateStudy: MutatorFn;
  deleteStudy: MutatorFn;
  getStudyExportUrl: () => string;
  getStudyIdentifier: StudyIdentifierGetter;
}

export function StudiesCard({
  userPk,
  isAdmin = false,
  service,
  studies,
  createStudy,
  updateStudy,
  deleteStudy,
  getStudyExportUrl,
  getStudyIdentifier,
  ...otherProps
}: StudiesCardProps) {
  const withReason = isAdmin;
  const { card } = useCard({ service });
  const { details, creationForm, updateForm, deletionDialog } = useEditableData(
    {
      create: asyncNoop,
      update: updateStudy,
      delete: deleteStudy,
    }
  );

  const handleDownloadStudyButtonClick = async () => {
    try {
      window.open(await getStudyExportUrl());
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card
      heading="Clinical Studies"
      arial-label="clinicalStudies"
      data-testid={StudiesCard.name}
      {...card}
      {...otherProps}
    >
      <Typography sx={{ mb: 2 }} variant="subtitle2" color="textSecondary">
        If you have opted-in to share data with Investigator Databank and have
        completed a study with one of the member companies, details of this
        study and your enrolment record for the study will display. You can also
        add additional clinical trials you have participated in
      </Typography>

      <StudiesTable
        entries={studies}
        withReason={withReason}
        updateStudy={updateStudy}
        onDownloadStudyButtonClick={handleDownloadStudyButtonClick}
        {...details}
      />

      {creationForm.open && (
        <StudyForm
          title="Add clinical study"
          withReason={withReason}
          createStudy={createStudy}
          getStudyIdentifier={getStudyIdentifier}
          {...creationForm}
        />
      )}

      {updateForm.open && updateForm.entry.source !== "MANUAL" && (
        <StudyByNctFormDialog withReason={withReason} {...updateForm} />
      )}

      {updateForm.open && updateForm.entry.source === "MANUAL" && (
        <StudyByManualFormDialog withReason={withReason} {...updateForm} />
      )}

      {deletionDialog.open && (
        <DeletionDialog withReason={withReason} {...deletionDialog} />
      )}
    </Card>
  );
}

export function validateCard({ studies }: Profile) {
  if (!studies.length) {
    return "Please add a study";
  }
}
