let siteStaffJobTitles = [
  {
    label: "Clinical Research Co-ordinator",
    value: "Clinical Research Co-ordinator",
  },
  { label: "Site Owner", value: "Site Owner" },
  { label: "Physician Assistant", value: "Physician Assistant" },
  { label: "Nurse", value: "Nurse" },
  { label: "Lab Director", value: "Lab Director" },
  { label: "Lab Technician", value: "Lab Technician" },
  { label: "Pharmacist", value: "Pharmacist" },
  { label: "Administrative Assistant", value: "Administrative Assistant" },
  { label: "Technician", value: "Technician" },
  { label: "Site Manager", value: "Site Manager" },
  { label: "Researcher", value: "Researcher" },
];

export default siteStaffJobTitles;
