const nameSuffixes = [
  { label: "Jr", value: "Jr" },
  { label: "Snr", value: "Snr" },
  { label: "Esq", value: "Esq" },
  { label: "I", value: "I" },
  { label: "II", value: "II" },
  { label: "III", value: "III" },
  { label: "IV", value: "IV" },
  { label: "V", value: "V" },
  { label: "VI", value: "VI" },
  { label: "VII", value: "VII" },
  { label: "VIII", value: "VIII" },
];

export default nameSuffixes;
