import React from "react";
import { styled } from "@mui/material/styles";
import { Card, Button } from "@mui/material";
import { Link, useLocation } from "wouter";
import { useMutation } from "@apollo/client";
import { InvestigatorDataBankTerms } from "components/InvestigatorDataBankTerms";
import gql from "graphql-tag";

const PREFIX = "optIn";

const classes = {
  card: `${PREFIX}-card`,
  terms: `${PREFIX}-terms`,
  options: `${PREFIX}-options`,
  optional: `${PREFIX}-optional`,
};

const CardStyled = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2, 3),

  [`& .${classes.terms}`]: {
    background: theme.palette.grey[100],
    padding: theme.spacing(1, 3),
  },

  [`& .${classes.options}`]: {
    display: "grid",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    gridTemplateColumns: "auto auto",
    gridGap: theme.spacing(1),
    justifyContent: "left",
  },

  [`& .${classes.optional}`]: {
    fontWeight: "bold",
    background: "#eb7923",
    borderRadius: 2,
    marginTop: theme.spacing(1),
    padding: theme.spacing(1, 2),
    display: "table",
  },
}));

export default function OptIn() {
  let [confirmOptin] = useMutation(gql`
    mutation optin {
      optIn {
        status
      }
    }
  `);

  // eslint-disable-next-line
  let [_location, setLocation] = useLocation();

  async function handleOptIn() {
    try {
      await confirmOptin();
    } catch (e) {
    } finally {
      setLocation(
        window.location.search
          ? `${new URL(window.location.href).searchParams.get("redirect")}`
          : "/"
      );
    }
  }

  return (
    <CardStyled>
      <img src="/databank-logo.png" alt="Databank Logo" />
      <span className={classes.optional}>Optional</span>
      <h3>
        Expand your access to clinical research opportunities by opting in to
        the Investigator Databank
      </h3>
      <p>
        The Investigator Databank is used to identify investigators for over 500
        new clinical trials planned in a typical year. In addition, the
        Investigator Databank reduces investigator burden in the clinical trials
        process by allowing member companies to mutually reference each others’
        training and site inventory records.
      </p>
      <p>
        In order to achieve this goal, the Participating Companies need your
        permission to share with one another information that each has in its
        own records related to you and your site’s clinical research activities.
      </p>
      <h3>What data is included in the Investigator Databank?</h3>
      <p>
        The Investigator Databank includes information from the Participating
        Companies clinical trial management systems such as: contact details,
        site infrastructure, GCP training, and recruitment history.
      </p>
      <h4>Terms and Conditions</h4>

      <div className={classes.terms}>{InvestigatorDataBankTerms}</div>
      <div className={classes.options}>
        <Button variant="contained" color="primary" onClick={handleOptIn}>
          Opt-in to investigator databank
        </Button>
        <Button
          component={Link}
          to={
            window.location.search
              ? `${new URL(window.location.href).searchParams.get("redirect")}`
              : "/"
          }
        >
          No thanks, skip this and take me to my profile
        </Button>
      </div>
    </CardStyled>
  );
}
