import React from "react";
import { TextField, Typography } from "@mui/material";
import {
  useContactUsForm,
  ContactFormMachineService,
} from "../hooks/useContactUsForm";
interface Props {
  className?: string;
  service: ContactFormMachineService;
}

export function ContactUsForm({ service, className }: Props) {
  let form = useContactUsForm(service);

  return (
    <form
      id="contact-us-form"
      className={className}
      onSubmit={form.handleSubmit}
      aria-label="contact us form"
      noValidate
    >
      <TextField
        id="contact-us-form-name"
        label="Name"
        name="name"
        fullWidth
        required
        onChange={form.onChange}
        error={form.name.helperText.length !== 0}
        helperText={form.name.helperText}
        value={form.name.value || ""}
      />
      <TextField
        id="contact-us-form-email"
        label="Email"
        name="email"
        type="email"
        fullWidth
        required
        error={form.email.helperText.length !== 0}
        helperText={form.email.helperText}
        onChange={form.onChange}
        value={form.email.value || ""}
      />
      <TextField
        id="contact-us-form-message"
        label="Message"
        name="message"
        multiline
        fullWidth
        required
        rows={6}
        onChange={form.onChange}
        error={form.message.helperText.length !== 0}
        helperText={form.message.helperText}
        value={form.message.value || ""}
      />
      <Typography variant="h6" style={{ marginTop: "16px" }}>
        Would you like us to call you back ?
      </Typography>
      <Typography variant="subtitle2">
        Add your telephone number and we'll call you back within three working
        days
      </Typography>
      <TextField
        id="contact-us-form-phone"
        label="Telephone number (please include country code)"
        name="phone"
        fullWidth
        onChange={form.onChange}
        error={form.phone.helperText.length !== 0}
        helperText={form.phone.helperText}
        value={form.phone.value || ""}
      />
    </form>
  );
}
