let nameTitles = [
  { label: "Prof", value: "Prof" },
  { label: "Ms", value: "Ms" },
  { label: "Mrs", value: "Mrs" },
  { label: "Mr", value: "Mr" },
  { label: "Miss", value: "Miss" },
  { label: "Dr", value: "Dr" },
  { label: "Assoc Prof", value: "Assoc Prof" },
  { label: "Sir", value: "Sir" },
];

export default nameTitles;
