import gql from "graphql-tag";
import $specialties from "fixtures/specialties";

import { omit } from "rambda";
import { RoleType } from "utils/role";
import { UserCardMachine } from "components/ProfileUserCard";
import { StudiesCardMachine } from "components/StudiesCard";
import { ContactCardMachine } from "components/ContactCard";
import { LicenceCardMachine } from "components/LicenceCard";
import { TrainingCardMachine } from "components/TrainingCard";
import { FacilityDepartments } from "types/facility";
import { FacilityCardMachine } from "components/FacilityAffiliationsCard";
import { ResearchCardMachine } from "components/ResearchCard";
import { EducationCardMachine } from "components/EducationCard";
import { ExperienceCardMachine } from "components/ExperienceCard";
import { specialtyCodesToObjects } from "utils/specialty";
import { MedicalSpecialtyCardMachine } from "components/MedicalSpecialtyCard";
import { Facility, Department, Phases } from "types/facility";
import { useApolloClient, useMutation } from "@apollo/client";
import { useCards, UseCardsResult, ReturnedCard } from "./useCards";

import {
  User,
  Phone,
  Study,
  Profile,
  Licence,
  Experience,
  GQLLicence,
  Qualification,
  FacilityDepartment,
  Training,
  GQLTraining,
} from "types/profile";

import {
  ContactArgs,
  toGQLLicence,
  toGQLTraining,
  SpecialtyValues,
  toNormalisedLicence,
  toNormalisedTraining,
  useGetStudyIdentifier,
  useGetTrainingDownloadUrl,
} from "./useProfile";

export const GET_STUDY_EXPORT = gql`
  query StudiesExportDelegateQuery($userPk: Int!) {
    studiesExportDelegate(userPk: $userPk) {
      url
    }
  }
`;

//@ts-ignore
export function useGetStudyExportUrl(variables) {
  const client = useApolloClient();
  return async () => {
    const { data } = (await client.query({
      query: GET_STUDY_EXPORT,
      variables,
    })) as any;
    return data.studiesExportDelegate.url;
  };
}

type CardIds =
  | "facility"
  | "user"
  | "contact"
  | "medicalSpecialty"
  | "research"
  | "education"
  | "experience"
  | "studies"
  | "licence"
  | "training";

export type UseProfileResult = UseCardsResult & {
  cards: {
    [key in CardIds]: ReturnedCard;
  };
  deleteProfile: () => Promise<any>;
};

interface UseProfileDelegateOptions {
  userPk: string;
  isAdmin?: boolean;
}

export function useProfileDelegate({
  userPk,
  isAdmin = false,
}: UseProfileDelegateOptions) {
  const result = useCards({
    ids: [
      "details",
      "facility",
      "user",
      "contact",
      "medicalSpecialty",
      "research",
      "education",
      "experience",
      "studies",
      "licence",
      "training",
    ],
    progressGroups: (data) => {
      return data?.user.role !== RoleType.SiteStaff
        ? {
            basic: [
              "facility",
              "user",
              "contact",
              "medicalSpecialty",
              "research",
            ],
            cv: ["education", "experience", "studies", "licence", "training"],
          }
        : {
            basic: ["facility", "user", "contact"],
            cv: ["research", "education", "experience", "licence", "training"],
          };
    },
    cards: {
      details: {
        mapDataToProps: ({ user }) => ({ user }),
      },
      facility: {
        //@ts-ignore
        machine: FacilityCardMachine,
        mapDataToProps: ({ facilities }) => ({ facilities }),
        mutatorProps: {
          createFacility: useCreateFacility(userPk, isAdmin),
          deleteFacility: useDeleteFacility(userPk, isAdmin),
          deleteDepartment: useDeleteDepartment(userPk, isAdmin),
        },
        additionalProps: {
          getGoldenDepartments: useGetGoldenDepartments(),
        },
      },
      user: {
        //@ts-ignore
        machine: UserCardMachine,
        mapDataToProps: ({ user }) => ({ user }),
        mutatorProps: {
          updateUser: useUpdateUser(userPk, isAdmin),
        },
      },
      contact: {
        //@ts-ignore
        machine: ContactCardMachine,
        mapDataToProps: ({ phones, profileEmail, preferredEmail }) => ({
          phones,
          profileEmail,
          preferredEmail,
        }),
        mutatorProps: {
          createPhone: useCreatePhone(userPk, isAdmin),
          updatePhone: useUpdatePhone(userPk, isAdmin),
          deletePhone: useDeletePhone(userPk, isAdmin),
          updatePreferredEmail: useUpdatePreferredEmail(userPk, isAdmin),
          deletePreferredEmail: useDeletePreferredEmail(userPk, isAdmin),
        },
      },
      medicalSpecialty: {
        //@ts-ignore
        machine: MedicalSpecialtyCardMachine,
        mapDataToProps: ({ specialties }) => ({ specialties }),
        mutatorProps: {
          createSpecialty: useCreateSpecialty(userPk, isAdmin),
          updateSpecialty: useUpdateSpecialty(userPk, isAdmin),
          deleteSpecialty: useDeleteSpecialty(userPk, isAdmin),
        },
        allowCard: (data) => {
          return data?.user.role !== RoleType.SiteStaff;
        },
      },
      research: {
        //@ts-ignore
        machine: ResearchCardMachine,
        mapDataToProps: ({ phases, indications }) => ({ phases, indications }),
        mutatorProps: {
          updatePhases: useUpdatePhases(userPk, isAdmin),
          deletePhases: useDeletePhases(userPk, isAdmin),
          createIndication: useCreateIndication(userPk, isAdmin),
          updateIndication: useUpdateIndication(userPk, isAdmin),
          deleteIndication: useDeleteIndication(userPk, isAdmin),
        },
      },
      education: {
        //@ts-ignore
        machine: EducationCardMachine,
        mapDataToProps: ({ qualifications }) => ({ qualifications }),
        mutatorProps: {
          createQualification: useCreateQualification(userPk, isAdmin),
          updateQualification: useUpdateQualification(userPk, isAdmin),
          deleteQualification: useDeleteQualification(userPk, isAdmin),
        },
      },
      experience: {
        //@ts-ignore
        machine: ExperienceCardMachine,
        mapDataToProps: ({ experiences }) => ({ experiences }),
        mutatorProps: {
          createExperience: useCreateExperience(userPk, isAdmin),
          updateExperience: useUpdateExperience(userPk, isAdmin),
          deleteExperience: useDeleteExperience(userPk, isAdmin),
        },
      },
      studies: {
        //@ts-ignore
        machine: StudiesCardMachine,
        mapDataToProps: ({ studies }) => ({ studies }),
        mutatorProps: {
          createStudy: useCreateStudy(userPk, isAdmin),
          updateStudy: useUpdateStudy(userPk, isAdmin),
          deleteStudy: useDeleteStudy(userPk, isAdmin),
        },
        additionalProps: {
          getStudyExportUrl: useGetStudyExportUrl({ userPk }),
          getStudyIdentifier: useGetStudyIdentifier(),
        },
        allowCard: (data) => {
          return data?.user.role !== RoleType.SiteStaff;
        },
      },
      licence: {
        //@ts-ignore
        machine: LicenceCardMachine,
        mapDataToProps: ({ user, licences }) => ({ user, licences }),
        mutatorProps: {
          createLicence: useCreateLicence(userPk, isAdmin),
          updateLicence: useUpdateLicence(userPk, isAdmin),
          deleteLicence: useDeleteLicence(userPk, isAdmin),
        },
      },
      training: {
        //@ts-ignore
        machine: TrainingCardMachine,
        mapDataToProps: ({ trainings }) => ({ trainings }),
        mutatorProps: {
          createTraining: useCreateTraining(userPk, isAdmin),
          updateTraining: useUpdateTraining(userPk, isAdmin),
          deleteTraining: useDeleteTraining(userPk, isAdmin),
        },
        additionalProps: {
          getTrainingDownloadUrl: useGetTrainingDownloadUrl(),
        },
      },
    },
    additionalCardProps: {
      userPk,
    },
    fetchData: useFetchProfile(userPk, isAdmin),
    transformData: (data) => {
      if (!data) return data;
      const specialties = specialtyCodesToObjects(
        data.specialties,
        $specialties
      );

      const licences = data.licences.map((licence: any) =>
        toNormalisedLicence(licence)
      );

      const trainings = data.trainings.map((training: any) =>
        toNormalisedTraining(training)
      );

      return { ...data, specialties, licences, trainings };
    },
  });

  (result as any).deleteProfile = useDeleteUser(userPk);

  return result as UseProfileResult;
}

export const GET_PROFILE_ADMIN = gql`
  query GetProfileDelegate($userPk: Int!) {
    profileDelegate(userPk: $userPk) {
      user {
        nameTitle
        firstName
        middleName
        lastName
        nameSuffix
        role
        jobTitle
        drugdevStatus
        optIn {
          status
        }
        goldenPersonPk
        memberIds {
          vpd
          ids
        }
        dateJoined
        lastLogin
        email
      }
      qualifications {
        id: pk
        countryCode
        university
        qualification
        yearStarted
        yearFinished
        isMedicalDegree
      }
      experiences {
        id: pk
        facilityGoldenId: goldenFacilityPk
        facilityName
        yearStarted
        yearFinished
        countryCode
        jobTitle
      }
      licences {
        id: pk
        countryCode
        state
        number
        issuer
        type
        issueDate
        expirationDate
        npi
        digitalBadgeUrl
      }
      trainings {
        pk
        courseProvider
        completionDate
        startDate
        certificateFilename
      }
      phones {
        id: pk
        type
        countryCode
        number
        ext
      }
      phases {
        phaseOne
        phaseOneOrTwo
        phaseTwo
        phaseTwoOrThree
        phaseThree
        phaseThreeOrFour
        phaseFour
      }
      studies {
        id: pk
        goldenProtocolId: goldenProtocolPk
        primaryIndication
        title
        phase
        type
        status
        source
        nctNumber
        comment
        role
        studyId
      }
      facilities {
        id: pk
        name
        countryCode
        city
        region
        zipCode
        street
        primaryLocation
        departments {
          name
          type
          subType
          primaryLocation
        }
      }
      indications
      specialties
      profileEmail: email
      preferredEmail: contactEmail
    }
  }
`;

export const GET_PROFILE_DELEGATE = gql`
  query GetProfileDelegate($userPk: Int!) {
    profileDelegate(userPk: $userPk) {
      user {
        nameTitle
        firstName
        middleName
        lastName
        nameSuffix
        role
        jobTitle
        drugdevStatus
        optIn {
          status
        }
        goldenPersonPk
        memberIds {
          vpd
          ids
        }
        dateJoined
        lastLogin
        email
      }
      qualifications {
        id: pk
        countryCode
        university
        qualification
        yearStarted
        yearFinished
        isMedicalDegree
      }
      experiences {
        id: pk
        facilityGoldenId: goldenFacilityPk
        facilityName
        yearStarted
        yearFinished
        countryCode
        jobTitle
      }
      licences {
        id: pk
        countryCode
        state
        number
        issuer
        type
        issueDate
        expirationDate
        npi
        digitalBadgeUrl
      }
      trainings {
        pk
        courseProvider
        completionDate
        startDate
        certificateFilename
      }
      phones {
        id: pk
        type
        countryCode
        number
        ext
      }
      phases {
        phaseOne
        phaseOneOrTwo
        phaseTwo
        phaseTwoOrThree
        phaseThree
        phaseThreeOrFour
        phaseFour
      }
      studies {
        id: pk
        goldenProtocolId: goldenProtocolPk
        primaryIndication
        title
        phase
        type
        status
        source
        nctNumber
        comment
        role
        studyId
      }
      facilities {
        id: pk
        name
        countryCode
        city
        region
        zipCode
        street
        primaryLocation
        departments {
          name
          type
          subType
          primaryLocation
        }
      }
      indications
      specialties
      profileEmail: email
      preferredEmail: contactEmail
    }
    userFacilities: facilities {
      id: pk
      name
      countryCode
      city
      region
      zipCode
      street
      departments {
        name
        type
        subType
      }
    }
  }
`;

export function useFetchProfile(userPk: string, isAdmin: boolean) {
  const client = useApolloClient();
  return async () => {
    const result = await client.query({
      query: isAdmin ? GET_PROFILE_ADMIN : GET_PROFILE_DELEGATE,
      variables: { userPk },
    });
    return {
      ...result.data.profileDelegate,
      userFacilities: result.data.userFacilities,
    };
  };
}

export const CREATE_FACILITY_DELEGATE = gql`
  mutation CreateFacilityDelegate(
    $adminComment: String
    $departments: [IDepartment]!
    $goldenFacilityPk: Int!
    $userPk: Int!
    $primaryLocation: Boolean!
  ) {
    createFacilityDelegate(
      adminComment: $adminComment
      departments: $departments
      goldenFacilityPk: $goldenFacilityPk
      userPk: $userPk
      primaryLocation: $primaryLocation
    ) {
      facility {
        id: pk
        name
        countryCode
        city
        region
        zipCode
        street
        primaryLocation
        departments {
          name
          type
          subType
          primaryLocation
        }
      }
    }
  }
`;

export function useCreateFacility(userPk: string | number, isAdmin: boolean) {
  const client = useApolloClient();
  return async (facility: FacilityDepartments, adminComment?: string) => {
    const result = await client.mutate({
      mutation: CREATE_FACILITY_DELEGATE,
      variables: { ...facility, userPk, adminComment: adminComment || "" },
      refetchQueries: [
        {
          query: isAdmin ? GET_PROFILE_ADMIN : GET_PROFILE_DELEGATE,
          variables: { userPk },
        },
      ],
      awaitRefetchQueries: true,
    });
    return result.data.createFacilityDelegate.facility;
  };
}

export const DELETE_FACILITY_DELEGATE = gql`
  mutation DeleteFacilityDelegate(
    $adminComment: String
    $goldenFacilityPk: Int!
    $userPk: Int!
  ) {
    deleteFacilityDelegate(
      adminComment: $adminComment
      goldenFacilityPk: $goldenFacilityPk
      userPk: $userPk
    ) {
      status
    }
  }
`;

export function useDeleteFacility(userPk: string | number, isAdmin: boolean) {
  const client = useApolloClient();
  return async (facility: Facility, adminComment?: string) => {
    const result = await client.mutate({
      mutation: DELETE_FACILITY_DELEGATE,
      variables: { goldenFacilityPk: facility.id, adminComment, userPk },
      refetchQueries: [
        {
          query: isAdmin ? GET_PROFILE_ADMIN : GET_PROFILE_DELEGATE,
          variables: { userPk },
        },
      ],
      awaitRefetchQueries: true,
    });

    return result.data.deleteFacilityDelegate.status;
  };
}

export function useDeleteDepartment(userPk: string | number, isAdmin: boolean) {
  const deleteDepartments = useDeleteDepartments(userPk, isAdmin);
  return (
    { facilityId, department }: FacilityDepartment,
    adminComment?: string
  ) => {
    return deleteDepartments(
      {
        goldenFacilityPk: facilityId,
        departments: [department],
      },
      adminComment
    );
  };
}

export const DELETE_DEPARTMENTS_DELEGATE = gql`
  mutation DeleteDepartmentsDelegate(
    $adminComment: String
    $departments: [IDepartment]!
    $goldenFacilityPk: Int!
    $userPk: Int!
  ) {
    deleteDepartmentsDelegate(
      adminComment: $adminComment
      departments: $departments
      goldenFacilityPk: $goldenFacilityPk
      userPk: $userPk
    ) {
      status
    }
  }
`;

export function useDeleteDepartments(
  userPk: string | number,
  isAdmin: boolean
) {
  const client = useApolloClient();
  return async (
    { goldenFacilityPk, departments }: FacilityDepartments,
    adminComment?: string
  ) => {
    const result = await client.mutate({
      mutation: DELETE_DEPARTMENTS_DELEGATE,
      variables: {
        goldenFacilityPk,
        departments,
        userPk,
        adminComment: adminComment || "",
      },
      refetchQueries: [
        {
          query: isAdmin ? GET_PROFILE_ADMIN : GET_PROFILE_DELEGATE,
          variables: { userPk },
        },
      ],
      awaitRefetchQueries: true,
    });
    return result.data.deleteDepartmentsDelegate.status as boolean;
  };
}

export const GET_GOLDEN_DEPARTMENTS = gql`
  query GoldenFacility($goldenFacilityPk: Int!) {
    goldenFacility(goldenFacilityPk: $goldenFacilityPk) {
      departments {
        name
        type
        subType
      }
    }
  }
`;

export function useGetGoldenDepartments() {
  const client = useApolloClient();
  return async (goldenFacilityPk: number) => {
    const result = await client.query({
      query: GET_GOLDEN_DEPARTMENTS,
      variables: { goldenFacilityPk },
      fetchPolicy: "network-only",
    });
    return result.data.goldenFacility.departments as Department[];
  };
}

const UPDATE_USER_DELEGATE = gql`
  mutation UpdateUserDelegate(
    $adminComment: String
    $user: IUser!
    $userPk: Int!
  ) {
    updateUserDelegate(
      adminComment: $adminComment
      user: $user
      userPk: $userPk
    ) {
      user {
        nameTitle
        firstName
        middleName
        lastName
        nameSuffix
        role
        jobTitle
      }
    }
  }
`;

function useUpdateUser(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const [updateUserDelegate] = useMutation(UPDATE_USER_DELEGATE, {
    refetchQueries: [
      {
        query: isAdmin ? GET_PROFILE_ADMIN : GET_PROFILE_DELEGATE,
        variables: { userPk },
      },
    ],
    awaitRefetchQueries: true,
  });

  return async (user: Partial<User>, adminComment?: string) => {
    const profile = readProfile();
    const updatedUser = { ...profile.user, ...user };
    //@ts-ignore
    delete updatedUser.dateJoined;
    //@ts-ignore
    delete updatedUser.drugdevStatus;
    //@ts-ignore
    delete updatedUser.goldenPersonPk;
    //@ts-ignore
    delete updatedUser.memberIds;
    //@ts-ignore
    delete updatedUser.lastLogin;
    //@ts-ignore
    delete updatedUser.optIn;

    const result = (await updateUserDelegate({
      variables: { user: updatedUser, userPk, adminComment },
    })) as any;
    return result.data.updateUserDelegate.user;
  };
}

function useReadProfile(userPk: string | number, isAdmin: boolean) {
  const client = useApolloClient();
  return () => {
    const data = client.readQuery({
      query: isAdmin ? GET_PROFILE_ADMIN : GET_PROFILE_DELEGATE,
      variables: { userPk },
    });
    return data.profileDelegate as Profile;
  };
}

const UPDATE_CONTACT_DELEGATE = gql`
  mutation UpdateContactDelegate(
    $adminComment: String
    $contactEmail: String
    $phones: [IPhone]
    $userPk: Int!
  ) {
    updateContactDelegate(
      adminComment: $adminComment
      contactEmail: $contactEmail
      phones: $phones
      userPk: $userPk
    ) {
      contact {
        phones {
          id: pk
          type
          countryCode
          number
          ext
        }
        contactEmail
      }
    }
  }
`;

function useUpdateContact(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const [updateContact] = useMutation(UPDATE_CONTACT_DELEGATE, {
    refetchQueries: [
      {
        query: isAdmin ? GET_PROFILE_ADMIN : GET_PROFILE_DELEGATE,
        variables: { userPk },
      },
    ],
    awaitRefetchQueries: true,
  });

  return async (
    { preferredEmail, phones }: ContactArgs,
    adminComment?: string
  ) => {
    const profile = readProfile();

    let email;

    if (preferredEmail === undefined) {
      email = profile.preferredEmail;
    } else if (preferredEmail === null) {
      email = null;
    } else {
      email = preferredEmail;
    }

    const contact = {
      userPk,
      contactEmail: email,
      phones: (phones || profile.phones).map(omit(["id"]) as any),
      adminComment,
    };

    const result = await updateContact({ variables: contact });
    return result.data.updateContactDelegate.contact;
  };
}

function useCreatePhone(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateContact = useUpdateContact(userPk, isAdmin);
  return async (phone: Phone, adminComment?: string) => {
    const profile = readProfile();
    const phones = profile.phones.concat([phone]);
    return (await updateContact({ phones }, adminComment)).phones;
  };
}

function useUpdatePhone(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateContact = useUpdateContact(userPk, isAdmin);
  return async (phone: Phone, adminComment?: string) => {
    const profile = readProfile();
    const phones = profile.phones.map(($phone) => {
      return $phone.id === phone.id ? { ...$phone, ...phone } : { ...$phone };
    });
    return (await updateContact({ phones }, adminComment)).phones;
  };
}

function useDeletePhone(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateContact = useUpdateContact(userPk, isAdmin);
  return async (phone: Phone, adminComment?: string) => {
    const profile = readProfile();
    const phones = profile.phones.filter((p) => p.id !== phone.id);
    return (await updateContact({ phones }, adminComment)).phones;
  };
}

function useUpdatePreferredEmail(userPk: string | number, isAdmin: boolean) {
  const updateContact = useUpdateContact(userPk, isAdmin);
  return async function fooBaz(preferredEmail: string, adminComment?: string) {
    const result = await updateContact({ preferredEmail }, adminComment);
    return result.contactEmail;
  };
}

function useDeletePreferredEmail(userPk: string | number, isAdmin: boolean) {
  const updateContact = useUpdateContact(userPk, isAdmin);
  return async () => {
    const result = await updateContact({ preferredEmail: null });
    return result.contactEmail;
  };
}

const UPDATE_SPECIALTIES_DELEGATE = gql`
  mutation UpdateSpecialtiesDelegate(
    $adminComment: String
    $specialties: [String]!
    $userPk: Int!
  ) {
    updateSpecialtiesDelegate(
      adminComment: $adminComment
      specialties: $specialties
      userPk: $userPk
    ) {
      specialties
    }
  }
`;

function useUpdateSpecialties(userPk: string | number, isAdmin: boolean) {
  const [updateSpecialties] = useMutation(UPDATE_SPECIALTIES_DELEGATE, {
    refetchQueries: [
      {
        query: isAdmin ? GET_PROFILE_ADMIN : GET_PROFILE_DELEGATE,
        variables: { userPk },
      },
    ],
    awaitRefetchQueries: true,
  });
  return async (specialties: string[] = [], adminComment?: string) => {
    const result = await updateSpecialties({
      variables: { specialties, userPk, adminComment },
    });
    return result.data.updateSpecialtiesDelegate.specialties;
  };
}

function useCreateSpecialty(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateSpecialties = useUpdateSpecialties(userPk, isAdmin);
  return async (
    { specialty, subspecialty }: SpecialtyValues,
    adminComment?: string
  ) => {
    const values = {
      specialty: specialty === "" ? null : specialty,
      subspecialty: subspecialty === "" ? null : subspecialty,
    };
    const specialties = readProfile().specialties.concat(
      values.subspecialty! || values.specialty!
    );
    return updateSpecialties(specialties, adminComment);
  };
}

function useUpdateSpecialty(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateSpecialties = useUpdateSpecialties(userPk, isAdmin);
  return async (
    { id, specialty, subspecialty }: SpecialtyValues,
    adminComment?: string
  ) => {
    const values = {
      specialty: specialty === "" ? null : specialty,
      subspecialty: subspecialty === "" ? null : subspecialty,
    };
    const specialties = readProfile().specialties.map((value) => {
      if (value === id) {
        return (values.subspecialty || values.specialty) as string;
      }
      return value;
    });
    return updateSpecialties(specialties, adminComment);
  };
}

function useDeleteSpecialty(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateSpecialties = useUpdateSpecialties(userPk, isAdmin);
  return async (
    { specialtyCode = "", subspecialtyCode = "" },
    adminComment?: string
  ) => {
    const specialties = readProfile().specialties.filter((value) => {
      return value !== (subspecialtyCode || specialtyCode);
    });
    return updateSpecialties(specialties, adminComment);
  };
}

export const UPDATE_RESEARCH_DELEGATE = gql`
  mutation UpdateResearchDelegate(
    $adminComment: String
    $indications: [String]!
    $phases: IPhases!
    $userPk: Int!
  ) {
    updateResearchDelegate(
      adminComment: $adminComment
      indications: $indications
      phases: $phases
      userPk: $userPk
    ) {
      phases {
        phaseOne
        phaseOneOrTwo
        phaseTwo
        phaseTwoOrThree
        phaseThree
        phaseThreeOrFour
        phaseFour
      }
      indications
    }
  }
`;

function useUpdateResearch(userPk: string | number, isAdmin: boolean) {
  const [updateResearch] = useMutation(UPDATE_RESEARCH_DELEGATE, {
    refetchQueries: [
      {
        query: isAdmin ? GET_PROFILE_ADMIN : GET_PROFILE_DELEGATE,
        variables: { userPk },
      },
    ],
    awaitRefetchQueries: true,
  });
  return async (
    variables: { indications: string[]; phases: Phases },
    adminComment?: string
  ) => {
    const result = await updateResearch({
      variables: { ...variables, adminComment, userPk },
    });
    return result.data.updateResearchDelegate;
  };
}

function useUpdatePhases(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateResearch = useUpdateResearch(userPk, isAdmin);
  return (phases: Phases, adminComment?: string) => {
    const profile = readProfile();
    const variables = {
      indications: profile.indications,
      phases,
    };
    return updateResearch(variables, adminComment);
  };
}

function useDeletePhases(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateResearch = useUpdateResearch(userPk, isAdmin);
  return (adminComment?: string) => {
    const profile = readProfile();
    const variables = {
      indications: profile.indications,
      phases: {
        phaseOne: false,
        phaseOneOrTwo: false,
        phaseTwo: false,
        phaseTwoOrThree: false,
        phaseThree: false,
        phaseThreeOrFour: false,
        phaseFour: false,
      } as any,
    };
    return updateResearch(variables, adminComment);
  };
}

function useCreateIndication(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateResearch = useUpdateResearch(userPk, isAdmin);
  return ({ indication }: { indication: string }, adminComment?: string) => {
    const profile = readProfile();
    const variables = {
      indications: profile.indications.concat(indication),
      phases: profile.phases as any,
    };
    return updateResearch(variables, adminComment);
  };
}

function useUpdateIndication(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateResearch = useUpdateResearch(userPk, isAdmin);
  return (
    { id, indication }: { id: string; indication: string },
    adminComment?: string
  ) => {
    const profile = readProfile();
    const variables = {
      indications: profile.indications.map((value) => {
        if (value === id) {
          return indication;
        }
        return value;
      }),
      phases: profile.phases as any,
    };
    return updateResearch(variables, adminComment);
  };
}

function useDeleteIndication(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateResearch = useUpdateResearch(userPk, isAdmin);
  return (indication: string, adminComment?: string) => {
    const profile = readProfile();
    const variables = {
      indications: profile.indications.filter((value) => value !== indication),
      phases: profile.phases as any,
    };
    return updateResearch(variables, adminComment);
  };
}

const UPDATE_QUALIFICATIONS_DELEGATE = gql`
  mutation UpdateQualificationsDelegate(
    $adminComment: String
    $qualifications: [IQualification]!
    $userPk: Int!
  ) {
    updateQualificationsDelegate(
      adminComment: $adminComment
      qualifications: $qualifications
      userPk: $userPk
    ) {
      qualifications {
        id: pk
        countryCode
        university
        qualification
        yearStarted
        yearFinished
        isMedicalDegree
      }
    }
  }
`;

function useUpdateQualifications(userPk: string | number, isAdmin: boolean) {
  const [updateQualificationsDelegate] = useMutation(
    UPDATE_QUALIFICATIONS_DELEGATE,
    {
      refetchQueries: [
        {
          query: isAdmin ? GET_PROFILE_ADMIN : GET_PROFILE_DELEGATE,
          variables: { userPk },
        },
      ],
      awaitRefetchQueries: true,
    }
  );
  return async (qualifications: Qualification[], adminComment?: string) => {
    const variables = {
      userPk,
      adminComment,
      qualifications: qualifications.map(omit(["id"]) as any),
    };
    const result = await updateQualificationsDelegate({ variables });
    return result.data.updateQualificationsDelegate.qualifications;
  };
}

function useCreateQualification(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateQualifications = useUpdateQualifications(userPk, isAdmin);
  return (qualification: Qualification, adminComment?: string) => {
    const profile = readProfile();
    const qualifications = profile.qualifications.concat(qualification);
    return updateQualifications(qualifications, adminComment);
  };
}

function useUpdateQualification(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateQualifications = useUpdateQualifications(userPk, isAdmin);
  return (qualification: Qualification, adminComment?: string) => {
    const profile = readProfile();
    const qualifications = profile.qualifications.map((value) => {
      if (value.id === qualification.id) return qualification;
      return value;
    });
    return updateQualifications(qualifications, adminComment);
  };
}

function useDeleteQualification(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateQualifications = useUpdateQualifications(userPk, isAdmin);
  return (qualification: Qualification, adminComment?: string) => {
    const profile = readProfile();
    const qualifications = profile.qualifications.filter((value) => {
      return value.id !== qualification.id;
    });
    return updateQualifications(qualifications, adminComment);
  };
}

export const UPDATE_EXPERIENCES_DELEGATE = gql`
  mutation UpdateExperienceDelegate(
    $adminComment: String
    $experiences: [IExperience]!
    $userPk: Int!
  ) {
    updateExperiencesDelegate(
      adminComment: $adminComment
      experiences: $experiences
      userPk: $userPk
    ) {
      experiences {
        id: pk
        facilityGoldenId: goldenFacilityPk
        facilityName
        yearStarted
        yearFinished
        countryCode
        jobTitle
      }
    }
  }
`;

function useUpdateExperiences(userPk: string | number, isAdmin: boolean) {
  const [updateExperiencesDelegate] = useMutation(UPDATE_EXPERIENCES_DELEGATE, {
    refetchQueries: [
      {
        query: isAdmin ? GET_PROFILE_ADMIN : GET_PROFILE_DELEGATE,
        variables: { userPk },
      },
    ],
    awaitRefetchQueries: true,
  });
  return async (experiences: Experience[], adminComment?: string) => {
    const variables = {
      userPk,
      adminComment,
      experiences: experiences.map((x: any) => ({
        ...omit(["id", "facilityGoldenId"], x),
        goldenFacilityPk: x.facilityGoldenId,
      })),
    };
    const result = await updateExperiencesDelegate({ variables });
    return result.data.updateExperiencesDelegate.experiences;
  };
}

function useCreateExperience(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateExperiences = useUpdateExperiences(userPk, isAdmin);
  return async (experience: Experience, adminComment?: string) => {
    const profile = readProfile();
    const experiences = profile.experiences.concat(experience);
    return await updateExperiences(experiences, adminComment);
  };
}

function useUpdateExperience(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateExperiences = useUpdateExperiences(userPk, isAdmin);
  return async (experience: Experience, adminComment?: string) => {
    const profile = readProfile();
    const experiences = profile.experiences.map((value) => {
      if (value.id === experience.id) return experience;
      return value;
    });
    return await updateExperiences(experiences, adminComment);
  };
}

function useDeleteExperience(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateExperiences = useUpdateExperiences(userPk, isAdmin);
  return (experience: Experience, adminComment?: string) => {
    const profile = readProfile();
    const experiences = profile.experiences.filter((value) => {
      return value.id !== experience.id;
    });
    return updateExperiences(experiences, adminComment);
  };
}

export const UPDATE_STUDIES_DELEGATE = gql`
  mutation UpdateStudiesDelegate(
    $adminComment: String
    $studies: [IStudy]!
    $userPk: Int!
  ) {
    updateStudiesDelegate(
      adminComment: $adminComment
      studies: $studies
      userPk: $userPk
    ) {
      studies {
        id: pk
        goldenProtocolId: goldenProtocolPk
        primaryIndication
        title
        phase
        type
        status
        source
        nctNumber
        comment
        role
        studyId
      }
    }
  }
`;

function useUpdateStudies(userPk: string | number, isAdmin: boolean) {
  const [updateStudies] = useMutation(UPDATE_STUDIES_DELEGATE, {
    refetchQueries: [
      {
        query: isAdmin ? GET_PROFILE_ADMIN : GET_PROFILE_DELEGATE,
        variables: { userPk },
      },
    ],
    awaitRefetchQueries: true,
  });
  return async (studies: Study[], adminComment?: string) => {
    const variables = {
      userPk,
      adminComment,
      studies: studies.map((t) => {
        const study = { ...t, pk: t.id };

        if ((study.pk as any) === "") {
          study.pk = null as any;
        }

        delete study.id;
        delete study.source;
        delete study.goldenProtocolId;

        return study;
      }),
    };
    const result = await updateStudies({ variables });
    return result.data.updateStudiesDelegate.studies;
  };
}

function useCreateStudy(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateStudies = useUpdateStudies(userPk, isAdmin);
  return async (study: Study, adminComment?: string) => {
    const profile = readProfile();
    const studies = profile.studies.concat(study);
    return await updateStudies(studies, adminComment);
  };
}

function useUpdateStudy(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateStudies = useUpdateStudies(userPk, isAdmin);
  return async (study: Study, adminComment?: string) => {
    const profile = readProfile();
    const studies = profile.studies.map((value) => {
      if (value.id === study.id) return { ...value, ...study };
      return value;
    });
    return await updateStudies(studies, adminComment);
  };
}

function useDeleteStudy(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateStudies = useUpdateStudies(userPk, isAdmin);
  return (study: Study, adminComment?: string) => {
    const profile = readProfile();
    const studies = profile.studies.filter((value) => {
      return value.id !== study.id;
    });
    return updateStudies(studies, adminComment);
  };
}

export const UPDATE_LICENCES_DELEGATE = gql`
  mutation UpdateLicencesDelegate(
    $adminComment: String
    $licences: [ILicence]!
    $userPk: Int!
  ) {
    updateLicencesDelegate(
      adminComment: $adminComment
      licences: $licences
      userPk: $userPk
    ) {
      licences {
        id: pk
        countryCode
        state
        number
        issuer
        type
        issueDate
        expirationDate
        npi
        digitalBadgeUrl
      }
    }
  }
`;

function useUpdateLicences(userPk: string | number, isAdmin: boolean) {
  const [updateLicencesDelegate] = useMutation(UPDATE_LICENCES_DELEGATE, {
    refetchQueries: [
      {
        query: isAdmin ? GET_PROFILE_ADMIN : GET_PROFILE_DELEGATE,
        variables: { userPk },
      },
    ],
    awaitRefetchQueries: true,
  });
  return async (licences: GQLLicence[], adminComment?: string) => {
    const variables = {
      userPk,
      adminComment,
      licences: licences.map((l: any) => {
        const licence = { ...(omit(["id"], l) as any), pk: l.id };
        if (!licence.pk) {
          delete licence.pk;
        }
        if (licence.digitalBadgeUrl === "") {
          licence.digitalBadgeUrl = null;
        }
        return licence;
      }),
    };
    const result = await updateLicencesDelegate({ variables });
    return result.data.updateLicencesDelegate.licences;
  };
}

function useCreateLicence(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateLicences = useUpdateLicences(userPk, isAdmin);
  return async (licence: Licence, adminComment?: string) => {
    const profile = readProfile();
    const licences = profile.licences.concat(toGQLLicence(licence));
    return await updateLicences(licences, adminComment);
  };
}

function useUpdateLicence(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateLicences = useUpdateLicences(userPk, isAdmin);
  return async (licence: Licence, adminComment?: string) => {
    const profile = readProfile();
    const licences = profile.licences.map((value) => {
      if (value.id === licence.id) return toGQLLicence(licence);
      return value;
    });
    return await updateLicences(licences, adminComment);
  };
}

function useDeleteLicence(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateLicences = useUpdateLicences(userPk, isAdmin);
  return (licence: Licence, adminComment?: string) => {
    const profile = readProfile();
    const licences = profile.licences.filter((value) => {
      return value.id !== licence.id;
    });
    return updateLicences(licences, adminComment);
  };
}

export const UPDATE_TRAININGS_DELEGATE = gql`
  mutation UpdateTrainingsDelegate(
    $adminComment: String
    $trainings: [ITraining]!
    $userPk: Int!
  ) {
    updateTrainingsDelegate(
      adminComment: $adminComment
      trainings: $trainings
      userPk: $userPk
    ) {
      trainings {
        pk
        courseProvider
        completionDate
        startDate
        certificateFilename
      }
    }
  }
`;

function useUpdateTrainings(userPk: string | number, isAdmin: boolean) {
  const [updateTrainings] = useMutation(UPDATE_TRAININGS_DELEGATE, {
    refetchQueries: [
      {
        query: isAdmin ? GET_PROFILE_ADMIN : GET_PROFILE_DELEGATE,
        variables: { userPk },
      },
    ],
    awaitRefetchQueries: true,
  });
  return async (trainings: GQLTraining[], adminComment?: string) => {
    const variables = {
      userPk,
      adminComment,
      trainings: trainings.map((t) => {
        const training = { ...t };
        if (!training.pk) {
          //@ts-ignore
          delete training.pk;
        }
        return training;
      }),
    };
    const result = await updateTrainings({ variables });
    return result.data.updateTrainingsDelegate.trainings;
  };
}

function useCreateTraining(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateTrainings = useUpdateTrainings(userPk, isAdmin);
  return async (training: Training, adminComment?: string) => {
    const profile = readProfile();
    const trainings = profile.trainings.concat(toGQLTraining(training));
    return await updateTrainings(trainings, adminComment);
  };
}

function useUpdateTraining(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateTrainings = useUpdateTrainings(userPk, isAdmin);
  return async (training: Training, adminComment?: string) => {
    const profile = readProfile();
    const trainings = profile.trainings.map((value) => {
      if (value.pk === training.pk) return toGQLTraining(training);
      return value;
    });
    return await updateTrainings(trainings, adminComment);
  };
}

function useDeleteTraining(userPk: string | number, isAdmin: boolean) {
  const readProfile = useReadProfile(userPk, isAdmin);
  const updateTrainings = useUpdateTrainings(userPk, isAdmin);
  return (training: Training, adminComment?: string) => {
    const profile = readProfile();
    const trainings = profile.trainings.filter((value) => {
      return value.pk !== training.pk;
    });
    return updateTrainings(trainings, adminComment);
  };
}

export const DELETE_USER = gql`
  mutation DeleteUser($adminComment: String, $userPk: Int!) {
    deleteUser(adminComment: $adminComment, userPk: $userPk) {
      status
    }
  }
`;

function useDeleteUser(userPk: string | number) {
  const [deleteUser] = useMutation(DELETE_USER);
  return async (_: any, adminComment?: string) => {
    const result = await deleteUser({ variables: { adminComment, userPk } });
    return result.data.deleteUser.status;
  };
}
