import React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";

import { Phases } from "types/profile";
import { FormDialog } from "./FormDialog";
import { FormCheckboxField } from "./FormCheckboxField";
import { noop, asyncNoop } from "utils/noop";
import { useForm, SubmitFn } from "hooks/useForm";

import {
  useChangeReasonFormDialog,
  ChangeReasonFormDialog,
} from "./ChangeReasonFormDialog";

const defaultSpecialty = {} as Phases;

interface Props {
  title?: string;
  open?: boolean;
  entry?: Phases;
  submit?: SubmitFn;
  onCancel?: Callback;
  onSuccess?: Callback;
  withReason?: boolean;
}

const FormGroupStyled = styled(FormGroup)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(7, 1fr)",
}));

export function ResearchPhasesForm({
  open,
  entry,
  title = "Update research phases",
  submit: doSubmit = asyncNoop,
  onCancel: doCancel = noop,
  onSuccess = noop,
  withReason = false,
  ...otherProps
}: Props) {
  const $entry = entry || defaultSpecialty;
  const reasonForm = useChangeReasonFormDialog({
    doSubmit,
    formHookSubmit,
    withReason,
  });
  const { id, fields, submit, reset, submitting } = useForm({
    id: "research-phases-form",
    fields: {
      phaseOne: {
        type: "checkbox",
        name: "phaseOne",
        label: "Phase I",
        value: $entry.phaseOne,
      },
      phaseOneOrTwo: {
        type: "checkbox",
        name: "phaseOneOrTwo",
        label: "Phase I/II",
        value: $entry.phaseOneOrTwo,
      },
      phaseTwo: {
        type: "checkbox",
        name: "phaseTwo",
        label: "Phase II",
        value: $entry.phaseTwo,
      },
      phaseTwoOrThree: {
        type: "checkbox",
        name: "phaseTwoOrThree",
        label: "Phase II/III",
        value: $entry.phaseTwoOrThree,
      },
      phaseThree: {
        type: "checkbox",
        name: "phaseThree",
        label: "Phase III",
        value: $entry.phaseThree,
      },
      phaseThreeOrFour: {
        type: "checkbox",
        name: "phaseThreeOrFour",
        label: "Phase III/IV",
        value: $entry.phaseThreeOrFour,
      },
      phaseFour: {
        type: "checkbox",
        name: "phaseFour",
        label: "Phase IV",
        value: $entry.phaseFour,
      },
    },
    onReset: doCancel,
    submit: reasonForm.performSubmit,
    onError: console.error,
    onSuccess,
  });

  function formHookSubmit() {
    return submit();
  }

  const testId = (otherProps as any)["data-testid"] || ResearchPhasesForm.name;

  return (
    <>
      <FormDialog
        id={id}
        open={open}
        title={title}
        onCancel={reset}
        onSubmit={reasonForm.handleSubmitButtonClick}
        data-testid={testId}
        showProgressIndicator={submitting}
        {...otherProps}
      >
        <FormGroupStyled>
          <FormCheckboxField {...fields.phaseOne} />
          <FormCheckboxField {...fields.phaseOneOrTwo} />
          <FormCheckboxField {...fields.phaseTwo} />
          <FormCheckboxField {...fields.phaseTwoOrThree} />
          <FormCheckboxField {...fields.phaseThree} />
          <FormCheckboxField {...fields.phaseThreeOrFour} />
          <FormCheckboxField {...fields.phaseFour} />
        </FormGroupStyled>
      </FormDialog>
      <ChangeReasonFormDialog {...reasonForm.reasonDialog} />
    </>
  );
}
