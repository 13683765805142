import React, { useEffect } from "react";
import { useForm } from "hooks/useForm";
import { FormField } from "./FormField";
import { asyncNoop, noop } from "utils/noop";
import { LanguageCapabilities } from "types/facility";
import { FormRadioButtonGroup } from "components/FormRadioButtonGroup";
import { FieldTrueFalseRadioGroup } from "components/FieldTrueFalseRadioGroup";

const defaultValue = {} as LanguageCapabilities;

interface LanguageCapabilitiesFieldProps {
  value?: LanguageCapabilities;
  form: string;
  onChange: Callback;
}

export function LanguageCapabilitiesField({
  value = defaultValue,
  form,
  onChange,
}: LanguageCapabilitiesFieldProps) {
  const { fields, setField } = useForm({
    id: form,
    fields: {
      hasSufficientEnglish: {
        name: "hasSufficientEnglish",
        label:
          "Do your study staff have sufficient English knowledge to understand communications in English?",
        value: boolAsString(value.hasSufficientEnglish),
        "aria-label": "has sufficient english",
      },
      translatorSupportStudyConduct: {
        name: "translatorSupportStudyConduct",
        label:
          "Does your Facility/department have access to translators and translation support for study conduct (e.g. consent, study specific instructions)?",
        value: value.translatorSupportStudyConduct,
        options: [
          { value: "yes", label: "Yes" },
          { value: "no", label: "No" },
          { value: "n/a", label: "N/A" },
        ],
        "aria-label": "translator support for study conduct",
      },
    },
    submit: asyncNoop,
    onError: noop,
    onChange: (fields: any) => {
      const hasSufficientEnglish =
        fields.hasSufficientEnglish.value === ""
          ? null
          : asBool(fields.hasSufficientEnglish.value);
      const translatorSupportStudyConduct =
        fields.translatorSupportStudyConduct.value === ""
          ? null
          : fields.translatorSupportStudyConduct.value;
      onChange({
        hasSufficientEnglish,
        translatorSupportStudyConduct,
      });
    },
  });

  useEffect(() => {
    for (const key of Object.keys(value)) {
      setField(
        key,
        // @ts-ignore
        key === "hasSufficientEnglish" ? boolAsString(value[key]) : value[key]
      );
    }
  }, [JSON.stringify(value)]); // eslint-disable-line

  return (
    <FormField label="Language capabilities">
      <FieldTrueFalseRadioGroup {...fields.hasSufficientEnglish} />
      <FormRadioButtonGroup {...fields.translatorSupportStudyConduct} />
    </FormField>
  );
}

function asBool(value?: string | null) {
  if (value) return value === "true";
}

function boolAsString(value: any) {
  if (typeof value === "boolean") {
    return value.toString();
  }
}
