import React from "react";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import AdminShell from "components/AdminShell";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import { useRoute } from "wouter";
import { DepartmentProfile } from "routes/facilities/department/id";
import { Link as WouterLink } from "wouter";
import { DepartmentProfile as DepartmentProfileType } from "types/facility";

export default function AdminDepartmentProfile() {
  // eslint-disable-next-line
  const [_, params] = useRoute(
    "/admin/profile/:profileId/facilities/:facilityId/department/:departmentId"
  );

  return (
    <AdminShell profileId={params?.profileId!}>
      <DepartmentProfile
        goldenFacilityPk={parseInt(params?.facilityId!)}
        departmentPk={parseInt(params?.departmentId!)}
        Breadcrumbs={({ departmentProfile }) => (
          <DepartmentProfileBreadcrumbs
            profileId={params?.profileId!}
            departmentProfile={departmentProfile}
          />
        )}
      />
    </AdminShell>
  );
}

interface DepartmentProfileBreadcrumbsProps {
  profileId: string;
  departmentProfile: DepartmentProfileType;
}

function DepartmentProfileBreadcrumbs({
  profileId,
  departmentProfile,
}: DepartmentProfileBreadcrumbsProps) {
  const { departmentDetails, facilityDetails } = departmentProfile;
  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <WouterLink href="/facilities">
          <Link color="inherit" underline="hover">
            My facility profiles
          </Link>
        </WouterLink>
        <WouterLink
          href={`/admin/profile/${profileId}/facilities/${departmentProfile.goldenFacilityPk}`}
        >
          <Link color="inherit" underline="hover">
            {facilityDetails.name}
          </Link>
        </WouterLink>
        <Typography color="textPrimary">
          {departmentDetails.departmentName}
        </Typography>
      </Breadcrumbs>
      <Divider sx={{ mt: 2 }} />
    </div>
  );
}
