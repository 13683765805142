import React, { useState } from "react";

import { Snackbar } from "components/Snackbar";

interface ErrorBarProps {
  noActionButton?: boolean;
  open?: boolean;
  message: string;
  contentId?: string;
  "data-testid"?: string;
}

export function ErrorBar({
  message,
  open = true,
  contentId = "error-bar-message",
  noActionButton = false,
  "data-testid": testId = "ErrorBar",
}: ErrorBarProps) {
  let [isOpen, setIsOpen] = useState(open);

  return (
    /* At the moment, the Snackbar component will vertically centered within a
     * div and we don't want that to happen. Instead we want it to be rendered
     * top left so that the consumer of this component can control where it goes.
     * A quick and simple way to make this happen is to wrap it with a div, hence
     * why it is wrapped with one.
     */
    <div data-testid={testId}>
      <Snackbar
        open={isOpen}
        variant="error"
        message={message}
        contentId={contentId}
        noActionButton={noActionButton}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </div>
  );
}
