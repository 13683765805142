import React from "react";

import { AppBar, MenuLink } from "./AppBar";
import { useLocation } from "wouter";
import { Content } from "./Content";

interface Props {
  children?: ReactNodes;
}

const investigatorMenuItems = (location: string): MenuLink[] => [
  {
    title: "Your Profile",
    link: "/",
    ariaLabel: "Link to your profile",
    selected: location === "/"
  },
  {
    title: "Your CV",
    link: "/cv",
    ariaLabel: "Link to your cv",
    selected: location.includes("/cv")
  },
  {
    title: "Facility Profiles",
    link: "/facilities",
    ariaLabel: "Link to your facilities",
    selected: location.includes("/facilities")
  },
  {
    title: "Delegates",
    link: "/delegates",
    ariaLabel: "Link to your delegates",
    selected: location.includes("/delegates")
  }
  ]


export default function DefaultLayout({ children }: Props) {
  const location = useLocation()
  return (
    <>
      <AppBar showNav={true} menuItems={investigatorMenuItems(location[0])} />
      <Content>
        {children}
      </Content>
    </>
  );
}
