import React from "react";

import { FormDialog } from "./FormDialog";
import { noop } from "utils/noop";
import { useForm } from "hooks/useForm";
import { isValidDepartments } from "utils/department";
import { FacilityDepartmentField } from "./FacilityDepartmentField";
import { FacilityDepartments, Facility } from "types/profile";

import {
  useChangeReasonFormDialog,
  ChangeReasonFormDialog,
} from "./ChangeReasonFormDialog";

export interface FacilityDepartmentCreationFormProps {
  open?: boolean;
  title?: string;
  submit: (
    departments: FacilityDepartments,
    adminComment?: string
  ) => Promise<any>;
  onError?: Callback;
  onCancel?: Callback;
  onSuccess?: Callback;
  facility: Facility;
  withReason?: boolean;
}

export function FacilityDepartmentCreationFormDialog({
  open = false,
  submit: doSubmit,
  withReason = false,
  onSuccess = noop,
  onCancel: doCancel = noop,
  onError = console.error,
  title = "Add a department",
  facility,
}: FacilityDepartmentCreationFormProps) {
  const reasonForm = useChangeReasonFormDialog({
    doSubmit: performSubmit,
    formHookSubmit,
    withReason,
  });

  const { id, fields, submit, reset, submitting } = useForm({
    id: "facility-department-creation-form",
    fields: {
      goldenFacilityPk: {
        name: "goldenFacilityPk",
        value: facility.id,
      },
      primaryLocation: {
        name: "primaryLocation",
        value: false,
      },
      departments: {
        name: "departments",
        defaultValue: [],
        validate(departments) {
          return isValidDepartments(departments.value);
        },
      },
    },
    onReset: doCancel,
    submit: reasonForm.performSubmit,
    onError,
    onSuccess,
  });

  function formHookSubmit() {
    return submit();
  }

  async function performSubmit(values: AnyObject, adminComment?: string) {
    return await doSubmit(values as any, adminComment);
  }

  return (
    <>
      <FormDialog
        id={id}
        open={open}
        title={title}
        onCancel={reset}
        onSubmit={reasonForm.handleSubmitButtonClick}
        showProgressIndicator={submitting}
        maxWidth="sm"
      >
        <FacilityDepartmentField
          facility={facility}
          onChange={noop}
          {...fields.departments}
        />
      </FormDialog>
      <ChangeReasonFormDialog {...reasonForm.reasonDialog} />
    </>
  );
}
