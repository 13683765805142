import React from "react";
import { useTypeaheadQueries } from "hooks/useTypeaheadQueries";

import {
  AsyncAutocompleteField,
  AsyncAutocompleteFieldProps,
} from "./AsyncAutocompleteField";

export interface IndicationsAutocompleteFieldProps
  extends Omit<
    AsyncAutocompleteFieldProps,
    "fetchOptions" | "additionalQueryArgs"
  > {}

export function IndicationsAutocompleteField({
  onQueryError = console.error,
  ...otherProps
}: IndicationsAutocompleteFieldProps) {
  const { getIndications } = useTypeaheadQueries();

  return (
    <AsyncAutocompleteField
      fetchOptions={getIndications}
      getOptionLabel={(option) => option}
      getOptionValue={(option) => option}
      {...otherProps}
    />
  );
}
