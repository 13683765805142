import React from "react";
import { styled } from "@mui/material/styles";
import { SxProps } from "@mui/system";

const Fieldset = styled("fieldset")<{ error: boolean }>(({ theme, error }) => {
  let border = error
    ? `1px solid ${theme.palette.error.main}`
    : `1px solid ${theme.palette.divider}`;

  return {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    borderRadius: 4,
    border,
  };
});

const Legend = styled("legend")(({ theme }) => ({
  color: theme.palette.grey[800],
  paddingInlineStart: "5px !important",
  paddingInlineEnd: "5px !important",
}));

const FieldsetContents = styled("div")(({ theme }) => ({
  "& > *:not(:first-child)": {
    marginTop: theme.spacing(1),
  },
}));

interface Props {
  error?: boolean;
  legend: string;
  disabled?: boolean;
  children: ReactNodes;
  sx?: SxProps;
  required?: boolean;
}

export function FormFieldset({
  error = false,
  legend,
  children,
  sx,
  disabled,
  required = false,
}: Props) {
  let label = legend;

  if (required) {
    label = `${label} *`;
  }
  return (
    <Fieldset error={error} sx={sx} disabled={disabled}>
      <Legend children={label} />
      <FieldsetContents children={children} />
    </Fieldset>
  );
}
