import { Machine, assign, send, spawn } from "xstate";

export interface NotificationMachineContext {
  message?: string;
  variant?: string;
}

export type NotificationMachineEvents =
  | { type: "CLOSE_NOTIFICATION" }
  | {
      type: "SET_NOTIFICATION";
      data: {
        message: string;
        variant: string;
      };
    };

export function createNotificationActions(to: string) {
  return {
    notify(variant: "success" | "error", message: string) {
      return send(
        {
          type: "SET_NOTIFICATION",
          data: {
            variant,
            message,
          },
        },
        { to }
      );
    },
    spawn(
      opts = { name: to, sync: true },
      config: any = {},
      context: Partial<NotificationMachineContext> = {}
    ) {
      return spawn(
        notificationMachine.withConfig(config).withContext(context),
        opts
      );
    },
  };
}

export const notificationMachine = Machine<
  NotificationMachineContext,
  any,
  NotificationMachineEvents
>(
  {
    initial: "closed",
    context: {
      message: "",
      variant: "",
    },
    states: {
      closed: {
        on: {
          SET_NOTIFICATION: {
            actions: "setNotification",
            target: "open",
          },
        },
      },
      open: {
        on: {
          CLOSE_NOTIFICATION: {
            target: "closed",
          },
          SET_NOTIFICATION: {
            actions: "setNotification",
          },
        },
      },
    },
  },
  {
    actions: {
      //@ts-ignore
      setNotification: assign((context, { data }) => ({
        ...context,
        ...data,
      })),
    },
  }
);
