import React from "react";
import departmentTypes from "../fixtures/departmentTypes";

import {
  AutocompleteField,
  AutocompleteInstanceFieldProps,
} from "./AutocompleteField";

interface DepartmentSubtypeSelectFieldProps
  extends AutocompleteInstanceFieldProps {
  type?: number;
}

export function DepartmentSubtypeSelectField({
  type,
  "data-testid": testId = DepartmentSubtypeSelectField.name,
  ...props
}: DepartmentSubtypeSelectFieldProps) {
  return (
    <AutocompleteField
      id="departmentSubtype"
      name="departmentSubtype"
      label="Department subtype"
      data-testid={testId}
      options={getDepartmentSubtypeOptions(type)}
      {...props}
    />
  );
}

export function getDepartmentSubtypeOptions(typeCode?: number) {
  if (!typeCode) return [];
  const $type = departmentTypes.find(($type) => $type.code === typeCode);
  if (!$type) return [];
  return $type.subtypes.map((t) => ({
    label: t.name,
    value: t.code,
  }));
}
