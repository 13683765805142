//@ts-nocheck
import React from "react";
import {
  CircularProgress,
  Autocomplete,
  AutocompleteProps,
} from "@mui/material";
import { noop } from "utils/noop";
import { useMachine } from "@xstate/react";
import { TextField, TextFieldProps } from "@mui/material";
import { createMachine, assign, StateMachine } from "xstate";

const fire = debounce(350);

interface Context {
  queryCount: number;
  query: null | string;
  options: any[];
}

interface HookOptions {
  fetchOptions: Callback;
  onQueryError?: Callback;
  additionalQueryArgs?: any[];
}

const AsyncAutocompleteMachine = createMachine<Context>(
  {
    initial: "idle",
    predictableActionArguments: true,
    context: {
      queryCount: 0,
      query: null,
      options: [],
    },
    states: {
      idle: {
        on: {
          FETCH: {
            target: "fetching",
            actions: "setQuery",
          },
          CLEAR_OPTIONS: {
            actions: "clearOptions",
          },
          CLEAR_QUERY: {
            actions: "clearQuery",
          },
        },
      },
      fetching: {
        invoke: {
          src: "fetchOptions",
          onError: {
            actions: ["callErrorHandler"],
            target: "idle",
          },
          onDone: {
            target: "idle",
            actions: ["setOptions", "incrementQueryCount"],
          },
        },
      },
    },
  },
  //@ts-ignore
  {
    actions: {
      setQuery: assign((_, event) => ({
        query: event.query,
      })),
      setOptions: assign((_, event) => ({
        options: event.data,
      })),
      incrementQueryCount: assign((context) => ({
        queryCount: context.queryCount + 1,
      })),
      clearOptions: assign(() => ({
        options: [],
      })),
      clearQuery: assign(() => ({
        query: null,
      })),
    },
  }
) as StateMachine<Context, any, any>;

function useAutoSelect({
  fetchOptions,
  onQueryError = console.error,
  additionalQueryArgs = [],
}: HookOptions) {
  const [state, send] = useMachine(AsyncAutocompleteMachine, {
    actions: {
      callErrorHandler: (_, event) => {
        onQueryError(event.data);
      },
    },
    services: {
      fetchOptions: (_, event) => {
        return fetchOptions(event.query, ...event.additionalQueryArgs);
      },
    },
  });

  return {
    query: state.context.query,
    queryCount: state.context.queryCount,
    options: state.context.options,
    loading: state.matches("fetching"),
    fetchOptions: (query: string) => {
      send({ type: "FETCH", query, additionalQueryArgs });
    },
    clearOptions: () => {
      send({ type: "CLEAR_OPTIONS" });
    },
    clearQuery: () => {
      send({ type: "CLEAR_QUERY" });
    },
  };
}

export interface AsyncAutocompleteFieldProps {
  id?: string;
  label?: string;
  error?: boolean;
  value?: any;
  variant?: "filled" | "outlined" | "standard";
  required?: boolean;
  freeSolo?: boolean;
  fullWidth?: boolean;
  className?: string;
  helperText?: string;
  loadingText?: string;
  noOptionsText?: string;
  searchInstructionsText?: string;
  fetchOnOpen?: boolean;
  fetchOnOpenOnce?: boolean;
  fetchOnChange?: boolean;
  fetchOptions: (q: string, ...args: any) => Promise<any>;
  onQueryError?: Callback;
  additionalQueryArgs?: any[];
  onChange?: (value: any) => void;
  TextFieldProps?: Partial<TextFieldProps>;
  AutocompleteProps?: Partial<AutocompleteProps<any>>;
  getOptionLabel?: (option?: any) => string;
  getOptionValue?: (option?: any) => string;
  startAdornment?: (option?: any) => JSX.Element;
  [key: string]: any;
}

export function AsyncAutocompleteField({
  id,
  value,
  label,
  error,
  required,
  helperText,
  className,
  freeSolo = false,
  fullWidth = false,
  onChange = noop,
  variant = "outlined",
  loadingText = "Loading...",
  noOptionsText = "No options",
  searchInstructionsText = "Start typing for options",
  fetchOnOpen = false,
  fetchOnOpenOnce = false,
  fetchOnChange = true,
  fetchOptions,
  onQueryError,
  additionalQueryArgs,
  TextFieldProps = {},
  AutocompleteProps = {},
  getOptionLabel = (option) => option.label,
  getOptionValue = (option) => option.value,
  startAdornment,
  ...props
}: AsyncAutocompleteFieldProps) {
  const autocomplete = useAutoSelect({
    fetchOptions,
    onQueryError,
    additionalQueryArgs,
  });

  const $noOptionsText =
    autocomplete.query && !autocomplete.options.length
      ? noOptionsText
      : searchInstructionsText;

  return (
    <Autocomplete
      id={id}
      value={value}
      freeSolo={freeSolo}
      className={className}
      filterOptions={(x) => x}
      onOpen={() => {
        if (fetchOnOpen || fetchOnOpenOnce) {
          fire(autocomplete.fetchOptions, autocomplete.query!);
        }
      }}
      onChange={(_, option: any, reason) => {
        if (!option || reason === "clear") {
          autocomplete.clearQuery();
          getOptionValue();
        }
        onChange(option ? getOptionValue(option) : "");
        autocomplete.clearOptions();
      }}
      onInputChange={(_, value) => {
        if (!value) {
          autocomplete.clearQuery();
          autocomplete.clearOptions();
        }
      }}
      options={autocomplete.options}
      loading={autocomplete.loading}
      loadingText={loadingText}
      noOptionsText={$noOptionsText}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            required={required}
            label={label}
            variant={variant}
            error={error}
            name={props.name}
            helperText={helperText}
            onChange={(event) => {
              if (freeSolo) {
                onChange(event.target.value);
              }
              if (fetchOnChange) {
                fire(autocomplete.fetchOptions, event.target.value);
              }
            }}
            fullWidth={fullWidth}
            InputProps={{
              ...params.InputProps,
              startAdornment: startAdornment && startAdornment(value),
              endAdornment: (
                <>
                  {autocomplete.loading ? (
                    <CircularProgress
                      sx={{ color: "text.secondary" }}
                      size={14}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            {...TextFieldProps}
          />
        );
      }}
      {...props}
      {...AutocompleteProps}
    />
  );
}

function debounce(time) {
  let timer;
  return (fn: Function, ...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, time);
  };
}
