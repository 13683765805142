import React, { PropsWithChildren } from "react";
import { createMachine } from "xstate";
import { useMachine } from "@xstate/react";
import { LinearProgress } from "@mui/material";
import { MaintenanceScreen } from "components/MaintenanceScreen";

let MaintenanceModeMachine = createMachine(
  {
    initial: "loading",
    states: {
      loading: {
        invoke: {
          src: "checkAvailability",
          onDone: {
            target: "success",
          },
          onError: {
            target: "maintenaceMode",
          },
        },
      },
      success: {
        type: "final",
      },
      maintenaceMode: {
        type: "final",
      },
    },
  },
  {
    services: {
      async checkAvailability(_context) {
        let response = await fetch("/healthz");
        let data = await response.json();
        return data.status_code === 200 ? Promise.resolve() : Promise.reject();
      },
    },
  }
);

export function MaintenanceModeProvider({ children }: PropsWithChildren<{}>) {
  //@ts-ignore
  const [state] = useMachine(MaintenanceModeMachine);

  switch (state.value) {
    case "loading":
      return <LinearProgress aria-label="checking status" />;
    case "maintenaceMode":
      return <MaintenanceScreen />;
    default:
      return <>{children}</>;
  }
}
