import React from "react";

import { Card } from "components/Card";
import { MutatorFn } from "hooks/useCards";
import { TrainingForm } from "./TrainingForm";
import { TrainingTable } from "./TrainingTable";
import { DeletionDialog } from "./DeletionDialog";
import { useEditableData } from "hooks/useEditableData";
import { Training, User, Profile } from "types/profile";
import { CardMachine, ManagedCardProps, useCard } from "hooks/useCard";
import { hasIncompleteTrainings } from "utils/trainings";

export const TrainingCardMachine = CardMachine({ validateCard });

interface TrainingCardProps extends ManagedCardProps {
  user: User;
  userPk?: number;
  isAdmin?: boolean;
  trainings: Training[];
  createTraining: MutatorFn;
  updateTraining: MutatorFn;
  deleteTraining: MutatorFn;
  getTrainingDownloadUrl: (training: Training) => string;
}

export function TrainingCard({
  id = "training-card",
  user,
  userPk,
  isAdmin = false,
  service,
  trainings,
  createTraining,
  updateTraining,
  deleteTraining,
  getTrainingDownloadUrl,
  ...otherProps
}: TrainingCardProps) {
  const withReason = isAdmin;
  const { card } = useCard({ service });
  const { details, creationForm, updateForm, deletionDialog } = useEditableData(
    {
      create: createTraining,
      update: updateTraining,
      delete: deleteTraining,
    }
  );

  const handleCertificateDownload = async (entry: Training) => {
    try {
      window.open(await getTrainingDownloadUrl(entry));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card
      id={id}
      heading="GCP Training"
      arial-label="gcpTraining"
      data-testid={TrainingCard.name}
      {...card}
      {...otherProps}
    >
      <TrainingTable
        entries={trainings}
        onDownloadButtonClick={handleCertificateDownload}
        {...details}
      />

      {creationForm.open && (
        <TrainingForm
          title="Add training"
          withReason={withReason}
          data-testid="TrainingCreationForm"
          {...creationForm}
        />
      )}

      {updateForm.open && (
        <TrainingForm
          title="Update training"
          withReason={withReason}
          data-testid="TrainingUpdateForm"
          {...updateForm}
        />
      )}

      {deletionDialog.open && (
        <DeletionDialog withReason={withReason} {...deletionDialog} />
      )}
    </Card>
  );
}

export function validateCard({ trainings }: Profile) {
  if (!trainings.length || hasIncompleteTrainings(trainings)) {
    return "Please let us have your most recent GCP training details & certificate";
  }
}
