import departmentTypes from "fixtures/departmentTypes";
import { PopulationKeyValue, Department } from "types/facility";
import { maxLength } from "../validators/maxLength";
import { Fields } from "hooks/useForm";

export function getDepartmentType(typeCode: number) {
  return departmentTypes.find((type) => type.code === typeCode)!;
}

export function getDepartmentSubtype(typeCode: number, subtypeCode: number) {
  return departmentTypes
    .find((type) => type.code === typeCode)!
    .subtypes.find((subtype) => subtype.code === subtypeCode)!;
}

export function getDepartmentSubtypeName(
  typeCode: number,
  subtypeCode: number
) {
  const subtype = getDepartmentSubtype(typeCode, subtypeCode);
  if (!subtype) return "-";
  return subtype.name;
}

export function toPopulationValuesObj(groups: PopulationKeyValue[] = []) {
  return groups.reduce((acc, item) => {
    acc[item.key] = item.value;
    return acc;
  }, {} as AnyObject);
}

export function fieldsToPopulationKeyValueArray(fields: Fields) {
  return Object.keys(fields).map((key) => {
    return { key: fields[key].name, value: fields[key].value };
  });
}

export function isValidDepartment(department?: Department) {
  return Boolean(
    department &&
      department.type &&
      department.name &&
      department.name.trim() !== "" &&
      maxLength(255)(department.name)
  );
}

export function isValidDepartments(departments: any[] = []) {
  for (const department of departments) {
    if (isValidDepartment(department)) {
      return true;
    }
  }
  return "Departments are not valid";
}
