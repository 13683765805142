import Fade from "@mui/material/Fade";
import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

import { Form } from "./Form";
import { noop } from "utils/noop";
import { useForm } from "hooks/useForm";
import { required } from "validators/required";
import { DialogTrigger } from "./DialogTrigger";
import { StudyNctNumberDialog } from "./StudyNctNumberDialog";

const SearchInputSection = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& > *:not(:first-child)": {
    marginLeft: theme.spacing(2),
  },
}));

const TitleSection = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const ProgressIndicator = styled(CircularProgress)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: -8,
  marginLeft: -12,
}));

interface Props {
  open?: boolean;
  testId?: string;
  submit: (nctNumber: string) => Promise<any>;
  onError?: (error: any) => void;
  onCancel?: Callback;
  onSuccess?: (submissionResult: any) => void;
  actionBarLeft?: ReactNodes;
  "data-testid"?: string;
}

export function StudySearchForm({
  open,
  submit: doSubmit,
  onError = console.error,
  onCancel = noop,
  onSuccess = noop,
  "data-testid": testId = StudySearchForm.name,
  actionBarLeft,
  ...otherProps
}: Props) {
  const { id, fields, submit, submitting, error, reset } = useForm({
    id: "study-search-form",    
    fields: {
      nctNumber: {
        name: "nctNumber",
        value: "",
        required: true,
        validate: required,
      },
    },
    submit: ({ nctNumber }) => doSubmit(nctNumber.trim()),
    // @ts-ignore
    onError: (_, { data }) => onError(data),
    onSuccess: (_, { data }) => onSuccess(data),
    onReset: onCancel,
  });

  return (
    <Form
      id={id}
      aria-label="Study search"
      open={open}
      data-testid={testId}
      onCancel={reset}
      error={error}
      actionBarLeft={actionBarLeft}
      errorCodeMessageMap={{
        400: "The NCT number is invalid",
        404: `Study does not exists on clinicaltrials.gov`,
      }}
      noSubmitButton
      noActivityIndicator
      {...otherProps}
    >
      <div>
        <TitleSection>
          <Typography variant="h6" component="h2" sx={{ fontSize: 18 }}>
            Add a clinical study
          </Typography>
          <DialogTrigger
            dialog={StudyNctNumberDialog}
            dialogCloseProp={["onConfirm", "onClose"]}
          >
            <Button
              sx={{ color: "text.disabled", fontWeight: "normal" }}
              startIcon={<HelpIcon />}
            >
              How to find NCT numbers
            </Button>
          </DialogTrigger>
        </TitleSection>

        <SearchInputSection>
          <TextField
            label="National Clinical Trial (NCT) number"
            sx={{ width: 300 }}
            disabled={submitting}
            data-testid={`${testId}NctNumberField`}
            {...fields.nctNumber}
          />
          <Box sx={{ position: "relative" }}>
            <Button
              variant="contained"
              sx={{ height: 40, marginTop: "3px" }}
              onClick={submit}
              disabled={submitting}
              data-testid={`${testId}SubmitButton`}
            >
              Find Study
            </Button>
            <Fade in={submitting}>
              <ProgressIndicator
                size={20}
                aria-hidden={!submitting}
                data-testid={`${testId}ActivityIndicator`}
              />
            </Fade>
          </Box>
        </SearchInputSection>
        <Typography
          sx={{
            fontStyle: "italic",
            fontSize: 12,
            marginTop: 1,
            width: 430,
          }}
          variant="subtitle2"
        >
          Enter an NCT (ClinicalTrials.gov study identifier) number for a study
          and we will search for the record. If you don't have an NCT number add
          your study below.
        </Typography>
      </div>
    </Form>
  );
}

// TODO: Test actionBarLeft
