//@ts-nocheck
import { assign } from "xstate";
import { produce } from "immer"
import { isEmpty } from "validators/isEmpty";

export function validate(formValidators) {
  return async function (context) {
    let errors = [];
    for (let [key, validator] of Object.entries(formValidators)) {
      for (let [validate, message] of validator) {
        if (!validate(context[key].value, context)) {
          errors.push([key, message]);
          break;
        }
      }
    }
    return isEmpty(errors) || Promise.reject(errors);
  };
}

export const setErrors = assign((context, { data }) => {
  return produce(context, (newContext) => {
    for (let [key, helperText] of data) {
      newContext[key].error = true;
      newContext[key].helperText = helperText;
    }
  });
});

export const setField = assign((context, { name, value }) => {
  return produce(context, (newContext) => {
    newContext[name].error = false;
    newContext[name].helperText = "";
    newContext[name].value = value.trim();
  });
});
