import gql from "graphql-tag";

import { GET_PROFILE } from "./useProfile";
import { useApolloClient, useMutation } from "@apollo/client";
import { DepartmentDetailsCardMachine } from "components/DepartmentDetailsCard";
import { DepartmentContactsCardMachine } from "components/DepartmentContactsCard";
import { DepartmentCommitteeCardMachine } from "components/DepartmentCommitteeCard";
import { DepartmentEquipmentCardMachine } from "components/DepartmentEquipmentCard";
import { DepartmentCharacteristicsCardMachine } from "components/DepartmentCharacteristicsCard";
import { UseCardsResult, ReturnedCard, useCards } from "./useCards";
import { DepartmentPatientPopulationCardMachine } from "components/DepartmentPatientPopulationCard";
import { useAuth } from "hooks/useAuth";

import {
  useCreateContact,
  useDeleteContact,
  useCreateSelfContact,
  useUpdateCharacteristics,
  useUpdateCommitteeDetails,
  useUpdatePatientPopulation,
  useUpdateEquipmentDetails,
} from "./useFacilityMutations";

import {
  LovItem,
  FacilityProfile,
  DepartmentProfile,
  DepartmentDetails,
} from "types/facility";

type CardIds =
  | "details"
  | "contacts"
  | "characteristics"
  | "committee"
  | "patientPopulation"
  | "equipment";

export type Profile = DepartmentProfile & FacilityProfile;

export interface DepartmentProfileHookData {
  profile: Profile;
  facilityTypes: LovItem[];
  therapeuticAreas: LovItem[];
  isAdmin?: boolean;
}

export type DepartmentProfileHookResult = UseCardsResult & {
  data: DepartmentProfileHookData;
  cards: {
    [key in CardIds]: ReturnedCard;
  };
};

interface UseDepartmentProfileProps {
  goldenFacilityPk: number;
  departmentPk?: number;
}

export function useFacilityDepartmentProfile(props: UseDepartmentProfileProps) {
  const { isAdmin } = useAuth();
  const { departmentPk } = props;
  const isForFacilityProfile = !props.departmentPk;

  const ids = [
    "details",
    "contacts",
    "characteristics",
    "committee",
    "patientPopulation",
    "equipment",
  ];

  const cards = {
    details: {
      machine: DepartmentDetailsCardMachine,
      mapDataToProps: ({ profile }: any) => ({ profile }),
      mutatorProps: {
        updateDepartmentDetails: useUpdateDepartmentDetails(props),
      },
    },
    contacts: {
      machine: DepartmentContactsCardMachine,
      mapDataToProps: ({ profile }: any) => ({ profile }),
      mutatorProps: {
        createContact: useCreateContact(
          props.goldenFacilityPk,
          props.departmentPk
        ),
        deleteContact: useDeleteContact(
          props.goldenFacilityPk,
          props.departmentPk
        ),
        createSelfContact: useCreateSelfContact(
          props.goldenFacilityPk,
          props.departmentPk
        ),
      },
    },
    characteristics: {
      machine: DepartmentCharacteristicsCardMachine,
      mapDataToProps: ({ profile, facilityTypes, therapeuticAreas }: any) => ({
        profile,
        facilityTypes,
        therapeuticAreas,
      }),
      mutatorProps: {
        updateCharacteristics: useUpdateCharacteristics(
          props.goldenFacilityPk,
          props.departmentPk
        ),
      },
    },
    committee: {
      machine: DepartmentCommitteeCardMachine,
      mapDataToProps: ({ profile, departmentPk }: any) => ({
        profile,
        departmentPk,
      }),
      mutatorProps: {
        updateCommitteeDetails: useUpdateCommitteeDetails(
          props.goldenFacilityPk,
          props.departmentPk
        ),
      },
    },
    patientPopulation: {
      machine: DepartmentPatientPopulationCardMachine,
      mapDataToProps: ({ profile }: any) => ({
        profile,
      }),
      mutatorProps: {
        updatePatientPopulation: useUpdatePatientPopulation(
          props.goldenFacilityPk,
          props.departmentPk
        ),
      },
    },
    equipment: {
      machine: DepartmentEquipmentCardMachine,
      mapDataToProps: ({ profile, departmentPk }: any) => ({
        profile,
        departmentPk,
      }),
      mutatorProps: {
        updateEquipmentDetails: useUpdateEquipmentDetails(
          props.goldenFacilityPk,
          props.departmentPk
        ),
      },
    },
  };

  if (isForFacilityProfile) {
    ids.shift();
    //@ts-ignore
    delete cards.details;
  }

  return useCards({
    ids,
    //@ts-ignore
    cards,
    fetchData: useFetchProfile(props),
    transformData: (data) => {
      if (!data) return null;

      const facilityTypes = data?.lovFacilityTypes;
      const therapeuticAreas = data?.lovTherapeuticAreas;
      const profile = data?.departmentProfile || data?.facilityProfile;

      return {
        isAdmin,
        profile,
        departmentPk,
        facilityTypes,
        therapeuticAreas,
      };
    },
  }) as DepartmentProfileHookResult;
}

export function useFetchProfile({
  goldenFacilityPk,
  departmentPk,
}: UseDepartmentProfileProps) {
  const fetchFacilityProfile = useFetchFacilityProfile({ goldenFacilityPk });
  const fetchDepartmentProfile = useFetchDepartmentProfile({
    goldenFacilityPk,
    departmentPk,
  });
  const fetchProfile = departmentPk
    ? fetchDepartmentProfile
    : fetchFacilityProfile;

  return async () => await fetchProfile();
}

export const GET_DEPARTMENT_PROFILE = gql`
  query GetDepartmentProfile($goldenFacilityPk: Int!, $departmentPk: Int!) {
    lovFacilityTypes {
      code
      name
      description
    }
    lovTherapeuticAreas {
      code
      name
      description
    }
    departmentProfile(
      goldenFacilityPk: $goldenFacilityPk
      departmentPk: $departmentPk
    ) {
      goldenFacilityPk
      contacts {
        name
        email
        myself
        type
      }
      facilityDetails {
        pk
        name
        countryCode
        city
        region
        zipCode
        street
        departments {
          name
          type
          subType
        }
        ethnicities {
          name
          value
          continent
          subGroups
        }
      }
      departmentDetails {
        departmentName
        address
        address2
        address3
        postal
        city
        state
        countryCode
        type
        subType
      }
      irb {
        anyCommittee
        ownLocalIrb
        otherIrb
        useCommitteeTypes {
          usesLocal
          usesCentral
          usesCentralAsLocal
          usesSponsorSupplied
        }
      }
      equipment {
        diagnostics {
          ct
          mri
          pet
          xray
          dxa
          flro
          mra
          mrs
          mammo
          nmed
          ultrasound
          ecg
          echo
          spirometry
          other
        }
        treatment {
          inPatientAdmission
          isInfusionCapable
          pgxCollectionResearch
          pkPdCapability
          pkPdOutsideHours
        }
        equipmentDetails {
          labSamplesCentrifuge
          refrigeratedCentrifuge
          refrigeratorForSamples
          sopRoutineMaintenance
          treatMedicalEmergencies
          freezers {
            minus20ToMinus30
            minus70
            minus135
          }
        }
      }
      patientPopulation {
        ethnicities {
          key
          value
        }
        ageGroups {
          key
          value
        }
      }
      characteristics {
        dedicatedResearchFacility
        govAffiliation
        url
        languageCapabilities {
          hasSufficientEnglish
          translatorSupportStudyConduct
        }
        experience {
          academic
          government
          industry
          investigatorInitiated
          other
        }
        hours {
          all
          evenings
          weekends
        }
        phases {
          phaseZero
          phaseOne
          phaseOneOrTwo
          phaseTwo
          phaseTwoOrThree
          phaseThree
          phaseThreeOrFour
          phaseFour
        }
        therapeuticAreas {
          code
          value
        }
        type
        typeOther
      }
    }
  }
`;

export function useFetchDepartmentProfile({
  goldenFacilityPk,
  departmentPk,
}: UseDepartmentProfileProps) {
  const client = useApolloClient();
  return async () => {
    const result = await client.query({
      query: GET_DEPARTMENT_PROFILE,
      variables: { goldenFacilityPk, departmentPk },
    });
    return result.data;
  };
}

export const GET_FACILITY_PROFILE = gql`
  query GetFacilityProfile($goldenFacilityPk: Int!) {
    lovFacilityTypes {
      code
      name
      description
    }
    lovTherapeuticAreas {
      code
      name
      description
    }
    facilityProfile(goldenFacilityPk: $goldenFacilityPk) {
      goldenFacilityPk
      contacts {
        name
        email
        myself
        type
      }
      facilityDetails {
        pk
        name
        countryCode
        city
        region
        zipCode
        street
        departments {
          name
          type
          subType
        }
        ethnicities {
          name
          value
          continent
          subGroups
        }
      }
      irb {
        anyCommittee
        ownLocalIrb
        otherIrb
        useCommitteeTypes {
          usesLocal
          usesCentral
          usesCentralAsLocal
          usesSponsorSupplied
        }
      }
      equipment {
        diagnostics {
          ct
          mri
          pet
          xray
          dxa
          flro
          mra
          mrs
          mammo
          nmed
          ultrasound
          ecg
          echo
          spirometry
          other
        }
        treatment {
          inPatientAdmission
          isInfusionCapable
          pgxCollectionResearch
          pkPdCapability
          pkPdOutsideHours
        }
        equipmentDetails {
          labSamplesCentrifuge
          refrigeratedCentrifuge
          refrigeratorForSamples
          sopRoutineMaintenance
          treatMedicalEmergencies
          freezers {
            minus20ToMinus30
            minus70
            minus135
          }
        }
      }
      patientPopulation {
        ethnicities {
          key
          value
        }
        ageGroups {
          key
          value
        }
      }
      characteristics {
        dedicatedResearchFacility
        govAffiliation
        url
        languageCapabilities {
          hasSufficientEnglish
          translatorSupportStudyConduct
        }
        experience {
          academic
          government
          industry
          investigatorInitiated
          other
        }
        hours {
          all
          evenings
          weekends
        }
        phases {
          phaseZero
          phaseOne
          phaseOneOrTwo
          phaseTwo
          phaseTwoOrThree
          phaseThree
          phaseThreeOrFour
          phaseFour
        }
        therapeuticAreas {
          code
          value
        }
        type
        typeOther
      }
    }
  }
`;

export function useFetchFacilityProfile({
  goldenFacilityPk,
}: Partial<UseDepartmentProfileProps>) {
  const client = useApolloClient();
  return async () => {
    const result = await client.query({
      query: GET_FACILITY_PROFILE,
      variables: { goldenFacilityPk },
    });
    return result.data;
  };
}

export const UPDATE_FACILITY_DEPARTMENT_DETAILS = gql`
  mutation UpdateFacilityDepartmentDetails(
    $departmentDetails: IDepartmentDetails!
    $departmentPk: Int!
    $goldenFacilityPk: Int!
  ) {
    updateFacilityDepartmentDetails(
      departmentDetails: $departmentDetails
      departmentPk: $departmentPk
      goldenFacilityPk: $goldenFacilityPk
    ) {
      goldenFacilityPk
      departmentDetails {
        departmentName
        address
        address2
        address3
        postal
        city
        state
        countryCode
        type
        subType
      }
      departmentPk
    }
  }
`;

function useUpdateDepartmentDetails({
  goldenFacilityPk,
  departmentPk,
}: UseDepartmentProfileProps) {
  const [updateDetails] = useMutation(UPDATE_FACILITY_DEPARTMENT_DETAILS, {
    refetchQueries: [
      {
        query: GET_DEPARTMENT_PROFILE,
        variables: { goldenFacilityPk, departmentPk },
      },
      {
        query: GET_PROFILE,
      },
    ],
    awaitRefetchQueries: true,
  });
  return async (department: DepartmentDetails) => {
    const result = await updateDetails({
      variables: {
        departmentDetails: {
          departmentName: department.departmentName,
          address: department.address,
          address2: department.address2,
          address3: department.address3,
          postal: department.postal,
        },
        departmentPk,
        goldenFacilityPk,
      },
    });
    return result.data.updateFacilityDepartmentDetails;
  };
}
