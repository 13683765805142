import React from "react";
import { useTypeaheadQueries } from "hooks/useTypeaheadQueries";

import {
  AsyncAutocompleteField,
  AsyncAutocompleteFieldProps,
} from "./AsyncAutocompleteField";

export interface StateAutocompleteFieldProps
  extends Omit<
    AsyncAutocompleteFieldProps,
    "fetchOptions" | "additionalQueryArgs"
  > {
  countryCode: string;
}

export function StateAutocompleteField({
  name = "state",
  label = "State/Province/County",
  onQueryError = console.error,
  countryCode,
  ...otherProps
}: StateAutocompleteFieldProps) {
  const { getRegions } = useTypeaheadQueries();

  function fetchOptions(q: string) {
    return getRegions(q, countryCode);
  }

  return (
    <AsyncAutocompleteField
      name={name}
      label={label}
      fetchOptions={fetchOptions}
      getOptionLabel={(option) => option}
      getOptionValue={(option) => option}
      additionalQueryArgs={[countryCode]}
      {...otherProps}
    />
  );
}
