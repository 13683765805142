import React from "react";
import { Card } from "components/Card";
import { noop } from "utils/noop";
import {
  isPrimaryLocationSelected,
  isNonePrimaryLocationSelected,
} from "utils/facilities";
import { MutatorFn } from "hooks/useCards";
import { FacilityList } from "./FacilityList";
import { DeletionDialog } from "./DeletionDialog";
import { useEditableData } from "hooks/useEditableData";
import { Facility, Profile } from "types/profile";
import { FacilityCreationFormDialog } from "./FacilityCreationFormDialog";
import { FacilityDepartmentCreationFormDialog } from "./FacilityDepartmentCreationFormDialog";
import { FacilityPrimaryLocationDialog } from "./FacilityPrimaryLocationFormDialog";
import { useCard, CardMachine, ManagedCardProps } from "hooks/useCard";
import { useAuth } from "hooks/useAuth";

export const SUB_HEADING =
  "Please select your facility / department name or click the pencil icon to view or add information";

export const FacilityCardMachine = CardMachine({ validateCard });

interface FacilityAffiliationsCardProps extends ManagedCardProps {
  userPk?: number;
  facilities: Facility[];
  userFacilities?: Facility[];
  createFacility: MutatorFn;
  deleteFacility: MutatorFn;
  deleteDepartment: MutatorFn;
}

export function FacilityAffiliationsCard({
  id = "facility-card",
  userPk,
  service,
  facilities,
  userFacilities = [],
  subHeading = SUB_HEADING,
  createFacility,
  deleteFacility,
  deleteDepartment,
  ...otherProps
}: FacilityAffiliationsCardProps) {
  let { isAdmin } = useAuth();

  const withReason = isAdmin;

  const { card } = useCard({ service });

  const facility = useEditableData({
    create: createFacility,
    delete: deleteFacility,
  });

  const department = useEditableData({
    create: createFacility,
    delete: deleteDepartment,
    useCreationFormArg: true,
  });

  const primaryLocation = useEditableData({
    create: createFacility,
  });

  return (
    <Card
      id={id}
      heading="Facility Affiliations"
      subHeading={subHeading}
      aria-label="Facility affiliations"
      data-testid={FacilityAffiliationsCard.name}
      showExtraWarning={facilities.length === 0 ? true : false}
      hideSubHeadingDuringWarning
      {...card}
      {...otherProps}
    >
      <FacilityList
        userPk={userPk}
        entries={facilities}
        userEntries={userFacilities}
        onCreateDepartmentButtonClick={department.details.onCreateButtonClick}
        onDeleteDepartmentButtonClick={department.details.onDeleteButtonClick}
        onUpdateDepartmentButtonClick={noop}
        onCreatePrimaryLocationButtonClick={
          primaryLocation.details.onCreateButtonClick
        }
        {...facility.details}
      />
      {facility.creationForm.open && (
        <FacilityCreationFormDialog
          withReason={withReason}
          {...facility.creationForm}
        />
      )}
      {facility.deletionDialog.open && (
        <DeletionDialog withReason={withReason} {...facility.deletionDialog} />
      )}
      {department.creationForm.open && (
        <FacilityDepartmentCreationFormDialog
          withReason={withReason}
          facility={department.creationForm.arg}
          {...department.creationForm}
        />
      )}
      {department.deletionDialog.open && (
        <DeletionDialog
          withReason={isAdmin}
          data-testid="FacilityDepartmentDeletionDialog"
          {...department.deletionDialog}
        />
      )}
      {primaryLocation.creationForm.open && (
        <FacilityPrimaryLocationDialog
          withReason={isAdmin}
          facilities={facilities}
          {...primaryLocation.creationForm}
        />
      )}
    </Card>
  );
}

function validateCard({ facilities }: Profile) {
  if (!facilities.length) {
    return "Tell us where you work to make it easier for Sponsors and CROs to find and select you!";
  }

  if (
    !isPrimaryLocationSelected(facilities) &&
    isNonePrimaryLocationSelected(facilities)
  ) {
    return "Please indicate your Primary Location";
  }
}
