import React from "react";
import {
  Fade,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogContentText,
  DialogProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Snackbar } from "./Snackbar";
import { useMachine } from "@xstate/react";
import { noop, asyncNoop } from "utils/noop";
import { assign, createMachine } from "xstate";
import {
  ChangeReasonFormDialog,
  useChangeReasonFormDialog,
} from "./ChangeReasonFormDialog";

export const GENERIC_ERROR_MESSAGE =
  "Something went wrong. Please try again later";

const DeletionMachine = createMachine(
  {
    id: "delete",
    initial: "idle",
    context: {
      error: null,
    },
    states: {
      idle: {
        on: {
          DELETE: {
            target: "deleting",
          },
          CLOSE_ERROR: {
            actions: "clearError",
          },
        },
      },
      deleting: {
        entry: "clearError",
        invoke: {
          src: "deleteEntry",
          onDone: {
            target: "idle",
            actions: "dispatchSuccess",
          },
          onError: {
            target: "idle",
            actions: ["setError", "dispatchError"],
          },
        },
      },
    },
  },
  {
    actions: {
      clearError: assign({ error: null }),
      setError: assign((_, event) => {
        return {
          //@ts-ignore
          error: event.data,
        };
      }),
    },
  }
);

interface DeletionDialogProps extends Omit<DialogProps, "open" | "children"> {
  open?: boolean;
  entry?: any;
  submit: (entry: any) => Promise<any>;
  testId?: string;
  onError?: Callback;
  onCancel?: Callback;
  onSuccess?: Callback;
  withReason?: boolean;
  confirmationMessage?: string;
  "data-testid"?: string;
}

function useDeletionDialog({
  open = false,
  entry,
  testId,
  submit,
  onCancel,
  onError = noop,
  onSuccess = noop,
}: DeletionDialogProps) {
  //@ts-ignore
  const [state, send] = useMachine(DeletionMachine, {
    actions: {
      //@ts-ignore
      dispatchSuccess: (_, event) => onSuccess(event.data),
      //@ts-ignore
      dispatchError: (_, event) => onError(event.data),
    },
    services: {
      deleteEntry: () => submit(entry),
    },
  });
  return {
    open,
    entry,
    onCancel,
    error: state.context.error ? GENERIC_ERROR_MESSAGE : undefined,
    loading: state.matches("deleting"),
    onConfirm: async () => send({ type: "DELETE" }),
    onErrorBarClose: () => send({ type: "CLOSE_ERROR" }),
    ["data-testid"]: testId, // eslint-disable-line no-useless-computed-key
  };
}

export function DeletionDialog({
  withReason = false,
  confirmationMessage,
  "data-testid": testId = DeletionDialog.name,
  ...otherProps
}: DeletionDialogProps) {
  const reasonForm = useChangeReasonFormDialog({
    doSubmit: otherProps.submit,
    formHookSubmit,
    withReason,
  });
  const dialog = useDeletionDialog({
    testId,
    ...otherProps,
    submit: reasonForm.performSubmit,
  });

  function formHookSubmit() {
    return dialog.onConfirm();
  }

  return (
    <>
      <StatelessDeletionDialog
        {...dialog}
        confirmationMessage={confirmationMessage}
        onConfirm={reasonForm.handleSubmitButtonClick}
      />
      <ChangeReasonFormDialog {...reasonForm.reasonDialog} />
    </>
  );
}

const DialogActionsStyled = styled(DialogActions)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "auto 1fr auto auto",
  alignItems: "center",
  margin: theme.spacing(0.5, 1),
}));

interface StatelessDeletionDialogProps extends Omit<DialogProps, "open"> {
  open?: boolean;
  error?: string;
  testId?: string;
  loading?: boolean;
  onCancel?: Callback;
  onConfirm?: Callback;
  onErrorBarClose?: Callback;
  confirmationMessage?: string;
  "data-testid"?: string;
}

export function StatelessDeletionDialog({
  error,
  open = false,
  loading = false,
  onCancel = noop,
  onConfirm = asyncNoop,
  onErrorBarClose = noop,
  confirmationMessage = "Are you sure you want to delete this entry?",
  "data-testid": testId = StatelessDeletionDialog.name,
}: StatelessDeletionDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      data-testid={testId}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
      <DialogContent>
        {error && (
          <Snackbar
            open
            variant="error"
            onClose={onErrorBarClose}
            message={error}
            data-testid={`${testId}ErrorBar`}
          />
        )}

        <DialogContentText id="alert-dialog-description">
          {confirmationMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActionsStyled>
        <Fade in={loading}>
          <CircularProgress size={20} aria-hidden={!loading} />
        </Fade>
        <span />
        <Button onClick={onCancel}>Cancel</Button>
        <Button variant="contained" onClick={onConfirm} autoFocus>
          Delete
        </Button>
      </DialogActionsStyled>
    </Dialog>
  );
}
