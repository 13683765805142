import React, { ChangeEvent, FunctionComponent } from "react";
import { styled } from "@mui/material/styles";
import { CheckCircle } from "@mui/icons-material";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { FacilityAutocompleteField } from "components/FacilityAutocompleteField";
import { CountrySelectField } from "components/CountrySelectField";
import { StateAutocompleteField } from "components/StateAutocompleteField";
import { Tooltip } from "components/Tooltip";
import {
  PrimaryFacilityFormMachineService,
  usePrimaryFacilityForm,
} from "hooks/usePrimaryFacilityForm";

const Root = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  width: "100%",
  alignItems: "center",
  color: theme.palette.grey[800],

  ".MuiFormHelperText-root": {
    marginLeft: 0,
  },
  ".MuiAutocomplete-root": {
    marginTop: theme.spacing(1),
    width: "100%",
  },
}));

const IntroTextStyled = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}));

const RegisterProfileTextStyled = styled(Typography)(({ theme }) => ({
  alignSelf: "flex-start",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const FacilityInputContainerStyled = styled(FormControl)(() => ({
  position: "relative",
}));

const FacilityInputInnerStyled = styled(FormControl)(() => ({
  position: "relative",
}));

const FacilityInputStyled = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const FormFieldsStyled = styled("div")(({ theme }) => ({
  justifyContent: "space-between",
  marginBottom: "auto",
  flex: "1 0 100%",
  width: "100%",
  marginTop: theme.spacing(3),
  paddingRight: theme.spacing(4),
}));

const SelectorTooltipStyled = styled("div")(({ theme }) => ({
  position: "absolute",
  right: `-${theme.spacing(2.7)}`,
  top: theme.spacing(2),
  height: 20,
  width: 20,
}));

const InlineTooltipStyled = styled("div")(({ theme }) => ({
  display: "inline-block",
  height: 20,
  width: 20,
  position: "relative",
  ".MuiButtonBase-root": {
    position: "absolute",
    top: `-${theme.spacing(0.7)}`,
  },
}));

const TooltipTextStyled = styled("div")(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
}));

interface Props {
  service: PrimaryFacilityFormMachineService;
  className?: string;
}

export const RegisterPrimaryFacilityForm: FunctionComponent<Props> = ({
  service,
  className,
}) => {
  const {
    countryCode,
    state,
    facility,
    customFacility,
    customFacilityAddress,
    customFacilityPostalCode,
    isCustomFacilityActive,
    onChange,
    setValue,
    addCustomFacility,
    customFacilityEnabled,
  } = usePrimaryFacilityForm(service);

  const handleFacilityOnChange = (value: string) => {
    setValue("facility", value);
  };

  return (
    <Root
      className={`${className}`}
      id="primary-facility-form"
      aria-label="Register primary facility form"
      data-testid="PrimaryFacility"
      noValidate={true}
    >
      <FormFieldsStyled>
        <IntroTextStyled>
          Please select your primary location for clinical research. You can add
          more facilities later.
        </IntroTextStyled>
        <CountrySelectField
          id="primary-facility-form-facilityCountry"
          aria-label="Search by country"
          label={`Country${customFacilityEnabled ? "*" : ""}`}
          fullWidth={true}
          value={countryCode.value || ""}
          onChange={onChange}
          error={countryCode.helperText.length !== 0}
          helperText={
            countryCode.helperText.length !== 0 ? countryCode.helperText : ""
          }
        />
        {countryCode.value === "US" ? (
          <StateAutocompleteField
            id="primary-facility-form-facilityState"
            aria-label="Search by state"
            label="State"
            name="state"
            countryCode={countryCode.value}
            value={state.value || ""}
            onChange={(value: string) => {
              onChange({
                target: { name: "state", value: value },
              } as ChangeEvent<HTMLInputElement>);
            }}
            fullWidth={true}
            error={state.helperText.length !== 0}
            helperText={state.helperText.length !== 0 ? state.helperText : ""}
          />
        ) : null}
        <FacilityInputContainerStyled variant="filled">
          <FacilityInputInnerStyled>
            <FacilityAutocompleteField
              id="primary-facility-form-facilityName"
              aria-label="Search by Primary Facility Name"
              label={`Search by Primary Facility Name${
                isCustomFacilityActive || !customFacilityEnabled ? "" : "*"
              }`}
              countryCode={countryCode?.value}
              region={state?.value}
              disabled={!countryCode?.value}
              fullWidth={true}
              submitUnknownFacility={true}
              handleSelectUnknownValue={addCustomFacility}
              getOptionValue={(value: string) => {
                //TODO: change type as the value is not expected to be a string but an object with all facility info
                handleFacilityOnChange(value);
              }}
              customFacilityEnabled={customFacilityEnabled}
              value={facility.value || ""}
              error={facility.helperText.length !== 0}
              helperText={
                facility.helperText.length !== 0 ? facility.helperText : ""
              }
              startAdornment={(value: string) =>
                value ? <CheckCircle color="success" /> : null
              }
            />
            <SelectorTooltipStyled>
              <Tooltip
                title="If you don't see the desired Primary Facility Name, you can
                    continue with the registration without selecting a Primary
                    Facility Name and add it later."
              />
            </SelectorTooltipStyled>
          </FacilityInputInnerStyled>

          <FormHelperText>
            Please select an affiliation that most closely matches your Facility
            recognizing that for big institutions the name and address may be
            related to the main hospital, rather than your department.
          </FormHelperText>
        </FacilityInputContainerStyled>
        {isCustomFacilityActive && customFacilityEnabled && (
          <Box sx={{ mt: 1.5, pl: 3 }}>
            <Grid container={true} spacing={2}>
              <Grid item={true}>
                <FormControl variant="filled">
                  <FacilityInputStyled
                    id="primary-facility-form-customFacility"
                    aria-label="Enter a Facility Name"
                    label="Facility Name*"
                    name="customFacility"
                    fullWidth={true}
                    onChange={onChange}
                    error={customFacility.helperText.length !== 0}
                    helperText={
                      customFacility.helperText.length !== 0
                        ? customFacility.helperText
                        : ""
                    }
                    value={customFacility.value || ""}
                  />
                  <FormHelperText>
                    Our teams will review the facility name you have suggested.
                    Please check back later to confirm your primary facility
                    affiliation.
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container={true} spacing={2}>
              <Grid item={true} sm={8}>
                <FacilityInputStyled
                  id="primary-facility-form-customFacilityAddress"
                  aria-label="Enter an address"
                  label="Address*"
                  name="customFacilityAddress"
                  fullWidth={true}
                  onChange={onChange}
                  error={customFacilityAddress.helperText.length !== 0}
                  helperText={
                    customFacilityAddress.helperText.length !== 0
                      ? customFacilityAddress.helperText
                      : ""
                  }
                  value={customFacilityAddress.value || ""}
                />
              </Grid>
              <Grid item={true} sm={4}>
                <FacilityInputStyled
                  id="primary-facility-form-customFacilityPostalCode"
                  aria-label="Enter a postal code"
                  label="Postal Code"
                  name="customFacilityPostalCode"
                  fullWidth={true}
                  onChange={onChange}
                  error={customFacilityPostalCode.helperText.length !== 0}
                  helperText={
                    customFacilityPostalCode.helperText.length !== 0
                      ? customFacilityPostalCode.helperText
                      : ""
                  }
                  value={customFacilityPostalCode.value || ""}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <RegisterProfileTextStyled>
          You will be able to add department(s) to your profile after you
          register.
          <InlineTooltipStyled>
            <Tooltip
              title={
                <TooltipTextStyled>
                  <strong>
                    What's the difference between Facility and Department?
                  </strong>
                  <br />
                  <strong>Facility</strong> is a geographical clinical research
                  location or collection of associated locations within close
                  proximity (example, 2 buildings on the same campus).
                  <br />
                  <strong>Department</strong> is a sub-division of a defined
                  facility that is capable of doing clinical research (example,
                  Department of Endocrinology or the Cardiac Cath Lab).
                </TooltipTextStyled>
              }
            />
          </InlineTooltipStyled>
        </RegisterProfileTextStyled>
      </FormFieldsStyled>
    </Root>
  );
};
