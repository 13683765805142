import React from "react";

import { Card } from "components/Card";
import { DelegateForm } from "./DelegateForm";
import { DelegatesTable } from "./DelegatesTable";
import { DeletionDialog } from "./DeletionDialog";
import { useEditableData } from "hooks/useEditableData";
import { EmailReminder } from "./EmailReminder";
import { useAuth } from "hooks/useAuth";

export function DelegatesForCard(props: any) {
  const {
    delegates,
    invitationData,
    deleteDelegate,
    inviteDelegate,
    onRequestError,
    onInvitationSuccess,
  } = props;

  const { details, creationForm, deletionDialog } = useEditableData({
    create: inviteDelegate,
    delete: deleteDelegate,
  });

  const { isAdmin } = useAuth();

  return (
    <Card
      id="delegates-for-card"
      heading="People who are a delegate for you"
      subHeading="Delegates have your permission to view and make changes to your profile"
      data-testid={DelegatesForCard.name}
      noExpandIcon
      expanded
    >
      {invitationData && invitationData.delegateType === "DELEGATE_FOR" && (
        <EmailReminder hasProfile={invitationData.hasProfile} />
      )}

      <DelegatesTable
        delegateType="DELEGATE_FOR"
        entries={delegates}
        {...details}
      />

      {creationForm.open && (
        <DelegateForm
          title="Invite a person to be your delegate"
          data-testid="DelegateForForm"
          onRequestSuccess={onInvitationSuccess}
          onRequestError={onRequestError}
          withReason={isAdmin}
          {...creationForm}
        />
      )}

      {deletionDialog.open && (
        <DeletionDialog withReason={isAdmin} {...deletionDialog} />
      )}
    </Card>
  );
}
