import React from "react";
import { Link, Divider, Typography, Breadcrumbs } from "@mui/material";

import { Suspend } from "components/Suspend";
import { useRoute } from "wouter";
import { Facility } from "types/facility";
import { CoreDataProvider } from "hooks/useCoreData";
import { Link as WouterLink } from "wouter";
import { ContactTypesProvider } from "hooks/useContactTypes";
import { useFacilityDepartmentProfile } from "hooks/useFacilityDepartmentProfile";
import { Screen, ScreenSection } from "components/Screen";
import { DepartmentContactsCard } from "components/DepartmentContactsCard";
import { DepartmentCommitteeCard } from "components/DepartmentCommitteeCard";
import { DepartmentEquipmentCard } from "components/DepartmentEquipmentCard";
import { FacilityProfileHeadingText } from "components/FacilityProfileHeadingText";
import { DepartmentCharacteristicsCard } from "components/DepartmentCharacteristicsCard";
import { DepartmentPatientPopulationCard } from "components/DepartmentPatientPopulationCard";

export default function FacilityProfilePage() {
  const [, params] = useRoute("/facilities/:facilityId");
  return <FacilityProfile facilityId={parseInt(params?.facilityId!)} />;
}

interface FacilityProfileProps {
  facilityId: number;
}

export function FacilityProfile({ facilityId }: FacilityProfileProps) {
  const { loading, cards, data, error } = useFacilityDepartmentProfile({
    goldenFacilityPk: facilityId,
  });
  const profile = data?.profile;
  const facilityDetails = profile?.facilityDetails;

  return (
    <Suspend
      when={loading}
      error={error}
      errorMessage="There was an error retrieving the facility profile. Please try again later"
    >
      <CoreDataProvider>
        <ContactTypesProvider>
          <Screen>
            <FacilityProfileBreadcrumbs facilityDetails={facilityDetails} />
            <ScreenSection>
              <FacilityProfileHeadingText facilityProfile={profile} />
              <DepartmentContactsCard {...cards.contacts} />
              <DepartmentCharacteristicsCard
                isForFacility
                {...cards.characteristics}
              />
              <DepartmentCommitteeCard {...cards.committee} />
              <DepartmentPatientPopulationCard {...cards.patientPopulation} />
              <DepartmentEquipmentCard {...cards.equipment} />
            </ScreenSection>
          </Screen>
        </ContactTypesProvider>
      </CoreDataProvider>
    </Suspend>
  );
}

interface FacilityProfileBreadcrumbsProps {
  facilityDetails: Facility;
}

function FacilityProfileBreadcrumbs({
  facilityDetails,
}: FacilityProfileBreadcrumbsProps) {
  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <WouterLink href="/facilities">
          <Link color="inherit" underline="hover">
            My facility profiles
          </Link>
        </WouterLink>
        <Typography color="textPrimary">{facilityDetails.name}</Typography>
      </Breadcrumbs>
      <Divider sx={{ mt: 2 }} />
    </div>
  );
}
