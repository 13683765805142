import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { noop } from "utils/noop";
import { useForm } from "hooks/useForm";
import { CommitteeDetails } from "types/facility";
import { Form } from "./Form";
import { FormRadioButtonGroup } from "./FormRadioButtonGroup";
import { FieldTrueFalseRadioGroup } from "./FieldTrueFalseRadioGroup";
import { CommitteeUseTypesField } from "./CommitteeUseTypesField";
import { maxLengthForValidator as maxLength } from "validators/maxLength";

export interface DepartmentCommitteeFormProps {
  open?: boolean;
  title?: string;
  entry?: CommitteeDetails;
  submit: (value: CommitteeDetails) => Promise<any>;
  onCancel?: Callback;
  onError?: Callback;
  onSuccess?: Callback;
  forFacility?: boolean;
  "data-testid"?: string;
}

const FormContent = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  "& > *:not(:first-child)": {
    marginTop: theme.spacing(1),
  },
}));

export function DepartmentCommitteeForm({
  open,
  title,
  entry = {} as CommitteeDetails,
  onCancel = noop,
  onError = console.error,
  onSuccess = noop,
  forFacility = false,
  submit: doSubmit,
  ...otherProps
}: DepartmentCommitteeFormProps) {
  const { id, fields, submit, reset, submitting, error } = useForm({
    id: "department-ethics-form",
    fields: {
      useCommitteeTypes: {
        name: "useCommitteeTypes",
        label: `What types of IRB/ERB/Ethics Committee does your ${
          forFacility ? "facility" : "department"
        } use (select all that apply):`,
        value: entry.useCommitteeTypes,
      },
      anyCommittee: {
        name: "anyCommittee",
        label:
          "Are you able to work with any Central IRB/ERB/Ethics committee provided by a sponsor?",
        value: entry.anyCommittee?.toString() ?? "",
        "aria-label": "any committee",
      },
      committeeType: {
        name: "committeeType",
        options: [
          { value: "ownLocalIrb", label: "My own local IRB" },
          { value: "otherIrb", label: "Other" },
        ],
        label: `What IRB/ERB/Ethics Committee does your ${
          forFacility ? "facility" : "department"
        } work with?`,
        value: getDefaultCommitteeTypeValue(),
        "aria-label": "committee type",
      },
      other: {
        name: "other",
        label: "Other, please indicate",
        value: getDefaultOtherIrbValue(),
        validate: maxLength(256),
      },
    },
    submit: (values: any) => {
      const details = {
        anyCommittee:
          values.anyCommittee === "" ? null : values.anyCommittee === "true",
        ownLocalIrb: values.committeeType === "ownLocalIrb",
        otherIrb: values.committeeType !== "otherIrb" ? "false" : values.other,
        useCommitteeTypes: {
          ...values.useCommitteeTypes,
        },
      };
      return doSubmit(details);
    },
    onReset: onCancel,
    onError,
    onSuccess,
  });

  return (
    <Form
      id={id}
      aria-label="department ethics committee"
      open={open}
      title={title}
      onSubmit={submit}
      submitting={submitting}
      onCancel={reset}
      error={error}
      {...otherProps}
    >
      <FormContent>
        <CommitteeUseTypesField {...fields.useCommitteeTypes} />
        <FieldTrueFalseRadioGroup {...fields.anyCommittee} />
        <FormRadioButtonGroup {...fields.committeeType} />
        {showOtherIrbTextField() && <TextField fullWidth {...fields.other} />}
      </FormContent>
    </Form>
  );

  function getDefaultCommitteeTypeValue() {
    if (entry.ownLocalIrb) return "ownLocalIrb";
    if (entry.otherIrb !== "false") return "otherIrb";
  }

  function getDefaultOtherIrbValue() {
    if (!entry.ownLocalIrb && entry.otherIrb === "false") {
      return "";
    }
    return entry.otherIrb;
  }

  function showOtherIrbTextField() {
    if (!fields.committeeType.value) {
      return false;
    }
    return fields.committeeType.value !== "ownLocalIrb";
  }
}
