import React from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import {
  FormGroup,
  FormControl,
  FormHelperText,
  Typography,
} from "@mui/material";

const PREFIX = "FormCheckboxFieldsGroup";

const classes = {
  field: `${PREFIX}-field`,
  fieldContents: `${PREFIX}-fieldContents`,
  label: `${PREFIX}-label`,
  asterisk: `${PREFIX}-asterisk`,
  checkboxesGroup: `${PREFIX}-checkboxesGroup`,
};

//TODO[CV-2891]: Component prop should be revisited at later Material UI releases
const FormControlStyled = styled(FormControl)<{ component: string }>(
  ({ theme }) => ({
    [`&.${classes.field}`]: {
      "&:not(:last-child)": {
        marginBottom: theme.spacing(2),
      },
    },

    [`& .${classes.fieldContents}`]: {
      display: "grid",
      gridTemplateRows: "auto auto",
      gridRowGap: theme.spacing(2),
    },

    [`& .${classes.label}`]: {
      marginTop: theme.spacing(1),
      lineHeight: 1,
      color: theme.palette.grey[700],
      "&.disabled": {
        color: theme.palette.text.disabled,
      },
    },

    [`& .${classes.asterisk}`]: {
      marginLeft: theme.spacing(0.2),
    },

    [`& .${classes.checkboxesGroup}`]: {
      justifySelf: "flex-start",
    },
  })
);

interface FormCheckboxFieldsGroupProps {
  row?: boolean;
  label?: string;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  children?: ReactNodes;
  helperText?: string;
}

export function FormCheckboxFieldsGroup({
  label,
  error,
  required,
  children,
  disabled,
  helperText,
  row = true,
}: FormCheckboxFieldsGroupProps) {
  return (
    <FormControlStyled
      className={classes.field}
      component="fieldset"
      error={error}
      disabled={disabled}
      fullWidth
    >
      <div className={classes.fieldContents}>
        <Typography className={clsx(classes.label, { disabled })}>
          {label}
          {required && <span className={classes.asterisk}>*</span>}
        </Typography>
        <FormGroup className={classes.checkboxesGroup} row={row}>
          {children}
        </FormGroup>
      </div>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControlStyled>
  );
}
