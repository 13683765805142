import React from "react";
import TextField from "@mui/material/TextField";
import DialogContentText from "@mui/material/DialogContentText";

import { required } from "validators/required";
import { FormDialog } from "./FormDialog";
import { noop, asyncNoop } from "utils/noop";
import { email as isEmail } from "validators/email";
import { useForm, SubmitFn } from "hooks/useForm";
import {
  ChangeReasonFormDialog,
  useChangeReasonFormDialog,
} from "./ChangeReasonFormDialog";
import { validators } from "utils/forms";
import { maxLengthForValidator as maxLength } from "validators/maxLength";

interface Props {
  email?: string;
  profileEmail?: string;
  open?: boolean;
  submit?: SubmitFn;
  onCancel?: Callback;
  onSuccess?: Callback;
  withReason?: boolean;
}

export function ContactEmailForm({
  open,
  email,
  profileEmail,
  submit: doSubmit = asyncNoop,
  onCancel: doCancel = noop,
  onSuccess = noop,
  withReason = false,
  ...otherProps
}: Props) {
  const reasonForm = useChangeReasonFormDialog({
    doSubmit,
    formHookSubmit,
    withReason,
  });

  const { id, fields, submit, reset, submitting } = useForm({
    id: "profile-user-form",
    fields: {
      preferredEmail: {
        id: "preferredEmail",
        name: "preferredEmail",
        label: "Preferred contact email address",
        fullWidth: true,
        required: true,
        value: email,
        validate: validators(required, isEmail, isDuplicate, maxLength(120)),
      },
    },
    submit: reasonForm.performSubmit,
    onReset: doCancel,
    onError: console.error,
    onSuccess,
  });

  function isDuplicate() {
    if (fields.preferredEmail.value === profileEmail) {
      return "Your alternative email can not be the same as your profile email";
    }
  }

  function formHookSubmit() {
    return submit();
  }

  return (
    <>
      <FormDialog
        id={id}
        open={open}
        onCancel={reset}
        onSubmit={reasonForm.handleSubmitButtonClick}
        maxWidth="sm"
        data-testid={ContactEmailForm.name}
        showProgressIndicator={submitting}
        title="Alternative contact email"
        {...otherProps}
      >
        <DialogContentText sx={{ pb: 1 }}>
          If you'd rather not use your profile email address for sponsors to
          contact you, please add your preferred contact email.
        </DialogContentText>
        <div>
          <TextField
            data-testid="PreferredEmailField"
            {...fields.preferredEmail}
          />
        </div>
      </FormDialog>
      <ChangeReasonFormDialog {...reasonForm.reasonDialog} />
    </>
  );
}
