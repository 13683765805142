import React from "react";
import { useInvitationService } from "hooks/useDelegates";
import { Snackbar } from "./Snackbar";
import InvitationMessage from "./InvitationMessage";

export function ManageInvitation(invitationRef: any) {
  let {
    open,
    closeMessage,
    ...rest
  } = useInvitationService(invitationRef);

  return (
    <Snackbar
      data-testid="invitationSnackbar"
      open={open}
      variant={rest.error || rest.invitationType === "DECLINE" ? "error" : "success"}
      message={<InvitationMessage {...rest} />}
      onClose={closeMessage}
    />
  );
}
