import { SpecialtyLookup } from "fixtures/specialties";

export function specialtyCodesToObjects(
  codes: string[] = [],
  specialties: SpecialtyLookup
) {
  return codes.map((code) => specialtyCodeToObject(code, specialties));
}

export function specialtyCodeToObject(
  code: string,
  specialties: SpecialtyLookup
) {
  const parts = code.split(".");
  if (parts.length === 1) {
    return {
      specialty: specialties[code],
      specialtyCode: code,
    };
  } else {
    return {
      specialty: specialties[parts[0]],
      specialtyCode: parts[0],
      subspecialty: specialties[code],
      subspecialtyCode: code,
    };
  }
}

export function extractSpecialtyOptions(specialties: SpecialtyLookup) {
  return Object.keys(specialties)
    .filter(isSpecialtyCode)
    .map(specialtyCodeToOption);

  function isSpecialtyCode(code: string) {
    return !code.includes(".");
  }

  function specialtyCodeToOption(code: string) {
    return { value: code, label: specialties[code] };
  }
}

export function extractSubspecialtyOptions(
  specialtyCode: string = "",
  specialties: SpecialtyLookup
) {
  return Object.keys(specialties)
    .filter(startsWithSpecialty)
    .map(specialtyCodeToOption);

  function startsWithSpecialty(code: string) {
    return code.startsWith(`${specialtyCode}.`);
  }

  function specialtyCodeToOption(code: string) {
    return { value: code, label: specialties[code] };
  }
}

export function hasSubspecialtyOptions(
  specialtyCode: string,
  specialties: SpecialtyLookup
) {
  const options = extractSubspecialtyOptions(specialtyCode, specialties);
  return options.length ? true : false;
}
