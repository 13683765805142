import gql from "graphql-tag";
import React from "react";
import { styled } from "@mui/material/styles";
import { useForm } from "hooks/useForm";
import { useMutation } from "@apollo/client";
import { FormErrorBar } from "./FormErrorBar";
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const DialogActionsStyled = styled(DialogActions)(({ theme }) => ({
  display: "grid",
  padding: theme.spacing(2, 3),
  gridTemplateColumns: "auto 1fr auto",
}));

interface Props {
  user: {
    firstName: string;
    lastName: string;
    email: string;
    ssoUid: string;
  };
  open: boolean;
  onSuccess(): void;
  onClickCancel(event: React.MouseEvent): void;
}

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($user: IUser!, $userPk: Int!) {
    updateUserDelegate(user: $user, userPk: $userPk) {
      user {
        firstName
      }
    }
  }
`;

export function AdminEditUserForm({
  open,
  user,
  onSuccess,
  onClickCancel,
}: Props): JSX.Element {
  const [updateUser] = useMutation(UPDATE_USER_MUTATION);
  const { id, error, fields, submit, submitting } = useForm({
    id: "admin-edit-form",
    fields: {
      firstName: {
        name: "firstName",
        value: user.firstName,
      },
      lastName: {
        name: "lastName",
        value: user.lastName,
      },
      email: {
        name: "email",
        value: user.email,
      },
      ssoUid: {
        name: "ssoUid",
        value: user.ssoUid,
      },
    },
    onSuccess,
    onError(data: any) {
      console.error(data);
    },
    submit(fields): Promise<any> {
      //@ts-ignore
      return updateUser({
        variables: {
          //@ts-ignore
          userPk: user.pk,
          user: {
            ...fields,
            //@ts-ignore
            role: user.role,
          },
        },
      });
    },
  });

  return (
    <Dialog open={open} data-testid="adminEditDialog">
      <form id={id} aria-label="edit user form" onSubmit={handleSubmit}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          {!!error && (
            <FormErrorBar
              error={error}
              gqlCodeMessageMap={{
                "409":
                  "This email already exists and can't be used on this profile, please choose another",
              }}
              renderNetworkError
            />
          )}
          <TextField label="First name" fullWidth {...fields.firstName} />
          <TextField label="Last name" fullWidth {...fields.lastName} />
          <TextField label="Email" fullWidth {...fields.email} />
          <TextField label="SSO UID" fullWidth {...fields.ssoUid} />
        </DialogContent>
        <DialogActionsStyled>
          <Button onClick={onClickCancel} variant="outlined" children="Cancel" />
          <span/>
          <LoadingButton
            form={id}
            type="submit"
            loading={submitting}
            loadingPosition="center"
            variant="contained"
            disabled={submitting}
            children="Save"
          />
        </DialogActionsStyled>
      </form>
    </Dialog>
  );

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    submit();
  }
}
