import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { noop } from "utils/noop";
import { Phases } from "types/profile";

import {
  TableHeadRow,
  TableBodyRow,
  TableActionsCell,
  TableEditActionButton,
  TableDeleteActionButton,
  TablePaneFooter,
  TablePaneButton,
  TablePane,
} from "./Table";

const phaseToLabelMap = {
  phaseOne: "Phase I",
  phaseOneOrTwo: "Phase I/II",
  phaseTwo: "Phase II",
  phaseTwoOrThree: "Phase II/III",
  phaseThree: "Phase III",
  phaseThreeOrFour: "Phase III/IV",
  phaseFour: "Phase IV",
};

interface Props {
  entry?: Phases;
  testId?: string;
  onCreateButtonClick?: Callback;
  onUpdateButtonClick?: Callback;
  onDeleteButtonClick?: Callback;
}

export function ResearchPhasesDisplay({
  testId,
  entry = {},
  onCreateButtonClick = noop,
  onUpdateButtonClick = noop,
  onDeleteButtonClick = noop,
}: Props) {
  const phases = phasesToList(entry);
  const $testId = testId || ResearchPhasesDisplay.name;

  return (
    <TablePane>
      <Table data-testid={$testId}>
        <TableHead>
          <TableHeadRow>
            <TableCell>Phase experience</TableCell>
            <TableCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {Boolean(phases.length) && (
            <TableBodyRow>
              <TableCell>
                {phases.map((phase, i) => (
                  <span key={phase.id} data-testid={phase.id}>
                    {phase.label}
                    {i < phases.length - 1 && ", "}
                  </span>
                ))}
              </TableCell>
              <TableActionsCell>
                <TableEditActionButton
                  onClick={() => onUpdateButtonClick(entry)}
                  data-testid={`${$testId}UpdateEntryButton`}
                />
                <TableDeleteActionButton
                  onClick={() => onDeleteButtonClick()}
                  data-testid={`${$testId}DeleteEntryButton`}
                />
              </TableActionsCell>
            </TableBodyRow>
          )}
        </TableBody>
      </Table>
      <TablePaneFooter>
        {!phases.length && (
          <TablePaneButton
            icon={AddCircleIcon}
            label="Add phase experience"
            onClick={onCreateButtonClick}
            data-testid={`${$testId}CreateEntryButton`}
          />
        )}
      </TablePaneFooter>
    </TablePane>
  );
}

function phasesToList(phases: Phases) {
  return Object.entries(phases)
    .filter(([phase]) => phase !== "phaseZero")
    .filter(([phase, value]) => value)
    .map(([phase]) => ({
      id: phase,
      label: (phaseToLabelMap as any)[phase],
    }));
}
