import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/material/styles";
import { Link as MuiLink } from "@mui/material";

import { useAuth } from "hooks/useAuth";
import { Suspend } from "components/Suspend";
import { RoleType } from "utils/role";
import { useEffect } from "react";
import { useRoute, Link as WouterLink } from "wouter";
import { ProfileProgress } from "components/ProfileProgress";
import { ProfileDelegatesLink } from "../components/ProfileDelegatesLink";
import { ProfileTrialSponsorsBox } from "../components/ProfileTrialSponsorsBox";
import { useProfileDelegate } from "hooks/useProfileDelegate";
import { NonSiteStaffContents } from "components/NonSiteStaffContents";
import { SiteStaffContents } from "components/SiteStaffcontents";

import { Screen, ScreenSection, ScreenSectionBar } from "../components/Screen";

const ProgressContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "60% auto",
  gridGap: theme.spacing(2),
  alignItems: "start",
}));

export default function DelegateProfile() {
  const [, param] = useRoute("/delegates/profile/:id");
  const profile = useProfileDelegate({ userPk: param?.id! });
  const { data, loading, error, showMissing, progress } = profile;
  const isSiteStaff = data?.user.role === RoleType.SiteStaff;
  const auth = useAuth();

  useEffect(
    () => {
      auth?.setDelegateStatus?.(true);
      return () => {
        auth?.setDelegateStatus?.(false);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Suspend
      when={loading}
      error={error}
      errorMessage="There was an error retrieving the profile. Please try again later"
    >
      <WouterLink href="/delegates">
        <MuiLink sx={{ color: "primary.main", textDecoration: "none" }}>
          <ArrowBackIcon sx={{ verticalAlign: "-7px", marginRight: "5px" }} />
          Back to delegate list
        </MuiLink>
      </WouterLink>
      <Screen data-testid={DelegateProfile.name}>
        <ScreenSection>
          <ScreenSectionBar
            role="heading"
            sx={{ display: "grid", gridTemplateColumns: "60% auto" }}
            title="Delegate Profile"
            actions={<ProfileDelegatesLink />}
          />
          <ProgressContainer>
            <ProfileProgress
              onClickShowMissing={showMissing}
              basicProgress={progress.basic}
              cvProgress={progress.all}
              optedIn={profile?.data?.user.optIn.status === "OPTED_IN"}
            />
            <ProfileTrialSponsorsBox />
          </ProgressContainer>
        </ScreenSection>

        {isSiteStaff ? (
          <SiteStaffContents profile={profile} />
        ) : (
          <NonSiteStaffContents profile={profile} />
        )}
      </Screen>
    </Suspend>
  );
}
