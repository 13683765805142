import React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { DepartmentProfile } from "types/facility";
import { Link } from "wouter";

const Root = styled("div")(({ theme }) => ({
  "& > *:not(:first-child)": {
    marginTop: theme.spacing(2),
  },
}));

interface DepartmentProfileHeadingTextProps {
  departmentProfile: DepartmentProfile;
}

export function DepartmentProfileHeadingText({
  departmentProfile,
}: DepartmentProfileHeadingTextProps) {
  return (
    <Root>
      <Typography variant="h5" color="textPrimary">
        {departmentProfile.departmentDetails.departmentName}
      </Typography>
      <Typography variant="subtitle2" color="textPrimary">
        {departmentProfile.facilityDetails.name}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        If you spot any problems with this profile, please{" "}
        <Link href="/contact-us">contact us</Link>
      </Typography>
      <Typography variant="subtitle2" color="textPrimary">
        Sharing this information will help sponsors match your department to the
        right trials.
      </Typography>
    </Root>
  );
}
