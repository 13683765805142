import React from "react";
import { TextField } from "@mui/material/";
import { styled } from "@mui/material/styles";

import { Form } from "./Form";
import { noop } from "utils/noop";
import { Study } from "types/profile";
import { useForm } from "hooks/useForm";
import { required } from "validators/required";
import { StudyPhaseSelectField } from "./StudyPhaseSelectField";
import { StudyCommentSelectField } from "./StudyCommentSelectField";
import { StudyRoleRadioGroupField } from "./StudyRoleRadioGroupField";
import { IndicationsAutocompleteField } from "./IndicationsAutocompleteField";
import { Tooltip } from "components/Tooltip";

import {
  useChangeReasonFormDialog,
  ChangeReasonFormDialog,
} from "./ChangeReasonFormDialog";

import { FormRadioButtonGroupField } from "./FormRadioButtonGroupField";

const TrialFieldWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& > *:not(:first-child)": {
    marginLeft: theme.spacing(1),
  },
}));

export interface StudyByManualFormProps {
  open?: boolean;
  title?: string;
  entry?: Study;
  submit: (study: Study) => Promise<any>;
  onCancel?: Callback;
  onError?: Callback;
  onSuccess?: Callback;
  withReason?: boolean;
  "data-testid"?: string;
}

export function StudyByManualForm({
  open,
  title = "Update clinical study",
  entry = {} as Study,
  onCancel = noop,
  onError = console.error,
  onSuccess = noop,
  withReason = false,
  submit: doSubmit,
  "data-testid": testId = StudyByManualForm.name,
  ...otherProps
}: StudyByManualFormProps) {
  const reasonForm = useChangeReasonFormDialog({
    doSubmit,
    formHookSubmit,
    withReason,
  });
  const { id, fields, submit, reset, submitting, error } = useForm({
    id: "study-nct-form",
    fields: {
      id: {
        name: "id",
        value: entry.id,
      },
      nctNumber: {
        name: "nctNumber",
        value: entry.nctNumber,
      },
      phase: {
        name: "phase",
        value: entry.phase,
        required: true,
        validate: required,
      },
      primaryIndication: {
        name: "primaryIndication",
        label: "Primary indication",
        value: (entry.primaryIndication || [])[0],
        required: true,
        validate: required,
      },
      status: {
        name: "status",
        label: "Study status",
        value: entry.status,
        options: [
          { value: "ongoing", label: "On-going" },
          { value: "completed", label: "Completed" },
          {
            value: "terminated",
            label: "Terminated",
          },
        ],
        "aria-label": "study status",
        required: true,
        validate: required,
      },
      title: {
        name: "title",
        label: "Study title",
        value: entry.title,
        required: true,
        validate: required,
      },
      type: {
        name: "type",
        label: "Type of study",
        value: entry.type,
        options: [
          { value: "industry", label: "Industry" },
          { value: "investigator", label: "Investigator" },
          { value: "nihcorporation", label: "NIH/Corporation" },
          { value: "other", label: "Other" },
        ],
        "aria-label": "study type",
        required: true,
        validate: required,
      },
      source: {
        name: "source",
        value: "MANUAL",
      },
      studyId: {
        name: "studyId",
        label: "Trial identification number",
        value: entry.studyId,
        required: true,
        validate: required,
      },
      role: {
        name: "role",
        value: entry.role,
        label: "What was your role on this study?",
        required: true,
        validate: required,
      },
      comment: {
        name: "comment",
        value: entry.comment,
      },
    },
    submit: (values: any) => {
      const valuesToSubmit = {
        ...values,
        primaryIndication: [values.primaryIndication],
      };
      return reasonForm.performSubmit(valuesToSubmit);
    },
    onReset: onCancel,
    onError,
    onSuccess,
  });

  function formHookSubmit() {
    return submit();
  }

  return (
    <>
      <Form
        id={id}
        open={open}
        title={title}
        data-testid={testId}
        onSubmit={reasonForm.handleSubmitButtonClick}
        submitting={submitting}
        onCancel={reset}
        error={error}
        pushActionsToRight
        {...otherProps}
      >
        <TrialFieldWrapper>
          <TextField
            data-testid={`${testId}StudyIdField`}
            {...fields.studyId}
            fullWidth
          />

          <Tooltip
            title="Where possible, please use the publically available
            identification number e.g. regulatory agency number or registry
            number"
            iconButtonOptions={{
              size: "small",
              arialLabel: "edit",
              "data-testid": `${testId}InfoIconButton`,
            }}
          />
        </TrialFieldWrapper>

        <TextField
          data-testid={`${testId}TitleField`}
          {...fields.title}
          fullWidth
        />

        <IndicationsAutocompleteField
          data-testid={`${testId}PrimaryIndicationField`}
          fullWidth
          {...fields.primaryIndication}
        />
        <FormRadioButtonGroupField
          data-testid={`${testId}TypeField`}
          {...fields.type}
        />

        <StudyPhaseSelectField
          data-testid={`${testId}PhaseField`}
          fullWidth
          {...fields.phase}
        />

        <FormRadioButtonGroupField
          data-testid={`${testId}StatusField`}
          {...fields.status}
        />

        <StudyRoleRadioGroupField
          data-testid={`${testId}RoleField`}
          {...fields.role}
        />

        <StudyCommentSelectField {...fields.comment} fullWidth />
      </Form>
      <ChangeReasonFormDialog {...reasonForm.reasonDialog} />
    </>
  );
}
