import React from "react";

import { styled } from "@mui/material/styles";

import { Link } from "wouter";
import { MoreMenu } from "./MoreMenu";
import { GoldenUser } from "../types/user";

import {
  Table,
  TableHead,
  TableRow,
  TablePagination,
  TableCell,
  TableBody,
  Paper,
  Typography,
  MenuItem,
  TableSortLabel,
} from "@mui/material";
const PREFIX = "AdminSearchTable";

const classes = {
  topBar: `${PREFIX}-topBar`,
  goldenId: `${PREFIX}-goldenId`,
  sortableColumnLabel: `${PREFIX}-sortableColumnLabel`,
};

const PaperStyled = styled(Paper)(({ theme }) => ({
  [`& .${classes.topBar}`]: {
    padding: theme.spacing(3),
  },

  [`& .${classes.goldenId}`]: {
    "& a": {
      textDecoration: "none",
    },
  },

  [`& .${classes.sortableColumnLabel}`]: {
    color: `${theme.palette.primary.main} !important`,
  },
}));

const headers = [
  { title: "Golden Id", sortField: "golden_person_id" },
  { title: "First Name", sortField: "first_name" },
  { title: "Last Name", sortField: "last_name" },
  { title: "Email", sortField: "email" },
  { title: "Role", sortField: null },
  { title: "DataBank Status", sortField: null },
  { title: "Facility", sortField: null },
  { title: "City", sortField: null },
  { title: "Country", sortField: null },
  { title: "VPD", sortField: null },
];

interface Props {
  className?: string;
  rowsPerPage: number;
  page: number;
  count: number;
  results?: GoldenUser[];
  orderBy?: string;
  sortOrder?: "asc" | "desc";
  onColumnClick(field: string): void;
  onRowsPerPageChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void;
  onPageChange(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ): void;
  onClickEdit(user: GoldenUser): void;
}

export function AdminSearchTable({
  rowsPerPage,
  page,
  count,
  results = [],
  onPageChange,
  onClickEdit,
  onRowsPerPageChange,
  onColumnClick,
  sortOrder = "desc",
  orderBy,
}: Props) {
  return (
    <PaperStyled>
      <div className={classes.topBar}>
        <Typography variant="h6">Results</Typography>
        {/* <Typography variant="subtitle1">{`Showing results ${page *
          rowsPerPage} of ${count}`}</Typography> */}
      </div>
      <Table size="small" aria-label="Admin search results table">
        <TableHead>
          <TableRow>
            {headers.map(({ title, sortField }, i) => (
              <TableCell key={i} sortDirection="asc">
                {sortField ? (
                  <TableSortLabel
                    className={classes.sortableColumnLabel}
                    classes={{
                      active: classes.sortableColumnLabel,
                    }}
                    direction={orderBy === sortField ? sortOrder : "desc"}
                    active={orderBy === sortField}
                    onClick={() => onColumnClick(sortField)}
                  >
                    {title}
                  </TableSortLabel>
                ) : (
                  <>{title}</>
                )}
              </TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((goldenUser, i) => (
            <TableRow key={`${goldenUser.pk}-${i}`}>
              <TableCell className={classes.goldenId}>
                <Link href={`/admin/profile/${goldenUser.pk}`}>
                  {goldenUser.goldenPersonPk}
                </Link>
              </TableCell>
              <TableCell>{goldenUser.firstName}</TableCell>
              <TableCell>{goldenUser.lastName}</TableCell>
              <TableCell>{goldenUser.email}</TableCell>
              <TableCell>{goldenUser.role}</TableCell>
              <TableCell>{goldenUser.optInStatus}</TableCell>
              <TableCell>{goldenUser.facility?.name ?? ""}</TableCell>
              <TableCell>{goldenUser.facility?.city ?? ""}</TableCell>
              <TableCell>{goldenUser.facility?.countryCode ?? ""}</TableCell>
              <TableCell>{goldenUser.vpds.join(", ")}</TableCell>
              <TableCell>
                <MoreMenu>
                  <MenuItem onClick={() => onClickEdit(goldenUser)}>
                    Edit
                  </MenuItem>
                </MoreMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        backIconButtonProps={{
          "aria-label": "previous page",
        }}
        nextIconButtonProps={{
          "aria-label": "next page",
        }}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </PaperStyled>
  );
}
