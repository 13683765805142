import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { noop } from "utils/noop";
import { Study } from "types/profile";
import { StudyByNctForm, StudyByNctFormProps } from "./StudyByNctForm";

interface StudyByNctFormDialogProps extends Omit<StudyByNctFormProps, "study"> {
  title?: string;
  entry: Study;
  withReason?: boolean;
}

export function StudyByNctFormDialog({
  open,
  submit,
  entry = {} as any,
  title = "Update clinical study",
  onError = console.error,
  onCancel = noop,
  onSuccess = noop,
  withReason = false,
  "data-testid": testId = StudyByNctFormDialog.name,
  ...otherProps
}: StudyByNctFormDialogProps) {
  if (!open) return null;

  return (
    <Dialog open={open} data-testid={testId} maxWidth="xl" {...otherProps}>
      <DialogContent>
        <StudyByNctForm
          open={open}
          title={title}
          study={entry as any}
          onCancel={onCancel}
          onError={onError}
          onSuccess={onSuccess}
          withReason={withReason}
          submit={submit}
        />
      </DialogContent>
    </Dialog>
  );
}
