import React from "react";
import { useCoreData } from "hooks/useCoreData";
import { matchSorter } from "match-sorter";

import {
  AutocompleteField,
  AutocompleteInstanceFieldProps,
} from "./AutocompleteField";

export function CountrySelectField(props: AutocompleteInstanceFieldProps) {
  const { countries } = useCoreData();
  const options = countries.map((c) => ({ value: c.code, label: c.name }));
  const filterOptions = (
    options: any,
    { inputValue }: { inputValue: string }
  ) => {
    const words = inputValue.split(" ");
    return words.reduceRight(
      (options, word) =>
        matchSorter(options, word, {
          keys: ["label", "value"],
        }),
      options
    );
  };

  return (
    <AutocompleteField
      id="countryCode"
      name="countryCode"
      label="Country"
      options={options}
      filterOptions={filterOptions}
      {...props}
    />
  );
}
