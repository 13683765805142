import React from "react";

import { User } from "types/profile";
import { Card } from "components/Card";
import { AdminDetailsTable } from "./AdminDetailsTable";
import { formatUserFullName } from "utils/user";

interface AdminDetailsCardProps {
  user: User;
}

export function AdminDetailsCard({ user }: AdminDetailsCardProps) {
  const name = formatUserFullName(user);
  return (
    <Card id="admin-details" heading={`Admin details for ${name}`}>
      <AdminDetailsTable user={user} />
    </Card>
  );
}
