import React, { useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "wouter";
import SupportIcon from '@mui/icons-material/Support';
import ArrowDropDownTwoTone from '@mui/icons-material/ArrowDropDownTwoTone';
import { ClickAwayListener } from '@mui/base';
import { ROLES } from "routes";
import { MenuLink } from "layouts/AppBar";

export interface User {
  firstName: string
  lastName: string
  email: string
  role: ROLES
}

const NavigationMenu = styled(Paper)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  marginRight: 8,
  boxShadow: 'rgba(0, 0, 0, 0.08) 0px 8px 20px 0px',
  border: '1px solid rgb(217, 217, 217)',
"& svg": {
  marginLeft: 5,
},
}))

const NavigationMenuItem = styled(MenuItem)(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
  userSelect: "none",
  lineHeight: "1.5",
  margin: 0,
  padding: 16,
  fontSize: 16,
  height: 40,
  fontWeight: 400,
  color: "#444444",
  "&.MuiMenuItem-root": {
    background: theme.palette.common.white,
  },
  "&:hover": {
    background: 'rgba(35, 114, 253, 0.08)'
  },
  "&.Mui-selected": {
    background: 'rgba(35, 114, 253, 0.08)',
    "&:hover": {
      background: 'rgba(35, 114, 253, 0.08)'
    },
  }
}))

const LogOutMenuItem = styled(Button)(({ theme }) => ({
  fontFamily: '"Proxima Nova", "Noto Sans", Helvetica, Arial, sans-serif',
  fontWeight: 500,
  fontSize: 16,
  height: 40,
  width: "100%",
  padding: "0px 16px",
  textAlign: 'center',
  color: "rgb(7, 104, 253)",
  border: "1px solid rgb(217, 217, 217)",
  background: theme.palette.common.white,
  boxShadow: 'rgba(0, 0, 0, 0.04) 0px 4px 16px 0px',

  '&:hover': {
    backgroundColor: '#EFF5FF',
    border: '1px solid #0768FD'
  }
}))

const NavigationDownIcon = styled(SupportIcon)(() => ({
  marginRight: 10,
  color: '#444444',
}))

const ProfileDetails = styled('div')(() => ({
  width: 280,
  padding: "8px 16px 0 16px;",
  "& hr": {
    marginTop: 12,
    marginBottom: 8,
    borderWidth: '0px 0px thin',
    borderStyle: 'solid',
    borderColor: 'rgb(233, 233, 233)'
  },
  "& .name": {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.5,
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  "& .email": {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.71,
    color: "#595959",
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
}))

interface IProfileDropdownMenu {
  contactUsLink: MenuLink
  pathname: string
  userName?: string
  email?: string
  role?: ROLES
  anchorEl?: HTMLElement
  handleLogOut: (() => void) | undefined
  onClose: () => void
}
function ProfileDropdownMenu({contactUsLink, userName, email, role, pathname, anchorEl, handleLogOut, onClose}:IProfileDropdownMenu) {
  return (
    <Popper
      id="acc-dropdown"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      style={{zIndex: 1}}
    >
    <ClickAwayListener onClickAway={() => onClose()}>
      <NavigationMenu elevation={1}>
        <MenuList>

      <ProfileDetails >
        { userName && <p className="name">{userName}</p> }
        { email && <p className="email">{email}</p> }
        <hr />
      </ProfileDetails>
      {(role === ROLES.INVESTIGATOR || role === ROLES.SITE_STAFF) &&
        <Link
          to={contactUsLink.link}
          onClick={() => onClose()}
        >
          <NavigationMenuItem
            key={contactUsLink.key}
            aria-label={contactUsLink.ariaLabel}
            selected={contactUsLink.link === pathname}
          >
          <NavigationDownIcon fontWeight="normal" fontSize="small"/>
            {contactUsLink.title}
          </NavigationMenuItem>
         </Link>
      }
      <Box component={"li"} padding="8px 16px 16px 16px">
        <LogOutMenuItem
        key="logout"
        aria-label="Log out"
        onClick={handleLogOut}
        >
          Log out
        </LogOutMenuItem>
       </Box>
       </MenuList>
       </NavigationMenu>
       </ClickAwayListener>
    </Popper>
  );
}

const ProfileDropDownButton = styled('button')(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "0 24px",
    marginRight: 8,
    border: 'none',
    background: 'none',
    outline: 'none',
    color: "#ffffff",

    "&.active, &:hover": {
      background: "rgba(255, 255, 255, 0.16)",
    },
    "& svg": {
      marginLeft: 5,
    },
}))

const ProfileInitials = styled('span')(() => ({
    position: "relative",
    display: "flex",
    WebkitBoxAlign: "center",
    alignItems: "center",
    WebkitBoxPack: "center",
    justifyContent: "center",
    flexShrink: 0,
    width: 32,
    height: 32,
    fontSize: 14,
    fontFamily: '"Proxima Nova", "Noto Sans", Helvetica, Arial, sans-serif',
    borderRadius: "50%",
    overflow: "hidden",
    userSelect: "none",
    backgroundColor: "#ffffff",
    color: "rgb(68, 68, 68)",
    lineHeight: "normal",
    fontWeight: "600",
}))

interface IProfileDropDown {
  user: User,
  pathname: string
  handleLogOut: (() => void) | undefined
}

export function ProfileDropDown({user, pathname, handleLogOut}: IProfileDropDown) {
  const {firstName, lastName, email, role} = user
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(undefined);
  };
  const contactUsLink: MenuLink ={
    link: "/contact-us",
    title: "Contact Us",
    blank: false,
    ariaLabel: "Link to contact us",
  }
  return (
    <>
      <ProfileDropDownButton
        className={anchorEl || pathname === contactUsLink.link ? 'active' : ''}
        aria-label="Open account menu"
        onClick={(event) => handleClick(event)}
      >
        {firstName && lastName && (
          <>
            <ProfileInitials>{`${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`}</ProfileInitials>
            <ArrowDropDownTwoTone fontSize="medium" />
          </>
        )}
      </ProfileDropDownButton>
      <ProfileDropdownMenu
        contactUsLink={contactUsLink}
        onClose={handleClose}
        userName={`${firstName ?? ""} ${lastName ?? ""}`}
        email={email}
        role={role}
        pathname={pathname}
        handleLogOut={handleLogOut}
        anchorEl={anchorEl}
      />
    </>
  );
}
