import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

interface FormCheckboxFieldProps {
  name?: string;
  label?: string;
  value?: any;
  color?: "primary" | "secondary" | "default";
  testId?: string;
  checked?: boolean;
  onChange?: Callback;
  readonly?: boolean;
  className?: string;
  [key: string]: any;
  sx?: {
    label?: SystemStyleObject;
    checkbox?: SystemStyleObject;
    root?: SystemStyleObject;
  };
}

export function FormCheckboxField({
  name,
  label = "",
  value,
  checked,
  onChange,
  readonly = false,
  sx = {},
  color = "primary",
}: FormCheckboxFieldProps) {
  function handleOnChange(event: any) {
    if (!readonly && onChange) {
      onChange(event);
    }
  }

  return (
    <FormControlLabel
      sx={{
        m: 0,
        cursor: () => (readonly ? "default" : "pointer"),
        ...sx.root,
        "& .MuiFormControlLabel-label": {
          ...sx.label,
        },
      }}
      control={
        <Checkbox
          name={name}
          value={value}
          checked={checked}
          onChange={handleOnChange}
          sx={{
            p: 0,
            cursor: () => (readonly ? "default" : "pointer"),
            ...sx.checkbox,
          }}
          color={color}
          data-testid={`${name}Checkbox`}
          data-testchecked={checked}
        />
      }
      label={label}
    />
  );
}
