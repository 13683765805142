import React from "react";
import parse from "date-fns/parseISO";
import format from "date-fns/format";

import { styled } from "@mui/material/styles";
import { User, Member } from "types/profile";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

const TableCellAligned = styled(TableCell)(() => ({
  verticalAlign: "top",
}));

interface Props {
  user: User;
  className?: string;
}

export function AdminDetailsTable({ user, className }: Props) {
  const members = (user.memberIds || []).filter(memberHasIds);

  return (
    <Table className={className} size="small">
      <TableHead>
        <TableRow>
          <TableCell>Databank status</TableCell>
          <TableCell>Golden ID</TableCell>
          <TableCell>VPD & Member ID</TableCell>
          <TableCell>Registered on</TableCell>
          <TableCell>Last login</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCellAligned>{user.optIn.status || "-"}</TableCellAligned>
          <TableCellAligned>{user.goldenPersonPk || "-"}</TableCellAligned>
          <TableCellAligned align="right">
            {!members.length && <>-</>}
            {members.map((member, i) => (
              <div key={i}>{formatMember(member)}</div>
            ))}
          </TableCellAligned>
          <TableCellAligned>{formatDate(user.dateJoined)}</TableCellAligned>
          <TableCellAligned>{formatDate(user.lastLogin)}</TableCellAligned>
        </TableRow>
      </TableBody>
    </Table>
  );
}

function memberHasIds(member: Member) {
  return Boolean(member.ids);
}

function formatMember(member: Member) {
  return `${member.vpd} - ${(member.ids || []).join(", ")}`;
}

function formatDate(date?: string) {
  if (!date) {
    return "-";
  }
  return format(parse(date), "MMM d, y");
}
