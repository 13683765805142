import React from "react";
import AdminShell from "components/AdminShell";
import { Suspend } from "components/Suspend";
import { DeletionDialog } from "components/DeletionDialog";
import { useEditableData } from "hooks/useEditableData";
import { AdminDetailsCard } from "components/AdminDetailsCard";
import { CoreDataProvider } from "hooks/useCoreData";
import { Button } from "@mui/material";
import { formatUserFullName } from "utils/user";
import { useProfileDelegate } from "hooks/useProfileDelegate";
import { useRoute, useLocation } from "wouter";
import { Screen, ScreenSection, ScreenSectionTitle } from "components/Screen";
import { RoleType } from "utils/role";
import { SiteStaffContents } from "components/SiteStaffcontents";
import { NonSiteStaffContents } from "components/NonSiteStaffContents";

export default function AdminProfile() {
  const [, param] = useRoute("/admin/profile/:id");
  const profile = useProfileDelegate({ userPk: param?.id!, isAdmin: true });
  return (
    <AdminShell profileId={param?.id!}>
      <CoreDataProvider>
        <Suspend
          label={`Loading profile ${param?.id} from admin`}
          when={profile.loading}
          error={profile.error}
          errorMessage="There was an error retrieving the profile. Please try again later"
        >
          <AdminProfileContents
            profile={profile}
            deleteProfile={profile.deleteProfile}
          />
        </Suspend>
      </CoreDataProvider>
    </AdminShell>
  );
}

function AdminProfileContents({ profile, deleteProfile }: any) {
  const { data, cards } = profile;
  const name = formatUserFullName(data.user);
  const [, setLocation] = useLocation();
  const { details, deletionDialog } = useEditableData({
    delete: deleteProfile,
  });
  const isSiteStaff = data?.user.role === RoleType.SiteStaff;

  function onDeletionSuccess() {
    deletionDialog.onSuccess();
    setLocation("/admin");
  }

  return (
    <Screen>
      <ScreenSection>
        <ScreenSectionTitle children={`${name} - Profile`} />
        <AdminDetailsCard {...cards.details} />
      </ScreenSection>
      {isSiteStaff ? (
        <SiteStaffContents profile={profile} />
      ) : (
        <NonSiteStaffContents profile={profile} />
      )}
      <ScreenSection>
        <Button
          sx={{ textTransform: "uppercase" }}
          variant="contained"
          onClick={details.onDeleteButtonClick}
        >
          Delete profile
        </Button>
        {deletionDialog.open && (
          <DeletionDialog
            withReason
            {...deletionDialog}
            onSuccess={onDeletionSuccess}
            confirmationMessage="Are you sure you want to delete this profile?"
          />
        )}
      </ScreenSection>
    </Screen>
  );
}
