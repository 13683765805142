import { createMachine, sendParent, assign, ActorRefFrom } from "xstate";
import { useActor } from "@xstate/react";
import { isValueEmpty } from "../utils/forms";
import { validate } from "../machines/utils";
import { produce } from "immer";
import { maxLength } from "validators/maxLength";
import { isEmail } from "validators/email";

const id = "basic-details-form";

interface Field {
  id: string;
  value?: string | number;
  name: string;
  label: string;
  error?: boolean | undefined;
  required: boolean;
  helperText: string;
}

export interface BasicDetailsFinalFields {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  countryCode: string;
}

export interface BasicDetailsFormMachineContext {
  id: string;
  email: Field;
  firstName: Field;
  lastName: Field;
  countryCode: Field;
  blockUserDetails: boolean;
  key?: keyof BasicDetailsFormMachineContext;
}

type SetFieldEvent = {
  type: "SET_FIELD";
  name: string;
  value: string;
};
type ValidateEvent = { type: "VALIDATE" };
type Validated = { type: "error.platform.validate"; data: string[] };
export type BasicDetailsFormEvents = SetFieldEvent | ValidateEvent | Validated;

let defaultContext: BasicDetailsFormMachineContext = {
  id,
  email: {
    id: `${id}-email`,
    value: "",
    name: "email",
    label: "Email",
    error: false,
    required: true,
    helperText: "",
  },
  firstName: {
    id: `${id}-firstName`,
    value: "",
    name: "firstName",
    label: "First Name",
    error: false,
    required: true,
    helperText: "",
  },
  lastName: {
    id: `${id}-lastName`,
    value: "",
    name: "lastName",
    label: "Last Name",
    error: false,
    required: true,
    helperText: "",
  },
  countryCode: {
    id: `${id}-countryCode`,
    value: "",
    name: "countryCode",
    label: "Country",
    required: true,
    error: false,
    helperText: "",
  },
  blockUserDetails: false
};

const getFinalValues = (context: BasicDetailsFormMachineContext) => {
  let final = {
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    countryCode: "",
  };
  for (let prop in context) {
    let fieldName = prop as keyof BasicDetailsFinalFields;
    let field = context[fieldName] as Field;
    if (typeof field.value === "string") {
      final[fieldName] = field.value;
    }
  }
  return final;
};

export let BasicDetailsMachine = createMachine(
  {
    context: defaultContext,
    schema: {
      context: {} as BasicDetailsFormMachineContext,
      events: {} as BasicDetailsFormEvents,
    },
    id: "basicDetailsMachine",
    initial: "idle",
    states: {
      idle: {
        on: {
          SET_FIELD: {
            actions: "setField",
          },
          VALIDATE: {
            target: "validate",
          },
        },
      },
      validate: {
        invoke: {
          id: "validate",
          src: "validate",
          onDone: [
            {
              actions: "sentToParent",
              target: "idle",
            },
          ],
          onError: [
            {
              actions: "setErrors",
              target: "idle",
            },
          ],
        },
      },
    },
  },
  {
    actions: {
      sentToParent: sendParent((context) => {
        return {
          type: "VALIDATED",
          data: getFinalValues(context),
        };
      }),
      setField: assign((context, event) => {
        if (event.type !== "SET_FIELD") return context;
        return produce(context, (newContext) => {
          let field = newContext[
            event.name as keyof BasicDetailsFormMachineContext
          ] as Field;
          field.error = false;
          field.helperText = "";
          field.value = event.name === 'email' ?  event.value.trim() : event.value;
        });
      }),
      setErrors: assign((context, event) => {
        if (event.type !== "error.platform.validate") return context;
        return produce(context, (newContext) => {
          for (let key of event.data) {
            let fieldName = key[0] as keyof BasicDetailsFormMachineContext;
            let field = newContext[fieldName] as Field;
            field.error = true;
            field.helperText = key[1];
          }
        });
      }),
    },
    services: {
      validate: validate({
        email: [
          isValueEmpty("Email is required"),
          [isEmail, "Email is not correctly formatted"],
          [
            maxLength(100),
            "Email must not be more than 100 characters long",
          ],
        ],
        firstName: [
          isValueEmpty("First name is required"),
          [
            maxLength(50),
            "First name must not be more than 50 characters long",
          ],
        ],
        lastName: [
          isValueEmpty("Last name is required"),
          [
            maxLength(50),
            "Last name must not be more than 50 characters long",
          ],
        ],
        countryCode: [isValueEmpty("Country is required")],
      }),
    },
  }
);

export type BasicDetailsFormMachineService = ActorRefFrom<typeof BasicDetailsMachine>;

export function useBasicDetailsForm(service: BasicDetailsFormMachineService) {
  let [state, send] = useActor(service);
  return {
    ...state?.context ?? defaultContext,
    changeField(event: React.ChangeEvent<HTMLInputElement>) {
      return send({
        type: "SET_FIELD",
        name: event.target.name,
        value: event.target.value,
      } as SetFieldEvent);
    },
  };
}
