import React from "react";
import { Button } from "@mui/material";
import { styled, useTheme, Theme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  [`& .back`]: {
    borderColor: theme.palette.grey[300],
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(3.5)}`,
}));

const Loader = styled(LoadingButton)(({ theme }) => ({
  height: theme.spacing(5),
  minWidth: theme.spacing(10),
  "& .MuiLoadingButton-loadingIndicator": {
    color: theme.palette.secondary.main,
  },
}));

interface RegisterActionProps {
  initial?: boolean;
  final?: boolean;
  loading?: boolean;
  blocked: boolean;
  next: () => void;
  back: () => void;
}

export function RegistrationActionButtons(props: RegisterActionProps) {
  const { initial, final, loading, blocked, next, back } = props;
  const theme: Theme = useTheme();

  return (
    <ButtonContainer>
      <div>
        {!initial && (
          <StyledButton
            variant="outlined"
            className="back"
            disabled={blocked}
            onClick={back}
          >
            Back
          </StyledButton>
        )}
      </div>
      <div>
        {!loading && (
          <StyledButton variant="contained" disabled={blocked} onClick={next}>
            {final ? "Register" : "Next"}
          </StyledButton>
        )}
        {loading && (
          <Loader
            loading
            variant="contained"
            style={{ backgroundColor: theme.palette.primary.main }}
          />
        )}
      </div>
    </ButtonContainer>
  );
}
