import React from "react";

import { useForm } from "hooks/useForm";
import { useEffect } from "react";
import { FormField } from "./FormField";
import { asyncNoop, noop } from "utils/noop";
import { ClinicalTrialHours } from "types/facility";
import { FieldTrueFalseRadioGroup } from "components/FieldTrueFalseRadioGroup";

const defaultValue = {} as ClinicalTrialHours;

interface ClinicalTrialHoursFieldProps {
  value?: ClinicalTrialHours;
  form: string;
  onChange: Callback;
  [key: string]: any;
}

export function ClinicalTrialHoursField({
  value = defaultValue,
  form,
  onChange,
}: ClinicalTrialHoursFieldProps) {
  const { fields, setField } = useForm({
    id: form,
    fields: {
      all: {
        name: "all",
        label:
          "Is your site open for clinical studies 24 hours a day, 7 days a week?",
        value: boolAsString(value.all),
        "aria-label": "non-stop clinical studies",
      },
      weekends: {
        name: "weekends",
        label:
          "Does your site offer weekend appointments for clinical studies?",
        value: boolAsString(value.weekends),
        "aria-label": "weekend clinical studies",
      },
      evenings: {
        name: "evenings",
        label:
          "Does your site offer appointments in the evening on week days for clinical studies?",
        value: boolAsString(value.evenings),
        "aria-label": "evening clinical studies",
      },
    },
    submit: asyncNoop,
    onError: noop,
    onChange: (fields: any) => {
      onChange({
        all: asBool(fields.all.value),
        evenings: asBool(fields.evenings.value),
        weekends: asBool(fields.weekends.value),
      });
    },
  });

  useEffect(() => {
    for (const key of Object.keys(value)) {
      // @ts-ignore
      setField(key, boolAsString(value[key]));
    }
  }, [JSON.stringify(value)]); // eslint-disable-line

  return (
    <FormField label="Clinical Trial Hours">
      <FieldTrueFalseRadioGroup {...fields.all} />
      {fields.all.value === "false" && (
        <>
          <FieldTrueFalseRadioGroup {...fields.weekends} />
          <FieldTrueFalseRadioGroup {...fields.evenings} />
        </>
      )}
    </FormField>
  );
}

function asBool(value?: string | null) {
  if (value) return value === "true";
}

function boolAsString(value: any) {
  if (typeof value === "boolean") {
    return value.toString();
  }
}
