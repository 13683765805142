import React from "react";
import { useEffect } from "react";
import { useForm, Field } from "hooks/useForm";
import { asyncNoop, noop } from "utils/noop";
import { CommitteeUseTypes } from "types/facility";
import { FormCheckboxField } from "./FormCheckboxField";
import { FormCheckboxFieldsGroup } from "./FormCheckboxFieldsGroup";

const defaultValue = {} as any;

interface CommitteeUseTypesFieldProps {
  label?: string;
  error?: boolean;
  form: string;
  helperText?: string;
  onChange: (experience: CommitteeUseTypes) => void;
  value?: CommitteeUseTypes;
  [key: string]: any;
}

export function CommitteeUseTypesField({
  label = "Committee Use Types",
  error = false,
  form,
  value = defaultValue,
  helperText,
  onChange,
  ...otherProps
}: CommitteeUseTypesFieldProps) {
  const { fields, setField } = useForm({
    id: form,
    fields: {
      usesLocal: {
        type: "checkbox",
        name: "usesLocal",
        label: "Local",
        value: value.usesLocal,
      },
      usesCentral: {
        type: "checkbox",
        name: "usesCentral",
        label: "Central",
        value: value.usesCentral,
      },
      usesCentralAsLocal: {
        type: "checkbox",
        name: "usesCentralAsLocal",
        label: "Central Acting as Local",
        value: value.usesCentralAsLocal,
      },
      usesSponsorSupplied: {
        type: "checkbox",
        name: "usesSponsorSupplied",
        label: "Sponsor Provided Central",
        value: value.usesSponsorSupplied,
      },
    },
    submit: asyncNoop,
    onError: noop,
    onChange: (fields: any) => {
      onChange({
        usesLocal: asBoolOrNull(fields.usesLocal.value),
        usesCentral: asBoolOrNull(fields.usesCentral.value),
        usesCentralAsLocal: asBoolOrNull(fields.usesCentralAsLocal.value),
        usesSponsorSupplied: asBoolOrNull(fields.usesSponsorSupplied.value),
      });
    },
  });

  useEffect(() => {
    for (const key of Object.keys(value)) {
      // @ts-ignore
      setField(key, value[key] || "");
    }
  }, [JSON.stringify(value)]); // eslint-disable-line

  return (
    <FormCheckboxFieldsGroup label={label} error={error} {...otherProps}>
      <CheckboxField {...fields.usesLocal} />
      <CheckboxField {...fields.usesCentral} />
      <CheckboxField {...fields.usesCentralAsLocal} />
      <CheckboxField {...fields.usesSponsorSupplied} />
    </FormCheckboxFieldsGroup>
  );
}

function CheckboxField(props: Field) {
  return (
    <FormCheckboxField
      sx={{
        root: { mr: 2 },
        checkbox: { pr: 1 },
      }}
      {...props}
    />
  );
}

function asBoolOrNull(value?: boolean) {
  if (typeof value === "boolean") {
    return value;
  }
  return null;
}
