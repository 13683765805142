import React from "react";
import { Card, Typography, Alert } from "@mui/material";
import { styled } from "@mui/material/styles";
import { RegistrationStepper } from "./RegistrationStepper";
import { BasicDetailsRegistrationForm } from "./BasicDetailsRegistrationForm";
import { useRegistration } from "hooks/useRegistration";
import { RegisterPrimaryFacilityForm } from "./RegisterPrimaryFacilityForm";
import { RegisterAccountDetailsForm } from "./RegisterAccountDetailsForm";
import { RegistrationActionButtons } from "./RegistrationActionButtons";
import { useAuth } from "hooks/useAuth";
import { LoginLink } from "./LoginLink";

const Root = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  padding: theme.spacing(2),
  position: "relative",
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

const RegistrationContent = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
}));

const RegistrationForms = styled("div")(() => ({
  marginBottom: "auto",
}));

export function RegistrationCard() {
  let {
    data,
    currentStep,
    hasProfile,
    basicDetailsFormRef,
    primaryFacilityFormRef,
    loginDetailsFormRef,
    loading,
    next,
    back,
    error,
    blocked,
  } = useRegistration();
  const auth = useAuth();
  return (
    <Root elevation={7}>
      <Title variant="h4">Create a Profile</Title>

      <RegistrationStepper currentStep={currentStep} />

      <RegistrationContent>
        <RegistrationForms>
          {error && (
            <Alert sx={{ mt: 2, mb: 2 }} severity="error">
              {error}
            </Alert>
          )}
          {currentStep === 0 && (
            <BasicDetailsRegistrationForm
              service={basicDetailsFormRef}
            />
          )}
          {currentStep === 1 && (
            <RegisterPrimaryFacilityForm service={primaryFacilityFormRef} />
          )}
          {currentStep === 2 && (
            <RegisterAccountDetailsForm
              service={loginDetailsFormRef}
              existingData={data.existingData}
              registrationDetails={data.registrationDetails}
            />
          )}
          {hasProfile && (
            <Alert sx={{ mt: 2, mb: 2 }} severity="success">
              {`Great news! We have found profile login details for you. It looks like you
          previously registered with ${data.existingData.existingEmail}. `}
              <LoginLink onClick={() => auth?.login?.()}>log in</LoginLink>
            </Alert>
          )}
        </RegistrationForms>
        <RegistrationActionButtons
          initial={currentStep === 0}
          final={currentStep === 2}
          loading={loading}
          blocked={blocked}
          next={next}
          back={back}
        />
      </RegistrationContent>
    </Root>
  );
}
