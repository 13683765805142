import React from "react";

import { Card } from "components/Card";
import { MutatorFn } from "hooks/useCards";
import { User, Profile } from "types/profile";
import { ProfileUserForm } from "./ProfileUserForm";
import { ProfileUserDetails } from "./ProfileUserDetails";
import { useEditableData, onlyAllowUpdate } from "hooks/useEditableData";
import { CardMachine, useCard, ManagedCardProps } from "hooks/useCard";

export const UserCardMachine = CardMachine({ validateCard });

interface UserCardProps extends ManagedCardProps {
  user: User;
  userPk?: number;
  isAdmin?: boolean;
  updateUser: MutatorFn;
}

export function ProfileUserCard({
  id = "user-card",
  user,
  userPk,
  isAdmin = false,
  service,
  updateUser,
  ...otherProps
}: UserCardProps) {
  const withReason = isAdmin;

  const { card } = useCard({ service });
  const { details, updateForm } = useEditableData({ update: updateUser });

  onlyAllowUpdate(details);

  return (
    <Card
      id={id}
      heading="Name & Role"
      arial-label="nameAndRole"
      data-testid={ProfileUserCard.name}
      {...card}
      {...otherProps}
    >
      <ProfileUserDetails user={user} {...details} />
      <ProfileUserForm {...updateForm} entry={user} withReason={withReason} />
    </Card>
  );
}

export function validateCard({ user }: Profile) {
  if (!user) {
    return "Please add your details";
  }

  const userJobSet = !!user.jobTitle;
  const userNameAvailable = user.firstName && user.lastName;

  if (!userNameAvailable && userJobSet) {
    return "Please add your name";
  }

  if (!userNameAvailable && !userJobSet) {
    return "Please add your name & job function";
  }

  if (!user.jobTitle && user.role === "Site staff") {
    return "Please add your job function";
  }
}
