import React from "react";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { noop } from "utils/noop";
import { Form } from "components/Form";
import { useForm } from "hooks/useForm";
import { required } from "validators/required";
import { useCoreData } from "hooks/useCoreData";
import { FormFieldset } from "components/FormFieldset";
import { DepartmentDetails } from "types/facility";
import { getDepartmentType, getDepartmentSubtypeName } from "utils/department";
import { validators } from "utils/forms";
import { maxLengthForValidator as maxLength } from "validators/maxLength";

export interface DepartmentAboutFormProps {
  open?: boolean;
  title?: string;
  entry?: DepartmentDetails;
  submit: (details: DepartmentDetails) => Promise<any>;
  onError?: Callback;
  onCancel?: Callback;
  onSuccess?: Callback;
}

export function DepartmentAboutForm({
  title,
  open = true,
  entry = {} as DepartmentDetails,
  onError = console.error,
  onCancel = noop,
  onSuccess = noop,
  submit: doSubmit,
  ...otherProps
}: DepartmentAboutFormProps) {
  const { id, fields, submit, reset, submitting, error } = useForm({
    id: "department-details-form",
    fields: {
      departmentName: {
        name: "departmentName",
        label: "Department name",
        value: entry.departmentName,
        required: true,
        validate: validators(required, maxLength(255)),
      },
      type: {
        name: "type",
        label: "Department type",
        value: entry.type,
        required: true,
        validate: required,
      },
      subType: {
        name: "subType",
        label: "Department subtype",
        value: entry.subType,
      },
      address: {
        name: "address",
        label: "Address line 1",
        value: entry.address,
        required: true,
        validate: validators(required, maxLength(500)),
      },
      address2: {
        name: "address2",
        label: "Address line 2",
        value: entry.address2,
        validate: maxLength(500),
      },
      address3: {
        name: "address3",
        label: "Address line 3",
        value: entry.address3,
        validate: maxLength(500),
      },
      city: {
        name: "city",
        label: "City",
        value: entry.city,
        required: true,
        validate: required,
      },
      state: {
        name: "state",
        label: "State/Province/County",
        value: entry.state,
      },
      countryCode: {
        name: "countryCode",
        label: "Country",
        value: entry.countryCode,
        required: true,
        validate: required,
      },
      postal: {
        name: "postal",
        label: "ZIP/Postal code",
        value: entry.postal,
        required: true,
        validate: validators(required, maxLength(30)),
      },
    },
    submit: doSubmit,
    onReset: onCancel,
    onError,
    onSuccess,
  });

  const { getCountryByCode } = useCoreData();

  const countryName = fields.countryCode.value
    ? getCountryByCode(fields.countryCode.value)!.name
    : "";

  const departmentTypeName = fields.type.value
    ? getDepartmentType(fields.type.value).name
    : "";

  const departmentSubtypeName = getDepartmentSubtypeName(
    fields.type.value,
    fields.subType.value
  );

  return (
    <Form
      id={id}
      open={open}
      title={title}
      error={error}
      onCancel={reset}
      onSubmit={submit}
      submitting={submitting}
      aria-label={`Update the '${
        entry.departmentName
      }' ${departmentTypeName.toLowerCase()} department contact details`}
      {...otherProps}
    >
      <FormFieldset legend="Department details">
        <TextField fullWidth {...fields.departmentName} />
        <FormGroup row>
          <TextField {...fields.type} value={departmentTypeName} disabled />
          <TextField
            {...fields.subType}
            value={departmentSubtypeName}
            disabled
          />
        </FormGroup>
      </FormFieldset>

      <FormFieldset legend="Address">
        <Typography variant="subtitle2" color="textPrimary">
          If you have specified a department, please provide the most accurate
          address for that department
        </Typography>
        <FormGroup row>
          <TextField {...fields.address} />
          <TextField {...fields.address2} />
        </FormGroup>
        <FormGroup row>
          <TextField {...fields.address3} />
          <TextField {...fields.city} disabled />
        </FormGroup>
        <FormGroup row>
          <TextField {...fields.state} disabled />
          <TextField {...fields.countryCode} value={countryName} disabled />
          <TextField {...fields.postal} />
        </FormGroup>
      </FormFieldset>
    </Form>
  );
}

export function formLabelFor(entry: DepartmentDetails) {
  return `Update the '${entry.departmentName}' ${getDepartmentType(
    entry.type
  ).name.toLowerCase()} department contact details`;
}
