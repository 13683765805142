//@ts-nocheck
import React from "react";
import { Menu } from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreVert";

interface Props {
  children: JSX.Element | JSX.Element[];
}

export function MoreMenu({ children }: Props): JSX.Element {
  let [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);

  function handleClick(event: React.MouseEvent<SVGSVGElement>): void {
    setAnchorEl(event.currentTarget);
  }

  function handleClose(): void {
    setAnchorEl(null);
  }

  let elements = React.Children.toArray(children).map((child) => {
    return React.cloneElement(child, {
      sx: {
        "&.MuiMenuItem-root": {
          color: `${
            child.props.disabled ? "text.secondary" : "rgb(7, 104, 253)"
          }`,
        },
      },
      async onClick(event: React.MouseEvent) {
        handleClose();
        await child.props?.onClick(event);
      },
    });
  });

  return (
    <>
      <MoreIcon
        sx={{ cursor: "pointer" }}
        aria-label="action menu"
        color="primary"
        onClick={handleClick}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {elements}
      </Menu>
    </>
  );
}
