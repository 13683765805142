import React from "react";
import { styled } from "@mui/material/styles";
import MuiTooltip, {
  tooltipClasses,
  TooltipProps,
} from "@mui/material/Tooltip";
import { Info } from "@mui/icons-material";
import { IconButton, IconButtonProps } from "@mui/material";

const TooltipTextStyled = styled("div")(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
}));

const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: theme.spacing(2),
    fontSize: 14,
    maxWidth: 400,
    color: theme.palette.common.black,
    background: theme.palette.common.white,
    boxShadow: theme.shadows[6],
    border: `solid 1px ${theme.palette.grey[300]}`,
    borderRadius: 4,
  },
}));

type TTooltipProps = Omit<TooltipProps, "children"> & {
  iconButtonOptions?: IconButtonProps & {
    arialLabel?: string;
    "data-testid"?: string;
  };
};

export function Tooltip({ title, iconButtonOptions, ...rest }: TTooltipProps) {
  return (
    <TooltipStyled
      title={<TooltipTextStyled>{title}</TooltipTextStyled>}
      {...rest}
    >
      <IconButton
        sx={(theme) => {
          return { color: theme.palette.grey[700] };
        }}
        {...iconButtonOptions}
      >
        <Info color="inherit" />
      </IconButton>
    </TooltipStyled>
  );
}
