import React from "react";
import { Link, Divider, Typography, Breadcrumbs } from "@mui/material";

import { Suspend } from "components/Suspend";
import { useRoute } from "wouter";
import { CoreDataProvider } from "hooks/useCoreData";
import { Link as WouterLink } from "wouter";
import { ContactTypesProvider } from "hooks/useContactTypes";
import { useFacilityDepartmentProfile } from "hooks/useFacilityDepartmentProfile";
import { Screen, ScreenSection } from "components/Screen";
import { DepartmentDetailsCard } from "components/DepartmentDetailsCard";
import { DepartmentContactsCard } from "components/DepartmentContactsCard";
import { DepartmentEquipmentCard } from "components/DepartmentEquipmentCard";
import { DepartmentCommitteeCard } from "components/DepartmentCommitteeCard";
import { DepartmentProfileHeadingText } from "components/DepartmentProfileHeadingText";
import { DepartmentCharacteristicsCard } from "components/DepartmentCharacteristicsCard";
import { DepartmentPatientPopulationCard } from "components/DepartmentPatientPopulationCard";
import { DepartmentProfile as DepartmentProfileType } from "types/facility";

export default function DepartmentProfilePage() {
  const [, params] = useRoute(
    "/facilities/:facilityId/department/:departmentId"
  );
  return (
    <DepartmentProfile
      goldenFacilityPk={parseInt(params?.facilityId!)}
      departmentPk={parseInt(params?.departmentId!)}
    />
  );
}

interface DepartmentProfileProps {
  goldenFacilityPk: number;
  departmentPk: number;
  Breadcrumbs?: React.FC<any>;
}

export function DepartmentProfile({
  goldenFacilityPk,
  departmentPk,
  Breadcrumbs = DepartmentProfileBreadcrumbs,
}: DepartmentProfileProps) {
  const { cards, data, loading, error } = useFacilityDepartmentProfile({
    goldenFacilityPk,
    departmentPk,
  });
  const departmentProfile = data?.profile;

  return (
    <Suspend
      when={loading}
      error={error}
      errorMessage="There was an error retrieving the department profile. Please try again later"
    >
      <CoreDataProvider>
        <ContactTypesProvider>
          <Screen>
            <Breadcrumbs departmentProfile={departmentProfile} />
            <ScreenSection>
              <DepartmentProfileHeadingText
                departmentProfile={departmentProfile}
              />
              <DepartmentDetailsCard {...cards.details} />
              <DepartmentContactsCard {...cards.contacts} />
              <DepartmentCharacteristicsCard {...cards.characteristics} />
              <DepartmentCommitteeCard {...cards.committee} />
              <DepartmentPatientPopulationCard {...cards.patientPopulation} />
              <DepartmentEquipmentCard {...cards.equipment} />
            </ScreenSection>
          </Screen>
        </ContactTypesProvider>
      </CoreDataProvider>
    </Suspend>
  );
}

interface DepartmentProfileBreadcrumbsProps {
  departmentProfile: DepartmentProfileType;
}

function DepartmentProfileBreadcrumbs({
  departmentProfile,
}: DepartmentProfileBreadcrumbsProps) {
  const { departmentDetails, facilityDetails } = departmentProfile;
  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <WouterLink href="/facilities">
          <Link color="inherit" underline="hover">
            My facility profiles
          </Link>
        </WouterLink>
        <WouterLink href={`/facilities/${departmentProfile.goldenFacilityPk}`}>
          <Link color="inherit" underline="hover">
            {facilityDetails.name}
          </Link>
        </WouterLink>
        <Typography color="textPrimary">
          {departmentDetails.departmentName}
        </Typography>
      </Breadcrumbs>
      <Divider sx={{ mt: 2 }} />
    </div>
  );
}
