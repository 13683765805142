import { User } from "types/profile";

export enum Role {
  SiteStaff = "Site staff",
}

export function formatUserFullName(user: User) {
  const title = user.nameTitle ? `${user.nameTitle}.` : "";
  return `${title || ""} ${user.firstName || ""} ${user.middleName || ""} ${
    user.lastName || ""
  } ${user.nameSuffix || ""}`
    .trim()
    .replace(/ +(?= )/g, "");
}

export function formatUserName(user: any) {
  return `${user.firstName || ""} ${user.lastName || ""} `
    .trim()
    .replace(/ +(?= )/g, "");
}
