import React from "react";
import { useApolloClient } from "@apollo/client";
import { createMachine, assign, DoneInvokeEvent } from "xstate";
import { useMachine } from "@xstate/react";
import gql from "graphql-tag";

import { AdminSearchForm } from "../../components/AdminSearchForm";
import { AdminSearchTable } from "../../components/AdminSearchTable";
import { AdminSearchResults, AdminSearchParams } from "types/admin";
import { AdminEditUserForm } from "../../components/AdminEditUserForm";
import { Suspend } from "components/Suspend";
import { extractFromMultiSelect } from "utils/forms";
import { AppBar } from "layouts/AppBar";
import { Content } from "layouts/Content";

interface AdminSearchResultsContext {
  results: AdminSearchResults;
  params: AdminSearchParams;
  error?: string;
  editing: any;
}

type SearchEvent = { type: "SEARCH"; params: Partial<AdminSearchParams> };
type SetPageEvent = { type: "SET_PAGE"; page: number };
type SetPageRowsEvent = { type: "SET_ROWS_PER_PAGE"; rowsPerPage: number };
type ErrorEvent = DoneInvokeEvent<{ error: string }>;
type AdminSearchResultEvent = DoneInvokeEvent<{
  adminSearch: AdminSearchResults;
}>;
type EditEvent = { type: "EDIT"; user: any };
type CloseEvent = { type: "CLOSE" };
type ReloadEvent = { type: "RELOAD" };
type SortEvent = { type: "SORT"; field: string; descending: boolean };

type AdminSearchResultsEvents =
  | SearchEvent
  | SetPageEvent
  | SetPageRowsEvent
  | EditEvent
  | ErrorEvent
  | CloseEvent
  | ReloadEvent
  | AdminSearchResultEvent
  | SortEvent;

export const SEARCH_QUERY = gql`
  query AdminSearch($options: IAdminSearchParams!) {
    adminSearch(params: $options) {
      resultsPerPage
      pageNumber
      pageCount
      totalResults
      users {
        pk
        goldenPersonPk
        vpds
        firstName
        lastName
        email
        role
        optInStatus
        facility {
          name
          city
          countryCode
        }
        ssoUid
      }
    }
  }
`;

const AdminSearchResultsMachine = createMachine(
  {
    schema: {
      context: {} as AdminSearchResultsContext,
      events: {} as AdminSearchResultsEvents,
    },
    initial: "loading",
    context: {
      editing: {},
      params: {
        descending: false,
        pageNumber: 1,
        resultsPerPage: 25,
      },
      results: {
        pageCount: 0,
        pageNumber: 1,
        resultsPerPage: 25,
        totalResults: 0,
        users: [],
      },
    },
    states: {
      loading: {
        invoke: {
          src: "search",
          onDone: {
            actions: ["setResults"],
            target: "success",
          },
          onError: {
            actions: ["setError"],
            target: "failure",
          },
        },
      },
      success: {
        on: {
          EDIT: {
            actions: ["setEditingUser"],
            target: "editing",
          },
          SORT: {
            actions: ["setSortParams"],
            target: "loading",
          },
          SEARCH: {
            actions: ["setParams"],
            target: "loading",
          },
          RELOAD: "loading",
          SET_PAGE: {
            actions: ["setPage"],
            target: "loading",
          },
          SET_ROWS_PER_PAGE: {
            actions: ["setRowsPerPage"],
            target: "loading",
          },
        },
      },
      failure: {
        on: {
          SORT: {
            actions: ["setSortParams"],
            target: "loading",
          },
          SEARCH: {
            actions: ["setParams"],
            target: "loading",
          },
          SET_PAGE: {
            actions: ["setPage"],
            target: "loading",
          },
          SET_ROWS_PER_PAGE: {
            actions: ["setRowsPerPage"],
            target: "loading",
          },
        },
      },
      editing: {
        on: {
          CLOSE: "success",
        },
      },
    },
  },
  {
    actions: {
      setParams: assign({
        params: (context, event) => ({
          ...context.params,
          ...(event as SearchEvent).params,
        }),
      }),
      setError: assign({
        error: (_context, event) => (event as ErrorEvent).data.error,
      }),
      setResults: assign({
        results: (_context, event) =>
          (event as AdminSearchResultEvent).data.adminSearch,
      }),
      setPage: assign({
        params: (context, event) => ({
          ...context.params,
          pageNumber: (event as SetPageEvent).page,
        }),
      }),
      setRowsPerPage: assign({
        params: (context, event) => ({
          ...context.params,

          resultsPerPage: (event as SetPageRowsEvent).rowsPerPage,
        }),
      }),
      setEditingUser: assign({
        //@ts-ignore
        editing: (_context, { user }) => user,
      }),
      setSortParams: assign({
        //@ts-ignore
        params: (context, { field, descending }) => ({
          ...context.params,
          orderBy: field,
          descending,
        }),
      }),
    },
  }
);

function useAdminSearchResults() {
  const client = useApolloClient();
  const [state, send] = useMachine(AdminSearchResultsMachine, {
    services: {
      async search(context) {
        let { data, errors } = await client.query<
          AdminSearchResults,
          { options: AdminSearchParams }
        >({
          fetchPolicy: "network-only",
          query: SEARCH_QUERY,
          variables: {
            options: context.params,
          },
        });
        return errors ? errors : data;
      },
    },
  });

  return {
    state: state.value,
    ...state.context.params,
    loading: state.matches("loading"),
    results: state.context.results,
    error: state.context.error,
    editing: state.context.editing,
    isEditing: state.matches("editing"),
    search(params: Partial<AdminSearchParams>) {
      send({ type: "SEARCH", params });
    },
    setPage(page: number) {
      send({ type: "SET_PAGE", page });
    },
    setRowsPerPage(rowsPerPage: number) {
      send({ type: "SET_ROWS_PER_PAGE", rowsPerPage });
    },
    edit(user: any) {
      send({ type: "EDIT", user });
    },
    close() {
      send({ type: "CLOSE" });
    },
    reload() {
      send({ type: "RELOAD" });
    },
    sort(field: string, descending: boolean) {
      send({ type: "SORT", field, descending });
    },
  };
}

export default function Admin() {
  const {
    state,
    results,
    search,
    setPage,
    setRowsPerPage,
    pageNumber,
    resultsPerPage,
    edit,
    editing,
    close,
    reload,
    isEditing,
    loading,
    error,
    sort,
    descending,
    orderBy,
  } = useAdminSearchResults();

  return (
    <>
      <AppBar showNav={false} />
      <Content fullWidth={true}>
        <>
          <AdminSearchForm
            onSubmit={({ fields }) => {
              search({
                vpd: fields.vpd.value?.value,
                names: fields.names.value,
                memberIds: fields.memberIds.value,
                emails: fields.emails.value,
                goldenPersonPks: fields.personGoldenIds.value.map((pk: string) =>
                  Number(pk)
                ),
                goldenFacilityPks: fields.facilityGoldenIds.value.map(
                  (pk: string) => Number(pk)
                ),
                role: fields.role.value?.value,
                countries: extractFromMultiSelect(fields.countries.value),
                specialties: extractFromMultiSelect(fields.specialties.value),
                ssoUid: fields.ssoUid.value
              });
            }}
          />
          <div id="user-data-table" data-state={state}>
            <Suspend
              label="Loading admin results"
              when={loading}
              error={error}
              errorMessage="There was an error retrieving the profile. Please try again later"
            >
              <AdminSearchTable
                page={pageNumber}
                count={results.totalResults}
                results={results.users}
                rowsPerPage={resultsPerPage}
                onPageChange={(_event, page) => setPage(page + 1)}
                onClickEdit={(user) => edit(user)}
                sortOrder={descending ? "desc" : "asc"}
                orderBy={orderBy}
                onColumnClick={(field) => {
                  sort(field, !descending);
                }}
                onRowsPerPageChange={(event) =>
                  setRowsPerPage(Number(event.target.value))
                }
              />
            </Suspend>
          </div>
          {isEditing && (
            <AdminEditUserForm
              open={isEditing}
              onClickCancel={close}
              onSuccess={() => {
                close();
                reload();
              }}
              user={editing}
            />
          )}
        </>
      </Content>
    </>
  );
}
