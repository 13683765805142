import React from "react";

import { Link } from "wouter";
import { Link as MuiLink } from "@mui/material";
import { styled } from "@mui/material/styles";

const LinkStyled = styled(MuiLink)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: "0.875rem",
}));

const DelegatesIcon = styled("img")(({ theme }) => ({
  height: "1.3rem",
  marginRight: theme.spacing(1),
}));

export function ProfileDelegatesLink() {
  return (
    <Link href="/delegates">
      <LinkStyled>
        <DelegatesIcon
          alt=""
          src="/Icon-Delegate.png"
          aria-label="link to delegates"
        />
        Invite Delegates to fill in your profile.
      </LinkStyled>
    </Link>
  );
}
