import React from "react";
import { styled } from "@mui/material/styles";
import { Dialog, DialogContent, Button, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { SubmitFn } from "hooks/useForm";
import { useMachine } from "@xstate/react";
import { StudyByNctForm } from "./StudyByNctForm";
import { assign, createMachine } from "xstate";
import { noop, asyncNoop } from "utils/noop";
import { StudySearchForm } from "./StudySearchForm";

import { Study, StudyIdentifier } from "types/profile";
import { StudyByManualForm } from "./StudyByManualForm";

interface Props {
  title?: string;
  open?: boolean;
  entry?: Study;
  testId?: string;
  submit?: SubmitFn;
  onCancel?: Callback;
  onSuccess?: Callback;
  withReason?: boolean;
  createStudy: Callback;
  isInvestigator?: boolean;
  getStudyIdentifier: Callback;
  "data-testid"?: string;
}

const ActionBarLeft = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  columnGap: theme.spacing(2),
  alignItems: "center",
}));

export function StudyForm({
  open,
  entry = {} as Study,
  title = "Update study",
  "data-testid": testId = StudyForm.name,
  submit: doSubmit = asyncNoop,
  withReason = false,
  createStudy,
  getStudyIdentifier,
  onCancel,
  onSuccess,
  ...otherProps
}: Props) {
  const { nctForm, manualForm, searchForm, addManualEntryButton } =
    useStudyForm({ onSuccess, onCancel });
  if (!open) return null;

  return (
    <Dialog
      open={Boolean(open)}
      data-testid={testId}
      maxWidth="xl"
      {...otherProps}
    >
      <DialogContent sx={{ p: 3, pb: 0 }}>
        {searchForm.open && (
          <StudySearchForm
            submit={getStudyIdentifier}
            actionBarLeft={
              <ActionBarLeft>
                <Typography component="h2" children="No NCT Number?" />
                <Button
                  sx={{
                    color: "text.disabled",
                    fontWeight: "normal",
                  }}
                  startIcon={<AddCircleIcon />}
                  data-testid={`${testId}CreateManualEntryButton`}
                  {...addManualEntryButton}
                >
                  Add a new study
                </Button>
              </ActionBarLeft>
            }
            {...searchForm}
          />
        )}
        {nctForm.open && (
          <StudyByNctForm
            withReason={withReason}
            submit={createStudy}
            {...nctForm}
          />
        )}
        {manualForm.open && (
          <StudyByManualForm
            title="Add a clinical study"
            withReason={withReason}
            submit={createStudy}
            {...manualForm}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

interface Context {
  study: StudyIdentifier | undefined;
}

type OpenNctEvent = { type: "OPEN_NCT"; study: StudyIdentifier };
type OpenManualEvent = { type: "OPEN_MANUAL"; study: StudyIdentifier };
type SetStudy = { type: "SET_STUDY"; study: StudyIdentifier };

type Events = OpenNctEvent | OpenManualEvent | SetStudy;

const StudyFormMachine = createMachine<Context, Events>(
  {
    initial: "search",
    context: {
      study: undefined,
    },
    states: {
      search: {
        on: {
          OPEN_NCT: {
            target: "nct",
          },
          OPEN_MANUAL: {
            target: "manual",
          },
          SET_STUDY: {
            actions: "setStudy",
          },
        },
      },
      nct: {},
      manual: {},
    },
  },
  {
    actions: {
      setStudy: assign((_, event) => ({
        study: event.study,
      })),
    },
  }
);

interface UseStudyFormProps {
  onCancel?: Callback;
  onSuccess?: Callback;
}

function useStudyForm({
  onCancel = noop,
  onSuccess = noop,
}: UseStudyFormProps = {}) {
  //@ts-ignore
  const [state, send] = useMachine(StudyFormMachine);
  return {
    searchForm: {
      open: state.matches("search"),
      onSuccess: (study: StudyIdentifier) => {
        send({ type: "SET_STUDY", study });
        send("OPEN_NCT");
      },
      onCancel,
    },
    nctForm: {
      open: state.matches("nct"),
      study: state.context.study,
      onSuccess,
      onCancel,
    },
    manualForm: {
      open: state.matches("manual"),
      onSuccess,
      onCancel,
    },
    addManualEntryButton: {
      onClick: () => {
        send("OPEN_MANUAL");
      },
    },
  };
}
