import React from "react";

import { Card } from "components/Card";
import { MutatorFn } from "hooks/useCards";
import { Box } from "@mui/material";
import { DeletionDialog } from "./DeletionDialog";
import { Phases, Profile } from "types/profile";
import { useEditableData } from "hooks/useEditableData";
import { ResearchPhasesForm } from "./ResearchPhasesForm";
import { ResearchPhasesDisplay } from "./ResearchPhasesDisplay";
import { ResearchIndicationForm } from "./ResearchIndicationForm";
import { ResearchIndicationsTable } from "./ResearchIndicationsTable";
import { CardMachine, ManagedCardProps, useCard } from "hooks/useCard";

export const ResearchCardMachine = CardMachine({ validateCard });

interface ResearchCardProps extends ManagedCardProps {
  userPk?: number;
  isAdmin?: boolean;
  phases: Phases;
  indications: string[];
  updatePhases: MutatorFn;
  deletePhases: MutatorFn;
  createIndication: MutatorFn;
  updateIndication: MutatorFn;
  deleteIndication: MutatorFn;
}

export function ResearchCard({
  id = "research-card",
  service,
  userPk,
  isAdmin = false,
  phases,
  indications,
  updatePhases,
  deletePhases,
  createIndication,
  updateIndication,
  deleteIndication,
  ...otherProps
}: ResearchCardProps) {
  const { card } = useCard({ service });

  const phasesData = useEditableData({
    create: updatePhases,
    update: updatePhases,
    delete: (_, adminComment?: string) => {
      return deletePhases(adminComment);
    },
  });

  const indicationsData = useEditableData({
    create: createIndication,
    update: updateIndication,
    delete: deleteIndication,
  });

  const withReason = isAdmin;

  return (
    <Card
      id={id}
      heading="Top 5 Research Indications"
      arial-label="top5ResearchIndications"
      data-testid={ResearchCard.name}
      {...card}
      {...otherProps}
    >
      <ResearchPhasesDisplay entry={phases} {...phasesData.details} />
      {phasesData.creationForm.open && (
        <ResearchPhasesForm
          withReason={withReason}
          {...phasesData.creationForm}
        />
      )}

      {phasesData.updateForm.open && (
        <ResearchPhasesForm
          withReason={withReason}
          {...phasesData.updateForm}
        />
      )}

      {phasesData.deletionDialog.open && (
        <DeletionDialog
          withReason={withReason}
          data-testid="ResearchPhasesDeletionDialog"
          {...phasesData.deletionDialog}
        />
      )}

      <Box sx={{ mt: 7 }}>
        <ResearchIndicationsTable
          entries={indications}
          {...indicationsData.details}
        />

        {indicationsData.creationForm.open && (
          <ResearchIndicationForm
            title="Add indication"
            withReason={withReason}
            data-testid="ResearchIndicationCreationForm"
            {...indicationsData.creationForm}
          />
        )}

        {indicationsData.updateForm.open && (
          <ResearchIndicationForm
            title="Update indication"
            withReason={withReason}
            data-testid="ResearchIndicationUpdateForm"
            {...indicationsData.updateForm}
          />
        )}

        {indicationsData.deletionDialog.open && (
          <DeletionDialog
            withReason={withReason}
            data-testid="ResearchIndicationDeletionDialog"
            {...indicationsData.deletionDialog}
          />
        )}
      </Box>
    </Card>
  );
}

export function validateCard({ indications, phases }: Profile) {
  const isPhasesComplete =
    phases && Object.values(phases).some((value) => value);

  if (!isPhasesComplete && !indications?.length) {
    return "Please enter which phases & indications you have experience in";
  }

  if (!isPhasesComplete) {
    return "Please enter which phases you have experience in";
  }

  if (!indications?.length) {
    return "Please enter which indications you have experience in";
  }
}
