import React from "react";

import { Card } from "components/Card";
import { RoleType } from "utils/role";
import { MutatorFn } from "hooks/useCards";
import { useCoreData } from "hooks/useCoreData";
import { LicenceForm } from "./LicenceForm";
import { LicenceTable } from "./LicenceTable";
import { DeletionDialog } from "./DeletionDialog";
import { useEditableData } from "hooks/useEditableData";
import { hasIncompleteLicences } from "utils/licence";
import { Licence, User, Profile } from "types/profile";
import { CardMachine, ManagedCardProps, useCard } from "hooks/useCard";

export const LicenceCardMachine = CardMachine({ validateCard });

interface LicenceCardProps extends ManagedCardProps {
  user: User;
  userPk?: number;
  isAdmin?: boolean;
  licences: Licence[];
  createLicence: MutatorFn;
  updateLicence: MutatorFn;
  deleteLicence: MutatorFn;
}

export function LicenceCard({
  id = "licence-card",
  user,
  userPk,
  isAdmin = false,
  service,
  licences,
  createLicence,
  updateLicence,
  deleteLicence,
  ...otherProps
}: LicenceCardProps) {
  const { card } = useCard({ service });
  const { countries } = useCoreData();
  const { details, creationForm, updateForm, deletionDialog } = useEditableData(
    {
      create: createLicence,
      update: updateLicence,
      delete: deleteLicence,
    }
  );

  const withReason = isAdmin;
  const isInvestigator = user.role === RoleType.Investigator;

  return (
    <Card
      id={id}
      heading="Professional Licence & Other Certification"
      arial-label="professionalLicenceAndOtherCertification"
      data-testid={LicenceCard.name}
      {...card}
      {...otherProps}
    >
      <LicenceTable entries={licences} countries={countries} {...details} />

      {creationForm.open && (
        <LicenceForm
          title="Add licence or certification"
          withReason={withReason}
          data-testid="LicenceCreationForm"
          isInvestigator={isInvestigator}
          {...creationForm}
        />
      )}

      {updateForm.open && (
        <LicenceForm
          title="Update licence or certification"
          withReason={withReason}
          data-testid="LicenceUpdateForm"
          isInvestigator={isInvestigator}
          flagIncompleteFields
          {...updateForm}
        />
      )}

      {deletionDialog.open && (
        <DeletionDialog withReason={withReason} {...deletionDialog} />
      )}
    </Card>
  );
}

export function validateCard({ licences }: Profile) {
  const message =
    "Please let us know your current professional licence or certification number";

  if (!licences.length) {
    return message;
  }

  if (hasIncompleteLicences(licences)) {
    return message;
  }
}
