import { FacilityDiagnostics } from "types/facility";
import { maxLength } from "../validators/maxLength";

export const EQUIPMENT_ERROR_MESSAGE =
  "Equipment length must not be more than 80 characters long.";

export function isValidOtherEquipment(
  otherEquipments: FacilityDiagnostics["other"]
) {
  return (
    otherEquipments.every((otherEquipment) => maxLength(80)(otherEquipment)) ||
    EQUIPMENT_ERROR_MESSAGE
  );
}
