import React from "react";
import jobTitles from "../fixtures/siteStaffJobTitles";

import {
  AutocompleteField,
  AutocompleteInstanceFieldProps,
} from "./AutocompleteField";

export function SiteStaffJobTitleSelectField(
  props: AutocompleteInstanceFieldProps
) {
  return (
    <AutocompleteField
      id="jobTitle"
      label="Job function"
      name="jobTitle"
      options={jobTitles}
      {...props}
    />
  );
}
