import React from "react";

export let InvestigatorDataBankTerms = (
  <>
    <p>
      Information to be shared across Participating Companies includes the
      following:
    </p>
    <ul style={{ fontSize: "0.9rem" }}>
      <li>Institutional Details: Institution name</li>
      <li>
        Investigator's name (first and last), business mailing address,
        telephone details and e-mail address
      </li>
      <li>
        Good Clinical Practice Training Details for investigators (GCP training;
        date(s) and related Sponsor of GCP training)
      </li>
      <li>
        Clinical Trial Details for those trials for which enrolment has been
        completed (e.g., protocol title, phase, protocol number, therapeutic
        area, indication, number of patients consented, enrolled, and completed
        per protocol)
      </li>
      <li>
        The following dates relevant for the individual site: site opened, first
        patient consented, last patient consented, first patient enrolled, last
        patient enrolled, and last patient last visit
      </li>
    </ul>

    <p>
      Any information entered or uploaded into the InvestigatorDatabank.org
      website by you or one of your delegates will also be shared including, but
      not limited to your CV, GCP training certificates, investigator profile
      information, and non-protocol specific site profile forms.
    </p>

    <p>
      You have no legal duty to provide your personal information, nor to allow
      its use and sharing as hereby requested. Participation is dependent on
      your free will and consent.
    </p>

    <p>
      If you would like to be included, please click the button below agreeing
      that you:
    </p>

    <p>
      Authorize the Databank Host, DrugDev and the Participating Companies
      (current participants are AbbVie, Amgen, AstraZeneca, Boehringer
      Ingelheim, Bristol-Myers Squibb, Merck, Janssen, Lilly, Novartis, Novo
      Nordisk, Pfizer, Regeneron, Roche, Sanofi, Takeda and UCB but which may
      change and be added to from time to time as notified to you) and their
      authorized representatives and business partners to place the clinical
      research related information about me and my site in the investigator
      database for use by the Participating Companies as described above.
    </p>

    <p>
      Give your permission to the Databank Host, DrugDev and any of the
      Participating Companies to use the contact and other information entered
      into the investigator database to qualify and contact you for purposes as
      described above.
    </p>

    <p>
      Once you have opted in, your consent for inclusion in the Investigator
      Databank will be in effect until such time as you revoke your consent,
      which you may do any time or when the Investigator Databank initiative
      ends. You will be able access a copy of your and your site’s data through
      the{" "}
      <a
        href="http://www.investigatordatabank.org"
        rel="noopener noreferrer"
        target="_blank"
      >
        www.InvestigatorDatabank.org
      </a>{" "}
      website or by contacting{" "}
      <a href="mailto:support@investigatordatabank.org">
        support@investigatordatabank.org
      </a>
      .
    </p>
  </>
);
