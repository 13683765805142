import React, { createContext } from "react";
import { useAuth } from "hooks/useAuth";
import { styled } from "@mui/material/styles";
import { LoginGettingStartedCard } from "components/LoginGettingStartedCard";
import { Button, Typography, Grid, Card, Link } from "@mui/material";
import { RegistrationCard } from "components/RegistrationCard";

interface RegistrationState {
  findOutMoreModalRef: () => void;
  modalRef: any;
};

export const RegistrationContext = createContext<Partial<RegistrationState>>({});

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  alignItems: "center",
  display: "grid",
  justifyContent: "center",
  gridGap: theme.spacing(2),
  color: theme.palette.grey[800],
}));

const Cover = styled("div")(() => ({
  minWidth: "100%",
  minHeight: 294,
  background:
    "url(../registration.png) no-repeat center center",
  backgroundSize: "cover",
}));

const LoginTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

const HelpLinkContainer = styled("p")(({ theme }) => ({
  fontSize: "1rem",
  marginBottom: theme.spacing(1),
}));

const LinkStyled = styled(Link)(() => ({
  color: "inherit",
}));

const CardInner = styled("p")(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(3)} `
}));

export default function Login() {
  const auth = useAuth();

  return (
    <Root data-testid="LoginScreen">
      <div>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item md={4}>
            <Card elevation={7} sx={{ mb: 2, p: 1}}>
              <LoginTitle variant="h4">Sign In</LoginTitle>
              <Typography
                sx={{ color: "inherit", marginBottom: 1 }}
                variant="subtitle2"
                component="p"
                align="center"
              >
                Already have a profile?
              </Typography>
              <Typography align="center">
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={{maxWidth: "155px", minWidth: "135px"}}
                  data-testid="LoginSSOButton"
                  children="Login"
                  onClick={() => auth?.login?.()}
                />
              </Typography>
            </Card>

            <Card elevation={7} >
              <CardInner data-testid={LoginGettingStartedCard.name}>
                <Typography variant="h5" component="h5" fontWeight="bold">
                  How can I get started?
                </Typography>
                <Typography
                  sx={{ color: "inherit", marginBottom: 2 }}
                  variant="subtitle1"
                  component="p"
                >
                  Register with the IQVIA network and create a complementary profile in
                  our network for full access to study sponsors and CROs who are seeking
                  sites like yours. If you are already registered with IQVIA create your
                  profile now!
                </Typography>
                <HelpLinkContainer>
                  Need help?{" "}
                  <LinkStyled href="/registration/contact-us">Contact us</LinkStyled>
                </HelpLinkContainer>
              </CardInner>
              <Cover sx={{ gridArea: { xs: "cover", lg: "auto" } }} />
            </Card>
          </Grid>

          <Grid item md={7}>
            <RegistrationCard />
          </Grid>
        </Grid>
      </div>
    </Root>
  );
}
