import { noop } from "utils/noop";
import { CardProps } from "components/Card";
import { useActor } from "@xstate/react";
import { useMachine } from "@xstate/react";
import { Machine, assign, spawn, sendParent, Interpreter } from "xstate";

type MachineService = Interpreter<any, any, any, any>;

export interface ManagedCardProps extends Omit<CardProps, "heading"> {
  heading?: string;
  service: MachineService;
}

type ValidatorFn = (data?: any) => string | undefined | null | void;

interface CardMachineContext {
  id: string;
  data: any;
  message: string | null;
  expanded: boolean;
  validateCard: ValidatorFn | null;
}

interface CardMachineStateSchema {
  states: {
    initialise: {};
    idle: {};
    validating: {};
  };
}

interface CardMachineOptions {
  validateCard?: (data?: any) => string | undefined | null | void;
}

export function CardMachine({ validateCard = noop }: CardMachineOptions = {}) {
  return Machine<CardMachineContext, CardMachineStateSchema>(
    {
      initial: "initialise",
      context: {
        id: "",
        data: null,
        message: null,
        expanded: false,
        validateCard: null,
      },
      states: {
        initialise: {
          always: [
            {
              actions: ["setupCard", "validateCard"],
              target: "idle",
            }
          ]
        },
        idle: {
          on: {
            VALIDATE: {
              // TODO: verify who sending the validate event
              target: "validating",
            },
            SET_DATA: {
              cond: "hasDataChanged",
              target: "validating",
              actions: "setData",
            },
            UPDATE_DATA: {
              // TODO: verify who sending the update event
              actions: ["setData", "validateData"],
            },
            SET_EXPANDED: {
              actions: "setExpanded",
            },
          },
        },
        validating: {
          invoke: {
            src: "validate",
            onError: {
              actions: ["saveValidationMessage", "notifyStatusChanged"],
              target: "idle",
            },
            onDone: {
              target: "idle",
              actions: ["clearValidationMessage", "notifyStatusChanged"],
            },
          },
        },
      },
    },
    {
      guards: {
        hasDataChanged: (context, event) => {
          return context.data !== event.data;
        },
      },
      actions: {
        setData: assign((_, event) => ({
          data: event.data,
        })),
        saveValidationMessage: assign((_, event: any) => ({
          message: event.data,
        })),
        clearValidationMessage: assign((_) => ({
          message: null,
        })),
        notifyStatusChanged: sendParent("CARD_STATUS_CHANGED"),
        setExpanded: assign((_, event) => ({
          expanded: event.expanded,
        })),
        setupCard: assign((_) => ({
          validateCard,
        })),
        validateCard: assign((context) => ({
          message: validateCard(context.data!) || null,
        })),
      },
      services: {
        validate: (context) => {
          return new Promise((resolve, reject) => {
            const message = validateCard(context.data);
            if (message) {
              reject(message);
            } else {
              resolve({});
            }
          });
        },
      },
    }
  );
}

interface UseCardOptions {
  service: MachineService;
}

export function useCard({ service }: UseCardOptions) {
  const [state, send] = useActor(service);
  const message = state.context.message;

  return {
    card: {
      expanded: state.context.expanded,
      warningMessage: message,
      showSuccessStatusIcon: message === null,
      showWarningStatusIcon: message !== null,
      onClick() {
        send({ type: "SET_EXPANDED", expanded: !state.context.expanded });
      },
    },
    setCardData(data: any) {
      send({ type: "SET_DATA", data });
    },
  };
}

interface CardsMachineContext {
  ids: string[];
  cards: {
    [id: string]: MachineService;
  };
}

interface CardsMachineSchema {
  states: {
    initialise: {};
    idle: {};
  };
}

type CardsMachineEvents =
  | { type: "CARD_STATUS_CHANGED" }
  | { type: "EXPAND_FIRST_INCOMPLETE" };

const CardsMachine = Machine<
  CardsMachineContext,
  CardsMachineSchema,
  CardsMachineEvents
>(
  {
    initial: "initialise",
    context: {
      ids: [],
      cards: {},
    },
    states: {
      initialise: {
        entry: "setupCards",
        always: [{ target: "idle" }]
      },
      idle: {
        on: {
          CARD_STATUS_CHANGED: {
            actions: "expandFirstIncomplete",
          },
          EXPAND_FIRST_INCOMPLETE: {
            actions: "expandFirstIncomplete",
          },
        },
      },
    },
  },
  {
    actions: {
      expandFirstIncomplete: (context) => {
        let firstIncomplete;
        for (const id of context.ids) {
          const cardRef = context.cards[id];
          const cardIsIncomplete = cardRef.state.context.message != null;

          if (!firstIncomplete && cardIsIncomplete) {
            firstIncomplete = id;
          }

          if (firstIncomplete === id) {
            cardRef.send({ type: "SET_EXPANDED", expanded: true });
          } else {
            cardRef.send({ type: "SET_EXPANDED", expanded: false });
          }
        }
      },
    },
  }
);

interface ReturnedCards {
  [key: string]: { service: MachineService };
}

interface UseCardsOptions {
  ids: string[];
  cards: AnyObject;
}

export function useCards(opts: UseCardsOptions) {
  const [state, send] = useMachine(
    //@ts-ignore
    CardsMachine.withContext({ ids: opts.ids, cards: {} }),
    {
      actions: {
        setupCards: assign((_) => ({
          cards: opts.ids.reduce((acc, id) => {
            const machine = opts.cards[id];
            acc[id] = spawn(machine.withContext({ id, expanded: false }));
            return acc;
          }, {} as AnyObject),
        })),
      },
    }
  );

  const cards = {} as ReturnedCards;

  const stats = {
    total: state.context.ids.length,
    complete: 0,
  };

  for (const id of opts.ids) {
    const cardRef = state.context.cards[id];
    const isComplete = cardRef.state.context.message == null;
    if (isComplete) {
      stats.complete++;
    }
    cards[id] = {
      service: cardRef,
    };
  }

  return {
    cards: cards,
    percentageComplete: Math.ceil((stats.complete / stats.total) * 100),
    showMissing: () => {
      send({ type: "EXPAND_FIRST_INCOMPLETE" });
    },
  };
}
