import React from "react";

import { Form } from "./Form";
import { noop } from "utils/noop";
import { useForm } from "hooks/useForm";
import { FormFieldset } from "./FormFieldset";
import { PatientPopulation, Facility } from "types/facility";
import { PatientPopulationAgeGroupsField } from "./PatientPopulationAgeGroupsField";
import { PatientPopulationEthnicitiesField } from "./PatientPopulationEthnicitiesField";
import { PatientPopulationEthnicitiesFieldUSA } from "./PatientPopulationEthnicitiesFieldUSA";

export interface DepartmentPatientPopulationFormProps {
  open?: boolean;
  title?: string;
  entry?: PatientPopulation;
  submit: (value: PatientPopulation) => Promise<any>;
  onCancel?: Callback;
  onError?: Callback;
  onSuccess?: Callback;
  facility: Facility;
  "data-testid"?: string;
}

export function DepartmentPatientPopulationForm({
  open,
  title,
  entry = {} as PatientPopulation,
  onCancel = noop,
  onError = console.error,
  onSuccess = noop,
  submit: doSubmit,
  facility,
  ...otherProps
}: DepartmentPatientPopulationFormProps) {
  const { id, fields, submit, reset, submitting, error } = useForm({
    id: "department-patient-population-form",
    fields: {
      ethnicities: {
        name: "ethnicities",
        value: entry.ethnicities,
      },
      ageGroups: {
        name: "ageGroups",
        value: entry.ageGroups,
      },
    },
    submit: doSubmit,
    onReset: onCancel,
    onError,
    onSuccess,
  });

  return (
    <Form
      id={id}
      open={open}
      title={title}
      aria-label="Department patient population"
      onSubmit={submit}
      submitting={submitting}
      onCancel={reset}
      error={error}
      {...otherProps}
    >
      <FormFieldset legend="Patient Ethnicity">
        {facility.countryCode === "US" ? (
          <PatientPopulationEthnicitiesFieldUSA
            ethnicities={facility.ethnicities}
            {...fields.ethnicities}
          />
        ) : (
          <PatientPopulationEthnicitiesField
            ethnicities={facility.ethnicities}
            {...fields.ethnicities}
          />
        )}
      </FormFieldset>

      <FormFieldset legend="Demographics of patient population">
        <PatientPopulationAgeGroupsField {...fields.ageGroups} />
      </FormFieldset>
    </Form>
  );
}
