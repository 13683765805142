let phoneTypes = [
  { label: "Main/Daytime", value: "Main" },
  { label: "Evening", value: "Evening" },
  { label: "24 hour", value: "24hour" },
  { label: "Cell/Mobile", value: "Cellphone" },
  { label: "Fax", value: "Fax" },
  { label: "Pager", value: "Pager" },
  { label: "Alternate", value: "Alternate" },
];

export default phoneTypes;
