import gql from "graphql-tag";
import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { useQuery } from "@apollo/client";

export const GET_CORE_DATA = gql`
  query getCountries {
    countries {
      code
      name
      hasStates
    }
  }
`;

export interface Country {
  code: string;
  name: string;
  hasStates: boolean;
}

interface CoreData {
  countries: Country[];
  hasStates: (countryCode: string) => Boolean;
  getCountryByCode: (countryCode: string) => Country | null;
}

interface CoreProviderProps {
  children?: ReactNodes;
}

export function CoreDataProvider({ children }: CoreProviderProps) {
  const { loading, error } = useQuery(GET_CORE_DATA, {
    context: { coreData: true },
  });

  if (loading) {
    return (
      <LinearProgress
        aria-label="Loading data"
        data-testid="CoreDataLoadingIndicator"
      />
    );
  }

  if (error) {
    return (
      <div data-testid="CoreDataErrorMessage">
        An unexpected error has occurred. Please try again later
      </div>
    );
  }
  return <>{children}</>;
}

export function useCoreData() {
  const { data } = useQuery(GET_CORE_DATA);

  if (!data) {
    throw new Error(
      `'${useCoreData.name}' must be used in the context of a '${CoreDataProvider.name}'`
    );
  }

  const hasStates = (countryCode: string) => {
    if (!countryCode) return false;
    const country = data.countries.find((c: Country) => c.code === countryCode);
    if (country) return country.hasStates;
    return false;
  };

  function getCountryByCode(code: string) {
    return (
      data.countries.find((country: Country) => country.code === code) || null
    );
  }

  return { ...data, hasStates, getCountryByCode } as CoreData;
}
