import React, { FunctionComponent } from "react";
import { CountrySelectField } from "./CountrySelectField";
import { Typography, TextField, Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  BasicDetailsFormMachineService,
  useBasicDetailsForm,
} from "../hooks/useBasicDetailForm";

const InfoTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[800],
  lineHeight: 1.25,
}));

const FormGroup = styled("div")(({ theme }) => ({
  display: "grid",
  gridGap: `${theme.spacing(1)}`,
  alignItems: "center",
}));

interface Props {
  service: BasicDetailsFormMachineService;
}

export const BasicDetailsRegistrationForm: FunctionComponent<Props> = ({service}) => {
  let { id, firstName, lastName, countryCode, email, changeField, blockUserDetails } =
    useBasicDetailsForm(service);

  return (
    <div style={{ marginTop: "1.2rem" }}>
      <form id={id} aria-label="basic details form">
        <Box sx={{ mb: 2 }}>
          <FormGroup>
            <Grid container={true} spacing={2}>
              <Grid item={true} md={6}>
                <TextField
                  {...firstName}
                  onChange={changeField}
                  fullWidth={true}
                />
              </Grid>
              <Grid item={true} md={6}>
                <TextField
                  {...lastName}
                  onChange={changeField}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
            <TextField
              {...email}
              onChange={changeField}
              disabled={blockUserDetails}
            />
            <InfoTypography variant="caption">
              Each registrant must have a unique email address. This is needed
              to comply with identity management standards.{" "}
            </InfoTypography>

            <CountrySelectField
              {...countryCode}
              onChange={changeField}
              value={countryCode.value}
            />
          </FormGroup>
        </Box>
      </form>
    </div>
  );
};
