import React from "react";
import { Dialog, DialogContent, TextField } from "@mui/material";

import { Form } from "./Form";
import { useState, useCallback, useEffect } from "react";

interface ChangeReasonFormDialogProps {
  reason: string;
  open?: boolean;
  title?: string;
  "data-testid"?: string;
  submit: Callback;
  cancel: Callback;
  onReasonChange: (reason: string) => void;
}

export function ChangeReasonFormDialog({
  open,
  submit,
  cancel,
  reason,
  onReasonChange,
  title = "Review & reasons for change",
  "data-testid": testId = ChangeReasonFormDialog.name,
  ...otherProps
}: ChangeReasonFormDialogProps) {
  if (!open) return null;

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      data-testid={testId}
      {...otherProps}
    >
      <DialogContent>
        <Form
          id="change-reason-form"
          open={open}
          title={title}
          onSubmit={submit}
          onCancel={cancel}
        >
          <TextField
            label="Reasons for change (optional)"
            value={reason}
            onChange={(event) => onReasonChange(event.target.value)}
            rows="6"
            multiline
            fullWidth
          />
        </Form>
      </DialogContent>
    </Dialog>
  );
}

interface UseChangeReasonFormDialog {
  doSubmit: (...values: any[]) => any;
  formHookSubmit: (...values: any[]) => any;
  withReason: boolean;
}

export function useChangeReasonFormDialog({
  doSubmit,
  formHookSubmit,
  withReason,
}: UseChangeReasonFormDialog) {
  const [reason, setReason] = useState("");
  const [submitFn, setSubmitFn] = useState<any>(() => formHookSubmit);
  const [showReasonDialog, setShowReasonDialog] = useState(false);

  const submitWithReason = useCallback(() => {
    setShowReasonDialog(true);
  }, []);

  function performSubmit(values: any) {
    if (withReason) {
      return doSubmit(values, reason || "");
    }
    return doSubmit(values);
  }

  function handleReasonDialogSubmit() {
    setShowReasonDialog(false);
    formHookSubmit();
  }

  function handleReasonDialogCancel() {
    setShowReasonDialog(false);
  }

  function handleReasonFieldChange(reason: string) {
    setReason(reason);
  }

  useEffect(() => {
    if (withReason) {
      setSubmitFn(() => submitWithReason);
    }
  }, [submitWithReason, withReason]);

  return {
    performSubmit,
    handleSubmitButtonClick: submitFn,
    reasonDialog: {
      reason,
      open: showReasonDialog,
      submit: handleReasonDialogSubmit,
      cancel: handleReasonDialogCancel,
      onReasonChange: handleReasonFieldChange,
    },
  };
}
