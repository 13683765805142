import React from "react";
import {
  FormRadioButtonGroup,
  FormRadioButtonGroupProps,
} from "./FormRadioButtonGroup";

export function FieldTrueFalseRadioGroup({
  "aria-label": ariaLabel = "yes/no radio button group",
  ...props
}: FormRadioButtonGroupProps) {
  return (
    <FormRadioButtonGroup
      aria-label={ariaLabel}
      options={[
        { value: "true", label: "Yes" },
        { value: "false", label: "No" },
      ]}
      {...props}
    ></FormRadioButtonGroup>
  );
}
