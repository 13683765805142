import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import MuiLink from "@mui/material/Link";
import TableHead from "@mui/material/TableHead";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import StarIcon from "@mui/icons-material/Star";
import departmentTypes from "fixtures/departmentTypes";
import { DelegateFacilityMessage } from "./DelegateFacilityMessage";

import { Facility } from "types/profile";
import { Department } from "types/facility";
import { Link as WouterLink } from "wouter";

import {
  TableBody,
  TableCell,
  TablePane,
  TableHeadRow,
  TableBodyRow,
  TableHeadCell,
  TableActionsCell,
  TableEditActionButton,
  TableDeleteActionButton,
  TablePaneFooter,
  TablePaneButton,
} from "./Table";
import { useAuth } from "hooks/useAuth";

export interface FacilityDepartmentTableProps {
  userPk?: number;
  testId?: string;
  entries: Department[];
  userEntries: Department[];
  showTable?: boolean;
  facility: Facility;
  onCreateButtonClick: Callback;
  onUpdateButtonClick: Callback;
  onDeleteButtonClick: Callback;
  "data-testid"?: string;
}

const GridTableCell = styled(TableCell)(({ theme }) => ({
  display: "grid",
  alignItems: "center",
  gridTemplateColumns: "auto 1fr",
}));

const StyledStarIcon = styled(StarIcon)(({ theme }) => ({
  fill: theme.palette.success.main,
  marginRight: theme.spacing(0.5),
}));

export function FacilityDepartmentTable({
  userPk,
  entries,
  userEntries,
  facility = {} as Facility,
  showTable = true,
  onCreateButtonClick,
  onDeleteButtonClick,
  "data-testid": testId = FacilityDepartmentTable.name,
}: FacilityDepartmentTableProps) {
  const { isAdmin, isDelegate } = useAuth();
  const [dialogOpen, setDialogOpen] = useState({
    open: false,
    entry: null,
    disabled: false,
  });

  const ShowDelegateMessage = (entry: any) => {
    setDialogOpen({
      open: !dialogOpen.open,
      entry,
      disabled: dialogOpen.open
        ? false
        : !userEntries.some(
            (e: Department) =>
              e.type === entry.departments[0].type &&
              e.subType === entry.departments[0].subType
          ),
    });
  };

  return (
    <>
      <TablePane data-testid={testId}>
        {showTable && (
          <Table>
            <TableHead>
              <TableHeadRow data-testid={`${testId}HeadRow`}>
                <TableHeadCell>Department</TableHeadCell>
                <TableHeadCell>Type</TableHeadCell>
                <TableHeadCell>Subtype</TableHeadCell>
                <TableCell />
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {entries.map((entry, i) => {
                const departmentId = entry.subType || entry.type;
                const departmentProfileUrl = isAdmin
                  ? `/admin/profile/${userPk}/facilities/${facility.id}/department/${departmentId}`
                  : `/facilities/${facility.id}/department/${departmentId}`;
                return (
                  <TableBodyRow key={i} data-testid={`${testId}BodyRow`}>
                    <GridTableCell>
                      {entry.primaryLocation && <StyledStarIcon />}
                      {isDelegate ? (
                        <MuiLink
                          onClick={() =>
                            ShowDelegateMessage({
                              ...facility,
                              departments: [entry],
                            })
                          }
                          underline="hover"
                        >
                          {entry.name}
                        </MuiLink>
                      ) : (
                        <WouterLink href={departmentProfileUrl}>
                          <MuiLink
                            data-testid={`${testId}NameLink`}
                            underline="hover"
                          >
                            {entry.name}
                          </MuiLink>
                        </WouterLink>
                      )}
                    </GridTableCell>
                    <TableCell>{departmentTypeName(entry.type)}</TableCell>
                    <TableCell>
                      {departmentSubtypeName(entry.type, entry.subType)}
                    </TableCell>
                    <TableActionsCell>
                      {isDelegate ? (
                        <MuiLink
                          onClick={() =>
                            ShowDelegateMessage({
                              ...facility,
                              departments: [entry],
                            })
                          }
                          underline="hover"
                        >
                          <TableEditActionButton
                            data-testid={`${testId}UpdateEntryButton`}
                          />
                        </MuiLink>
                      ) : (
                        <WouterLink href={departmentProfileUrl}>
                          <TableEditActionButton
                            data-testid={`${testId}UpdateEntryButton`}
                          />
                        </WouterLink>
                      )}
                      <TableDeleteActionButton
                        onClick={() =>
                          onDeleteButtonClick({
                            facilityId: facility.id,
                            department: entry,
                          })
                        }
                        data-testid={`${testId}DeleteEntryButton`}
                      />
                    </TableActionsCell>
                  </TableBodyRow>
                );
              })}
            </TableBody>
          </Table>
        )}
        <TablePaneFooter>
          <TablePaneButton
            icon={AddCircleIcon}
            label="Add my department to this facility"
            onClick={() => onCreateButtonClick(facility)}
            data-testid={`${testId}CreateEntryButton`}
          />
        </TablePaneFooter>
      </TablePane>
      {isDelegate && dialogOpen.open && (
        <DelegateFacilityMessage
          open={dialogOpen.open}
          type="department"
          //@ts-ignore
          entry={dialogOpen.entry}
          disabled={dialogOpen.disabled}
          onCancel={ShowDelegateMessage}
        />
      )}
    </>
  );
}

export function departmentTypeName(typeCode?: number | null) {
  if (!typeCode) return "-";
  return departmentTypes.find((type) => type.code === typeCode!)!.name;
}

export function departmentSubtypeName(
  typeCode?: number | null,
  subtypeCode?: number | null
) {
  if (!typeCode || !subtypeCode) return "-";
  return departmentTypes
    .find((type) => type.code === typeCode!)!
    .subtypes.find((subtype) => subtype.code === subtypeCode)!.name;
}
