import React from "react";
import { ContactCard } from "components/ContactCard";
import { LicenceCard } from "components/LicenceCard";
import { ResearchCard } from "components/ResearchCard";
import { TrainingCard } from "components/TrainingCard";
import { EducationCard } from "components/EducationCard";
import { ExperienceCard } from "components/ExperienceCard";
import { ProfileUserCard } from "../components/ProfileUserCard";
import { FacilityAffiliationsCard } from "components/FacilityAffiliationsCard";
import { ScreenSection, ScreenSectionTitle } from "../components/Screen";
import { UseProfileResult } from "hooks/useProfileDelegate";
import { useAuth } from "hooks/useAuth";

interface ProfileContentsProps {
  profile: UseProfileResult;
}

export function SiteStaffContents({ profile }: ProfileContentsProps) {
  const { cards } = profile;
  const { isAdmin } = useAuth();

  return (
    <>
      <ScreenSection data-testid="BasicDetailsSection">
        <ScreenSectionTitle children="Basic Details" />
        <FacilityAffiliationsCard {...cards.facility} />
        <ProfileUserCard {...cards.user} isAdmin={isAdmin} />
        <ContactCard {...cards.contact} />
      </ScreenSection>
      <ScreenSection data-testid="AdditionalDetailsSection">
        <ScreenSectionTitle children="Additional Details For CV Creation" />
        <ResearchCard {...cards.research} />
        <EducationCard {...cards.education} />
        <ExperienceCard {...cards.experience} />
        <LicenceCard {...cards.licence} />
        <TrainingCard {...cards.training} />
      </ScreenSection>
    </>
  );
}
