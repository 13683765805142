import React from "react";
import $specialties from "fixtures/specialties";

import { SpecialtyLookup } from "fixtures/specialties";
import { extractSubspecialtyOptions } from "utils/specialty";
import {
  AutocompleteField,
  AutocompleteInstanceFieldProps,
} from "./AutocompleteField";

export type MedicalSubspecialtySelectFieldProps = AutocompleteInstanceFieldProps & {
  specialty?: string;
  specialties?: SpecialtyLookup;
};

export function MedicalSubspecialtySelectField({
  specialty,
  specialties = $specialties,
  "data-testid": testId = "MedicalSubspecialtySelectField",
  ...otherProps
}: MedicalSubspecialtySelectFieldProps) {
  const options = extractSubspecialtyOptions(specialty, specialties);
  return (
    <AutocompleteField
      id="subspecialty"
      name="subspecialty"
      label="Subspecialty"
      data-testid={testId}
      options={options}
      {...otherProps}
    />
  );
}
