import { Experience } from "types/profile";

export function isCompleteExperience(experience: Experience) {
  return Boolean(
    experience.facilityName &&
      experience.jobTitle &&
      experience.yearStarted &&
      experience.yearFinished &&
      experience.countryCode
  );
}

export function hasIncompleteExperiences(experiences: Experience[]) {
  return experiences.some((experience) => {
    return !isCompleteExperience(experience);
  });
}
