//@ts-nocheck
import React from "react";
import { Machine } from "xstate";
import { useMachine } from "@xstate/react";

interface UseDialogTriggerProps {
  dialogCloseProp?: string | string[];
}

const DialogTriggerMachine = Machine({
  initial: "closed",
  states: {
    closed: {
      on: {
        OPEN: "opened"
      }
    },
    opened: {
      on: {
        CLOSE: "closed"
      }
    }
  }
});

function useDialogTrigger({
  dialogCloseProp = "onClose"
}: UseDialogTriggerProps) {
  const [state, send] = useMachine(DialogTriggerMachine);
  const closeHandlerPropNames = Array.isArray(dialogCloseProp)
    ? dialogCloseProp
    : [dialogCloseProp];
  const closeHandlerProps = closeHandlerPropNames.reduce((acc, prop) => {
    acc[prop] = () => send("CLOSE");
    return acc;
  }, {} as AnyObject);
  return {
    dialog: {
      open: state.matches("opened"),
      ...closeHandlerProps
    },
    trigger: {
      onClick: () => send("OPEN")
    }
  };
}

interface DialogTriggerProps {
  dialog: React.FC<any>;
  dialogProps?: AnyObject;
  dialogCloseProp?: string | string[];
  children?: ReactNodes;
  "data-testid"?: string;
}

export function DialogTrigger({
  dialog: Dialog,
  dialogProps: props = {},
  "data-testid": testId = DialogTrigger.name,
  dialogCloseProp,
  children
}: DialogTriggerProps) {
  const { trigger, dialog } = useDialogTrigger({ dialogCloseProp });
  return (
    <div data-testid={testId}>
      {dialog.open && <Dialog {...dialog} {...props} />}
      <div data-testid={`${testId}Trigger`} {...trigger}>
        {children}
      </div>
    </div>
  );
}
