import React from "react";
import { TypographyProps, Box, Typography, BoxProps } from "@mui/material";
import { SxProps } from "@mui/system";

/**
 * The screen related components that are meant to be used with routes as follows:
 *
 * @example
 * export default Profiles() {
 *  return (
 *   <Screen>
 *    <ScreenSection>
 *      <ScreenSectionTitle children="Your profile"/>
 *      <SomeCard />
 *    </ScreenSection>
 *
 *    <ScreenSection>
 *      <ScreenSectionTitle children="Additional details"/>
 *      <SomeOtherCard />
 *    </ScreenSection>
 *   </Screen>
 *  )
 * }
 */

/**
 * The root level Screen component
 *
 * @example
 * <Screen>
 *    ...
 * </Screen>
 */
export function Screen({ children, ...otherProps }: BoxProps) {
  return (
    <Box
      component="div"
      sx={{
        "& > :not(:first-child)": {
          mt: 5,
        },
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
}

/**
 * Defines a section within a <Screen /> and must be used as follows:
 *
 * @example
 * <Screen>
 *  <ScreenSection>
 *    ...
 *  </ScreenSection>
 * </Screen>
 */
export function ScreenSection({ children, ...otherProps }: BoxProps) {
  return (
    <Box
      sx={{
        "& > :not(:first-child)": {
          mt: 2,
        },
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
}

export interface ScreenSectionTitleProps extends TypographyProps {
  children?: ReactNodes;
  sx?: SxProps;
}

/**
 * Sets the screen section title and must be used as follows:
 *
 * @example
 * <Screen>
 *  <ScreenSection>
 *    <ScreenSectionTitle children="Some Title" />
 *    ...
 *  </ScreenSection>
 * </Screen>
 */
export function ScreenSectionTitle({
  children,
  sx,
  ...otherProps
}: ScreenSectionTitleProps) {
  return (
    <Typography sx={sx} variant="h4" noWrap {...otherProps}>
      {children}
    </Typography>
  );
}

export interface ScreenSectionBarProps extends TypographyProps {
  sx?: SxProps;
  title: string;
  actions: ReactNodes;
}

/**
 * Sets the screen section bar and must be used as follows:
 *
 * @example
 * <Screen>
 *  <ScreenSection>
 *   <ScreenSectionBar
        role="heading"
        sx={{ display: "grid", gridTemplateColumns: "60% auto" }}
        title="Your profile"
        actions={<ProfileDelegatesLink />}
      />
 *    ...
 *  </ScreenSection>
 * </Screen>
 */
export function ScreenSectionBar({
  sx,
  title,
  actions,
}: ScreenSectionBarProps) {
  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          alignItems: "center",
        }}
      >
        <ScreenSectionTitle sx={{ m: 0 }} children={title} />
        <Box sx={{ justifySelf: "right" }}>{actions}</Box>
      </Box>
    </Box>
  );
}
