import React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  Alert,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { noop } from "utils/noop";
import { Phone } from "types/profile";

import { isCompletePhone, hasIncompletePhones } from "utils/contacts";

import {
  TablePane,
  TableHeadRow,
  TableBodyRow,
  TableActionsCell,
  TableEditActionButton,
  TableDeleteActionButton,
  TablePaneFooter,
  TablePaneButton,
} from "./Table";

const PREFIX = "ContactPhoneEntries";

const classes = {
  warningIcon: `${PREFIX}-warningIcon`,
  warningAlert: `${PREFIX}-warningAlert`,
};

const TablePaneRoot = styled(TablePane)(({ theme }) => ({
  [`& .${classes.warningIcon}`]: {
    fill: theme.palette.warning.main,
  },

  [`& .${classes.warningAlert}`]: {
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  testId?: string;
  entries?: Phone[];
  onCreateButtonClick?: Callback;
  onUpdateButtonClick?: Callback;
  onDeleteButtonClick?: Callback;
  "data-testid"?: string;
}

export function ContactPhoneEntries({
  entries = [],
  onCreateButtonClick = noop,
  onUpdateButtonClick = noop,
  onDeleteButtonClick = noop,
  "data-testid": testId = ContactPhoneEntries.name,
}: Props) {
  return (
    <TablePaneRoot heading="Telephone number">
      {hasIncompletePhones(entries) && (
        <Alert className={classes.warningAlert} severity="warning">
          Please provide missing information for the entries indicated below
        </Alert>
      )}
      <Table data-testid={testId}>
        <TableHead>
          <TableHeadRow>
            <TableCell>Type</TableCell>
            <TableCell>Country code</TableCell>
            <TableCell>Telephone number</TableCell>
            <TableCell>Ext.</TableCell>
            <TableCell />
            <TableCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {entries.map((phone, i) => (
            <TableBodyRow key={i}>
              <TableCell>{phone.type}</TableCell>
              <TableCell>{phone.countryCode}</TableCell>
              <TableCell>{phone.number}</TableCell>
              <TableCell>{phone.ext}</TableCell>
              <TableCell>
                {!isCompletePhone(phone) && (
                  <WarningIcon
                    className={classes.warningIcon}
                    aria-label="Warning"
                    titleAccess="This experience entry is incomplete"
                  />
                )}
              </TableCell>
              <TableActionsCell>
                <TableEditActionButton
                  onClick={() => onUpdateButtonClick(phone)}
                  data-testid={`${testId}UpdateEntryButton`}
                />
                <TableDeleteActionButton
                  onClick={() => onDeleteButtonClick(phone)}
                  data-testid={`${testId}DeleteEntryButton`}
                />
              </TableActionsCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
      <TablePaneFooter>
        <TablePaneButton
          icon={AddCircleIcon}
          label="Add another telephone number"
          onClick={onCreateButtonClick}
          data-testid="AddPhoneEntryButton"
        />
      </TablePaneFooter>
    </TablePaneRoot>
  );
}
