import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { ErrorBar } from "./ErrorBar";

interface SuspendProps {
  when: boolean;
  error?: any;
  label?: string;
  errorMessage?: string;
  children?: ReactNodes;
}

export function Suspend({
  when: loading,
  label,
  error,
  errorMessage = "Something went wrong. Please try again later",
  children,
}: SuspendProps) {
  if (loading) {
    return <LinearProgress aria-label={label} />;
  }
  if (error) {
    console.error(error);
    return <ErrorBar message={errorMessage} />;
  }
  return <>{children}</>;
}
