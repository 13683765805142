export interface SpecialtyLookup {
  [code: string]: string;
}

export default {
  I1: "Allergy and Immunology",
  "I1.1": "Autoimmune and Inflammatory",
  "I1.2": "Respiratory",
  "I1.3": "Transplantation",
  "I1.4": "Other",
  P1: "Anesthesiology (Anaesthesiology)",
  "P1.1": "Critical Care Medicine/Intensive Care",
  "P1.2": "Emergency Medicine",
  "P1.3": "Hospice and Palliative Medicine",
  "P1.4": "Pain Medicine",
  "P1.5": "Sleep Medicine",
  "P1.6": "Other",
  C1: "Cardiology",
  "C1.1": "Cardiovascular Surgery or Transplant",
  "C1.2": "Clinical Cardiology",
  "C1.3": "Electrophysiology",
  "C1.4": "Interventional Cardiology",
  "C1.5": "Vascular Medicine",
  "C1.6": "Other",
  A1: "Clinical Pharmacology & Therapeutics",
  "A1.1": "Other",
  A2: "Aesthetic Medicine",
  "A2.1": "Other",
  I8: "Critical Care Medicine/Intensive Care",
  "I8.1": "Other",
  F1: "Dentistry",
  "F1.1": "Other",
  D1: "Dermatology",
  "D1.1": "Dermatopathology",
  "D1.2": "Venereology",
  "D1.3": "Other",
  E9: "Emergency Medicine",
  "E9.1": "Critical Care Medicine/Intensive Care",
  "E9.2": "Emergency Medical Services (includes Pre-hospital)",
  "E9.3": "Sports Medicine / Sports and Exercise Medicine",
  "E9.4": "Trauma Medicine",
  "E9.5": "Other",
  E1: "Endocrinology",
  "E1.1": "Bariatric Medicine (Obesity)",
  "E1.2": "Diabetes & Metabolism",
  "E1.3": "Reproductive Endocrinology/Infertility",
  "E1.4": "Other",
  G1: "Gastroenterology",
  "G1.1": "Bariatric Medicine (Obesity)",
  "G1.2": "Hepatology",
  "G1.3": "Other",
  G3: "Genetics / Medical genetics",
  "G3.1": "Other",
  G2: "Geriatric Medicine",
  "G2.1": "Orthogeriatrician",
  "G2.2": "Stroke Medicine",
  "G2.3": "Other",
  H1: "Hematology (Haematology)",
  "H1.1": "Blood Disorders",
  "H1.2": "Hematology - Oncology",
  "H1.3": "Transfusion medicine",
  "H1.4": "Other",
  I2: "Infectious Diseases",
  "I2.1": "HIV/AIDS",
  "I2.2": "Tropical Medicine",
  "I2.3": "Vaccine",
  "I2.4": "Other",
  N1: "Nephrology",
  "N1.1": "Dialysis",
  "N1.2": "Interventional Nephrology",
  "N1.3": "Renal Medicine (Clinical Nephrology)",
  "N1.4": "Transplants",
  "N1.5": "Other",
  N2: "Neurology",
  "N2.1": "Brain Injury Medicine",
  "N2.2": "Clinical Neurophysiology",
  "N2.3": "Epilepsy",
  "N2.4": "Hospice and Palliative Medicine",
  "N2.14": "Memory",
  "N2.5": "Movement Disorders",
  "N2.6": "Multiple Sclerosis",
  "N2.7": "Neurodevelopmental Disabilities",
  "N2.8": "Neuromuscular Medicine",
  "N2.15": "Neuropsychology",
  "N2.9": "Neurorehabilitation",
  "N2.10": "Pain Medicine",
  "N2.11": "Sleep Medicine",
  "N2.12": "Vascular Neurology / Cerebrovascular Disease",
  "N2.13": "Other",
  X1: "Nuclear Medicine",
  "X1.1": "Other",
  O2: "Obstetrics and Gynecology",
  "O2.1": "Female Pelvic Medicine and Reconstructive",
  "O2.2": "Gynecology (Gynaecology)",
  "O2.3": "Maternal and Fetal Medicine (Obstetrics)",
  "O2.4": "Reproductive Endocrinology/Infertility",
  "O2.5": "Sexual Health Medicine",
  "O2.6": "Urogynecology (Urogynaecology)",
  "O2.7": "Other",
  Q1: "Occupational Health",
  "Q1.1": "Other",
  O1: "Oncology",
  "O1.1": "Medical Oncology - blood",
  "O1.2": "Medical Oncology - bone",
  "O1.12": "Medical Oncology - breast",
  "O1.3": "Medical Oncology - endocrine",
  "O1.4": "Medical Oncology - gastric",
  "O1.5": "Medical Oncology - genitourinary",
  "O1.6": "Medical Oncology - lung",
  "O1.7": "Medical Oncology - neurological",
  "O1.8": "Medical Oncology - renal",
  "O1.9": "Medical Oncology - skin",
  "O1.10": "Surgical Oncology",
  "O1.11": "Other",
  O3: "Ophthalmology",
  "O3.1": "Other",
  L1: "Otolaryngology",
  "L1.1": "Other",
  B1: "Pathology",
  "B1.1": "Other",
  P2: "Pediatrics (Paediatrics)",
  "P2.1": "Adolescent Medicine",
  "P2.2": "Clinical Genetics",
  "P2.3": "Developmental-Behavioral /Neurodevelopmental Disabilities",
  "P2.4": "Hospice and Palliative Medicine",
  "P2.5": "Neonatal-Perinatal Medicine",
  "P2.6": "Pediatric (Paediatric) Allergy and Immunology",
  "P2.7": "Pediatric (Paediatric) Anesthesiology (Anaesthesiology)",
  "P2.8": "Pediatric (Paediatric) Cardiology",
  "P2.9": "Pediatric (Paediatric) Dermatology",
  "P2.10": "Pediatric (Paediatric) Emergency Medicine",
  "P2.11": "Pediatric (Paediatric) Endocrinology",
  "P2.12": "Pediatric (Paediatric) Gastroenterology",
  "P2.13": "Pediatric (Paediatric) Hematology (Haematology)",
  "P2.14": "Pediatric (Paediatric) Infectious Diseases",
  "P2.15": "Pediatric (Paediatric) Intensive Care/Critical Care Medicine",
  "P2.16": "Pediatric (Paediatric) Nephrology",
  "P2.17": "Pediatric (Paediatric) Neurology",
  "P2.18": "Pediatric (Paediatric) Oncology",
  "P2.19": "Pediatric (Paediatric) Otolaryngology",
  "P2.20": "Pediatric (Paediatric) Respiratory (and Sleep Medicine )",
  "P2.21": "Pediatric (Paediatric) Rheumatology",
  "P2.22": "Pediatric (Paediatric) Surgery",
  "P2.23": "Pediatric (Paediatric) Transplant",
  "P2.24": "Pediatric (Paediatric) Urology",
  "P2.25": "Other",
  M1: "Physical Medicine and Rehabilitation",
  "M1.1": "Brain & Spinal Cord Injury",
  "M1.2": "Neuromuscular Medicine",
  "M1.3": "Pain Medicine",
  "M1.4": "Sports Medicine/Sports and Exercise Medicine",
  "M1.5": "Other",
  P5: "Podiatry",
  "P5.1": "Other",
  K1: "Preventive Medicine/Public Health and Preventive Medicine",
  "K1.1": "Other",
  P3: "Primary Care (General Practice, Family Practice, Internal Medicine)",
  "P3.1": "Allergy and Immunology",
  "P3.2": "Cardiology",
  "P3.3": "Endocrinology, Diabetes and Metabolism",
  "P3.4": "Family planning/Fertility",
  "P3.5": "Gastroenterology",
  "P3.6": "Genitourinary Medicine",
  "P3.7": "Geriatric Medicine",
  "P3.8": "Hematology (Haematology)",
  "P3.9": "Hospice and Palliative Medicine",
  "P3.10": "Infectious Disease",
  "P3.11": "Nephrology",
  "P3.12": "Neurology",
  "P3.13": "Psychiatry/Psychology",
  "P3.14": "Respiratory/Pulmonology",
  "P3.15": "Rheumatology",
  "P3.16": "Urgent Care Medicine",
  "P3.17": "Other",
  O4: "Psychiatry",
  "O4.1": "Addiction Psychiatry",
  "O4.2": "Forensic Psychiatry",
  "O4.3": "Geriatric Psychiatry",
  "O4.4": "Learning Disability",
  "O4.5": "Pediatric (Paediatric)/Adolescent Psychiatry",
  "O4.6": "Other",
  R2: "Radiology",
  "R2.1": "Interventional Radiology",
  "R2.2": "Neuroradiology",
  "R2.3": "Radiation Oncology",
  "R2.4": "Other",
  P4: "Respiratory/ Pulmonology/Pneumology",
  "P4.1": "Sleep Medicine",
  "P4.2": "Other",
  R1: "Rheumatology",
  "R1.1": "Autoimmune and Inflammatory",
  "R1.2": "Musculoskeletal",
  "R1.3": "Other",
  S1: "Surgery",
  "S1.1": "Abdominal or Colon and Rectal Surgery",
  "S1.2": "Bariatric Surgery",
  "S1.3": "Cardiothoracic Surgery",
  "S1.4": "Head and Neck Surgery or Otolaryngology",
  "S1.5": "Neurosurgery",
  "S1.6": "Ophthalmic Surgery",
  "S1.7": "Oral and Facial Surgery",
  "S1.8": "Orthopedic (Orthopaedic) Surgery",
  "S1.9": "Plastic Surgery",
  "S1.10": "Surgical Oncology",
  "S1.11": "Transplant Surgery",
  "S1.12": "Urological Surgery",
  "S1.13": "Vascular Surgery",
  "S1.14": "Other",
  T1: "Transplant",
  "T1.1": "Other",
  U1: "Urology",
  "U1.1": "Men's Health",
  "U1.2": "Women's Health",
  "U1.3": "Other"
} as SpecialtyLookup;
