import React from "react";
import { useRoute } from "wouter";
import { AppBar } from "layouts/AppBar";
import { Content } from "layouts/Content";




interface AdminShellProps {
  children: ReactNodes;
  profileId: string;
  showMenu?: boolean
}

export default function AdminShell({
  children,
  profileId,
  showMenu = true,
}: AdminShellProps) {
  const [isProfileRoute] = useRoute("/admin/profile/:profileId");

  const menuItems = [
    {
      title: "Search",
      link: "/admin",
      selected: false,
      ariaLabel: "Link to admin search",
    },
    {
      title: "Profile",
      link: `/admin/profile/${profileId}`,
      selected: isProfileRoute,
      ariaLabel: "Link to user profile",
    },
    {
      title: "Delegates",
      link: `/delegates/${profileId}`,
      selected: false,
      ariaLabel: "Link to users delegates",
    },
  ];

  return (
    <>
      <AppBar showNav={showMenu} menuItems={menuItems} />
      <Content>{children}</Content>
    </>
  );
}
