import React from "react";
import studyPhases from "../fixtures/studyPhases";

import {
  AutocompleteField,
  AutocompleteInstanceFieldProps,
} from "./AutocompleteField";

export function StudyPhaseSelectField({
  "data-testid": testId = StudyPhaseSelectField.name,
  ...props
}: AutocompleteInstanceFieldProps) {
  return (
    <AutocompleteField
      id="phase"
      label="Phase"
      name="phase"
      data-testid={testId}
      options={studyPhases}
      {...props}
    />
  );
}
