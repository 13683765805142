let departmentTypes = [
  {
    subtypes: [
      { code: 101, name: "Pediatric Allergy and Immunology" },
      { code: 102, name: "Pediatric Anesthesiology" },
      { code: 103, name: "Pediatric Cardiology" },
      { code: 104, name: "Pediatric Dentistry" },
      { code: 105, name: "Pediatric Dermatology" },
      { code: 106, name: "Pediatric Developmental/Behavioral" },
      { code: 107, name: "Pediatric Emergency Medicine" },
      { code: 108, name: "Pediatric Endocrinology" },
      { code: 109, name: "Pediatric Gastroenterology" },
      { code: 110, name: "Pediatric Genetics" },
      { code: 111, name: "Pediatric Hematology" },
      { code: 112, name: "Pediatric Infectious Diseases" },
      { code: 113, name: "Pediatric Intensive Care" },
      { code: 114, name: "Pediatric Neonate Unit" },
      { code: 115, name: "Pediatric Nephrology" },
      { code: 116, name: "Pediatric Neurology" },
      { code: 117, name: "Pediatric Oncology" },
      { code: 118, name: "Pediatric Orthopedics" },
      { code: 119, name: "Pediatric Otolaryngology" },
      { code: 120, name: "Pediatric Radiology" },
      { code: 121, name: "Pediatric Respiratory" },
      { code: 122, name: "Pediatric Rheumatology" },
      { code: 123, name: "Pediatric Surgery" },
      { code: 124, name: "Pediatric Transplant" },
      { code: 125, name: "Pediatric Urology" },
      { code: 126, name: "Pediatric Critical Care" },
    ],
    code: 100,
    name: "Pediatrics",
  },
  {
    subtypes: [
      { code: 151, name: "Abdominal or Colon and Rectal Surgery" },
      { code: 152, name: "Bariatric Surgery" },
      { code: 153, name: "Cardiothoracic Surgery" },
      { code: 154, name: "Surgical Dermatology" },
      { code: 155, name: "Head and Neck Surgery or Otolaryngology" },
      { code: 156, name: "Neurosurgery" },
      { code: 157, name: "Ophthalmic Surgery" },
      { code: 158, name: "Oral and Facial Surgery" },
      { code: 159, name: "Orthopedic Surgery" },
      { code: 160, name: "Pediatric surgery" },
      { code: 161, name: "Plastic Surgery" },
      { code: 162, name: "Surgical Oncology" },
      { code: 163, name: "Transplant Surgery" },
      { code: 164, name: "Urological Surgery" },
      { code: 165, name: "Vascular Surgery" },
      { code: 166, name: "General Surgery" },
      { code: 167, name: "Prostate Surgery" },
      { code: 168, name: "Obstetric and Gynecological Surgery" },
      { code: 169, name: "Cardiac Surgery" },
      { code: 170, name: "Trauma and Acute Care" },
      { code: 171, name: "Other Surgery" },
    ],
    code: 150,
    name: "Surgery",
  },
  {
    subtypes: [
      { code: 117, name: "Pediatric oncology" },
      { code: 162, name: "Surgical Oncology" },
      { code: 201, name: "Bone/Sarcoma" },
      { code: 202, name: "Breast" },
      { code: 203, name: "Chemotherapy/Medical Oncology" },
      { code: 204, name: "Colon and rectal" },
      { code: 205, name: "Endocrine" },
      { code: 206, name: "Esophageal Cancer" },
      { code: 207, name: "Gastrointestinal/Stomach" },
      { code: 208, name: "Genitourinary" },
      { code: 209, name: "Gynecological oncology" },
      { code: 210, name: "Head and Neck" },
      { code: 211, name: "Hematologic Malignancies" },
      { code: 212, name: "Liver" },
      { code: 213, name: "Lung" },
      { code: 214, name: "Neuro-oncology" },
      { code: 215, name: "Palliative Care" },
      { code: 216, name: "Pancreatic" },
      { code: 217, name: "Radiotherapy/Radiation Oncology" },
      { code: 218, name: "Renal" },
      { code: 219, name: "Skin" },
      { code: 220, name: "Urological Cancers" },
      { code: 221, name: "Transfusion medicine" },
      { code: 222, name: "Bone Marrow Transplantation" },
      { code: 223, name: "Genetic oncology" },
      { code: 224, name: "Other Hematology" },
      { code: 225, name: "Other Oncology" },
    ],
    code: 200,
    name: "Oncology and/or Hematology",
  },
  {
    subtypes: [
      { code: 261, name: "Allergy" },
      { code: 262, name: "Immunology" },
    ],
    code: 260,
    name: "Allergy & Immunology",
  },
  {
    subtypes: [
      { code: 102, name: "Pediatric Anesthesiology" },
      { code: 166, name: "General Surgery" },
      { code: 281, name: "Cardiothoracic" },
      { code: 282, name: "Neuroanesthesia" },
      { code: 283, name: "Obstetric, gynecological and fetal" },
      { code: 284, name: "Opthalmological" },
      { code: 285, name: "Orthopedic" },
      { code: 286, name: "Pain Management" },
      { code: 341, name: "Intensive Care Unit" },
      { code: 342, name: "Critical Care Unit" },
    ],
    code: 280,
    name: "Anesthesiology & pain management",
  },
  {
    subtypes: [
      { code: 165, name: "Vascular Surgery" },
      { code: 301, name: "Cardiac Cath Lab" },
      { code: 302, name: "Cardiac Electrophysiology Lab" },
      { code: 303, name: "Cardiac Imaging" },
      { code: 304, name: "Hypertension" },
      { code: 305, name: "Metabolic Disorders" },
    ],
    code: 300,
    name: "Cardiology",
  },
  { subtypes: [], code: 320, name: "Clinical Research" },
  {
    subtypes: [
      { code: 341, name: "Intensive Care Unit" },
      { code: 342, name: "Critical Care Unit" },
    ],
    code: 340,
    name: "Critical Care/Intensive Care",
  },
  { subtypes: [], code: 360, name: "Dentistry" },
  {
    subtypes: [
      { code: 105, name: "Pediatric Dermatology" },
      { code: 154, name: "Surgical Dermatology" },
      { code: 219, name: "Skin Cancers" },
      { code: 381, name: "Allergy/Inflammatory skin diseases" },
      { code: 382, name: "Cosmetic Dermatology" },
      { code: 383, name: "Dermatoimmunology" },
      { code: 384, name: "Photodermatology" },
    ],
    code: 380,
    name: "Dermatology",
  },
  {
    subtypes: [
      { code: 401, name: "Emergency Room" },
      { code: 402, name: "Trauma Center" },
    ],
    code: 400,
    name: "Emergency medicine",
  },
  {
    subtypes: [
      { code: 108, name: "Pediatric Endocrinology" },
      { code: 305, name: "Metabolic Disorders" },
      { code: 421, name: "Diabetes" },
      { code: 422, name: "Obesity and bariatric services" },
      { code: 423, name: "Osteoporosis" },
      { code: 424, name: "Reproductive Endocrinology and Infertility" },
      { code: 425, name: "Thyroid and pituitary diseases" },
    ],
    code: 420,
    name: "Endocrinology & metabolism",
  },
  {
    subtypes: [
      { code: 441, name: "GI Surgery/endoscopy" },
      { code: 442, name: "GI Infusion center" },
      { code: 443, name: "Inflammatory Bowel Disease" },
      { code: 444, name: "Liver disease (includes Hepatitis)" },
      { code: 445, name: "Nutrition" },
      { code: 446, name: "Pancreatic disease" },
    ],
    code: 440,
    name: "Gastroenterology",
  },
  {
    subtypes: [{ code: 223, name: "Genetic Oncology" }],
    code: 460,
    name: "Genetics",
  },
  {
    subtypes: [
      { code: 481, name: "Geriatric psychiatry" },
      { code: 601, name: "Cognitive Health / Memory Disorders" },
    ],
    code: 480,
    name: "Geriatrics",
  },
  {
    subtypes: [
      { code: 209, name: "Gynecologic Oncology" },
      { code: 423, name: "Osteoporosis" },
      { code: 424, name: "Reproductive Endocrinology and Infertility" },
      { code: 501, name: "Gynecology" },
      { code: 502, name: "Neonatology / Fetal Medicine" },
      { code: 503, name: "Midwifery Practice" },
      { code: 504, name: "Obstetrics" },
      { code: 505, name: "Sexually Transmitted Diseases" },
      { code: 506, name: "Urogynecology" },
      { code: 507, name: "Women's Health" },
    ],
    code: 500,
    name: "Gynecology, Obstetrics, and women's health",
  },
  {
    subtypes: [
      { code: 444, name: "Liver disease (includes Hepatitis)" },
      { code: 505, name: "Sexually Transmitted Diseases" },
      { code: 521, name: "HIV/AIDS" },
      { code: 522, name: "Tropical Diseases" },
      { code: 523, name: "Travel Medicine" },
    ],
    code: 520,
    name: "Infectious Disease",
  },
  { subtypes: [], code: 540, name: "Internal Medicine" },
  {
    subtypes: [
      { code: 561, name: "Eating and weight disorders" },
      { code: 562, name: "Geriatric psychiatry" },
      { code: 563, name: "Mental health clinic" },
      { code: 564, name: "Neuropsychiatry" },
      { code: 565, name: "Substance abuse" },
    ],
    code: 560,
    name: "Mental health / Psychiatry",
  },
  {
    subtypes: [
      { code: 218, name: "Renal Cancer" },
      { code: 304, name: "Hypertension" },
      { code: 581, name: "Chronic kidney disease" },
      { code: 582, name: "Dialysis clinic" },
      { code: 786, name: "Kidney transplant" },
    ],
    code: 580,
    name: "Nephrology",
  },
  {
    subtypes: [
      { code: 156, name: "Neurosurgery" },
      { code: 214, name: "Neuro-oncology" },
      { code: 286, name: "Pain Management" },
      { code: 564, name: "Neuropsychiatry" },
      { code: 601, name: "Cognitive Health / Memory Disorders" },
      { code: 602, name: "Multiple Sclerosis clinic" },
      { code: 603, name: "Parkinsons disease clinic" },
      { code: 604, name: "Sleep medicine" },
      { code: 605, name: "Spinal cord injury (includes paralysis)" },
      { code: 606, name: "Stroke and vascular neurology" },
      { code: 607, name: "Traumatic brain injury" },
      { code: 705, name: "Neuroradiology" },
      { code: 722, name: "Neurorehabilitation" },
    ],
    code: 600,
    name: "Neurology",
  },
  { subtypes: [], code: 620, name: "Ophthalmology" },
  {
    subtypes: [
      { code: 118, name: "Pediatric Orthopedics" },
      { code: 159, name: "Orthopedic Surgery" },
      { code: 201, name: "Bone/Sarcoma" },
      { code: 641, name: "Sports Medicine" },
      { code: 721, name: "Physical therapy" },
    ],
    code: 640,
    name: "Orthopedics",
  },
  { subtypes: [], code: 660, name: "Pharmacy" },
  {
    subtypes: [
      { code: 505, name: "Sexually Transmitted Diseases" },
      { code: 521, name: "HIV/AIDS" },
      { code: 563, name: "Mental health clinic" },
      { code: 565, name: "Substance abuse" },
      { code: 681, name: "Family planning clinic" },
    ],
    code: 680,
    name: "Public Health",
  },
  {
    subtypes: [
      { code: 120, name: "Pediatric Radiology" },
      { code: 303, name: "Cardiac Imaging" },
      { code: 701, name: "Breast Imaging" },
      { code: 702, name: "CT" },
      { code: 703, name: "Interventional Radiology" },
      { code: 704, name: "MRI" },
      { code: 705, name: "Neuroradiology" },
      { code: 706, name: "Nuclear Medicine" },
      { code: 707, name: "PET" },
      { code: 708, name: "Ultrasound" },
    ],
    code: 700,
    name: "Radiology",
  },
  {
    subtypes: [
      { code: 721, name: "Physical therapy" },
      { code: 722, name: "Neurorehabilitation" },
      { code: 742, name: "Respiratory therapy" },
    ],
    code: 720,
    name: "Rehabilitation & Physical Medicine",
  },
  {
    subtypes: [
      { code: 741, name: "Asthma & COPD" },
      { code: 742, name: "Respiratory therapy" },
      { code: 743, name: "Sleep Disorders" },
    ],
    code: 740,
    name: "Respiratory",
  },
  {
    subtypes: [
      { code: 761, name: "Autoimmune and Inflammatory" },
      { code: 762, name: "Rheumatology infusion clinic" },
      { code: 763, name: "Musculoskeletal" },
    ],
    code: 760,
    name: "Rheumatology",
  },
  {
    subtypes: [
      { code: 781, name: "Bone Marrow / Stem Cell" },
      { code: 782, name: "Corneal" },
      { code: 783, name: "Heart" },
      { code: 784, name: "Liver" },
      { code: 785, name: "Lung" },
      { code: 786, name: "Kidney transplant" },
      { code: 787, name: "Pancreatic" },
    ],
    code: 780,
    name: "Transplant services",
  },
  {
    subtypes: [
      { code: 164, name: "Urological Surgery" },
      { code: 506, name: "Urogynecology" },
      { code: 801, name: "Male urological services" },
    ],
    code: 800,
    name: "Urology",
  },
  { subtypes: [], code: 820, name: "Pathology" },
  {
    subtypes: [
      {
        code: 841,
        name: "Podiatry",
      },
    ],
    code: 840,
    name: "Family Medicine/Primary Care/General Practice",
  },
];

export default departmentTypes;
