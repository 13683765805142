import React from "react";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";
import { ErrorBar } from "./ErrorBar";
import { Training } from "types/profile";
import { required } from "validators/required";
import { FormDialog } from "./FormDialog";
import { styled } from "@mui/material/styles";
import { FormDateField } from "./FormDateField";
import { noop, asyncNoop } from "utils/noop";
import { isDateValid } from "utils/date";
import { validators } from "utils/forms";
import { useForm, SubmitFn } from "hooks/useForm";
import { Dropzone, DroppedFile } from "./Dropzone";
import { CourseProviderAutocompleteField } from "./CourseProviderAutocompleteField";

import {
  useChangeReasonFormDialog,
  ChangeReasonFormDialog,
} from "./ChangeReasonFormDialog";

const PREFIX = "TrainingForm";

const classes = {
  formGroup: `${PREFIX}-formGroup`,
};

const DialogRoot = styled("div")(({ theme }) => ({
  [`& .${classes.formGroup}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(0.4),
  },
}));

interface Props {
  title?: string;
  open?: boolean;
  entry?: Training;
  testId?: string;
  submit?: SubmitFn;
  onCancel?: Callback;
  onSuccess?: Callback;
  onError?: Callback;
  withReason?: boolean;
  isInvestigator?: boolean;
  "data-testid"?: string;
}

export function TrainingForm({
  open,
  entry = {} as Training,
  title = "Update training",
  "data-testid": testId = TrainingForm.name,
  submit: doSubmit = asyncNoop,
  onCancel: doCancel = noop,
  onSuccess = noop,
  onError = console.error,
  withReason = false,
  ...otherProps
}: Props) {
  const handleSubmit = (values: any, adminComment?: string) => {
    const file = values.certificate as DroppedFile;
    values.certificateFilename = file.name;
    values.certificateContents = file.contents;
    delete values.certificate;
    return doSubmit(values, adminComment);
  };

  const reasonForm = useChangeReasonFormDialog({
    doSubmit: handleSubmit,
    formHookSubmit,
    withReason,
  });

  const { id, fields, submit, reset, submitting, error } = useForm({
    id: "training-form",
    fields: {
      pk: {
        name: "pk",
        value: entry.pk,
      },
      certificateFilename: {
        name: "certificateFilename",
        value: entry.certificateFilename,
      },
      courseProvider: {
        name: "courseProvider",
        label: "Course provider",
        value: entry.courseProvider,
        required: true,
        validate: required,
      },
      startDate: {
        name: "startDate",
        label: "Date started",
        "aria-label": "Time started",
        value: entry.startDate,
        defaultValue: null,
        validate: isDateValid,
      },
      completionDate: {
        name: "completionDate",
        label: "Date completed",
        "aria-label": "Time completed",
        value: entry.completionDate,
        required: true,
        validate: validators(required, isDateValid),
        defaultValue: null,
      },
      certificate: {
        name: "certificate",
        value: { name: entry.certificateFilename, contents: undefined },
        defaultValue: null,
        onError: console.error,
      },
    },
    submit: reasonForm.performSubmit,
    onReset: doCancel,
    onError,
    onSuccess,
  });

  function formHookSubmit() {
    return submit();
  }

  const maxStartDate = fields.completionDate.value || new Date();
  const errorMessage = error?.message;

  return (
    <div>
      <FormDialog
        id={id}
        open={open}
        title={title}
        onCancel={reset}
        onSubmit={reasonForm.handleSubmitButtonClick}
        maxWidth="sm"
        data-testid={testId}
        showProgressIndicator={submitting}
        {...otherProps}
      >
        {errorMessage && <ErrorBar message={errorMessage} />}
        <DialogRoot>
          <FormGroup className={classes.formGroup}>
            <CourseProviderAutocompleteField
              data-testid={`${testId}CourseProviderField`}
              fullWidth
              {...fields.courseProvider}
            />

            <FormGroup row>
              <FormDateField
                maxDate={maxStartDate}
                data-testid={`${testId}StartDateField`}
                {...fields.startDate}
              />

              <FormDateField
                minDate={fields.startDate.value || 0}
                maxDate={new Date()}
                data-testid={`${testId}CompletionDateField`}
                {...fields.completionDate}
              />
            </FormGroup>

            <Box sx={{ mt: 0.8 }}>
              <Dropzone {...fields.certificate} />
            </Box>
          </FormGroup>
        </DialogRoot>
      </FormDialog>
      <ChangeReasonFormDialog {...reasonForm.reasonDialog} />
    </div>
  );
}
