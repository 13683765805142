import React from "react";
import $specialties from "fixtures/specialties";
import { SpecialtyLookup } from "fixtures/specialties";
import { extractSpecialtyOptions } from "utils/specialty";

import {
  AutocompleteField,
  AutocompleteInstanceFieldProps,
} from "./AutocompleteField";

export type MedicalSpecialtySelectFieldProps = AutocompleteInstanceFieldProps & {
  specialties?: SpecialtyLookup;
};

export function MedicalSpecialtySelectField({
  specialties = $specialties,
  "data-testid": testId = "MedicalSpecialtySelectField",
  ...otherProps
}: MedicalSpecialtySelectFieldProps) {
  const options = extractSpecialtyOptions(specialties);
  return (
    <AutocompleteField
      id="specialty"
      name="specialty"
      label="Specialty"
      data-testid={testId}
      options={options}
      {...otherProps}
    />
  );
}
