import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { noop } from "utils/noop";

import {
  TablePane,
  TableHeadRow,
  TableBodyRow,
  TableActionsCell,
  TableEditActionButton,
  TableDeleteActionButton,
  TablePaneFooter,
  TablePaneButton
} from "./Table";

interface Props {
  testId?: string;
  entries?: string[];
  maxEntries?: number;
  onCreateButtonClick?: Callback;
  onUpdateButtonClick?: Callback;
  onDeleteButtonClick?: Callback;
}

export function ResearchIndicationsTable({
  testId,
  entries = [],
  maxEntries = 5,
  onCreateButtonClick = noop,
  onUpdateButtonClick = noop,
  onDeleteButtonClick = noop
}: Props) {
  const $testId = testId || ResearchIndicationsTable.name;
  const maxEntriesReached = entries.length >= maxEntries;
  return (
    <TablePane>
      <Table data-testid={$testId} data-testmaxentries={maxEntries}>
        <TableHead>
          <TableHeadRow>
            <TableCell>Indication experience</TableCell>
            <TableCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {entries.map(entry => (
            <TableBodyRow key={entry} data-testid={`${$testId}BodyRow`}>
              <TableCell>{entry}</TableCell>
              <TableActionsCell>
                <TableEditActionButton
                  onClick={() => onUpdateButtonClick(entry)}
                  data-testid={`${$testId}UpdateEntryButton`}
                />
                <TableDeleteActionButton
                  onClick={() => onDeleteButtonClick(entry)}
                  data-testid={`${$testId}DeleteEntryButton`}
                />
              </TableActionsCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
      <TablePaneFooter>
        {!maxEntriesReached ? (
          <TablePaneButton
            icon={AddCircleIcon}
            label="Add another indication"
            onClick={onCreateButtonClick}
            data-testid={`${$testId}CreateEntryButton`}
          />
        ) : (
          <Typography variant="subtitle2" data-testid="MaxReachedNotification">
            You can include up to 5 indications, please choose those in which
            you have the most experience. You can add all of your research
            experience in the Clinical Studies section.
          </Typography>
        )}
      </TablePaneFooter>
    </TablePane>
  );
}
