import React from "react";
import { styled } from "@mui/material/styles";

import { Suspend } from "components/Suspend";
import { RoleType } from "utils/role";
import { ContactCard } from "components/ContactCard";
import { LicenceCard } from "components/LicenceCard";
import { StudiesCard } from "components/StudiesCard";
import { ResearchCard } from "components/ResearchCard";
import { TrainingCard } from "components/TrainingCard";
import { EducationCard } from "components/EducationCard";
import { ExperienceCard } from "components/ExperienceCard";
import { ProfileProgress } from "components/ProfileProgress";
import { ProfileUserCard } from "../components/ProfileUserCard";
import { OptInConfirmation } from "../components/OptInConfirmation";
import { ProfileDelegatesLink } from "../components/ProfileDelegatesLink";
import { ProfileTrialSponsorsBox } from "../components/ProfileTrialSponsorsBox";
import { OptedInConfirmation } from "components/OptedInConfirmation";
import { MedicalSpecialtyCard } from "components/MedicalSpecialtyCard";
import { FacilityAffiliationsCard } from "components/FacilityAffiliationsCard";
import { useProfile, UseProfileResult } from "hooks/useProfile";

import {
  Screen,
  ScreenSection,
  ScreenSectionTitle,
  ScreenSectionBar,
} from "../components/Screen";
import { OneHomeBillboard } from "components/OneHomeBillboard";
import { useAuth } from "hooks/useAuth";

const ProgressContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridGap: theme.spacing(2),
  alignItems: "start",
}));

export default function Profiles() {
  const profile = useProfile();
  const auth = useAuth();
  const { data, error, loading, progress, showMissing } = profile;
  const isSiteStaff = data?.user.role === RoleType.SiteStaff;
  const params = new URLSearchParams(window.location.search);

  return (
    <Suspend
      label="Loading profile"
      when={loading}
      error={error}
      errorMessage="There was an error retrieving the profile. Please try again later"
    >
      <Screen data-testid={Profiles.name}>
        {auth.isOneHomeBannerOpen && (
          <ScreenSection>
            <OneHomeBillboard
              onRequest={() => auth.goToOneHome?.()}
              onHide={() => auth.hideOneHomeBanner?.()}
            />
          </ScreenSection>
        )}
        <ScreenSection>
          <ScreenSectionBar
            role="heading"
            sx={{ display: "grid", gridTemplateColumns: "60% auto" }}
            title="Your Profile"
            actions={<ProfileDelegatesLink />}
          />
          {params.has("optedIn") && <OptedInConfirmation />}
          {profile?.data?.user.optIn.status === "NON_RESPONDER" && (
            <OptInConfirmation email={profile?.data?.user.optIn.email} />
          )}
          <ProgressContainer sx={{ gridTemplateColumns: { sm: "60% auto" } }}>
            <ProfileProgress
              onClickShowMissing={showMissing}
              basicProgress={progress.basic}
              cvProgress={progress.all}
              optedIn={profile?.data?.user.optIn.status === "OPTED_IN"}
            />
            <ProfileTrialSponsorsBox />
          </ProgressContainer>
        </ScreenSection>

        {isSiteStaff ? (
          <SiteStaffContents profile={profile} />
        ) : (
          <NonSiteStaffContents profile={profile} />
        )}
      </Screen>
    </Suspend>
  );
}

interface ProfileContentsProps {
  profile: UseProfileResult;
}

function NonSiteStaffContents({ profile }: ProfileContentsProps) {
  const { cards } = profile;

  return (
    <>
      <ScreenSection data-testid="BasicDetailsSection">
        <ScreenSectionTitle children="Basic Details" />
        <FacilityAffiliationsCard {...cards.facility} />
        <ProfileUserCard {...cards.user} />
        <ContactCard {...cards.contact} />
        <MedicalSpecialtyCard {...cards.medicalSpecialty} />
        <ResearchCard {...cards.research} />
      </ScreenSection>
      <ScreenSection data-testid="AdditionalDetailsSection">
        <ScreenSectionTitle children="Additional Details For CV Creation" />
        <EducationCard {...cards.education} />
        <ExperienceCard {...cards.experience} />
        <StudiesCard {...cards.studies} />
        <LicenceCard {...cards.licence} />
        <TrainingCard {...cards.training} />
      </ScreenSection>
    </>
  );
}

function SiteStaffContents({ profile }: ProfileContentsProps) {
  const { cards } = profile;
  return (
    <>
      <ScreenSection data-testid="BasicDetailsSection">
        <ScreenSectionTitle children="Basic Details" />
        <FacilityAffiliationsCard {...cards.facility} />
        <ProfileUserCard {...cards.user} />
        <ContactCard {...cards.contact} />
      </ScreenSection>
      <ScreenSection data-testid="AdditionalDetailsSection">
        <ResearchCard {...cards.research} />
        <EducationCard {...cards.education} />
        <ExperienceCard {...cards.experience} />
        <LicenceCard {...cards.licence} />
        <TrainingCard {...cards.training} />
      </ScreenSection>
    </>
  );
}
