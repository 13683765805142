import React from "react";
import Close from "@mui/icons-material/Close";
import {
  Paper,
  Typography,
  Button,
  Box,
  Divider,
  IconButton,
} from "@mui/material";

interface ProfileProgressProps {
  onRequest: () => void;
  onHide: () => void;
}

export function OneHomeBillboard({ onRequest, onHide }: ProfileProgressProps) {
  return (
    <Paper>
      <Box p={2}>
        <Box display="flex" justifyContent="space-between">
          <div>
            <Box mb={2}>
              <img src="/logo-icon.svg" alt="One Home for Sites" />
            </Box>
            <Typography fontSize={20} fontWeight={600} mb={0}>
              One Home for Sites
            </Typography>
            <Typography variant="body1" mb={2} color="GrayText">
              By IQVIA
            </Typography>
          </div>
          <div>
            <IconButton onClick={onHide}>
              <Close />
            </IconButton>
          </div>
        </Box>
        <Typography variant="body1" mb={3}>
          Your Profile account can be used to log into One Home for Sites, our
          new study management tool that's designed to let you access all your
          study systems from one single location, share study information
          between team members, and more!
        </Typography>
        <Divider />
        <Box mt={3}>
          <Button variant="contained" onClick={onRequest}>
            Try One Home for Sites
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
