import React from "react";
import studyComments from "fixtures/studyComments";

import {
  AutocompleteField,
  AutocompleteInstanceFieldProps,
} from "./AutocompleteField";

export function StudyCommentSelectField({
  "data-testid": testId = StudyCommentSelectField.name,
  ...otherProps
}: AutocompleteInstanceFieldProps) {
  return (
    <AutocompleteField
      id="comment"
      label="Would you like to add a comment?"
      name="comment"
      options={studyComments}
      {...otherProps}
    />
  );
}
