import { Field } from "hooks/useForm";
import { isEmpty } from "validators/isEmpty";

export function validators(...fns: Function[]) {
  return (field: Field) => {
    for (let validate of fns) {
      const error = validate(field);
      if (error) return error;
    }
  };
}

export function extractFromString(fieldValue: string) {
  return fieldValue
    .split(/\r|\n/)
    .filter((v: string) => v.trim() !== "")
    .map((v: string) => v.trim());
}

export function extractFromMultiSelect(fieldsValue: any[]) {
  return fieldsValue.length ? fieldsValue.map(({ value }: any) => value) : [];
}

export function isValueEmpty(message: string) {
  return [(value: any) => !isEmpty(value), message];
}
