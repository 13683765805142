import React from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Check from '@mui/icons-material/Check';
import { StepIconProps } from '@mui/material/StepIcon';

interface CurrentStepProps {
  currentStep: number;
}

const PREFIX = "RegisterStepIcon";

const classes = {
  completedIcon: `${PREFIX}-completedIcon`,
  circle: `${PREFIX}-circle`,
};

const StepsConnector = styled(StepConnector)(({theme}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 1rem)',
    right: 'calc(50% + 1rem)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[400],
    borderTopWidth: '2px',
  },
}));

const RegisterStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.common.white,
    display: 'flex',
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: '50%',
    alignItems: 'center',
    [`& .${classes.completedIcon}`]: {
      color: theme.palette.success.main,
      zIndex: 1,
      fontSize: 22,
    },
    [`& .${classes.circle}`]: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      borderRadius: '50%',
      backgroundColor: theme.palette.grey[400],
      ...(ownerState.active && {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.light,
      }),
    },
  }),
);

const StepInfo = styled(StepLabel)(() => ({
  '& .MuiStepLabel-label.Mui-active': {
    fontWeight: "bold",
  }
}));

function RegisterStepIcon( { active, completed, className, icon }: StepIconProps) {
  return (
    <RegisterStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className={classes.completedIcon}/>
      ) : (
        <div className={classes.circle}>
          <Typography align="center" sx={{lineHeight: "1.6"}}>{icon}</Typography>
        </div>
      )}
    </RegisterStepIconRoot>
  );
}

export function RegistrationStepper({ currentStep } : CurrentStepProps) {
  const steps = [
    'Basic Details',
    'Primary Facility',
    'Account Details'
  ];

  return (
    <Stepper sx={{marginTop: 1}} activeStep={currentStep} alternativeLabel={true} connector={<StepsConnector/>}>
      {steps.map((label) => (
        <Step key={ label }>
          <StepInfo StepIconComponent={ RegisterStepIcon }>
            { label }
          </StepInfo>
        </Step>
      ))}
    </Stepper>
  );
}
