import React from "react";
import gql from "graphql-tag";
import { styled } from "@mui/material/styles";
import {
  Button,
  Box,
  Fade,
  Dialog,
  DialogProps,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
} from "@mui/material";

import { noop } from "utils/noop";
import { Snackbar } from "./Snackbar";
import { useQuery } from "@apollo/client";
import { StudyStats } from "./StudyStats";

export const GET_STUDY_STATS = gql`
  query studyStatistics($variationPk: Int!) {
    studyStatistics(variationPk: $variationPk) {
      id: pk
      role
      title
      primaryIndication
      nctNumber
      sponsor
      status
      phase
      siteOpenDate
      enrolled
      enrolledMonths
      patientsPspm
      screenFail
      complete
      medianEnrolled
      medianEnrolledMonths
      medianPatientsPspm
      medianScreenFail
      medianComplete
      comment
    }
  }
`;

const LoadingIndicator = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

interface StudyStatsDialogProps extends Omit<DialogProps, "open"> {
  open?: boolean;
  testId?: string;
  studyId?: number;
  onClose?: Callback;
  onError?: Callback;
  "data-testid"?: string;
}

export function StudyStatsDialog({
  studyId,
  open = false,
  onClose = noop,
  onError = console.error,
  "data-testid": testId = StudyStatsDialog.name,
}: StudyStatsDialogProps) {
  const { loading, error, data } = useQuery(GET_STUDY_STATS, {
    variables: { variationPk: studyId },
    fetchPolicy: "cache-and-network"
  });
  const stats = data ? data.studyStatistics : undefined;

  if (error) {
    onError(error);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-testid={testId}
      aria-labelledby="title"
      maxWidth="xl"
    >
      <DialogTitle id="title">Study metrics</DialogTitle>
      <DialogContent>
        <Snackbar
          open={Boolean(error)}
          variant="error"
          message="Something went wrong. Please try again later"
          data-testid={`${testId}ErrorBar`}
          noActionButton
        />

        <Fade in={!loading && !error}>
          <Box>
            <StudyStats
              entry={stats}
              aria-hidden={loading || Boolean(error)}
              data-testid={`${testId}StudyStats`}
            />
          </Box>
        </Fade>

        {loading && (
          <LoadingIndicator>
            <Fade in={loading}>
              <CircularProgress
                size={20}
                aria-hidden={!loading}
                data-testid={`${testId}ActivityIndicator`}
              />
            </Fade>
          </LoadingIndicator>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} data-testid={`${testId}CloseButton`}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
