import React, { useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import { Phases } from "types/facility";
import { FormField } from "./FormField";
import { useForm, Field } from "hooks/useForm";
import { asyncNoop, noop } from "utils/noop";
import { FormCheckboxField } from "./FormCheckboxField";

const defaultValue = {} as Phases;

const FormContent = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridRowGap: theme.spacing(1.5),
}));

interface ResearchPhasesFieldProps {
  label?: string;
  error?: boolean;
  form: string;
  helperText?: string;
  onChange: (experience: Phases) => void;
  value?: Phases;
  [key: string]: any;
}

export function ResearchPhasesField({
  label = "Trial phase capabilities",
  form,
  error = false,
  value = defaultValue,
  helperText,
  onChange,
  ...phaseThreeProps
}: ResearchPhasesFieldProps) {
  const { fields, setField } = useForm({
    id: form,
    fields: {
      phaseZero: {
        type: "checkbox",
        name: "phaseZero",
        label: "Phase 0",
        value: value.phaseZero,
      },
      phaseOne: {
        type: "checkbox",
        name: "phaseOne",
        label: "Phase I",
        value: value.phaseOne,
      },
      phaseOneOrTwo: {
        type: "checkbox",
        name: "phaseOneOrTwo",
        label: "Phase I/II",
        value: value.phaseOneOrTwo,
      },
      phaseTwo: {
        type: "checkbox",
        name: "phaseTwo",
        label: "Phase II",
        value: value.phaseTwo,
      },
      phaseTwoOrThree: {
        type: "checkbox",
        name: "phaseTwoOrThree",
        label: "Phase II/III",
        value: value.phaseTwoOrThree,
      },
      phaseThree: {
        type: "checkbox",
        name: "phaseThree",
        label: "Phase III",
        value: value.phaseThree,
      },
      phaseThreeOrFour: {
        type: "checkbox",
        name: "phaseThreeOrFour",
        label: "Phase III/IV",
        value: value.phaseThreeOrFour,
      },
      phaseFour: {
        type: "checkbox",
        name: "phaseFour",
        label: "Phase IV",
        value: value.phaseFour,
      },
    },
    submit: asyncNoop,
    onError: noop,
    onChange: (fields: any) => {
      onChange({
        phaseZero: asBoolOrNull(fields.phaseZero.value),
        phaseOne: asBoolOrNull(fields.phaseOne.value),
        phaseOneOrTwo: asBoolOrNull(fields.phaseOneOrTwo.value),
        phaseTwo: asBoolOrNull(fields.phaseTwo.value),
        phaseTwoOrThree: asBoolOrNull(fields.phaseTwoOrThree.value),
        phaseThree: asBoolOrNull(fields.phaseThree.value),
        phaseThreeOrFour: asBoolOrNull(fields.phaseThreeOrFour.value),
        phaseFour: asBoolOrNull(fields.phaseFour.value),
      });
    },
  });

  useEffect(() => {
    for (const key of Object.keys(value)) {
      // @ts-ignore
      setField(key, value[key]);
    }
  }, [JSON.stringify(value)]); // eslint-disable-line

  return (
    <FormField
      error={error}
      label={label}
      helperText={helperText}
      {...phaseThreeProps}
    >
      <FormContent>
        <Typography variant="subtitle1" color="textSecondary">
          Please tell us in which clinical trial phases your site has experience
          (select all that apply)
        </Typography>
        <FormGroup row>
          <CheckboxField {...fields.phaseOne} />
          <CheckboxField {...fields.phaseOneOrTwo} />
          <CheckboxField {...fields.phaseTwo} />
          <CheckboxField {...fields.phaseTwoOrThree} />
          <CheckboxField {...fields.phaseThree} />
          <CheckboxField {...fields.phaseThreeOrFour} />
          <CheckboxField {...fields.phaseFour} />
        </FormGroup>
      </FormContent>
    </FormField>
  );
}

function CheckboxField(props: Field) {
  return (
    <FormCheckboxField
      sx={{
        root: { mr: 2 },
        checkbox: { pr: 1 },
      }}
      {...props}
    />
  );
}

function asBoolOrNull(value?: boolean) {
  if (typeof value === "boolean") {
    return value;
  }
  return null;
}
