import React from "react";
import Table from "@mui/material/Table";
import HelpIcon from "@mui/icons-material/Help";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { CloudDownload } from "@mui/icons-material";
import { noop } from "utils/noop";
import { Study } from "types/profile";
import { styled } from "@mui/material/styles";
import { DialogTrigger } from "./DialogTrigger";
import { StudyStatsDialog } from "./StudyStatsDialog";
import { StudyCommentForm } from "./StudyCommentForm";
import { StudyAboutInfoDialog } from "./StudyAboutInfoDialog";
import { StudyDetailsDialog } from "./StudyDetailsDialog";

import {
  TablePane,
  TableHeadRow,
  TableBodyRow,
  TableActionsCell,
  TablePaneFooter,
  TablePaneButton,
  TableCell,
  TableEditActionButton,
  TableDeleteActionButton,
} from "./Table";

const PREFIX = "StudiesTable";

const classes = {
  title: `${PREFIX}-title`,
};

const TablePaneRoot = styled(TablePane)(({ theme }) => ({
  [`& .${classes.title}`]: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    "& :hover": {
      textDecoration: "underline",
    },
  },
}));

interface Props {
  testId?: string;
  entries?: Study[];
  withReason?: boolean;
  updateStudy: (study: Study) => Promise<any>;
  onHelpButtonClick?: EntryActionCallback;
  onCreateButtonClick?: Callback;
  onMetricsButtonClick?: EntryActionCallback;
  onCommentsButtonClick?: EntryActionCallback;
  onDownloadStudyButtonClick?: Callback;
  onUpdateButtonClick?: (study: Study) => void;
  onDeleteButtonClick?: (study: Study) => void;
  "data-testid"?: string;
}

export function StudiesTable({
  entries = [],
  updateStudy,
  withReason = false,
  onHelpButtonClick = noop,
  onUpdateButtonClick = noop,
  onDeleteButtonClick = noop,
  onMetricsButtonClick = noop,
  onCommentsButtonClick = noop,
  onCreateButtonClick,
  onDownloadStudyButtonClick,
  "data-testid": testId = StudiesTable.name,
}: Props) {
  return (
    <TablePaneRoot>
      <Table data-testid={testId}>
        <TableHead>
          <TableHeadRow data-testid={`${testId}HeadRow`}>
            <TableCell>NCT No.</TableCell>
            <TableCell>Title</TableCell>
            <TableCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {entries.map((entry) => (
            <TableBodyRow key={entry.id} data-testid={`${testId}BodyRow`}>
              <TableCell>{entry.nctNumber || "-"}</TableCell>
              <TableCell className={classes.title}>
                <DialogTrigger
                  dialog={StudyDetailsDialog}
                  dialogProps={{ study: entry }}
                >
                  {entry.title}
                </DialogTrigger>
              </TableCell>
              <TableActionsCell>
                {entry.source === "CTMS" ? (
                  <>
                    <DialogTrigger dialog={StudyAboutInfoDialog}>
                      <IconButton
                        aria-label="help"
                        size="small"
                        color="primary"
                        data-testid={`${testId}HelpButton`}
                        onClick={() => onHelpButtonClick(entry)}
                      >
                        <HelpIcon fontSize="inherit" />
                      </IconButton>
                    </DialogTrigger>

                    <DialogTrigger
                      dialog={StudyStatsDialog}
                      dialogProps={{ studyId: entry.id }}
                    >
                      <IconButton
                        aria-label="metrics"
                        size="small"
                        color="primary"
                        data-testid={`${testId}MetricsButton`}
                        onClick={() => onMetricsButtonClick(entry)}
                      >
                        <TrendingUpIcon fontSize="inherit" />
                      </IconButton>
                    </DialogTrigger>

                    <DialogTrigger
                      dialog={StudyCommentForm}
                      dialogProps={{ entry, submit: updateStudy, withReason }}
                      dialogCloseProp={["onCancel", "onSuccess"]}
                    >
                      <IconButton
                        aria-label="comments"
                        size="small"
                        color="primary"
                        data-testid={`${testId}CommentsButton`}
                        onClick={() => onCommentsButtonClick(entry)}
                      >
                        <CommentIcon fontSize="inherit" />
                      </IconButton>
                    </DialogTrigger>
                  </>
                ) : (
                  <>
                    <TableEditActionButton
                      onClick={() => onUpdateButtonClick(entry)}
                      data-testid={`${testId}UpdateEntryButton`}
                    />
                    <TableDeleteActionButton
                      onClick={() => onDeleteButtonClick(entry)}
                      data-testid={`${testId}DeleteEntryButton`}
                    />
                  </>
                )}
              </TableActionsCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
      <TablePaneFooter>
        <TablePaneButton
          icon={AddCircleIcon}
          label="Add another clinical study"
          onClick={onCreateButtonClick}
          data-testid={`${testId}CreateEntryButton`}
        />
        <TablePaneButton
          icon={CloudDownload}
          label="Download my study information"
          onClick={onDownloadStudyButtonClick}
          data-testid={`${testId}DownloadStudyButton`}
          disabled={entries.length === 0}
        />
      </TablePaneFooter>
    </TablePaneRoot>
  );
}
