import React from "react";

import { Form } from "./Form";
import { noop } from "utils/noop";
import { useForm } from "hooks/useForm";
import { FormFieldset } from "./FormFieldset";
import { FacilityEquipment } from "types/facility";
import { FacilityFreezerField } from "./FacilityFreezerField";
import { EquipmentDiagnosticsField } from "./EquipmentDiagnosticsField";
import { FieldTrueFalseRadioGroup } from "components/FieldTrueFalseRadioGroup";
import { isValidOtherEquipment } from "utils/equipment";

export interface DepartmentEquipmentFormProps {
  open?: boolean;
  title?: string;
  entry?: FacilityEquipment;
  submit: (value: FacilityEquipment) => Promise<any>;
  onCancel?: Callback;
  onError?: Callback;
  onSuccess?: Callback;
  forFacility?: boolean;
  "data-testid"?: string;
}

export function DepartmentEquipmentForm({
  open,
  title,
  entry = {} as FacilityEquipment,
  onCancel = noop,
  onError = console.error,
  onSuccess = noop,
  forFacility = false,
  submit: doSubmit,
  ...otherProps
}: DepartmentEquipmentFormProps) {
  const label = forFacility ? "facility" : "department";
  const { id, fields, submit, reset, submitting, error } = useForm({
    id: "department-equipment-form",
    fields: {
      diagnostics: {
        name: "diagnostics",
        value: entry.diagnostics,
        validate(diagnostics) {
          return isValidOtherEquipment(diagnostics.value.other);
        },
      },
      // Treatment
      pkPdCapability: {
        name: "pkPdCapability",
        label: `Can your ${label} collect and store PK/PD samples?`,
        value: entry.treatment.pkPdCapability?.toString(),
        "aria-label": "PK/PD capability",
      },
      pkPdOutsideHours: {
        name: "pkPdOutsideHours",
        label: `Can your ${label} collect PK/PD samples beyond normal business hours?`,
        value: entry.treatment.pkPdOutsideHours?.toString(),
        "aria-label": "PK/PD outside hours",
      },
      pgxCollectionResearch: {
        name: "pgxCollectionResearch",
        label: `Does your ${label} allow the collection of Pharmacogenomic (PGX) samples for research purposes?`,
        value: entry.treatment.pgxCollectionResearch?.toString(),
        "aria-label": "PGX collection research",
      },
      isInfusionCapable: {
        name: "isInfusionCapable",
        label: `Does your ${label} have infusion capabilities?`,
        value: entry.treatment.isInfusionCapable?.toString(),
        "aria-label": "infusion capabilities",
      },
      inPatientAdmission: {
        name: "inPatientAdmission",
        label:
          "Can you admit research subjects to an in-patient setting for research purposes?",
        value: entry.treatment.inPatientAdmission?.toString(),
        "aria-label": "in-patient admission",
      },
      // Details
      sopRoutineMaintenance: {
        name: "sopRoutineMaintenance",
        label: `Does your ${label} have an SOP or process that ensures routine calibration and maintenance of general equipment?
            (Examples of general equipment include: scale, pulse oximeter, stadiometer, sphygmomanometer etc)`,
        value: entry.equipmentDetails.sopRoutineMaintenance?.toString(),
        "aria-label": "SOP routine maintenance",
      },
      refrigeratorForSamples: {
        name: "refrigeratorForSamples",
        label: "Do you have refrigerators (2-8°C) for biological storage?",
        value: entry.equipmentDetails.refrigeratorForSamples?.toString(),
        "aria-label": "refrigerator for samples",
      },
      refrigeratedCentrifuge: {
        name: "refrigeratedCentrifuge",
        label:
          "Do you have a refrigerated centrifuge for processing lab samples?",
        value: entry.equipmentDetails.refrigeratedCentrifuge?.toString(),
        "aria-label": "refrigerated centrifuge",
      },
      labSamplesCentrifuge: {
        name: "labSamplesCentrifuge",
        label:
          "Do you have a non-refrigerated centrifuge for processing lab samples?",
        value: entry.equipmentDetails.labSamplesCentrifuge?.toString(),
        "aria-label": "sample centrifuge",
      },
      freezers: {
        name: "freezers",
        value: entry.equipmentDetails.freezers,
      },
      treatMedicalEmergencies: {
        name: "treatMedicalEmergencies",
        label: `Does your ${label} have the necessary equipment to treat medical emergencies (ie code cart)?`,
        value: entry.equipmentDetails.treatMedicalEmergencies?.toString(),
        "aria-label": "treat medical emergencies",
      },
    },
    submit: (values: any) => {
      const details = {
        diagnostics: values.diagnostics,
        treatment: {
          inPatientAdmission: stringAsBool(values.inPatientAdmission),
          isInfusionCapable: stringAsBool(values.isInfusionCapable),
          pgxCollectionResearch: stringAsBool(values.pgxCollectionResearch),
          pkPdCapability: stringAsBool(values.pkPdCapability),
          pkPdOutsideHours: stringAsBool(values.pkPdOutsideHours),
        },
        equipmentDetails: {
          sopRoutineMaintenance: stringAsBool(values.sopRoutineMaintenance),
          labSamplesCentrifuge: stringAsBool(values.labSamplesCentrifuge),
          refrigeratedCentrifuge: stringAsBool(values.refrigeratedCentrifuge),
          refrigeratorForSamples: stringAsBool(values.refrigeratorForSamples),
          freezers: {
            minus20ToMinus30: values.freezers.minus20ToMinus30,
            minus70: values.freezers.minus70,
            minus135: values.freezers.minus135,
          },
          treatMedicalEmergencies: stringAsBool(values.treatMedicalEmergencies),
        },
      };

      return doSubmit(details);
    },
    onReset: onCancel,
    onError,
    onSuccess,
  });

  return (
    <Form
      id={id}
      aria-label="Department equipment form"
      open={open}
      title={title}
      onSubmit={submit}
      submitting={submitting}
      onCancel={reset}
      error={error}
      {...otherProps}
    >
      <FormFieldset legend="Treatment and diagnostics">
        <EquipmentDiagnosticsField {...fields.diagnostics} />
        <FieldTrueFalseRadioGroup {...fields.sopRoutineMaintenance} />
        <FieldTrueFalseRadioGroup {...fields.pkPdCapability} />
        {fields.pkPdCapability.value === "true" && (
          <FieldTrueFalseRadioGroup {...fields.pkPdOutsideHours} />
        )}
        <FieldTrueFalseRadioGroup {...fields.pgxCollectionResearch} />
        <FieldTrueFalseRadioGroup {...fields.isInfusionCapable} />
        <FieldTrueFalseRadioGroup {...fields.inPatientAdmission} />
        <FieldTrueFalseRadioGroup {...fields.treatMedicalEmergencies} />
      </FormFieldset>

      <FormFieldset legend="Equipment details">
        <FieldTrueFalseRadioGroup {...fields.refrigeratorForSamples} />
        <FieldTrueFalseRadioGroup {...fields.refrigeratedCentrifuge} />
        <FieldTrueFalseRadioGroup {...fields.labSamplesCentrifuge} />
        <FacilityFreezerField {...fields.freezers} />
      </FormFieldset>
    </Form>
  );
}

function stringAsBool(value: string) {
  return value === "" ? null : asBool(value);
}

function asBool(value?: string | null): boolean {
  return value === "true" ? true : false;
}
