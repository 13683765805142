import React from "react";
import studyRoles from "fixtures/studyRoles";

import {
  FormRadioButtonGroupField,
  FormRadioButtonGroupFieldProps,
} from "./FormRadioButtonGroupField";

export function StudyRoleRadioGroupField({
  ...props
}: FormRadioButtonGroupFieldProps) {
  function getStudyOptions() {
    return studyRoles.map((role) => ({
      key: role.value,
      value: role.value,
      label: role.label,
    }));
  }

  return (
    <FormRadioButtonGroupField
      name="role"
      label="What was your role on this study?"
      options={getStudyOptions()}
      aria-label="study role"
      {...props}
    />
  );
}
