import React from "react";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import { FormFieldset } from "./FormFieldset";
import { styled } from "@mui/material/styles";

const FieldsetContent = styled("div")(({ theme }) => ({
  "& > *:not(:first-child)": {
    marginTop: theme.spacing(1),
  },
}));

export interface FormFieldProps {
  label?: string;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  children?: ReactNodes;
  helperText?: string;
  helperTextId?: string;
  "aria-label"?: string;
}

export function FormField({
  label,
  error,
  required,
  children,
  disabled,
  helperText,
  helperTextId,
}: FormFieldProps) {
  return (
    <FormFieldset legend={label!} disabled={disabled} aria-hidden>
      <FormControl
        sx={{ width: "100%" }}
        component="fieldset"
        error={error}
        disabled={disabled}
      >
        <FormLabel
          component="legend"
          required={required}
          disabled={disabled}
          hidden
        >
          {label}
        </FormLabel>
        <FieldsetContent>{children}</FieldsetContent>
        {helperText && (
          <FormHelperText
            error={error}
            {...(helperTextId && { id: helperTextId })}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </FormFieldset>
  );
}
