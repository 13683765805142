import React from "react";
import { styled } from "@mui/material/styles";
import {
  FormHelperText,
  FormControl,
  FormControlProps,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";

const PREFIX = "FormRadioButtonGroup";

const classes = {
  field: `${PREFIX}-field`,
  fieldContents: `${PREFIX}-fieldContents`,
  labelFocused: `${PREFIX}-labelFocused`,
  radioGroup: `${PREFIX}-radioGroup`,
};

const FormControlStyled = styled(FormControl)<
  FormControlProps & { component: string }
>(({ theme }) => ({
  [`&.${classes.field}`]: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
  },

  [`& .${classes.fieldContents}`]: {
    display: "grid",
    gridTemplateRows: "auto auto",
    gridRowGap: theme.spacing(1),
  },

  [`& .${classes.labelFocused}`]: {
    color: `${theme.palette.grey[600]} !important`,
  },

  [`& .${classes.radioGroup}`]: {
    justifySelf: "start",
  },
}));

type InputChangeHandler = (
  event: React.ChangeEvent<HTMLInputElement>,
  value: string
) => void;

interface RadioOptions {
  label: string;
  value: string;
  key?: string;
}

export interface FormRadioButtonGroupProps {
  id: string;
  row?: boolean;
  name?: string;
  label?: string;
  value?: string;
  options?: RadioOptions[];
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  onChange?: InputChangeHandler | undefined;
  children?: ReactNodes;
  helperText?: string;
  "aria-label"?: string;
}

export function FormRadioButtonGroup({
  id,
  name,
  label,
  value,
  options = [],
  error,
  required,
  disabled,
  onChange,
  helperText,
  "aria-label": ariaLabel,
  row = true,
}: FormRadioButtonGroupProps) {
  return (
    <FormControlStyled
      id={id}
      className={classes.field}
      component="fieldset"
      error={error}
      disabled={disabled}
      fullWidth
    >
      <div className={classes.fieldContents}>
        <FormLabel
          required={required}
          error={error}
          classes={{ focused: classes.labelFocused }}
        >
          {label}
        </FormLabel>
        <RadioGroup
          aria-label={ariaLabel}
          className={classes.radioGroup}
          name={name}
          value={value}
          onChange={onChange}
          row={row}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.key || option.label}
              value={option.value}
              control={
                <Radio
                  color="primary"
                  required={required}
                  checked={option.value === value}
                />
              }
              label={option.label}
            />
          ))}
        </RadioGroup>
      </div>
      <FormHelperText id={`${id}-helper-text`} error={error}>
        {helperText}
      </FormHelperText>
    </FormControlStyled>
  );
}
