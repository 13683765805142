import React from "react";
import nameSuffixes from "../fixtures/nameSuffixes";

import {
  AutocompleteField,
  AutocompleteInstanceFieldProps,
} from "./AutocompleteField";

export function NameSuffixesSelectField(props: AutocompleteInstanceFieldProps) {
  return (
    <AutocompleteField
      id="suffix"
      label="Suffix"
      name="nameSuffix"
      options={nameSuffixes}
      {...props}
    />
  );
}
