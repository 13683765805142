import React from "react";
import FormGroup from "@mui/material/FormGroup";

import { required } from "validators/required";
import { styled } from "@mui/material/styles";
import { FormDialog } from "./FormDialog";
import { Experience } from "types/profile";
import { noop, asyncNoop } from "utils/noop";
import { YearSelectField } from "./YearSelectField";
import { useForm, SubmitFn } from "hooks/useForm";
import { CountrySelectField } from "./CountrySelectField";
import { TextFieldWithWarning } from "./TextFieldWithWarning";
import { FacilityNameAutocompleteField } from "./FacilityNameAutocompleteField";
import { validators } from "utils/forms";
import { maxLengthForValidator as maxLength } from "validators/maxLength";

import {
  useChangeReasonFormDialog,
  ChangeReasonFormDialog,
} from "./ChangeReasonFormDialog";

const PREFIX = "ExperienceForm";

const classes = {
  yearFormGroup: `${PREFIX}-yearFormGroup`,
  warningInputLabel: `${PREFIX}-warningInputLabel`,
  warningInputOutline: `${PREFIX}-warningInputOutline`,
};

const DialogRoot = styled("div")(({ theme }) => ({
  [`& .${classes.yearFormGroup}`]: {
    justifyContent: "space-between",
    "& > *": {
      flexGrow: 1,
    },
    "& > *:not(:first-child)": {
      marginLeft: theme.spacing(2),
    },
  },

  [`& .${classes.warningInputLabel}`]: {
    color: theme.palette.warning.main,
  },

  [`& .${classes.warningInputOutline}`]: {
    borderColor: theme.palette.warning.main,
  },
}));

const defaultExperience = {} as Experience;

interface Props {
  title?: string;
  open?: boolean;
  entry?: Experience;
  testId?: string;
  submit?: SubmitFn;
  onCancel?: Callback;
  onSuccess?: Callback;
  withReason?: boolean;
  flagIncompleteFields?: boolean;
  "data-testid"?: string;
}

export function ExperienceForm({
  open,
  entry,
  "data-testid": testId = ExperienceForm.name,
  title = "Update experience",
  submit: doSubmit = asyncNoop,
  onCancel: doCancel = noop,
  withReason = false,
  onSuccess = noop,
  flagIncompleteFields = false,
  ...otherProps
}: Props) {
  const experience = entry || defaultExperience;

  const handleSubmit = async (values: AnyObject, adminComment?: string) => {
    // TODO[CV-2921]: useForm does not cope well with objects as field values
    // This should be considered as a workaround and useForm be improved
    return await doSubmit(
      {
        ...values,
        facilityName:
          typeof fields.facilityName.value === "string"
            ? fields.facilityName.value
            : fields.facilityName.value.name,
        facilityGoldenId:
          typeof fields.facilityName.value === "string"
            ? null
            : fields.facilityName.value.id,
      },
      adminComment
    );
  };

  const reasonForm = useChangeReasonFormDialog({
    doSubmit: handleSubmit,
    formHookSubmit,
    withReason,
  });

  const { id, fields, submit, reset, submitting } = useForm({
    id: "experience-form",
    fields: {
      id: {
        name: "id",
        value: experience.id,
      },
      countryCode: {
        id: "countryCode",
        name: "countryCode",
        value: experience.countryCode,
        required: true,
        validate: required,
        dependantFields: ["facilityName"],
      },
      facilityGoldenId: {
        name: "facilityGoldenId",
        value: experience.facilityGoldenId,
      },
      facilityName: {
        name: "facilityName",
        label: "Institution",
        value: experience.facilityName,
        required: true,
        validate: validators(required, maxLength(250)),
      },
      jobTitle: {
        name: "jobTitle",
        label: "Job title",
        value: experience.jobTitle,
        required: true,
        validate: validators(required, maxLength(80)),
      },
      yearStarted: {
        id: "yearStarted",
        name: "yearStarted",
        label: "Year started",
        value: experience.yearStarted ? experience.yearStarted.toString() : undefined,
        defaultValue: undefined,
        required: true,
        validate: required,
      },
      yearFinished: {
        id: "yearFinished",
        name: "yearFinished",
        label: "Year finished",
        value: experience.yearFinished,
        required: true,
        validate: required,
      },
    },
    onReset: doCancel,
    submit: reasonForm.performSubmit,
    onError: console.error,
    onSuccess,
  });

  function formHookSubmit() {
    return submit();
  }

  return (
    <div>
      <FormDialog
        id={id}
        open={open}
        title={title}
        onCancel={reset}
        onSubmit={reasonForm.handleSubmitButtonClick}
        maxWidth="sm"
        data-testid={testId}
        showProgressIndicator={submitting}
        {...otherProps}
      >
        <DialogRoot>
          <FormGroup>
            <CountrySelectField
              data-testid={`${testId}CountryCodeField`}
              {...fields.countryCode}
            />

            <FacilityNameAutocompleteField
              data-testid={`${testId}FacilityNameField`}
              countryCode={fields.countryCode.value}
              fullWidth
              {...fields.facilityName}
            />

            <TextFieldWithWarning
              data-testid={`${testId}JobTitleField`}
              warn={flagIncompleteFields && !fields.jobTitle.value}
              {...fields.jobTitle}
            />

            <FormGroup row className={classes.yearFormGroup}>
              <YearSelectField
                data-testid={`${testId}YearStartedField`}
                {...fields.yearStarted}
              />

              <YearSelectField
                allowPresent
                data-testid={`${testId}YearFinishedField`}
                startYear={fields.yearStarted.value}
                {...fields.yearFinished}
              />
            </FormGroup>
          </FormGroup>
        </DialogRoot>
      </FormDialog>
      <ChangeReasonFormDialog {...reasonForm.reasonDialog} />
    </div>
  );
}
