import React from "react";

import { Card } from "components/Card";
import { MutatorFn } from "hooks/useCards";
import { DeletionDialog } from "./DeletionDialog";
import { ExperienceForm } from "./ExperienceForm";
import { ExperienceTable } from "./ExperienceTable";
import { useEditableData } from "hooks/useEditableData";
import { Experience, Profile } from "types/profile";
import { hasIncompleteExperiences } from "utils/experience";
import { ManagedCardProps, CardMachine, useCard } from "hooks/useCard";

export const ExperienceCardMachine = CardMachine({ validateCard });

interface ExperienceCardProps extends ManagedCardProps {
  userPk?: number;
  isAdmin?: boolean;
  experiences: Experience[];
  createExperience: MutatorFn;
  updateExperience: MutatorFn;
  deleteExperience: MutatorFn;
}

export function ExperienceCard({
  id = "experience-card",
  userPk,
  isAdmin = false,
  service,
  experiences,
  createExperience,
  updateExperience,
  deleteExperience,
  ...otherProps
}: ExperienceCardProps) {
  const withReason = isAdmin;
  const { card } = useCard({ service });
  const { details, creationForm, updateForm, deletionDialog } = useEditableData(
    {
      create: createExperience,
      update: updateExperience,
      delete: deleteExperience,
    }
  );

  return (
    <Card
      id={id}
      heading="Professional Experience"
      arial-label="professionalExperience"
      data-testid={ExperienceCard.name}
      {...card}
      {...otherProps}
    >
      <ExperienceTable entries={experiences} {...details} />

      {creationForm.open && (
        <ExperienceForm
          title="Add experience"
          withReason={withReason}
          data-testid="ExperienceCreationForm"
          {...creationForm}
        />
      )}

      {updateForm.open && (
        <ExperienceForm
          title="Update experience"
          withReason={withReason}
          data-testid="ExperienceUpdateForm"
          flagIncompleteFields
          {...updateForm}
        />
      )}

      {deletionDialog.open && (
        <DeletionDialog withReason={withReason} {...deletionDialog} />
      )}
    </Card>
  );
}

export function validateCard({ experiences }: Profile) {
  const message = "Please let us know your work history";

  if (!experiences.length) {
    return message;
  }

  if (hasIncompleteExperiences(experiences)) {
    return message;
  }
}
