import { useMemo } from "react";
import { useMachine } from "@xstate/react";
import { CardMachine } from "./useCard";
import { spawn, assign, Machine, Interpreter, StateMachine } from "xstate";

export type MutatorFn = (...args: any[]) => Promise<any>;

type MachineService = Interpreter<any, any, any, any>;
interface CardsMachineContext {
  ids: string[];
  cards: {
    [id: string]: MachineService;
  };
  data: any;
  error: Error | null;
}

interface CardsMachineSchema {
  states: {
    idle: {};
    initialise: {};
    fetching: {};
    refetching: {};
  };
}

const CardsMachine = Machine<CardsMachineContext, CardsMachineSchema>(
  {
    initial: "initialise",
    context: {
      ids: [],
      cards: {},
      data: null,
      error: null,
    },
    states: {
      initialise: {
        always: [{ target: "fetching" }]
      },
      idle: {
        on: {
          CARD_DATA_CHANGED: {
            target: "refetching",
          },
          CARD_STATUS_CHANGED: {
            actions: "expandFirstIncompleteCard",
          },
          EXPAND_FIRST_INCOMPLETE: {
            actions: "expandFirstIncompleteCard",
          },
          FETCH: {
            target: "fetching",
          },
        },
      },
      fetching: {
        invoke: {
          src: "fetchData",
          onDone: {
            actions: ["assignData", "setupCards"],
            target: "idle",
          },
          onError: {
            actions: "assignFetchError",
            target: "idle",
          },
        },
      },
      refetching: {
        invoke: {
          src: "fetchData",
          onDone: {
            actions: ["assignData", "setEachChildData"],
            target: "idle",
          },
          onError: {
            actions: "assignFetchError",
            target: "idle",
          },
        },
      },
    },
  },
  {
    actions: {
      assignData: assign((_, event) => ({
        data: event.data,
      })),
      assignFetchError: assign((_, event) => ({
        error: event.data,
      })),
    },
  }
);

export type ReturnedCard = any & {
  data?: any;
  service: MachineService;
};

export interface ReturnedCards {
  [key: string]: ReturnedCard;
}

export interface UseCardsResult {
  data: any;
  cards: ReturnedCards;
  error: any;
  loading: boolean;
  showMissing: () => void;
  progress: {
    all: number;
    [key: string]: number;
  };
}

interface IdGroups {
  [key: string]: string[];
}

interface UseCardsOptions {
  ids: string[];
  cards: {
    [id: string]: {
      machine?: StateMachine<any, any, any, any>;
      mutatorProps?: {
        [key: string]: (...args: any) => Promise<any>;
      };
      additionalProps?: {
        [key: string]: any;
      };
      mapDataToProps?: (data: any) => { [key: string]: any };
      allowCard?: (data?: any) => boolean;
    };
  };
  progressGroups?: (data: any) => IdGroups;
  additionalCardProps?: {
    [key: string]: any;
  };
  fetchData: () => Promise<any>;
  transformData?: (data: any) => any;
}

export function useCards({
  ids,
  cards,
  fetchData,
  progressGroups = () => ({}),
  transformData = (data) => data,
  additionalCardProps = {},
}: UseCardsOptions) {
  const [state, send] = useMachine(
    CardsMachine,
    {
      context: {
        ids,
        cards: {},
        data: null,
        error: null,
      },
      actions: {
        setupCards: assign(({ data }) => {
          let firstIncompleteId: string;
          const transformedData = transformData(data);
          return {
            cards: ids.reduce((acc, id) => {
              const card = cards[id];
              const enableCard = card.allowCard || ((data: any) => true);
              const shouldAllowCard = enableCard(transformedData);
              if (shouldAllowCard) {
                const machine = card.machine || CardMachine();
                acc[id] = spawn(
                  machine.withContext({
                    id,
                    data: transformedData,
                    message: null,
                    expanded: false,
                    validateCard: null,
                  })
                );

                if (!firstIncompleteId && acc[id].state.context.message) {
                  firstIncompleteId = id;
                  acc[id].state.context.expanded = true;
                }
              }

              return acc;
            }, {} as AnyObject),
          };
        }),
        transformData: assign((context) => ({
          data: transformData(context.data),
        })),
        setEachChildData: (context) => {
          const transformedData = transformData(context.data);
          for (const id of context.ids) {
            const cardRef = context.cards[id];
            const enableCard = cards[id].allowCard || ((data: any) => true);
            const shouldAllowCard = enableCard(transformedData);
            if (shouldAllowCard && context.data) {
              cardRef.send({
                type: "SET_DATA",
                data: transformedData,
              });
            }
          }
        },
        expandFirstIncompleteCard: (context) => {
          let firstIncomplete;
          const transformedData = transformData(context.data);
          for (const id of context.ids) {
            const enableCard = cards[id].allowCard || ((data: any) => true);
            const shouldAllowCard = enableCard(transformedData);
            if (shouldAllowCard) {
              const cardRef = context.cards[id];
              const cardIsIncomplete = cardRef.state.context.message != null;
              if (!firstIncomplete && cardIsIncomplete) {
                firstIncomplete = id;
              }
              const isExpanded = cardRef.state.context.expanded;
              if (firstIncomplete === id && !isExpanded) {
                cardRef.send({ type: "SET_EXPANDED", expanded: true });
              } else if (firstIncomplete !== id && isExpanded) {
                cardRef.send({ type: "SET_EXPANDED", expanded: false });
              }
            }
          }
        },
      },
      services: {
        fetchData,
      },
    }
  );

  /*eslint-disable */
  const $data = useMemo(
    () => transformData(state.context.data),
    [state.context.data]
  );
  /*eslint-enable */

  const $cards = {} as ReturnedCards;

  const stats = {
    total: state.context.ids.filter((id) => {
      const enableCard = cards[id].allowCard || ((data: any) => true);
      return enableCard($data);
    }).length,
    complete: 0,
  };

  const cardCompleteMap = {} as { [key: string]: boolean };

  for (const id of ids) {
    const enableCard = cards[id].allowCard || ((data: any) => true);
    const shouldAllowCard = enableCard($data);

    if (shouldAllowCard) {
      const cardRef = state.context.cards[id];
      const isComplete = cardRef?.state.context.message == null;
      const cardMutators = cards[id].mutatorProps;
      const additionalProps = cards[id].additionalProps || {};
      const mapDataToProps = cards[id].mapDataToProps;

      cardCompleteMap[id] = false;

      if (isComplete) {
        stats.complete++;
        cardCompleteMap[id] = true;
      }

      $cards[id] = {
        service: cardRef,
      };

      if (cardMutators) {
        for (const mutatorName of Object.keys(cardMutators)) {
          const mutate = cardMutators[mutatorName];
          // @ts-ignore
          $cards[id][mutatorName] = async (...args: any[]) => {
            await mutate(...args);
            send({ type: "CARD_DATA_CHANGED" });
          };
        }
      }

      $cards[id].data = $data;

      if (mapDataToProps && $data) {
        $cards[id] = Object.assign({}, $cards[id], mapDataToProps($data));
      }

      $cards[id] = Object.assign(
        {},
        $cards[id],
        additionalCardProps,
        additionalProps
      );
    }
  }

  // Lets work out the progress for each group
  const idGroups = progressGroups($data);
  const progress = {} as any;
  const groupNames = Object.keys(idGroups);

  for (const key of groupNames) {
    const ids = idGroups[key];
    let complete = 0;
    const total = ids.filter((id) => {
      const enableCard = cards[id].allowCard || ((data: any) => true);
      return enableCard($data);
    }).length;

    for (const id of ids) {
      const enableCard = cards[id].allowCard || ((data: any) => true);
      const shouldAllowCard = enableCard($data);

      if (shouldAllowCard) {
        if (cardCompleteMap[id]) {
          complete++;
        }
      }
    }

    progress[key] = Math.ceil((complete / total) * 100);
  }

  progress.all = Math.ceil((stats.complete / stats.total) * 100);

  return {
    data: $data,
    cards: $cards as ReturnedCards,
    error: state.context.error,
    loading: state.matches("fetching"),
    progress,
    showMissing: () => {
      send({ type: "EXPAND_FIRST_INCOMPLETE" });
    },
  } as UseCardsResult;
}
