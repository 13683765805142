import React from "react";
import { Link, useLocation } from "wouter";
import { Button, Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const HelpLinkContainer = styled("p")(({ theme }) => ({
  fontSize: "0.95rem",
  marginBottom: theme.spacing(0),
}));

const LinkStyled = styled(Link)(({ theme }) => ({
  color: "inherit",
}));

export function LoginGettingStartedCard() {
  const setLocation = useLocation()[1];
  return (
    <div data-testid={LoginGettingStartedCard.name}>
      <Button
        sx={{ mb: 2 }}
        variant="contained"
        color="secondary"
        fullWidth
        children="Create a Profile"
        onClick={() => setLocation(`/registration${window.location.search}`)}
      />
      <Typography variant="h5" component="h2">
        How can I get started?
      </Typography>

      <Typography
        sx={{ color: "inherit", marginBottom: 2 }}
        variant="subtitle1"
        component="p"
      >
        Register with the IQVIA network and create a complementary profile in
        our network for full access to study sponsors and CROs who are seeking
        sites like yours. If you are already registered with IQVIA create your
        profile now!
      </Typography>

      <Divider sx={{ mb: 2, mt: 2 }} />

      <HelpLinkContainer>
        Need help?{" "}
        <LinkStyled href="/registration/contact-us">Contact us</LinkStyled>
      </HelpLinkContainer>
    </div>
  );
}
