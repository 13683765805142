import React from "react";
import { styled } from "@mui/material/styles";

import { lightGreen } from "@mui/material/colors";
import { Typography } from "@mui/material";

const PREFIX = "EmailReminder";

const classes = {
  container: `${PREFIX}-container`,
  message: `${PREFIX}-message`,
  email: `${PREFIX}-email`,
};

const Root = styled("div")(({ theme }) => {
  return {
    [`&.${classes.container}`]: {
      backgroundColor: "#766b88",
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    [`& .${classes.message}`]: {
      color: "white",
    },
    [`& .${classes.email}`]: {
      color: lightGreen[400],
      textDecoration: "none",
    },
  };
});

export function EmailReminder({ hasProfile }: any) {
  const message = hasProfile
    ? `Please let your invited delegate know that they should receive an email
    from IQVIA shortly that contains a link to access your profile.`
    : `Unfortunately, your delegate does not yet have a Profile set up in the system.
    We have sent an email to your delegate to prompt them to create their own User Profile,
    and once complete, they will be able to assist with your Profile. `;
  return (
    <Root className={classes.container}>
      <Typography variant="subtitle2" className={classes.message}>
        {message}
      </Typography>
      <Typography variant="subtitle2" className={classes.message}>
        If your delegate does not receive an email within 1 day, can you please
        suggest that they check their spam filter or contact us at:
        <a className={classes.email} href={"mailto:profile@drugdev.com"}>
          {" "}
          profile@drugdev.com{" "}
        </a>
      </Typography>
    </Root>
  );
}
