import React from "react";

import { Card } from "components/Card";
import { MutatorFn } from "hooks/useCards";
import { Phone, Profile } from "types/profile";
import { DeletionDialog } from "./DeletionDialog";
import { ContactEmailForm } from "./ContactEmailForm";
import { ContactPhoneForm } from "./ContactPhoneForm";
import { ContactPhoneEntries } from "./ContactPhoneEntries";
import { ContactEmailEntries } from "./ContactEmailEntries";
import { useEditableData, onlyDisallowCreate } from "hooks/useEditableData";
import { CardMachine, useCard, ManagedCardProps } from "hooks/useCard";
import { hasIncompletePhones } from "utils/contacts";

export const ContactCardMachine = CardMachine({ validateCard });

interface ContactCardProps extends ManagedCardProps {
  phones: Phone[];
  userPk?: number;
  isAdmin?: boolean;
  createPhone: MutatorFn;
  updatePhone: MutatorFn;
  deletePhone: MutatorFn;
  profileEmail: string;
  preferredEmail: string;
  updatePreferredEmail: MutatorFn;
  deletePreferredEmail: MutatorFn;
}

export function ContactCard({
  id = "contact-card",
  userPk,
  isAdmin = false,
  phones,
  service,
  createPhone,
  updatePhone,
  deletePhone,
  profileEmail,
  preferredEmail,
  updatePreferredEmail,
  deletePreferredEmail,
  ...otherProps
}: ContactCardProps) {
  const withReason = isAdmin;
  const { card } = useCard({ service });
  const phone = useEditableData({
    create: createPhone,
    update: updatePhone,
    delete: deletePhone,
  });

  const email = useEditableData({
    update: ({ preferredEmail }, adminComment) =>
      updatePreferredEmail(preferredEmail, adminComment),
    delete: deletePreferredEmail,
  });

  onlyDisallowCreate(email);

  return (
    <Card
      id={id}
      heading="Telephone Number & Email Address"
      arial-label="telephoneNumAndEmail"
      data-testid={ContactCard.name}
      {...card}
      {...otherProps}
    >
      <ContactPhoneEntries entries={phones} {...phone.details} />

      {phone.updateForm.open && (
        <ContactPhoneForm
          withReason={withReason}
          data-testid="ContactPhoneUpdateForm"
          {...phone.updateForm}
        />
      )}

      {phone.creationForm.open && (
        <ContactPhoneForm
          withReason={withReason}
          data-testid="ContactPhoneCreationForm"
          {...phone.creationForm}
        />
      )}

      {phone.deletionDialog.open && (
        <DeletionDialog
          withReason={withReason}
          data-testid="ContactPhoneDeletionDialog"
          {...phone.deletionDialog}
        />
      )}

      <ContactEmailEntries
        profileEmail={profileEmail}
        preferredEmail={preferredEmail}
        {...email.details}
      />

      {email.deletionDialog.open && (
        <DeletionDialog withReason={withReason} {...email.deletionDialog} />
      )}

      <ContactEmailForm
        email={preferredEmail}
        profileEmail={profileEmail}
        withReason={withReason}
        data-testid="ContactPreferredEmailForm"
        {...email.updateForm}
      />
    </Card>
  );
}

function validateCard({ phones }: Profile) {
  const hasPhones = !!phones.length;
  const isComplete = hasPhones && !hasIncompletePhones(phones);
  if (!isComplete) {
    return "Please add a telephone number";
  }
}
