import React from "react";

import { Card } from "components/Card";
import { values } from "rambda";
import { DepartmentCharacteristicsForm } from "./DepartmentCharacteristicsForm";

import {
  DepartmentProfileHookData,
  Profile,
} from "hooks/useFacilityDepartmentProfile";
import { CardMachine, ManagedCardProps, useCard } from "hooks/useCard";
import { LovItem, Characteristics, TherapeuticArea } from "types/facility";

export const DepartmentCharacteristicsCardMachine = CardMachine({
  validateCard,
});

interface DepartmentCharacteristicsCardProps extends ManagedCardProps {
  profile: Profile;
  facilityTypes: LovItem[];
  therapeuticAreas: LovItem[];
  updateCharacteristics: (characteristics: Characteristics) => Promise<any>;
  isForFacility?: boolean;
}

export function DepartmentCharacteristicsCard({
  id = "characteristics-card",
  service,
  profile,
  updateCharacteristics,
  therapeuticAreas,
  facilityTypes,
  isForFacility = false,
  ...otherProps
}: DepartmentCharacteristicsCardProps) {
  const { card } = useCard({ service });

  return (
    <Card
      id={id}
      heading={formatCardHeading(profile)}
      {...card}
      {...otherProps}
    >
      <DepartmentCharacteristicsForm
        entry={profile.characteristics}
        submit={updateCharacteristics}
        therapeuticAreas={therapeuticAreas}
        facilityTypes={facilityTypes}
        isForFacility={isForFacility}
      />
    </Card>
  );
}

function formatCardHeading(profile: Profile) {
  if (profile.departmentDetails) {
    return `Department Characteristics: ${profile.departmentDetails.departmentName} - ${profile.facilityDetails.name}`;
  } else {
    return `Facility Characteristics: ${profile.facilityDetails.name}`;
  }
}

function validateCard(data?: DepartmentProfileHookData) {
  const message =
    "Please tell us more details about your place or work (e.g. therapeutic areas)";

  if (!data) return message;

  const facilityTypes = data?.facilityTypes;
  const characteristics = data?.profile.characteristics;

  if (!isCharacteristicsComplete(facilityTypes, characteristics)) {
    return message;
  }
}

function isCharacteristicsComplete(
  facilityTypes: LovItem[],
  characteristics?: Characteristics
) {
  if (!characteristics) return false;
  if (!characteristics.type) return false;

  const typeIsOther = characteristicsTypeIsOther(
    facilityTypes,
    characteristics.type
  );

  if (typeIsOther && !characteristics.typeOther) {
    return false;
  }

  if (!characteristics.dedicatedResearchFacility) return false;

  if (!characteristics.govAffiliation) return false;

  if (
    !characteristics.hours.all &&
    characteristics.hours.evenings === null &&
    characteristics.hours.weekends === null
  ) {
    return false;
  }

  if (
    characteristics.languageCapabilities.hasSufficientEnglish === null ||
    characteristics.languageCapabilities.translatorSupportStudyConduct === null
  ) {
    return false;
  }

  if (!values(characteristics.phases).some((val) => val === true)) return false;
  if (!values(characteristics.experience).some(isSet)) return false;

  if (
    !therapeuticAreaValues(characteristics.therapeuticAreas).some(
      (val) => val === true
    )
  ) {
    return false;
  }

  return true;
}

function characteristicsTypeIsOther(
  facilityTypes: LovItem[],
  typeCode: number
) {
  const otherType = facilityTypes.find((t) =>
    t.name.toLocaleLowerCase().startsWith("other")
  )!;
  return otherType.code === typeCode.toString();
}

function therapeuticAreaValues(items: TherapeuticArea[]) {
  return items.map((item) => item.value);
}

function isSet(value: any) {
  return ![null, undefined, ""].includes(value);
}
