import React from "react";
import FormGroup from "@mui/material/FormGroup";

import { Study } from "types/profile";
import { useForm } from "hooks/useForm";
import { FormDialog } from "./FormDialog";
import { noop, asyncNoop } from "utils/noop";
import { StudyCommentSelectField } from "./StudyCommentSelectField";

import {
  ChangeReasonFormDialog,
  useChangeReasonFormDialog,
} from "./ChangeReasonFormDialog";

interface Props {
  title?: string;
  open?: boolean;
  entry?: Study;
  testId?: string;
  submit?: (study: Study) => Promise<any>;
  onCancel?: Callback;
  onSuccess?: Callback;
  withReason?: boolean;
  isInvestigator?: boolean;
  "data-testid"?: string;
}

export function StudyCommentForm({
  open,
  entry = {} as Study,
  title = "Update comment",
  "data-testid": testId = StudyCommentForm.name,
  submit: doSubmit = asyncNoop,
  onCancel: doCancel = noop,
  onSuccess = noop,
  withReason = false,
  ...otherProps
}: Props) {
  const reasonForm = useChangeReasonFormDialog({
    doSubmit,
    formHookSubmit,
    withReason,
  });

  const { id, fields, submit, reset, submitting } = useForm({
    id: "study-comment-form",
    fields: {
      id: {
        name: "id",
        value: entry.id,
      },
      comment: {
        name: "comment",
        value: entry.comment,
      },
    },
    submit: reasonForm.performSubmit,
    onReset: doCancel,
    onError: console.error,
    onSuccess,
  });

  function formHookSubmit() {
    return submit();
  }

  return (
    <>
      <FormDialog
        id={id}
        open={open}
        title={title}
        onCancel={reset}
        onSubmit={reasonForm.handleSubmitButtonClick}
        data-testid={testId}
        showProgressIndicator={submitting}
        maxWidth="sm"
        {...otherProps}
      >
        <FormGroup>
          <StudyCommentSelectField
            data-testid={`${testId}CommentField`}
            {...fields.comment}
          />
        </FormGroup>
      </FormDialog>
      <ChangeReasonFormDialog {...reasonForm.reasonDialog} />
    </>
  );
}
