import React from "react";
import specialties from "fixtures/specialties";

import { ErrorBar } from "./ErrorBar";
import { required } from "validators/required";
import { Specialty } from "types/profile";
import { FormDialog } from "./FormDialog";
import { styled } from "@mui/material/styles";
import { noop, asyncNoop } from "utils/noop";
import { useForm, SubmitFn } from "hooks/useForm";
import { hasSubspecialtyOptions } from "utils/specialty";
import { MedicalSpecialtySelectField } from "./MedicalSpecialtySelectField";
import { MedicalSubspecialtySelectField } from "./MedicalSubspecialtySelectField";

import {
  useChangeReasonFormDialog,
  ChangeReasonFormDialog,
} from "./ChangeReasonFormDialog";

const defaultSpecialty = {} as Specialty;

const FieldsetAligned = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

interface Props {
  title?: string;
  open?: boolean;
  entry?: Specialty;
  submit?: SubmitFn;
  onCancel?: Callback;
  onSuccess?: Callback;
  withReason?: boolean;
}

export function MedicalSpecialtyForm({
  open,
  entry,
  title = "Update medical specialty",
  submit: doSubmit = asyncNoop,
  onCancel: doCancel = noop,
  onSuccess = noop,
  withReason = false,
  ...otherProps
}: Props) {
  const $entry = entry || defaultSpecialty;

  const reasonForm = useChangeReasonFormDialog({
    doSubmit,
    formHookSubmit,
    withReason,
  });

  const { id, fields, submit, reset, submitting, error } = useForm({
    id: "medical-specialty-form",
    fields: {
      // The `id` field exists only for the purpose of identifying the entry that is being added updated
      id: {
        name: "id",
        value: $entry.subspecialtyCode || $entry.specialtyCode,
      },
      specialty: {
        id: "specialty",
        name: "specialty",
        value: $entry.specialtyCode,
        required: true,
        validate: required,
        fullWidth: true,
        dependantFields: ["subspecialty"],
      },
      subspecialty: {
        id: "subspecialty",
        name: "subspecialty",
        fullWidth: true,
        value: $entry.subspecialtyCode,
      },
    },
    onReset: doCancel,
    submit: reasonForm.performSubmit,
    // onError({ error }: any) {
    //   console.log(error.graphQLErrors[0].message);
    //   return error.graphQLErrors[0].message;
    // },
    onSuccess,
  });

  const testId =
    (otherProps as any)["data-testid"] || MedicalSpecialtyForm.name;

  const hasSubspecialties = hasSubspecialtyOptions(
    fields.specialty.value,
    specialties
  );

  function formHookSubmit() {
    return submit();
  }

  const duplicateSpecialty =
    (error as any)?.hasOwnProperty("graphQLErrors") &&
    (error as any)?.graphQLErrors[0].message
      .toLowerCase()
      .includes("duplicate");

  return (
    <>
      <FormDialog
        id={id}
        open={open}
        title={title}
        onCancel={reset}
        onSubmit={reasonForm.handleSubmitButtonClick}
        maxWidth="sm"
        data-testid={testId}
        showProgressIndicator={submitting}
        {...otherProps}
      >
        {duplicateSpecialty && <ErrorBar message="Specialty already added" />}
        <FieldsetAligned>
          <MedicalSpecialtySelectField {...fields.specialty} />
          {hasSubspecialties && (
            <MedicalSubspecialtySelectField
              specialty={fields.specialty.value}
              sx={{ ml: 1 }}
              {...fields.subspecialty}
            />
          )}
        </FieldsetAligned>
      </FormDialog>
      <ChangeReasonFormDialog {...reasonForm.reasonDialog} />
    </>
  );
}
