import React from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogProps,
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
const DialogActionsStyled = styled(DialogActions)(({ theme }) => ({
  display: "grid",
  padding: theme.spacing(2, 3),
  gridTemplateColumns: "auto 1fr auto",
}));

interface Props extends Omit<DialogProps, "open" | "children"> {
  open?: boolean;
  title?: string;
  onCancel?: Callback;
  onSubmit(...values: any[]): void;
  children?: ReactNodes;
  showProgressIndicator?: boolean;
  "data-testid"?: string;
}

export function FormDialog({
  id,
  open,
  title,
  children,
  onCancel,
  onSubmit,
  fullWidth = true,
  maxWidth = "md",
  showProgressIndicator,
  ...otherProps
}: Props) {
  const testId = otherProps["data-testid"] || "FormDialog";

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    onSubmit();
  }

  return (
    <Dialog
      open={Boolean(open)}
      aria-labelledby="form-dialog-title"
      data-testid={testId}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      {...otherProps}
    >
      <form
        id={id}
        aria-labelledby="form-dialog-title"
        onSubmit={handleSubmit}
        noValidate
      >
        {title && (
          <DialogTitle id="form-dialog-title" data-testid={`${testId}Title`}>
            {title}
          </DialogTitle>
        )}
        <DialogContent>{children}</DialogContent>
        <DialogActionsStyled>
          <Button onClick={onCancel} variant="outlined" children="Cancel" />
          <span/>
          <LoadingButton
            form={id}
            type="submit"
            loading={showProgressIndicator}
            loadingPosition="center"
            variant="contained"
            disabled={showProgressIndicator}
            children="Save"
          />
        </DialogActionsStyled>
      </form>
    </Dialog>
  );
}
