import React, { useEffect } from "react";
import { useForm, Field } from "hooks/useForm";
import { asyncNoop, noop } from "utils/noop";
import { FacilityFreezers } from "types/facility";
import { FormCheckboxField } from "./FormCheckboxField";
import { FormCheckboxFieldsGroup } from "./FormCheckboxFieldsGroup";

const defaultValue = {} as FacilityFreezers;

interface FacilityFreezerFieldProps {
  label?: string;
  error?: boolean;
  form: string;
  helperText?: string;
  onChange: (freezers: FacilityFreezers) => void;
  value?: FacilityFreezers;
  [key: string]: any;
}

export function FacilityFreezerField({
  label = "Which of these non-frost-free freezers do you use for biological sample storage?",
  error = false,
  value = defaultValue,
  helperText,
  onChange,
  form,
  ...otherProps
}: FacilityFreezerFieldProps) {
  const { fields, setField } = useForm({
    id: form,
    fields: {
      minus20ToMinus30: {
        type: "checkbox",
        name: "minus20ToMinus30",
        label: "-20 to -30°C",
        value: value.minus20ToMinus30,
      },
      minus70: {
        type: "checkbox",
        name: "minus70",
        label: "-70°C to -80°C",
        value: value.minus70,
      },
      minus135: {
        type: "checkbox",
        name: "minus135",
        label: "-135°C (Liquid nitrogen)",
        value: value.minus135,
      },
      none: {
        type: "checkbox",
        name: "none",
        label: "None",
        value:
          value.minus20ToMinus30 === false &&
          value.minus135 === false &&
          value.minus20ToMinus30 === false,
      },
    },
    submit: asyncNoop,
    onError: noop,
    onChange: (fields: any) => {
      onChange({
        minus20ToMinus30: fields.minus20ToMinus30.value,
        minus70: fields.minus70.value,
        minus135: fields.minus135.value,
      });
    },
  });

  useEffect(() => {
    for (const key of Object.keys(value)) {
      // @ts-ignore
      setField(key, value[key]);
    }

    if (
      value.minus135 === false &&
      value.minus70 === false &&
      value.minus20ToMinus30 === false
    ) {
      setField("none", true);
    }
  }, [JSON.stringify(value)]); // eslint-disable-line

  // FIXME: Doing this for now until we can address
  // to dynamically change the field values
  useEffect(() => {
    if (fields.none.value) {
      setField("minus20ToMinus30", false);
      setField("minus70", false);
      setField("minus135", false);
    }
  }, [setField, fields.none.value]);

  // FIXME: Doing this for now until we can address
  // to dynamically change the field values
  useEffect(() => {
    if (
      fields.minus20ToMinus30.value ||
      fields.minus70.value ||
      fields.minus135.value
    ) {
      setField("none", false);
    }
  }, [
    setField,
    fields.minus20ToMinus30.value,
    fields.minus70.value,
    fields.minus135.value,
  ]);

  return (
    <FormCheckboxFieldsGroup label={label} error={error} {...otherProps}>
      <CheckboxField {...fields.minus20ToMinus30} />
      <CheckboxField {...fields.minus70} />
      <CheckboxField {...fields.minus135} />
      <CheckboxField {...fields.none} />
    </FormCheckboxFieldsGroup>
  );
}

function CheckboxField(props: Field) {
  return (
    <FormCheckboxField
      sx={{
        root: { mr: 2 },
        checkbox: { pr: 1 },
      }}
      {...props}
    />
  );
}
