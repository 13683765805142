import { produce } from "immer"
import { useMachine } from "@xstate/react";
import { useCallback } from "react";
import { createMachine, assign } from "xstate";
import { noop, asyncNoop } from "utils/noop";

export type SubmitFn = (...values: any[]) => Promise<any>;

export interface Field {
  name: string;
  value?: any;
  defaultValue?: any;
  validate?(field: Field, fields: Context["fields"]): string | true | undefined;
  dependantFields?: string[];
  disabled?: boolean | Function;
  [key: string]: any;
}

export interface Fields {
  [key: string]: Field;
}

interface Context {
  fields: {
    [key: string]: Field;
  };
  error?: Error | null;
}

const FormMachine = createMachine<Context>(
  {
    initial: "editing",
    context: {
      fields: {},
      error: null,
    },
    states: {
      editing: {
        on: {
          SET_FIELD: {
            actions: ["setField", "clearDependantFields", "dispatchOnChange"],
          },
          SET_FIELDS: {
            actions: "setFields",
          },
          RESET: {
            actions: ["reset", "clearError", "onReset"],
          },
          SUBMIT: {
            target: "validating",
            actions: "clearError",
          },
        },
      },
      validating: {
        invoke: {
          id: "validation",
          src: "validate",
          onError: {
            actions: ["assignValidationErrors", "setTopLevelValidationError"],
            target: "editing",
          },
          onDone: {
            target: "submitting",
          },
        },
      },
      submitting: {
        invoke: {
          id: "submit",
          src: "submit",
          onDone: {
            actions: "onSuccess",
            target: "editing",
          },
          onError: {
            actions: ["onError", "setError"],
            target: "editing",
          },
        },
      },
    },
  },
  {
    actions: {
      setFields: assign((_, event) => ({
        fields: event.fields,
      })),
      setField: assign((context, event) =>
        produce(context, (draft: Context) => {
          draft.fields[event.name].value = event.value;
          draft.fields[event.name].error = false;
          draft.fields[event.name].helperText = "";
        })
      ),
      setError: assign((_, event) => ({
        error: event.data,
      })),
      setTopLevelValidationError: assign((_) => ({
        error: new ValidationError(),
      })),
      clearError: assign({ error: null } as any),
      assignValidationErrors: assign((context, event) => {
        return produce(context, (draft: Context) => {
          for (let [key, error] of event.data) {
            draft.fields[key].error = true;
            draft.fields[key].helperText = error;
          }
        });
      }),
      clearDependantFields: assign((context, event) => {
        const entry = Object.entries(context.fields).find(
          ([_, field]) => field.name === event.name
        );
        const dependantFields = (entry && entry[1].dependantFields) || [];
        if (!dependantFields.length) {
          return { fields: context.fields };
        }

        return {
          fields: fromEntries(
            Object.entries(context.fields).map(([fieldName, field]) => {
              if (dependantFields!.includes(field.name)) {
                const defaultValue = field.hasOwnProperty("defaultValue")
                  ? field.defaultValue
                  : "";
                return [fieldName, { ...field, value: defaultValue }];
              }
              return [fieldName, { ...field }];
            })
          ),
        };
      }),
    },
  }
);

function fromEntries(entries: [string, any][]) {
  return entries.reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {} as any);
}

const setupFields = produce((draft: any) => {
  for (let key of Object.keys(draft)) {
    if (draft[key].hasOwnProperty("defaultValue")) {
      draft[key].value =
        draft[key].value === undefined
          ? draft[key].defaultValue
          : draft[key].value;
    } else {
      draft[key].value = draft[key].value == null ? "" : draft[key].value;
    }
  }
});

const getValidations = produce((draft: any) => {
  let validators = [];
  for (let key of Object.keys(draft)) {
    let validate = draft[key].validate;
    if (validate) {
      validators.push([key, validate]);
    }
  }
  return validators;
});

interface Options {
  id: string;
  fields: Fields;
  submit?: SubmitFn;
  onSuccess?: Callback;
  onChange?: Callback;
  onError?: Function;
  onReset?(): void;
}

export function useForm({
  id,
  submit: doSubmit = asyncNoop,
  onError = noop,
  onSuccess = noop,
  onReset = noop,
  onChange = noop,
  ...options
}: Options) {
  const fields = setupFields(options.fields);
  const validations = getValidations(options.fields);
  const [state, send] = useMachine(FormMachine, {
    context: {
      fields: fields,
      error: null,
    },
    actions: {
      reset: assign(() => ({ fields })),
      onReset,
      onSuccess,
      onError: onError as any,
      dispatchOnChange: (context: any) => onChange(context.fields),
    },
    services: {
      async submit(context) {
        let values = Object.keys(context.fields).reduce((acc, key) => {
          acc[key] = context.fields[key].value;
          return acc;
        }, {} as { [key: string]: any });
        return doSubmit(values);
      },
      async validate(context) {
        let errors = [];
        for (let [key, validator] of validations) {
          let result = await validator(context.fields[key], context.fields);
          if (result && result !== true) {
            errors.push([key, result]);
          }
        }
        return errors.length ? Promise.reject(errors) : true;
      },
    },
  });

  function handleChange(name: string) {
    return (event: React.FormEvent<HTMLInputElement>) => {
      const target = event?.target as HTMLInputElement;
      const value = !target
        ? event
        : target.type === "checkbox"
        ? target.checked
        : target.value;

      send({ type: "SET_FIELD", name, value });
    };
  }

  const finalFields = (fields: Fields) => {
    return produce(fields, (draft: any) => {
      for (let key of Object.keys(draft)) {
        const name = draft[key].name;
        const field = Object.values(fields).find(
          (v) => v.name === name
        ) as Field;

        draft[key].onChange = handleChange(name);

        if (draft[key].type === "checkbox") {
          draft[key].checked = draft[key].value || false;
        }

        if (field.dependantFields && field.dependantFields.length) {
          delete draft[key].dependantFields;
        }

        if (
          fields[key].disabled &&
          typeof fields[key].disabled === "function"
        ) {
          draft[key].disabled = (fields[key]!.disabled as any)(fields);
        }

        if (id) {
          draft[key].id = `${id}-${name}`;
        }

        delete draft[key].defaultValue;
        delete draft[key].dependantFields;
        delete draft[key].validate;
      }
    });
  };

  const setField = useCallback(
    (name: string, value: any) => {
      send({ type: "SET_FIELD", name, value });
    },
    [send]
  );

  return {
    id,
    error: state.context.error,
    submitting: state.matches("validating") || state.matches("submitting"),
    fields: finalFields(state.context.fields) as { [any: string]: any },
    submit() {
      send("SUBMIT");
    },
    reset() {
      send("RESET");
    },
    setField,
  };
}

export class ValidationError extends Error {
  type = "validation";
  constructor(
    message = "The form contains validation errors",
    constructorOpt = ValidationError
  ) {
    super(message);
    this.name = this.constructor.name;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, constructorOpt);
    }
  }
}
