import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { Field } from "hooks/useForm";

export function formattedDate(date: Date | string | null | undefined) {
  if (!date) return;
  const dateToUse = typeof date === "string" ? parseISO(date) : date;
  return format(dateToUse, "MMM d, yyyy");
}

export function isDateValid({ value }: Field) {
  // eslint-disable-next-line
  return value?.getTime() !== value?.getTime() || value?.getYear() < 0
    ? "Invalid Date"
    : undefined;
}
