import React from "react";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import MuiTableCell from "@mui/material/TableCell";
import MuiTableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MuiTableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";

import { TableRowProps } from "@mui/material/TableRow";
import { TableBodyProps } from "@mui/material/TableBody";
import { TableCellProps as MuiTableCellProps } from "@mui/material/TableCell";

interface TablePaneProps {
  heading?: string;
  children?: ReactNodes;
}

export function TablePane({
  heading,
  children,
  ...otherProps
}: TablePaneProps) {
  return (
    <Box sx={{ width: 1 }} {...otherProps}>
      {heading && (
        <Typography
          sx={{ fontSize: 15, mb: 1 }}
          component="h4"
          variant="h6"
          children={heading}
          noWrap
          {...otherProps}
        />
      )}
      {children}
    </Box>
  );
}

interface TablePaneFooterProps {
  children?: ReactNodes;
}

export function TablePaneFooter({ children }: TablePaneFooterProps) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        pt: 2,
      }}
    >
      {children}
    </Box>
  );
}

interface TablePaneButtonProps {
  icon?: any;
  label: string;
  onClick?: Callback;
  disabled?: boolean;
}

export function TablePaneButton({
  label,
  icon: Icon,
  ...otherProps
}: TablePaneButtonProps) {
  return (
    <Button {...otherProps}>
      {Icon && <Icon sx={{ mr: 1 }} />}
      {label}
    </Button>
  );
}

interface TableActionsCellProps {
  children?: ReactNodes;
}

export function TableActionsCell({
  children,
  ...otherProps
}: TableActionsCellProps) {
  return (
    <MuiTableCell {...otherProps}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          "& > *:not(:first-of-type)": {
            ml: 1,
          },
        }}
      >
        {children}
      </Box>
    </MuiTableCell>
  );
}

export function TableEditActionButton(props: any) {
  return (
    <IconButton aria-label="Edit" size="small" {...props}>
      <EditIcon fontSize="inherit" />
    </IconButton>
  );
}

export function TableDeleteActionButton(props: any) {
  return (
    <IconButton aria-label="Delete" size="small" {...props}>
      <DeleteIcon fontSize="inherit" />
    </IconButton>
  );
}

export function TableBody(props: TableBodyProps) {
  return <MuiTableBody data-testid="TableBody" {...props} />;
}

export function TableBodyRow(props: TableRowProps) {
  return <MuiTableRow data-testid="TableBodyRow" {...props} />;
}

export function TableHeadRow(props: TableRowProps) {
  return <MuiTableRow data-testid="TableHeadRow" {...props} />;
}

interface TableCellProps extends MuiTableCellProps {
  "data-testid"?: string;
  children?: ReactNodes;
}

export function TableCell({
  "data-testid": testId = "TableCell",
  ...otherProps
}: TableCellProps) {
  return <MuiTableCell data-testid={testId} {...otherProps} />;
}

interface TableHeadCellProps {
  children: ReactNodes;
  "data-testid"?: string;
}

export function TableHeadCell({
  children,
  "data-testid": testId,
  ...otherProps
}: TableHeadCellProps) {
  return (
    <TableCell
      variant="head"
      sx={{ color: (theme) => theme.palette.grey[600] }}
      data-testid={testId}
      {...otherProps}
    >
      {children}
    </TableCell>
  );
}
