import React from "react";

import { Card } from "components/Card";
import { values } from "rambda";
import { toPopulationValuesObj } from "utils/department";
import { PatientPopulation } from "types/facility";
import { DepartmentPatientPopulationForm } from "./DepartmentPatientPopulationForm";
import { CardMachine, ManagedCardProps, useCard } from "hooks/useCard";

import {
  Profile,
  DepartmentProfileHookData,
} from "hooks/useFacilityDepartmentProfile";

interface DepartmentPatientPopulationCardProps extends ManagedCardProps {
  profile: Profile;
  updatePatientPopulation: (data: PatientPopulation) => Promise<any>;
}

export const DepartmentPatientPopulationCardMachine = CardMachine({
  validateCard,
});

export function DepartmentPatientPopulationCard({
  id = "patient-population-card",
  service,
  profile,
  updatePatientPopulation,
  ...otherProps
}: DepartmentPatientPopulationCardProps) {
  const { card } = useCard({ service });

  return (
    <Card
      id={id}
      heading={formatCardHeading(profile)}
      {...card}
      {...otherProps}
    >
      <DepartmentPatientPopulationForm
        entry={profile.patientPopulation}
        submit={updatePatientPopulation}
        facility={profile.facilityDetails}
      />
    </Card>
  );
}

function formatCardHeading(profile: Profile) {
  if (profile.departmentDetails) {
    return `Patient Population: ${profile.departmentDetails.departmentName} - ${profile.facilityDetails.name}`;
  } else {
    return `Patient Population: ${profile.facilityDetails.name}`;
  }
}

function validateCard(data?: DepartmentProfileHookData) {
  const patientPopulation = data?.profile.patientPopulation;
  const isComplete = Boolean(
    patientPopulation &&
      values(toPopulationValuesObj(patientPopulation?.ageGroups)).some(
        (val) => val === true
      ) &&
      values(toPopulationValuesObj(patientPopulation?.ethnicities)).some(
        (val) => val === true
      )
  );

  if (!isComplete) {
    return "Please tell us more about your patient population";
  }
}
