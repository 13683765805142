let studyPhase = [
  { value: "1", label: "Phase I" },
  { value: "1or2", label: "Phase I/II" },
  { value: "2", label: "Phase II" },
  { value: "2or3", label: "Phase II/III" },
  { value: "3", label: "Phase III" },
  { value: "3or4", label: "Phase III/IV" },
  { value: "4", label: "Phase IV" },
];

export default studyPhase;
