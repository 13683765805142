type Check = {
  key: string;
  predicate: boolean;
  message: string;
};

export function validator(...results: Check[]) {
  let errors = [];

  for (let { key, predicate, message } of results) {
    if (predicate) errors.push([key, message]);
  }

  return {
    errors,
    get hasErrors(): boolean {
      return errors.length !== 0;
    },
  };
}
