import React from "react";
import CV from "./CV";
import Login from "./Login";
import Admin from "./admin";
import Profiles from "./Profiles";
import Delegates from "./Delegates";
import ContactUs from "./ContactUs";
import Facilities from "./facilities";
import DeclineInvitation from "./ExternalDeclineInvitation";
import DefaultLayout from "layouts/DefaultLayout";
import OptIn from "./OptIn";
import ProfileAdmin from "./admin/profile/id";
import DelegatesAdmin from "./admin/delegates/id";
import FacilityProfile from "./facilities/id";
import DepartmentProfile from "./facilities/department/id";
import AdminFacilityProfile from "./admin/profile/facilities/id";
import AdminDepartmentProfile from "./admin/profile/facilities/department/id";
import RegistrationContactUs from "./registration/ContactUs";
import DelegateProfile from "./DelegateProfile";
import { ConfirmOptIn } from "./ConfirmOptIn";
import { useAuth } from "../hooks/useAuth";
import { ProtectedRoute } from "./auth";
import { RegistrationLayout } from "layouts/RegistrationLayout";
import { Route, Switch, Redirect } from "wouter";
import { CoreDataProvider } from "hooks/useCoreData";
import { LinearProgress } from "@mui/material";

export enum ROLES {
  ADMIN = "ADMIN",
  INVESTIGATOR = "INVESTIGATOR",
  SITE_STAFF = "SITE_STAFF",
}

type RoutesForRolesProps = {
  role: ROLES;
};

function RoutesForRoles({ role }: RoutesForRolesProps) {
  switch (role) {
    case ROLES.ADMIN:
      return (
        <Switch>
          <Route path="/">
            <Redirect to="/admin" />
          </Route>
          <Route path="/admin" component={Admin} />
          <Route path="/admin/profile/:id" component={ProfileAdmin} />
          <Route
            path="/admin/profile/:profileId/facilities/:facilityId"
            component={AdminFacilityProfile}
          />
          <Route
            path="/admin/profile/:profileId/facilities/:facilityId/department/:departmentId"
            component={AdminDepartmentProfile}
          />
          <Route path="/delegates/:id">
            {(params) => <DelegatesAdmin userPk={params.id} />}
          </Route>
          <Route path="/:rest*">
            <Redirect to="/admin" />
          </Route>
        </Switch>
      );
    case ROLES.SITE_STAFF:
    case ROLES.INVESTIGATOR:
      return (
        <Switch>
          <Route path="/optin">
            <RegistrationLayout>
              <CoreDataProvider>
                <OptIn />
              </CoreDataProvider>
            </RegistrationLayout>
          </Route>
          <Route path="/:rest*">
            <DefaultLayout>
              <CoreDataProvider>
                <Switch>
                  <Route path="/" component={Profiles} />
                  <Route path="/confirm/:id" component={ConfirmOptIn} />
                  <Route path="/cv" component={CV} />
                  <Route path="/facilities" component={Facilities} />
                  <Route
                    path="/facilities/:facilityId"
                    component={FacilityProfile}
                  />
                  <Route
                    path="/facilities/:facilityId/department/:departmentId"
                    component={DepartmentProfile}
                  />
                  <Route path="/contact-us" component={ContactUs} />
                  <Route path="/delegates">{(params) => <Delegates />}</Route>
                  <Route
                    path="/delegates/profile/:id"
                    component={DelegateProfile}
                  />
                  <Route path="/delegates/accept/:token">
                    {(params) => (
                      <Delegates token={params.token} invitationType="ACCEPT" />
                    )}
                  </Route>
                  <Route path="/decline-invitation/:token">
                    {(params) => (
                      <Delegates
                        token={params.token}
                        invitationType="DECLINE"
                      />
                    )}
                  </Route>
                  <Route path="/:rest*">
                    <Redirect to="/" />
                  </Route>
                </Switch>
              </CoreDataProvider>
            </DefaultLayout>
          </Route>
        </Switch>
      );
    default:
      return <div></div>;
  }
}

export function Routes() {
  let auth = useAuth();
  switch (auth.state) {
    case "registeringSsoNonUser":
      return (
        <RegistrationLayout>
          <CoreDataProvider>
            <Switch>
              <Route path="/login" component={Login}/>
              <Route
                path="/registration/contact-us"
                component={RegistrationContactUs}
              />
              <Route path="/:rest*">
                <Redirect to="/login" />
              </Route>
            </Switch>
          </CoreDataProvider>
        </RegistrationLayout>
      );
    case "unauthorised":
      return (
        <RegistrationLayout>
          <CoreDataProvider>
            <Switch>
              <Route path="/login" component={Login} />
              <Route
                path="/registration/contact-us"
                component={RegistrationContactUs}
              />
              <Route path="/:rest*">
                <Redirect to="/login" />
              </Route>
            </Switch>
          </CoreDataProvider>
        </RegistrationLayout>
      );
    case "loggedIn":
      return (
        <ProtectedRoute path="/:any*">
          <RoutesForRoles role={auth?.user?.role} />
        </ProtectedRoute>
      );
    case "loggedOut":
      return (
        <RegistrationLayout>
          <CoreDataProvider>
            <Switch>
              <Route path="/login" component={Login} />
              <Route
                path="/registration/contact-us"
                component={RegistrationContactUs}
              />
              <Route path="/login" component={Login} />
              <Route
                path="/decline-invitation/:token"
                component={DeclineInvitation}
              />
              <Route
                path="/:rest*"
                component={() => (
                  <Redirect
                    to={`/login?redirect=${window.location.pathname}`}
                  />
                )}
              />
            </Switch>
          </CoreDataProvider>
        </RegistrationLayout>
      );
    default:
      return <LinearProgress aria-label="Authorising user" />;
  }
}
