import Link from "@mui/material/Link";
import React from "react";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { styled } from "@mui/material/styles";

import {
  TablePane,
  TableActionsCell,
  TableEditActionButton,
  TableDeleteActionButton,
  TablePaneFooter,
  TablePaneButton,
} from "./Table";
import { noop } from "utils/noop";

const PREFIX = "ContactEmailEntries";

const classes = {
  note: `${PREFIX}-note`,
  noteContainer: `${PREFIX}-noteContainer`,
};

const TablePaneRoot = styled(TablePane)(({ theme }) => ({
  [`& .${classes.note}`]: {
    fontSize: 12,
    color: "#999",
    marginTop: 3,
  },

  [`& .${classes.noteContainer}`]: {
    display: "flex",
    alignItems: "center",
    "& > *:not(:first-of-type)": {
      marginLeft: theme.spacing(1),
    },
  },
}));

interface Props {
  onEdit?: Callback;
  profileEmail?: string;
  preferredEmail?: string;
  onUpdateButtonClick?: Callback;
  onDeleteButtonClick?: Callback;
}

export function ContactEmailEntries({
  onEdit,
  profileEmail,
  preferredEmail,
  onUpdateButtonClick = noop,
  onDeleteButtonClick = noop,
  ...otherProps
}: Props) {
  const entries = [
    {
      type: "Profile",
      email: profileEmail,
      isDefault: preferredEmail ? false : true,
      isEditable: false,
      testId: "ProfileEmailRow",
    },
    {
      type: "Preferred",
      email: preferredEmail,
      isDefault: true,
      isEditable: true,
      testId: "PreferredEmailRow",
    },
  ].filter((entry) => !!entry.email);

  return (
    <TablePaneRoot heading="Email">
      <Table data-testid={ContactEmailEntries.name}>
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Email address </TableCell>
            <TableCell>Default contact address?</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map((entry, i) => (
            <TableRow key={i} data-testid={entry.testId}>
              <TableCell>{entry.type}</TableCell>
              <TableCell>{entry.email}</TableCell>
              <TableCell>
                {entry.isDefault ? (
                  "Yes"
                ) : (
                  <div className={classes.noteContainer}>
                    <div>No</div>
                    <div className={classes.note}>
                      (If you need to change your profile email address please{" "}
                      <Link href="/contact-us" underline="hover">Contact us</Link>)
                    </div>
                  </div>
                )}
              </TableCell>
              <TableActionsCell>
                {entry.isEditable && (
                  <>
                    <TableEditActionButton
                      onClick={() => onUpdateButtonClick()}
                    />
                    <TableDeleteActionButton
                      onClick={() => onDeleteButtonClick()}
                    />
                  </>
                )}
              </TableActionsCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePaneFooter>
        {!preferredEmail && (
          <TablePaneButton
            icon={AddCircleIcon}
            label="Add preferred contact email address"
            data-testid="AddPreferredEmailButton"
            onClick={() => onUpdateButtonClick()}
          />
        )}
      </TablePaneFooter>
    </TablePaneRoot>
  );
}
