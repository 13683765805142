import React from "react";
import phoneTypes from "../fixtures/phoneTypes";

import {
  AutocompleteField,
  AutocompleteInstanceFieldProps,
} from "./AutocompleteField";

export function PhoneTypeSelectField({
  "data-testid": testId = PhoneTypeSelectField.name,
  ...props
}: AutocompleteInstanceFieldProps) {
  return (
    <AutocompleteField
      id="type"
      name="type"
      label="Type"
      options={phoneTypes}
      {...props}
    />
  );
}
