import React from "react";
import { useRoute } from "wouter";

import { FacilityProfile } from "routes/facilities/id";
import { AppBar } from "layouts/AppBar";
import { Content } from "layouts/Content";

export default function AdminFacilityProfile() {
  // eslint-disable-next-line
  const [_, params] = useRoute(
    "/admin/profile/:profileId/facilities/:facilityId"
  );

  const menuItems = [
    {
      title: "Search",
      link: "/admin",
      selected: false,
      ariaLabel: "Link to admin search",
    },
    {
      title: "Profile",
      link: `/admin/profile/${params?.profileId}`,
      selected: true,
      ariaLabel: "Link to user profile",
    },
    {
      title: "Delegates",
      link: `/delegates/${params?.profileId}`,
      selected: false,
      ariaLabel: "Link to users delegates",
    },
  ];

  return (
    <>
      <AppBar showNav={true} menuItems={menuItems} />
      <Content>
        <FacilityProfile facilityId={parseInt(params?.facilityId!)} />
      </Content>
    </>
  );
}
