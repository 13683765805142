import React from "react";
import countryCodes from "../fixtures/phoneCountryCodes";

import {
  AutocompleteField,
  AutocompleteInstanceFieldProps,
} from "./AutocompleteField";

const options = countryCodes.map(([key, value]) => ({
  value: key,
  label: value,
}));

export function PhoneCountryCodeSelectField({
  "data-testid": testId = PhoneCountryCodeSelectField.name,
  ...props
}: AutocompleteInstanceFieldProps) {
  return (
    <AutocompleteField
      id="countryCode"
      name="countryCode"
      label="Country code"
      options={options}
      {...props}
    />
  );
}
