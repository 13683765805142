import React from "react";
import { styled } from "@mui/material/styles";

import {
  MenuItem,
  ListSubheader,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import type { SelectChangeEvent } from "@mui/material";
import { Facility } from "types/profile";

interface FieldPrimaryLocationSelectProps {
  facilities: Facility[];
  value: { name: string; facilityId: number };
  onChange: Callback;
}

const SubMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  "&.MuiMenuItem-root": {
    paddingLeft: theme.spacing(4),
  },
}));

// MUI Select in combination with Typescript has issues when working
// with dynamic arrays which requires the JSON stringify/parse as a workaround
export function FieldPrimaryLocationSelect({
  facilities,
  value,
  onChange,
}: FieldPrimaryLocationSelectProps) {
  return (
    <FormControl sx={{ mt: 1 }} fullWidth>
      <InputLabel id="primary-location">
        Primary location for the conduct of clinical research
      </InputLabel>
      <Select
        size="small"
        value={JSON.stringify(value)}
        labelId="primary-location"
        id="primary-location-select"
        label="Primary location for the conduct of clinical research"
        onChange={(event: SelectChangeEvent<string>) => {
          onChange({
            ...(JSON.parse(event.target.value) as typeof value),
          });
        }}
      >
        <MenuItem value={JSON.stringify({ name: "", facilityId: null }) as any}>
          No Primary Location
        </MenuItem>
        {facilities.map((facility) => {
          if (facility.departments.length > 0) {
            let departmentItems = facility.departments.map((department) => {
              let departmentValue = {
                name: department.name,
                facilityId: facility.id,
              };
              return (
                <SubMenuItem
                  key={department.name}
                  value={JSON.stringify(departmentValue)}
                >
                  {department.name}
                </SubMenuItem>
              );
            });

            return [
              <ListSubheader key={facility.name}>
                {facility.name}
              </ListSubheader>,
              ...departmentItems,
            ];
          }
          let facilityValue = { name: facility.name, facilityId: facility.id };
          return (
            <MenuItem key={facility.name} value={JSON.stringify(facilityValue)}>
              {facility.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
