import React from "react";
import { Link, Alert, Box } from "@mui/material";

import { Card } from "components/Card";
import { useAuth } from "hooks/useAuth";
import { createMachine } from "xstate";
import { useMachine } from "@xstate/react";
import { styled } from "@mui/material/styles";
import { FieldMessage } from "components/FieldMessage";
import { DeletionDialog } from "./DeletionDialog";
import { useContactTypes } from "hooks/useContactTypes";
import { useEditableData } from "hooks/useEditableData";
import { Link as WouterLink } from "wouter";
import { DepartmentContactsTable } from "./DepartmentContactsTable";
import { DepartmentContactAdditionForm } from "./DepartmentContactAdditionForm";
import { ProfileContact, FacilityContact } from "types/facility";
import {
  DepartmentProfileHookData,
  Profile,
} from "hooks/useFacilityDepartmentProfile";
import { ManagedCardProps, CardMachine, useCard } from "hooks/useCard";

export const DepartmentContactsCardMachine = CardMachine({ validateCard });

const InnerContainer = styled("div")(({ theme }) => ({
  "& > *:not(:first-child)": {
    marginTop: theme.spacing(5),
  },
}));

interface DepartmentContactsCardProps extends ManagedCardProps {
  profile: Profile;
  formatCardHeading?: (entry: any) => string;
  deleteContact: (
    contact: ProfileContact,
    adminComment?: string
  ) => Promise<any>;
  createContact: (facilityContact: FacilityContact) => Promise<any>;
  createSelfContact: (facilityContact: FacilityContact) => Promise<any>;
}

export function DepartmentContactsCard({
  id = "contacts-card",
  profile,
  service,
  deleteContact,
  createContact,
  createSelfContact,
  ...otherProps
}: DepartmentContactsCardProps) {
  const { isAdmin } = useAuth();
  const { card } = useCard({ service });
  const { user } = useAuth();
  const { getContactTypeLabel } = useContactTypes();
  const invitation = useInvitationNote({ submitInvitation: createContact });
  const contact = useEditableData({
    delete: deleteContact,
  });

  return (
    <Card
      id={id}
      heading={formatCardHeading(profile)}
      subHeading={
        <>
          If you need to remove a person from this list please{" "}
          <WouterLink href="/contact-us">
            <Link underline="hover">contact us</Link>
          </WouterLink>
        </>
      }
      hideSubHeadingDuringWarning
      {...card}
      {...otherProps}
    >
      <InnerContainer>
        {Boolean(profile.contacts.length) && (
          <DepartmentContactsTable
            entries={profile.contacts}
            getContactTypeLabel={getContactTypeLabel}
            {...contact.details}
          />
        )}

        {!isAdmin && (
          <>
            <DepartmentContactAdditionForm
              id="contact-self-addition-form"
              title="Would you like to add yourself as a contact?"
              email={user.email}
              emailFieldDisabled
              submitButtonLabel="Add me to the list"
              submit={createSelfContact}
            />

            <InvitationSentNotification {...invitation.note} />

            <FieldMessage
              variant="warning"
              message="Sending an invitation will generate an email to the address listed above. The email recipient, once logged in, will be able to join the facility or department."
            >
              <DepartmentContactAdditionForm
                id="contact-invitation-form"
                title="Would you like to invite a colleague to register as a contact?"
                submitButtonLabel="Invite colleague"
                {...invitation.form}
              />
            </FieldMessage>
          </>
        )}
      </InnerContainer>

      {contact.deletionDialog.open && (
        <DeletionDialog withReason={isAdmin} {...contact.deletionDialog} />
      )}
    </Card>
  );
}

interface InvitationSentNotificationProps {
  open?: boolean;
}

function InvitationSentNotification({
  open = false,
}: InvitationSentNotificationProps) {
  if (!open) return null;
  return (
    <Alert severity="info" icon={false}>
      <div>
        <Box component="p" sx={{ mb: 0, mt: 0 }}>
          Please let your invited contact know that they should receive an email
          from IQVIA shortly that contains instructions as to how to add
          themselves as a contact for facility or department.
        </Box>
        <Box component="p" sx={{ mb: 0, mt: 1 }}>
          If your colleague does not receive an email within 1 day, can you
          please suggest that they check their spam filter or contact us at:{" "}
          <Link href="mailto:profile@drugdev.com" underline="hover">
          profile@drugdev.com
          </Link>
        </Box>
      </div>
    </Alert>
  );
}

const InvitationNoteMachine = createMachine({
  initial: "closed",
  context: {},
  states: {
    closed: {
      on: {
        OPEN: "opened",
      },
    },
    opened: {
      on: {
        CLOSE: "closed",
      },
    },
  },
});

interface UseInvitationNoteProps {
  submitInvitation: (invitation: any) => any;
}

function useInvitationNote({ submitInvitation }: UseInvitationNoteProps) {
  //@ts-ignore
  const [state, send] = useMachine(InvitationNoteMachine);
  const submit = async (invitation: any) => {
    send({ type: "CLOSE" });
    return submitInvitation(invitation);
  };
  return {
    form: {
      submit,
      onSuccess: () => {
        send({ type: "OPEN" });
      },
    },
    note: {
      open: state.matches("opened"),
    },
  };
}

function formatCardHeading(profile: Profile) {
  if (profile.departmentDetails) {
    return `Department Contacts: ${profile.departmentDetails.departmentName} - ${profile.facilityDetails.name}`;
  } else {
    return `Facility Contacts: ${profile.facilityDetails.name}`;
  }
}

function validateCard(data?: DepartmentProfileHookData) {
  if (!data?.profile.contacts.length && !data?.isAdmin) {
    return "Please add yourself and others as a contact";
  }
}
