//@ts-nocheck
import gql from "graphql-tag";
import React from "react";
import { useMachine } from "@xstate/react";
import { useLocation } from "wouter";
import { useApolloClient } from "@apollo/client";
import { KeycloakInstance } from "keycloak-js";
import { assign, StateValue } from "xstate";
import { AuthenticationMachine } from "../machines/authentication";
import { createContext, useContext } from "react";
import { useConfig } from "providers/Config";

interface AuthState {
  token: string | null;
  state: StateValue;
  user: any;
  isAdmin: boolean;
  isDelegate: boolean;
  setDelegateStatus: (status: boolean) => void;
  login: (url?: string | null) => void;
  logout: () => void;
  isLoggedIn: boolean;
  isLoginPage: boolean;
  loginPagePath: string;
  isAuthenticating: boolean;
  isRegisteringSsoNonUser: boolean;
  ssoUser: any;
  isOneHomeBannerOpen: boolean;
  hideOneHomeBanner: () => void;
  goToOneHome: () => void;
}

const AuthContext = createContext<Partial<AuthState>>({});

interface AuthProviderProps {
  loginPagePath?: string;
  children?: ReactNodes;
  refreshTokenTime?: number;
  client: KeycloakInstance;
}

export const USER_QUERY = gql`
  query getUserForAuth {
    user {
      firstName
      lastName
      email
      role
    }
  }
`;

export const OHFS_USER_TOKEN = gql`
  query {
    ohfsUserToken
  }
`;

// TODO: Tidy up
async function makeQuery(client, query) {
  let response = await client.query({ query: query, errorPolicy: "all" });
  if (response.errors) {
    throw response;
  }
  return response.data;
}

export function AuthProvider({
  loginPagePath = "/login",
  children,
  client,
  refreshTokenTime = 10000,
}: AuthProviderProps) {
  const { config } = useConfig();
  const gqlClient = useApolloClient();
  const [, setLocation] = useLocation();
  const [state, send] = useMachine(AuthenticationMachine, {
    context: {
      token: localStorage.getItem("authToken"),
      url: `${window.location.origin}${loginPagePath}`,
      refreshTokenTime: refreshTokenTime,
      isdelegate: false,
      isOneHomeBannerOpen: config?.ohfsRedirect,
      oneHomeUrl: config?.ohfsUrl,
    },
    actions: {
      authenticated: assign({
        token() {
          localStorage.setItem("authToken", client.token!);
          return client.token || null;
        },
        ssoUser: (_context, { data }) => data || _context?.ssoUser,
      }),
      setUser: assign({
        user(_context, { data }) {
          return data.user;
        },
      }),
      setDelegateStatus: assign({
        isDelegate(_context, { status }) {
          return status;
        },
      }),
      getToRegistrationPage: () => {
        setLocation("/registration");
      },
      hideOneHomeBanner: assign({
        isOneHomeBannerOpen: false,
      }),
      redirectToOneHome: (context, event) => {
        context.oneHomeUrl &&
          event.data.ohfsUserToken &&
          window.open(
            `${context.oneHomeUrl}/register?token=${event.data.ohfsUserToken}`,
            "_blank"
          );
      },
    },
    guards: {
      isAdmin(_context, event) {
        return event.data.data.user?.role === "ADMIN";
      },
      hasNoUser(_context, event) {
        return !event.data.data.user;
      },
    },
    services: {
      async authorise() {
        return makeQuery(gqlClient, USER_QUERY);
      },
      login(_, { redirect }) {
        let params = new URLSearchParams(window.location.search);
        return client.login({
          redirectUri: `${window.location.origin}${
            redirect
              ? redirect
              : window.location.search
              ? params.has("redirect")
                ? params.get("redirect")
                : "/"
              : "/"
          }`,
        });
      },
      logout({ url }) {
        localStorage.removeItem("authToken");
        return client.logout({
          redirectUri: `${url}?redirect=${window.location.pathname}`,
        });
      },
      async authenticate({ token }) {
        let options = {
          flow: "standard",
          onLoad: token !== null && "login-required",
          checkLoginIframe: process.env.NODE_ENV === "production",
        };

        let loggedIn = await client.init(options);

        if (loggedIn) {
          let user = await client.loadUserInfo();
          return user;
        } else {
          throw new Error("Not authenticated");
        }
      },
      async requestOneHomeUserToken() {
        return makeQuery(gqlClient, OHFS_USER_TOKEN);
      },
    },
  });
  sessionStorage.setItem("authorization", state.value);
  window.kc = client;
  client.onTokenExpired = () => {
    client.updateToken(10);
  };
  client.onAuthRefreshSuccess = () => {
    send("SET_TOKEN");
  };
  client.onAuthRefreshError = () => {
    send("LOGOUT");
  };
  return (
    <AuthContext.Provider
      value={{
        state: state.value,
        user: state.context.user,
        isAdmin: state.context.user
          ? state.context.user.role === "ADMIN"
          : false,
        isDelegate: state.context.isDelegate,
        ssoUser: state.context.ssoUser,
        token: state.context.token,
        client: client,
        isOneHomeBannerOpen: state.context.isOneHomeBannerOpen,
        setDelegateStatus(status) {
          send({ type: "TOGGLE_DELEGATE", status });
        },
        login(redirect = null) {
          send({ type: "LOGIN", redirect });
        },
        logout() {
          send("LOGOUT");
        },
        hideOneHomeBanner() {
          send("HIDE_ONE_HOME_BANNER");
        },
        goToOneHome() {
          send("GO_TO_ONE_HOME");
        },
        isLoggedIn: state.matches("loggedIn"),
        isLoginPage: state.matches("loggedOut"),
        loginPagePath,
        isAuthenticating: state?.matches("authenticating"),
        isRegisteringSsoNonUser: state?.matches("registeringSsoNonUser"),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const auth = useContext(AuthContext);

  if (!auth)
    throw new Error(
      `'${useAuth.name}' should be used in the context of an '${AuthProvider.name}'`
    );
  return auth;
}
