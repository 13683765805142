import React from "react";
import {
  styled,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const TitleWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& > *:not(:first-child)": {
    marginLeft: theme.spacing(1),
  },
}));

interface FacilityDefinitionDialogProps extends Omit<DialogProps, "open"> {
  open?: boolean;
  onConfirm?: Callback;
}

export function FacilityDefinitionDialog({
  open = false,
  onConfirm,
}: FacilityDefinitionDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onConfirm}
      PaperProps={{ "aria-labelledby": "title" }}
      maxWidth="xl"
    >
      <DialogTitle id="title">
        <TitleWrapper>
          <InfoIcon color="primary" />
          <div>What's the difference between a facility and a department?</div>
        </TitleWrapper>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          We define a facility as a geographical clinical research location or
          collection of associated locations within close proximity (e.g., two
          buildings on the same campus). A department is a sub-division of a
          defined facility that is capable of doing clinical research (e.g.,
          Department of Endocrinology or the Cardiac Cath Lab).
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm}>Ok, got it</Button>
      </DialogActions>
    </Dialog>
  );
}
