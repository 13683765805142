import React from "react";
import { useTypeaheadQueries } from "hooks/useTypeaheadQueries";

import {
  AsyncAutocompleteField,
  AsyncAutocompleteFieldProps,
} from "./AsyncAutocompleteField";

export interface QualificationAutocompleteFieldProps
  extends Omit<
    AsyncAutocompleteFieldProps,
    "fetchOptions" | "additionalQueryArgs"
  > {}

export function QualificationAutocompleteField({
  name = "qualification",
  label = "Qualification",
  onQueryError = console.error,
  ...otherProps
}: QualificationAutocompleteFieldProps) {
  const { getQualifications } = useTypeaheadQueries();
  return (
    <AsyncAutocompleteField
      name={name}
      label={label}
      fetchOptions={getQualifications}
      getOptionLabel={(option) => option}
      getOptionValue={(option) => option}
      fetchOnOpen
      {...otherProps}
    />
  );
}
