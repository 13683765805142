import React from "react";
import Link from "@mui/material/Link";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import { noop } from "utils/noop";
import { styled } from "@mui/material/styles";

const DialogContentTextStyled = styled(DialogContentText)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.text.primary,
}));

interface StudyAboutInfoDialogProps extends Omit<DialogProps, "open"> {
  open?: boolean;
  testId?: string;
  onClose?: Callback;
  "data-testid"?: string;
}

export function StudyAboutInfoDialog({
  open = false,
  onClose = noop,
  "data-testid": testId = StudyAboutInfoDialog.name,
}: StudyAboutInfoDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-testid={testId}
      aria-labelledby="title"
      aria-describedby="description"
    >
      <DialogTitle id="title">
        Where does this information come from?
      </DialogTitle>
      <DialogContent id="description">
        <DialogContentTextStyled>
          The information on study dates and enrolment displayed comes from the
          Clinical Trial Management Systems (CTMS) of the member companies. We
          include interventional Phase I-IV trials that opened in 2008 or later.
          Please note that enrolment numbers will not be displayed until
          enrolment for that study is completed. The data are refreshed on a
          monthly basis, so what you see will be accurate for approximately one
          month behind the current date.
        </DialogContentTextStyled>

        <DialogContentTextStyled>
          These are large databases that sometimes contain errors, so please
          don't be alarmed if the information does not exactly match your
          records. If you have any concerns or questions, please do{" "}
          <Link
            data-testid={`${testId}ContactUsLink`}
            href="/contact-us"
            target="_blank"
            underline="hover"
          >
            Contact us
          </Link>{" "}
          and we can liaise with the member company to resolve the query.
        </DialogContentTextStyled>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} data-testid={`${testId}CloseButton`}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
