import React, { useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";

import { styled } from "@mui/material/styles";
import { useForm, Field } from "hooks/useForm";
import { asyncNoop, noop } from "utils/noop";
import { FormCheckboxField } from "./FormCheckboxField";
import { PopulationKeyValue } from "types/facility";

import {
  toPopulationValuesObj,
  fieldsToPopulationKeyValueArray,
} from "utils/department";

const defaultValue = [] as PopulationKeyValue[];

const Root = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridRowGap: theme.spacing(1.5),
}));

const CheckboxesWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridRowGap: theme.spacing(4),
}));

interface PatientPopulationAgeGroupsFieldProps {
  form: string;
  value?: PopulationKeyValue[];
  onChange: Callback;
  [key: string]: any;
}

export function PatientPopulationAgeGroupsField({
  value = defaultValue,
  form,
  onChange,
}: PatientPopulationAgeGroupsFieldProps) {
  const values = toPopulationValuesObj(value);

  const { fields, setField } = useForm({
    id: form,
    fields: {
      neonates: {
        type: "checkbox",
        name: "neonates",
        label: "Neonates (less than 1 month old)",
        value: values.neonates,
      },
      infants1: {
        type: "checkbox",
        name: "infants1",
        label: "Infants (1 month - less than 12 months)",
        value: values.infants1,
      },
      infants2: {
        type: "checkbox",
        name: "infants2",
        label: "Infants (12 months - less than 24 months)",
        value: values.infants2,
      },
      children: {
        type: "checkbox",
        name: "children",
        label: "Children (2 years - less than 12 years old)",
        value: values.children,
      },
      adolescents: {
        type: "checkbox",
        name: "adolescents",
        label: "Adolescents (12 years - less than 18 years old)",
        value: values.adolescents,
      },
      adults: {
        type: "checkbox",
        name: "adults",
        label: "18 - 64 years old",
        value: values.adults,
      },
      geriatrics: {
        type: "checkbox",
        name: "geriatrics",
        label: "65 years+",
        value: values.geriatrics,
      },
    },
    submit: asyncNoop,
    onError: noop,
    onChange: (fields: any) => {
      onChange(fieldsToPopulationKeyValueArray(fields));
    },
  });

  function valueCacheKey(items: PopulationKeyValue[]) {
    return items.reduce((acc, item) => {
      return `${acc}|${item.key}:${item.value}`;
    }, "");
  }

  useEffect(() => {
    for (let item of value) {
      setField(item.key, item.value);
    }
  }, [setField, valueCacheKey(value)]); // eslint-disable-line

  return (
    <Root>
      <Typography variant="h6">
        Which of the following age groups do you have access to for enrolment in
        clinical trials?
      </Typography>
      <CheckboxesWrapper>
        <FormGroup>
          <Typography variant="subtitle1" color="textSecondary">
            Pediatric
          </Typography>
          <CheckboxField {...fields.neonates} />
          <CheckboxField {...fields.infants1} />
          <CheckboxField {...fields.infants2} />
          <CheckboxField {...fields.children} />
          <CheckboxField {...fields.adolescents} />
        </FormGroup>

        <FormGroup>
          <Typography variant="subtitle1" color="textSecondary">
            Adult / Geriatric
          </Typography>
          <CheckboxField {...fields.adults} />
          <CheckboxField {...fields.geriatrics} />
        </FormGroup>
      </CheckboxesWrapper>
    </Root>
  );
}

function CheckboxField(props: Field) {
  return (
    <FormCheckboxField
      sx={{ root: { mt: 1 }, checkbox: { pr: 1 } }}
      {...props}
    />
  );
}
