import gql from "graphql-tag";
import parse from "date-fns/parseISO";
import format from "date-fns/format";
import $specialties from "fixtures/specialties";

import { omit } from "rambda";
import { RoleType } from "utils/role";
import { UserCardMachine } from "components/ProfileUserCard";
import { StudiesCardMachine } from "components/StudiesCard";
import { ContactCardMachine } from "components/ContactCard";
import { LicenceCardMachine } from "components/LicenceCard";
import { TrainingCardMachine } from "components/TrainingCard";
import { FacilityCardMachine } from "components/FacilityAffiliationsCard";
import { ResearchCardMachine } from "components/ResearchCard";
import { EducationCardMachine } from "components/EducationCard";
import { Facility, Department } from "types/facility";
import { ExperienceCardMachine } from "components/ExperienceCard";
import { specialtyCodesToObjects } from "utils/specialty";
import { MedicalSpecialtyCardMachine } from "components/MedicalSpecialtyCard";
import { useApolloClient, useMutation } from "@apollo/client";
import { useCards, UseCardsResult, ReturnedCard } from "./useCards";

import {
  User,
  Phone,
  Study,
  Phases,
  Licence,
  Training,
  Experience,
  GQLLicence,
  GQLTraining,
  Qualification,
  StudyIdentifier,
  FacilityDepartment,
  FacilityDepartments,
} from "types/profile";

type CardIds =
  | "facility"
  | "user"
  | "contact"
  | "medicalSpecialty"
  | "research"
  | "education"
  | "experience"
  | "studies"
  | "licence"
  | "training";

export type UseProfileResult = UseCardsResult & {
  cards: {
    [key in CardIds]: ReturnedCard;
  };
};

export type StudyIdentifierGetter = (
  nctNumber: string
) => Promise<StudyIdentifier>;

export function useProfile() {
  return useCards({
    ids: [
      "facility",
      "user",
      "contact",
      "medicalSpecialty",
      "research",
      "education",
      "experience",
      "studies",
      "licence",
      "training",
    ],
    progressGroups: (data) => {
      return data?.user.role !== RoleType.SiteStaff
        ? {
            basic: [
              "facility",
              "user",
              "contact",
              "medicalSpecialty",
              "research",
            ],
            cv: ["education", "experience", "studies", "licence", "training"],
          }
        : {
            basic: ["facility", "user", "contact"],
            cv: ["research", "education", "experience", "licence", "training"],
          };
    },
    cards: {
      facility: {
        //@ts-ignore
        machine: FacilityCardMachine,
        mapDataToProps: ({ facilities }) => ({ facilities }),
        mutatorProps: {
          createFacility: useCreateFacility(),
          deleteFacility: useDeleteFacility(),
          deleteDepartment: useDeleteDepartment(),
        },
      },
      user: {
        //@ts-ignore
        machine: UserCardMachine,
        mapDataToProps: ({ user }) => ({ user }),
        mutatorProps: {
          updateUser: useUpdateUser(),
        },
      },
      contact: {
        //@ts-ignore
        machine: ContactCardMachine,
        mapDataToProps: ({ phones, profileEmail, preferredEmail }) => ({
          phones,
          profileEmail,
          preferredEmail,
        }),
        mutatorProps: {
          createPhone: useCreatePhone(),
          updatePhone: useUpdatePhone(),
          deletePhone: useDeletePhone(),
          updatePreferredEmail: useUpdatePreferredEmail(),
          deletePreferredEmail: useDeletePreferredEmail(),
        },
      },
      medicalSpecialty: {
        //@ts-ignore
        machine: MedicalSpecialtyCardMachine,
        mapDataToProps: ({ specialties }) => ({ specialties }),
        mutatorProps: {
          createSpecialty: useCreateSpecialty(),
          updateSpecialty: useUpdateSpecialty(),
          deleteSpecialty: useDeleteSpecialty(),
        },
        allowCard: (data) => {
          return data?.user.role !== RoleType.SiteStaff;
        },
      },
      research: {
        //@ts-ignore
        machine: ResearchCardMachine,
        mapDataToProps: ({ phases, indications }) => ({ phases, indications }),
        mutatorProps: {
          updatePhases: useUpdatePhases(),
          deletePhases: useDeletePhases(),
          createIndication: useCreateIndication(),
          updateIndication: useUpdateIndication(),
          deleteIndication: useDeleteIndication(),
        },
      },
      education: {
        //@ts-ignore
        machine: EducationCardMachine,
        mapDataToProps: ({ qualifications }) => ({ qualifications }),
        mutatorProps: {
          createQualification: useCreateQualification(),
          updateQualification: useUpdateQualification(),
          deleteQualification: useDeleteQualification(),
        },
      },
      experience: {
        //@ts-ignore
        machine: ExperienceCardMachine,
        mapDataToProps: ({ experiences }) => ({ experiences }),
        mutatorProps: {
          createExperience: useCreateExperience(),
          updateExperience: useUpdateExperience(),
          deleteExperience: useDeleteExperience(),
        },
      },
      studies: {
        //@ts-ignore
        machine: StudiesCardMachine,
        mapDataToProps: ({ studies }) => ({ studies }),
        mutatorProps: {
          createStudy: useCreateStudy(),
          updateStudy: useUpdateStudy(),
          deleteStudy: useDeleteStudy(),
        },
        additionalProps: {
          getStudyExportUrl: useGetStudyExportUrl(),
          getStudyIdentifier: useGetStudyIdentifier(),
        },
        allowCard: (data) => {
          return data?.user.role !== RoleType.SiteStaff;
        },
      },
      licence: {
        //@ts-ignore
        machine: LicenceCardMachine,
        mapDataToProps: ({ user, licences }) => ({ user, licences }),
        mutatorProps: {
          createLicence: useCreateLicence(),
          updateLicence: useUpdateLicence(),
          deleteLicence: useDeleteLicence(),
        },
      },
      training: {
        //@ts-ignore

        machine: TrainingCardMachine,
        mapDataToProps: ({ trainings }) => ({ trainings }),
        mutatorProps: {
          createTraining: useCreateTraining(),
          updateTraining: useUpdateTraining(),
          deleteTraining: useDeleteTraining(),
        },
        additionalProps: {
          getTrainingDownloadUrl: useGetTrainingDownloadUrl(),
        },
      },
    },
    fetchData: useFetchProfile(),
    transformData: (data) => {
      if (!data) return data;
      const specialties = specialtyCodesToObjects(
        data.specialties,
        $specialties
      );

      const licences = data.licences.map((licence: any) =>
        toNormalisedLicence(licence)
      );

      const trainings = data.trainings.map((training: any) =>
        toNormalisedTraining(training)
      );

      return { ...data, specialties, licences, trainings };
    },
  }) as UseProfileResult;
}

export const GET_PROFILE = gql`
  query GetProfile {
    profile {
      user {
        nameTitle
        firstName
        middleName
        lastName
        nameSuffix
        role
        jobTitle
        optIn {
          status
          email
        }
      }
      phones {
        id: pk
        type
        countryCode
        number
        ext
      }
      phases {
        phaseOne
        phaseOneOrTwo
        phaseTwo
        phaseTwoOrThree
        phaseThree
        phaseThreeOrFour
        phaseFour
      }
      qualifications {
        id: pk
        countryCode
        university
        qualification
        yearStarted
        yearFinished
        isMedicalDegree
      }
      experiences {
        id: pk
        facilityGoldenId: goldenFacilityPk
        facilityName
        yearStarted
        yearFinished
        countryCode
        jobTitle
      }
      licences {
        id: pk
        countryCode
        state
        number
        issuer
        type
        issueDate
        expirationDate
        npi
        digitalBadgeUrl
      }
      trainings {
        pk
        courseProvider
        completionDate
        startDate
        certificateFilename
      }
      studies {
        id: pk
        goldenProtocolId: goldenProtocolPk
        primaryIndication
        title
        phase
        type
        status
        source
        nctNumber
        comment
        role
        studyId
      }
      facilities {
        id: pk
        name
        countryCode
        city
        region
        zipCode
        street
        primaryLocation
        departments {
          name
          type
          subType
          primaryLocation
        }
      }
      indications
      specialties
      profileEmail: email
      preferredEmail: contactEmail
    }
  }
`;

export function useFetchProfile() {
  const client = useApolloClient();
  return async () => {
    const result = await client.query({ query: GET_PROFILE });
    return result.data.profile;
  };
}

export const CREATE_FACILITY = gql`
  mutation CreateFacility(
    $departments: [IDepartment]!
    $goldenFacilityPk: Int!
    $primaryLocation: Boolean!
  ) {
    createFacility(
      departments: $departments
      goldenFacilityPk: $goldenFacilityPk
      primaryLocation: $primaryLocation
    ) {
      facility {
        id: pk
        name
        countryCode
        city
        region
        zipCode
        street
        primaryLocation
        departments {
          name
          type
          subType
          primaryLocation
        }
      }
    }
  }
`;

export function useCreateFacility() {
  const client = useApolloClient();
  return async (facility: FacilityDepartments) => {
    const result = await client.mutate({
      mutation: CREATE_FACILITY,
      variables: facility,
      refetchQueries: [{ query: GET_PROFILE }],
      awaitRefetchQueries: true,
    });
    return result.data.createFacility.facility;
  };
}

export const DELETE_FACILITY = gql`
  mutation DeleteFacility($goldenFacilityPk: Int!) {
    deleteFacility(goldenFacilityPk: $goldenFacilityPk) {
      status
    }
  }
`;

export function useDeleteFacility() {
  const client = useApolloClient();

  return async (facility: Facility) => {
    const result = await client.mutate({
      mutation: DELETE_FACILITY,
      variables: { goldenFacilityPk: facility.id },
      refetchQueries: [{ query: GET_PROFILE }],
      awaitRefetchQueries: true,
    });

    return result.data.deleteFacility.status;
  };
}

export function useDeleteDepartment() {
  const deleteDepartments = useDeleteDepartments();
  return ({ facilityId, department }: FacilityDepartment) => {
    return deleteDepartments({
      goldenFacilityPk: facilityId,
      departments: [department],
    });
  };
}

export const DELETE_DEPARTMENTS = gql`
  mutation DeleteDepartments(
    $departments: [IDepartment]!
    $goldenFacilityPk: Int!
  ) {
    deleteDepartments(
      departments: $departments
      goldenFacilityPk: $goldenFacilityPk
    ) {
      status
    }
  }
`;

export function useDeleteDepartments() {
  const client = useApolloClient();
  return async ({ goldenFacilityPk, departments }: FacilityDepartments) => {
    const result = await client.mutate({
      mutation: DELETE_DEPARTMENTS,
      variables: { goldenFacilityPk, departments },
      refetchQueries: [{ query: GET_PROFILE }],
      awaitRefetchQueries: true,
    });
    return result.data.deleteDepartments.status as boolean;
  };
}

export const GET_GOLDEN_DEPARTMENTS = gql`
  query GoldenFacility($goldenFacilityPk: Int!) {
    goldenFacility(goldenFacilityPk: $goldenFacilityPk) {
      departments {
        name
        type
        subType
      }
    }
  }
`;

export function useGetGoldenDepartments() {
  const client = useApolloClient();
  return async (goldenFacilityPk: number) => {
    const result = await client.query({
      query: GET_GOLDEN_DEPARTMENTS,
      variables: { goldenFacilityPk },
      fetchPolicy: "network-only",
    });
    return result.data.goldenFacility.departments as Department[];
  };
}

export const UPDATE_USER = gql`
  mutation UpdateUser($user: IUser!) {
    updateUser(user: $user) {
      user {
        nameTitle
        firstName
        middleName
        lastName
        nameSuffix
        role
        jobTitle
      }
    }
  }
`;

function useUpdateUser() {
  const client = useApolloClient();
  const readProfile = useReadProfile();
  return async (user: Partial<User>) => {
    const profile = readProfile();
    const updatedUser = { ...profile.user, ...user };
    delete updatedUser.optIn;
    const result = await client.mutate({
      mutation: UPDATE_USER,
      variables: { user: updatedUser },
      refetchQueries: [{ query: GET_PROFILE }],
      awaitRefetchQueries: true,
    });
    return result.data.updateUser.user;
  };
}

function useReadProfile() {
  const client = useApolloClient();
  return () => {
    const data = client.readQuery({ query: GET_PROFILE });
    return data.profile;
  };
}

export const UPDATE_CONTACT = gql`
  mutation UpdateContact($contactEmail: String, $phones: [IPhone]) {
    updateContact(contactEmail: $contactEmail, phones: $phones) {
      contact {
        phones {
          id: pk
          type
          countryCode
          number
          ext
        }
        contactEmail
      }
    }
  }
`;

export interface ContactArgs {
  preferredEmail?: string | null;
  phones?: Phone[];
}

export function useUpdateContact() {
  const readProfile = useReadProfile();
  const [updateContact] = useMutation(UPDATE_CONTACT, {
    refetchQueries: [{ query: GET_PROFILE }],
    awaitRefetchQueries: true,
  });

  return async ({ preferredEmail, phones }: ContactArgs) => {
    const profile = readProfile();

    let email;

    if (preferredEmail === undefined) {
      email = profile.preferredEmail;
    } else if (preferredEmail === null) {
      email = null;
    } else {
      email = preferredEmail;
    }

    const contact = {
      contactEmail: email,
      phones: (phones || profile.phones).map(omit(["id"]) as any),
    };

    const result = await updateContact({ variables: contact });
    return result.data.updateContact.contact;
  };
}

function useCreatePhone() {
  const readProfile = useReadProfile();
  const updateContact = useUpdateContact();
  return async (phone: Phone) => {
    const profile = readProfile();
    const phones = profile.phones.concat([phone]);
    return (await updateContact({ phones })).phones;
  };
}

function useUpdatePhone() {
  const readProfile = useReadProfile();
  const updateContact = useUpdateContact();
  return async (phone: Phone) => {
    const profile = readProfile();
    const phones = profile.phones.map(($phone: Phone) => {
      return $phone.id === phone.id ? { ...$phone, ...phone } : { ...$phone };
    });
    return (await updateContact({ phones })).phones;
  };
}

function useDeletePhone() {
  const readProfile = useReadProfile();
  const updateContact = useUpdateContact();
  return async (phone: Phone) => {
    const profile = readProfile();
    const phones = profile.phones.filter((p: Phone) => p.id !== phone.id);
    return (await updateContact({ phones })).phones;
  };
}

function useUpdatePreferredEmail() {
  const updateContact = useUpdateContact();
  return async (preferredEmail: string) => {
    const result = await updateContact({ preferredEmail });
    return result.contactEmail;
  };
}

function useDeletePreferredEmail() {
  const updateContact = useUpdateContact();
  return async () => {
    const result = await updateContact({ preferredEmail: null });
    return result.contactEmail;
  };
}

export const UPDATE_SPECIALTIES = gql`
  mutation UpdateSpecialties($specialties: [String]!) {
    updateSpecialties(specialties: $specialties) {
      specialties
    }
  }
`;

function useUpdateSpecialties() {
  const [updateSpecialties] = useMutation(UPDATE_SPECIALTIES, {
    refetchQueries: [{ query: GET_PROFILE }],
    awaitRefetchQueries: true,
  });
  return async (specialties: string[] = []) => {
    const result = await updateSpecialties({ variables: { specialties } });
    return result.data.updateSpecialties.specialties;
  };
}

export interface SpecialtyValues {
  id: string;
  specialty: string;
  subspecialty: string;
}

export function useCreateSpecialty() {
  const readProfile = useReadProfile();
  const updateSpecialties = useUpdateSpecialties();
  return async ({ specialty, subspecialty }: SpecialtyValues) => {
    const values = {
      specialty: specialty === "" ? null : specialty,
      subspecialty: subspecialty === "" ? null : subspecialty,
    };
    const specialties = readProfile().specialties.concat(
      values.subspecialty! || values.specialty!
    );
    return updateSpecialties(specialties);
  };
}

export function useUpdateSpecialty() {
  const readProfile = useReadProfile();
  const updateSpecialties = useUpdateSpecialties();
  return async ({ id, specialty, subspecialty }: SpecialtyValues) => {
    const values = {
      specialty: specialty === "" ? null : specialty,
      subspecialty: subspecialty === "" ? null : subspecialty,
    };
    const specialties = readProfile().specialties.map((value: string) => {
      if (value === id) {
        return (values.subspecialty || values.specialty) as string;
      }
      return value;
    });
    return updateSpecialties(specialties);
  };
}

export function useDeleteSpecialty() {
  const readProfile = useReadProfile();
  const updateSpecialties = useUpdateSpecialties();
  return async ({ specialtyCode = "", subspecialtyCode = "" }) => {
    const specialties = readProfile().specialties.filter((value: string) => {
      return value !== (subspecialtyCode || specialtyCode);
    });
    return updateSpecialties(specialties);
  };
}

export const UPDATE_RESEARCH = gql`
  mutation UpdateResearch($indications: [String]!, $phases: IPhases!) {
    updateResearch(indications: $indications, phases: $phases) {
      phases {
        phaseOne
        phaseOneOrTwo
        phaseTwo
        phaseTwoOrThree
        phaseThree
        phaseThreeOrFour
        phaseFour
      }
      indications
    }
  }
`;

function useUpdateResearch() {
  const [updateResearch] = useMutation(UPDATE_RESEARCH, {
    refetchQueries: [{ query: GET_PROFILE }],
    awaitRefetchQueries: true,
  });
  return async (variables: { indications: string[]; phases: Phases }) => {
    const result = await updateResearch({ variables });
    return result.data.updateResearch;
  };
}

function useUpdatePhases() {
  const readProfile = useReadProfile();
  const updateResearch = useUpdateResearch();
  return (phases: Phases) => {
    const profile = readProfile();
    const variables = {
      indications: profile.indications,
      phases,
    };
    return updateResearch(variables);
  };
}

function useDeletePhases() {
  const readProfile = useReadProfile();
  const updateResearch = useUpdateResearch();
  return () => {
    const profile = readProfile();
    const variables = {
      indications: profile.indications,
      phases: {
        phaseOne: false,
        phaseOneOrTwo: false,
        phaseTwo: false,
        phaseTwoOrThree: false,
        phaseThree: false,
        phaseThreeOrFour: false,
        phaseFour: false,
      },
    };
    return updateResearch(variables);
  };
}

function useCreateIndication() {
  const readProfile = useReadProfile();
  const updateResearch = useUpdateResearch();
  return ({ indication }: { indication: string }) => {
    const profile = readProfile();
    const variables = {
      indications: profile.indications.concat(indication),
      phases: profile.phases,
    };
    return updateResearch(variables);
  };
}

function useUpdateIndication() {
  const readProfile = useReadProfile();
  const updateResearch = useUpdateResearch();
  return ({ id, indication }: { id: string; indication: string }) => {
    const profile = readProfile();
    const variables = {
      indications: profile.indications.map((value: string) => {
        if (value === id) {
          return indication;
        }
        return value;
      }),
      phases: profile.phases,
    };
    return updateResearch(variables);
  };
}

function useDeleteIndication() {
  const readProfile = useReadProfile();
  const updateResearch = useUpdateResearch();
  return (indication: string) => {
    const profile = readProfile();
    const variables = {
      indications: profile.indications.filter(
        (value: string) => value !== indication
      ),
      phases: profile.phases,
    };
    return updateResearch(variables);
  };
}

export const UPDATE_QUALIFICATIONS = gql`
  mutation UpdateQualifications($qualifications: [IQualification]!) {
    updateQualifications(qualifications: $qualifications) {
      qualifications {
        id: pk
        countryCode
        university
        qualification
        yearStarted
        yearFinished
        isMedicalDegree
      }
    }
  }
`;

function useUpdateQualifications() {
  const [updateQualifications] = useMutation(UPDATE_QUALIFICATIONS, {
    refetchQueries: [{ query: GET_PROFILE }],
    awaitRefetchQueries: true,
  });
  return async (qualifications: Qualification[]) => {
    const variables = {
      qualifications: qualifications.map(omit(["id"]) as any),
    };
    const result = await updateQualifications({ variables });
    return result.data.updateQualifications.qualifications;
  };
}

function useCreateQualification() {
  const readProfile = useReadProfile();
  const updateQualifications = useUpdateQualifications();
  return (qualification: Qualification) => {
    const profile = readProfile();
    const qualifications = profile.qualifications.concat(qualification);
    return updateQualifications(qualifications);
  };
}

function useUpdateQualification() {
  const readProfile = useReadProfile();
  const updateQualifications = useUpdateQualifications();
  return (qualification: Qualification) => {
    const profile = readProfile();
    const qualifications = profile.qualifications.map(
      (value: Qualification) => {
        if (value.id === qualification.id) return qualification;
        return value;
      }
    );
    return updateQualifications(qualifications);
  };
}

function useDeleteQualification() {
  const readProfile = useReadProfile();
  const updateQualifications = useUpdateQualifications();
  return (qualification: Qualification) => {
    const profile = readProfile();
    const qualifications = profile.qualifications.filter(
      (value: Qualification) => {
        return value.id !== qualification.id;
      }
    );
    return updateQualifications(qualifications);
  };
}

export const UPDATE_EXPERIENCES = gql`
  mutation UpdateExperience($experiences: [IExperience]!) {
    updateExperiences(experiences: $experiences) {
      experiences {
        id: pk
        facilityGoldenId: goldenFacilityPk
        facilityName
        yearStarted
        yearFinished
        countryCode
        jobTitle
      }
    }
  }
`;

function useUpdateExperiences() {
  const [updateExperiences] = useMutation(UPDATE_EXPERIENCES, {
    refetchQueries: [{ query: GET_PROFILE }],
    awaitRefetchQueries: true,
  });
  return async (experiences: Experience[]) => {
    const variables = {
      experiences: experiences.map((x: any) => ({
        ...omit(["id", "facilityGoldenId"], x),
        goldenFacilityPk: x.facilityGoldenId,
      })),
    };
    const result = await updateExperiences({ variables });
    return result.data.updateExperiences.experiences;
  };
}

function useCreateExperience() {
  const readProfile = useReadProfile();
  const updateExperiences = useUpdateExperiences();
  return async (experience: Experience) => {
    const profile = readProfile();
    const experiences = profile.experiences.concat(experience);
    return await updateExperiences(experiences);
  };
}

function useUpdateExperience() {
  const readProfile = useReadProfile();
  const updateExperiences = useUpdateExperiences();
  return async (experience: Experience) => {
    const profile = readProfile();
    const experiences = profile.experiences.map((value: Experience) => {
      if (value.id === experience.id) return experience;
      return value;
    });
    return await updateExperiences(experiences);
  };
}

function useDeleteExperience() {
  const readProfile = useReadProfile();
  const updateExperiences = useUpdateExperiences();
  return (experience: Experience) => {
    const profile = readProfile();
    const experiences = profile.experiences.filter((value: Experience) => {
      return value.id !== experience.id;
    });
    return updateExperiences(experiences);
  };
}

export const UPDATE_STUDIES = gql`
  mutation UpdateStudies($studies: [IStudy]!) {
    updateStudies(studies: $studies) {
      studies {
        id: pk
        goldenProtocolId: goldenProtocolPk
        primaryIndication
        title
        phase
        type
        status
        source
        nctNumber
        comment
        role
        studyId
      }
    }
  }
`;

function useUpdateStudies() {
  const [updateStudies] = useMutation(UPDATE_STUDIES, {
    refetchQueries: [{ query: GET_PROFILE }],
    awaitRefetchQueries: true,
  });
  return async (studies: Study[]) => {
    const variables = {
      studies: studies.map((t) => {
        const study = { ...t, pk: t.id };

        if ((study.pk as any) === "") {
          study.pk = null as any;
        }

        delete study.id;
        delete study.source;
        delete study.goldenProtocolId;

        return study;
      }),
    };
    const result = await updateStudies({ variables });
    return result.data.updateStudies.studies;
  };
}

function useCreateStudy() {
  const readProfile = useReadProfile();
  const updateStudies = useUpdateStudies();
  return async (study: Study) => {
    const profile = readProfile();
    const studies = profile.studies.concat(study);
    return await updateStudies(studies);
  };
}

function useUpdateStudy() {
  const readProfile = useReadProfile();
  const updateStudies = useUpdateStudies();
  return async (study: Study) => {
    const profile = readProfile();
    const studies = profile.studies.map((value: Study) => {
      if (value.id === study.id) return { ...value, ...study };
      return value;
    });
    return await updateStudies(studies);
  };
}

function useDeleteStudy() {
  const readProfile = useReadProfile();
  const updateStudies = useUpdateStudies();
  return (study: Study) => {
    const profile = readProfile();
    const studies = profile.studies.filter((value: Study) => {
      return value.id !== study.id;
    });
    return updateStudies(studies);
  };
}

export const GET_STUDY_EXPORT = gql`
  query StudiesExport {
    studiesExport {
      url
    }
  }
`;

export function useGetStudyExportUrl() {
  const client = useApolloClient();
  return async () => {
    const { data } = (await client.query({ query: GET_STUDY_EXPORT })) as any;
    return data.studiesExport.url;
  };
}

export const GET_STUDY_IDENTIFIER = gql`
  query Studies($nctNumber: String!) {
    studies(nctNumber: $nctNumber) {
      nctNumber
      title
      primaryIndication
      status
      type
      phase
    }
  }
`;

export function useGetStudyIdentifier() {
  const client = useApolloClient();
  return async (nctNumber: string) => {
    const result = await client.query({
      query: GET_STUDY_IDENTIFIER,
      variables: { nctNumber },
    });
    return result.data.studies as StudyIdentifier;
  };
}

export const UPDATE_LICENCES = gql`
  mutation UpdateLicences($licences: [ILicence]!) {
    updateLicences(licences: $licences) {
      licences {
        id: pk
        countryCode
        state
        number
        issuer
        type
        issueDate
        expirationDate
        npi
        digitalBadgeUrl
      }
    }
  }
`;

function useUpdateLicences() {
  const [updateLicences] = useMutation(UPDATE_LICENCES, {
    refetchQueries: [{ query: GET_PROFILE }],
    awaitRefetchQueries: true,
  });
  return async (licences: GQLLicence[]) => {
    const variables = {
      licences: licences.map((l: any) => {
        const licence = { ...(omit(["id"], l) as any), pk: l.id };
        if (!licence.pk) {
          delete licence.pk;
        }
        if (licence.digitalBadgeUrl === "") {
          licence.digitalBadgeUrl = null;
        }
        return licence;
      }),
    };
    const result = await updateLicences({ variables });
    return result.data.updateLicences.licences;
  };
}

export function useCreateLicence() {
  const readProfile = useReadProfile();
  const updateLicences = useUpdateLicences();
  return async (licence: Licence) => {
    const profile = readProfile();
    const licences = profile.licences.concat(toGQLLicence(licence));
    return await updateLicences(licences);
  };
}

export function useUpdateLicence() {
  const readProfile = useReadProfile();
  const updateLicences = useUpdateLicences();
  return async (licence: Licence) => {
    const profile = readProfile();
    const licences = profile.licences.map((value: GQLLicence) => {
      if (value.id === licence.id) return toGQLLicence(licence);
      return value;
    });
    return await updateLicences(licences);
  };
}

export function useDeleteLicence() {
  const readProfile = useReadProfile();
  const updateLicences = useUpdateLicences();
  return (licence: Licence) => {
    const profile = readProfile();
    const licences = profile.licences.filter((value: GQLLicence) => {
      return value.id !== licence.id;
    });
    return updateLicences(licences);
  };
}

export const UPDATE_TRAININGS = gql`
  mutation UpdateTrainings($trainings: [ITraining]!) {
    updateTrainings(trainings: $trainings) {
      trainings {
        pk
        courseProvider
        completionDate
        startDate
        certificateFilename
      }
    }
  }
`;

function useUpdateTrainings() {
  const [updateTrainings] = useMutation(UPDATE_TRAININGS, {
    refetchQueries: [{ query: GET_PROFILE }],
    awaitRefetchQueries: true,
  });
  return async (trainings: GQLTraining[]) => {
    const variables = {
      trainings: trainings.map((t) => {
        const training = { ...t };
        if (!training.pk) {
          //@ts-ignore
          delete training.pk;
        }
        return training;
      }),
    };
    const result = await updateTrainings({ variables });
    return result.data.updateTrainings.trainings;
  };
}

function useCreateTraining() {
  const readProfile = useReadProfile();
  const updateTrainings = useUpdateTrainings();
  return async (training: Training) => {
    const profile = readProfile();
    const trainings = profile.trainings.concat(toGQLTraining(training));
    return await updateTrainings(trainings);
  };
}

function useUpdateTraining() {
  const readProfile = useReadProfile();
  const updateTrainings = useUpdateTrainings();
  return async (training: Training) => {
    const profile = readProfile();
    const trainings = profile.trainings.map((value: GQLTraining) => {
      if (value.pk === training.pk) return toGQLTraining(training);
      return value;
    });
    return await updateTrainings(trainings);
  };
}

function useDeleteTraining() {
  const readProfile = useReadProfile();
  const updateTrainings = useUpdateTrainings();
  return (training: Training) => {
    const profile = readProfile();
    const trainings = profile.trainings.filter((value: GQLTraining) => {
      return value.pk !== training.pk;
    });
    return updateTrainings(trainings);
  };
}

export const GET_TRAINING_DOWNLOAD = gql`
  query TrainingDownload($trainingPk: Int!) {
    trainingDownload(trainingPk: $trainingPk) {
      url
    }
  }
`;

export function useGetTrainingDownloadUrl() {
  const client = useApolloClient();
  return async (training: Training) => {
    const { data } = (await client.query({
      query: GET_TRAINING_DOWNLOAD,
      variables: { trainingPk: training.pk },
      fetchPolicy: "network-only",
    })) as any;
    return data.trainingDownload.url;
  };
}

export function toNormalisedLicence(licence: GQLLicence): Licence {
  return {
    ...licence,
    issueDate: parse(licence.issueDate),
    expirationDate: licence.expirationDate
      ? parse(licence.expirationDate)
      : null,
  };
}

export function toGQLLicence(licence: Licence): GQLLicence {
  return {
    ...licence,
    npi: licence?.npi?.length !== 0 ? licence.npi : undefined,
    issueDate: format(licence.issueDate, "yyyy-MM-dd"),
    expirationDate: licence.expirationDate
      ? format(licence.expirationDate, "yyyy-MM-dd")
      : undefined,
  };
}

export function toNormalisedTraining(training: GQLTraining) {
  return {
    ...training,
    startDate: training.startDate ? parse(training.startDate) : null,
    completionDate: training.completionDate
      ? parse(training.completionDate)
      : null,
  } as Training;
}

export function toGQLTraining(training: Training): GQLTraining {
  return {
    ...training,
    startDate: training.startDate
      ? format(training.startDate, "yyyy-MM-dd")
      : null,
    completionDate: format(training.completionDate, "yyyy-MM-dd"),
  };
}
