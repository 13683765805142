import Link from "@mui/material/Link";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ReactDropzone from "react-dropzone";
import base64ArrayBuffer from "utils/base64ArrayBuffer";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import { noop } from "utils/noop";
import { useRef } from "react";
import { styled } from "@mui/material/styles";
import { useMachine } from "@xstate/react";
import { createMachine, assign } from "xstate";

const PREFIX = "Dropzone";

const classes = {
  zone: `${PREFIX}-zone`,
  centeredHStack: `${PREFIX}-centeredHStack`,
  browseLink: `${PREFIX}-browseLink`,
};

const ReactDropzoneContent = styled("section")(({ theme }) => {
  return {
    position: "relative",
    border: `dashed 1px ${theme.palette.grey[400]}`,
    height: 150,
    [`& .${classes.zone}`]: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    [`& .${classes.centeredHStack}`]: {
      display: "flex",
      alignItems: "center",
    },
    [`& .${classes.browseLink}`]: {
      cursor: "pointer",
    },
  };
});

export interface DroppedFile {
  name: string | null;
  contents: string | null;
}

interface DropzoneProps {
  value?: DroppedFile;
  onError?: (error: Error) => any;
  onChange: (file: DroppedFile | null) => any;
  "data-testid"?: string;
}

type MachineContext = Omit<Required<DropzoneProps>, "data-testid">;

const DropzoneMachine = createMachine<MachineContext>(
  {
    id: "dropzone",
    initial: "idle",
    context: {
      onError: noop,
      onChange: noop,
      value: { name: null, contents: null } as any,
    },
    states: {
      idle: {
        on: {
          DROP: {
            target: "dropping",
          },
          BROWSE: {
            target: "browsing",
          },
          DELETE: {
            actions: ["deleteDroppedFile", "dispatchOnChange"],
          },
        },
      },
      browsing: {
        entry: "openBrowser",
        on: {
          DROP: {
            target: "dropping",
          },
        },
      },
      dropping: {
        invoke: {
          src: "prepareDroppedFile",
          onDone: {
            target: "idle",
            actions: ["dispatchOnChange", "assignDroppedFile"],
          },
          onError: {
            target: "idle",
            actions: "dispatchOnError",
          },
        },
      },
    },
  },
  {
    actions: {
      assignDroppedFile: assign((_, { data }: any) => ({
        value: data,
      })),
      //@ts-ignore
      deleteDroppedFile: assign(() => ({
        value: { name: null, contents: null } as any,
      })),
      dispatchOnChange: (context: DropzoneProps) => {
        context.onChange(context.value!);
      },
      dispatchOnError: (context: any, { data }: any) => {
        context.onError(data);
      },
    },
    services: {
      prepareDroppedFile: (_: any, { files }: any) => {
        return toDroppedFile(files[0]);
      },
    },
  }
);

export function toDroppedFile(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => reject(reader.error);
    reader.onload = () => {
      const contents = base64ArrayBuffer(reader.result! as ArrayBuffer);
      const droppedFile = { name: file.name, contents };
      resolve(droppedFile);
    };
    reader.readAsArrayBuffer(file);
  });
}

function useDropzone({
  value = { name: null, contents: null },
  onError = noop,
  onChange,
}: DropzoneProps) {
  const dropzoneRef = useRef() as any;
  const [state, send] = useMachine(
    //@ts-ignore
    DropzoneMachine.withContext({ value, onChange, onError }),
    {
      actions: {
        openBrowser() {
          dropzoneRef.current.open();
        },
      },
    }
  );

  return {
    dropzone: {
      ref: dropzoneRef,
      onDrop(files: File[]) {
        //@ts-ignore
        send({ type: "DROP", files });
      },
      multiple: false,
      noClick: true,
    },
    browseButton: {
      onClick() {
        send("BROWSE");
      },
    },
    deleteButton: {
      onClick() {
        send("DELETE");
      },
    },
    //@ts-ignore
    droppedFile: state.context.value,
  };
}

export function Dropzone({
  "data-testid": testId = Dropzone.name,
  ...props
}: DropzoneProps) {
  const { dropzone, droppedFile, browseButton, deleteButton } =
    useDropzone(props);

  return (
    <ReactDropzone {...dropzone}>
      {({ getRootProps, getInputProps }) => (
        <ReactDropzoneContent data-testid={testId}>
          <div {...getRootProps()}>
            <input data-testid={`${testId}Input`} {...getInputProps()} />

            {!droppedFile.name && (
              <div data-testid={`${testId}Zone`} className={classes.zone}>
                <div className={classes.centeredHStack}>
                  <InsertDriveFileIcon color="disabled" />
                  <div data-testid={`${testId}DropInstructions`}>
                    Drag & Drop your file here to upload or{" "}
                    <Link
                      className={classes.browseLink}
                      data-testid={`${testId}BrowseLink`}
                      {...browseButton}
                      underline="hover"
                    >
                      BROWSE
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {droppedFile.name && (
              <div className={classes.zone}>
                <div className={classes.centeredHStack}>
                  <div data-testid={`${testId}DeletionInstructions`}>
                    {droppedFile.name}
                  </div>
                  <IconButton
                    aria-label="help"
                    size="small"
                    data-testid={`${testId}DeleteButton`}
                    {...deleteButton}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </ReactDropzoneContent>
      )}
    </ReactDropzone>
  );
}
