import { Phone } from "types/profile";

export function isCompletePhone(phone: Phone) {
  return Boolean(phone.number && phone.type && phone.countryCode);
}

export function hasIncompletePhones(phones: Phone[]) {
  return phones.some((phone: Phone) => {
    return !isCompletePhone(phone);
  });
}
