//@ts-nocheck
import { asyncNoop } from "utils/noop";
import { useMachine } from "@xstate/react";
import { Machine, assign } from "xstate";

type AsyncHandlerFunction = (...args: any) => Promise<any>;

interface UseEditableDataProps {
  create?: AsyncHandlerFunction;
  update?: AsyncHandlerFunction;
  delete?: AsyncHandlerFunction;
  useCreationFormArg?: boolean;
}

const EditableDataMachine = Machine(
  {
    id: "editableData",
    initial: "viewing",
    context: {
      entry: null,
      creationArg: null,
    },
    states: {
      viewing: {
        on: {
          CREATE: {
            target: "creating",
            actions: "assignCreationArg",
          },
          UPDATE: {
            target: "updating",
            actions: "setEntry",
          },
          DELETE: {
            target: "deleting",
            actions: "setEntry",
          },
        },
      },
      updating: {
        on: {
          DONE: "viewing",
        },
      },
      creating: {
        on: {
          DONE: "viewing",
        },
      },
      deleting: {
        on: {
          DONE: "viewing",
        },
      },
    },
  },
  {
    actions: {
      setEntry: assign((_, event) => ({
        entry: event.entry,
      })),
      assignCreationArg: assign((_, event) => ({
        creationArg: event.arg,
      })),
    },
  }
);

export function useEditableData({
  create = asyncNoop,
  update = asyncNoop,
  delete: doDelete = asyncNoop,
  useCreationFormArg = false,
}: UseEditableDataProps = {}) {
  const [state, send] = useMachine(EditableDataMachine);

  const result = {
    details: {
      onCreateButtonClick: (arg: any) => {
        send({ type: "CREATE", arg });
      },
      onUpdateButtonClick: (entry?: any) => {
        send({ type: "UPDATE", entry });
      },
      onDeleteButtonClick: (entry?: any) => {
        send({ type: "DELETE", entry });
      },
    },
    updateForm: {
      open: state.matches("updating"),
      entry: state.context.entry as any,
      onCancel: () => {
        send({ type: "DONE" });
      },
      submit: update,
      onSuccess: () => {
        send({ type: "DONE" });
      },
    },
    creationForm: {
      arg: state.context.creationArg as any,
      open: state.matches("creating"),
      onCancel: () => {
        send({ type: "DONE" });
      },
      submit: create,
      onSuccess: () => {
        send({ type: "DONE" });
      },
    },
    deletionDialog: {
      open: state.matches("deleting"),
      entry: state.context.entry as any,
      submit: doDelete,
      onCancel: () => {
        send({ type: "DONE" });
      },
      onSuccess: () => {
        send({ type: "DONE" });
      },
    },
  };

  if (!useCreationFormArg) {
    delete result.creationForm.arg;
  }

  return result;
}

export function onlyAllowUpdate(details: any) {
  delete details.onDeleteButtonClick;
  delete details.onCreateButtonClick;
}

export function onlyDisallowCreate(details: any) {
  delete details.onCreateButtonClick;
}
