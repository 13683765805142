import { assign, sendParent, createMachine, ActorRefFrom } from "xstate";
import { useActor } from "@xstate/react";
import { maxLength } from "validators/maxLength";
import { isEmpty } from "validators/isEmpty";
import { validator } from "./utils";
import { produce } from "immer"

export interface PrimaryFacilityFormMachineContext {
  countryCode: Field;
  state: Field;
  facility: Field;
  customFacility: Field;
  customFacilityAddress: Field;
  customFacilityPostalCode: Field;
  isCustomFacilityActive: boolean;
  customFacilityEnabled: boolean;
}

export interface PrimaryFacilityFormFinalValues {
  countryCode?: string;
  state?: string;
  facility?: string;
  customFacility?: string;
  customFacilityAddress?: string;
  customFacilityPostalCode?: string;
}

type SetValueEvent = {
  type: "SET_VALUE";
  name: string;
  value: string | boolean;
};

type ValidateEvent = { type: "VALIDATE"; name: string; data: any };
type ValidatedEvent = {
  type: "VALIDATED";
  data: PrimaryFacilityFormFinalValues;
};
type validateResponse = {
  type: "error.platform.validation";
  data: string[];
};
type AddCustomFacilityEvent = { type: "ADD_CUSTOM_FACILITY" };

type PrimaryFacilityFormEvents =
  | SetValueEvent
  | ValidateEvent
  | ValidatedEvent
  | validateResponse
  | AddCustomFacilityEvent;

interface Field {
  value: string;
  helperText: string;
  error?: boolean;
  key?: keyof PrimaryFacilityFormMachineContext;
}

const getFinalValues = (context: PrimaryFacilityFormMachineContext) => {
  let final: PrimaryFacilityFormFinalValues = {};
  for (let prop in context) {
    let fieldName = prop as keyof PrimaryFacilityFormMachineContext;
    let field = context[fieldName] as Field;
    if (typeof field?.value === "string") {
      final[prop as keyof typeof final] = field.value;
    } else if (prop === "facility") {
      final.facility = context.facility.value;
    }
  }
  return final;
};

function restoreFieldData(): Field {
  return {
    value: "",
    helperText: "",
  };
}

export let PrimaryFacilityFormMachine = createMachine(
  {
    id: "PrimaryFacilityFormMachine",
    schema: {
      context: {} as PrimaryFacilityFormMachineContext,
      events: {} as PrimaryFacilityFormEvents,
    },
    context: {
      countryCode: {
        helperText: "",
        value: "",
      },
      state: {
        helperText: "",
        value: "",
      },
      facility: {
        helperText: "",
        value: "",
      },
      customFacility: {
        helperText: "",
        value: "",
      },
      customFacilityAddress: {
        helperText: "",
        value: "",
      },
      customFacilityPostalCode: {
        helperText: "",
        value: "",
      },
      isCustomFacilityActive: false,
      customFacilityEnabled: false,
    },
    initial: "idle",
    states: {
      idle: {
        on: {
          VALIDATE: {
            target: "validating",
          },
          SET_VALUE: {
            actions: ["setValue"],
          },
          ADD_CUSTOM_FACILITY: {
            actions: ["addCustomFacility"],
          },
        },
      },
      validating: {
        entry: "resetValidationMessages",
        invoke: [
          {
            id: "validation",
            src: "validation",
            onDone: [
              {
                actions: ["sendDataToParent"],
                target: "idle",
              },
            ],
            onError: [
              {
                actions: "setValidationErrors",
                target: "idle",
              },
            ],
          },
        ],
      },
    },
  },
  {
    actions: {
      setValue: assign((context, event) => {
        let newContext: any = {};
        if ((event as SetValueEvent).name === "facility") {
          newContext.isCustomFacilityActive = false;
          newContext.customFacility = restoreFieldData();
          newContext.customFacilityAddress = restoreFieldData();
          newContext.customFacilityPostalCode = restoreFieldData();
        }
        if (
          (event as SetValueEvent).name === "countryCode" ||
          (event as SetValueEvent).name === "state"
        ) {
          newContext.facility = restoreFieldData();
        }
        newContext[(event as SetValueEvent).name] = {
          value: (event as SetValueEvent).value,
          helperText: "",
        };
        return { ...context, ...newContext };
      }),
      addCustomFacility: assign((context, _event) => {
        return {
          ...context,
          facility: restoreFieldData(),
          isCustomFacilityActive: true,
        };
      }),
      setValidationErrors: assign((context, event) => {
        if (event.type !== "error.platform.validation") return context;
        return produce(context, (newContext) => {
          for (let key of event.data) {
            let fieldName = key[0] as keyof PrimaryFacilityFormMachineContext;
            let field = newContext[fieldName] as Field;
            field.helperText = key[1];
          }
        });
      }),
      resetValidationMessages: assign((context, event) => {
        if (event.type !== "error.platform.validation") return context;
        return produce(context, (newContext) => {
          for (let key of event.data) {
            let fieldName = key[0] as keyof PrimaryFacilityFormMachineContext;
            let field = newContext[fieldName] as Field;
            field.helperText = "";
          }
        });
      }),
      sendDataToParent: sendParent((context) => {
        return {
          type: "VALIDATED",
          data: getFinalValues(context),
        } as ValidatedEvent;
      }),
    },
    services: {
      async validation(context) {
        let { hasErrors, errors } = validator(
          {
            key: "countryCode",
            message: "This field is required",
            predicate: context.customFacilityEnabled && isEmpty(context.countryCode.value),
          },
          {
            key: "facility",
            message: "This field is required",
            predicate:
              context.customFacilityEnabled &&
              context.isCustomFacilityActive === false &&
              isEmpty(context.facility.value),
          },
          {
            key: "customFacilityAddress",
            message: "Should not be more than 100 characters long",
            predicate:
              context.isCustomFacilityActive === true &&
              !maxLength(100)(context.customFacilityAddress.value),
          },
          {
            key: "customFacilityPostalCode",
            message: "Should not be more than 100 characters long",
            predicate:
              context.isCustomFacilityActive === true &&
              !maxLength(100)(context.customFacilityPostalCode.value) &&
              !isEmpty(context.customFacilityPostalCode.value),
          },
          {
            key: "customFacility",
            message: "Should not be more than 100 characters long",
            predicate:
              context.isCustomFacilityActive === true &&
              !maxLength(100)(context.customFacility.value),
          },
          {
            key: "customFacility",
            message: "This field is required",
            predicate:
              context.isCustomFacilityActive === true &&
              isEmpty(context.customFacility.value),
          },
          {
            key: "customFacilityAddress",
            message: "This field is required",
            predicate:
              context.isCustomFacilityActive === true &&
              isEmpty(context.customFacilityAddress.value),
          }
        );

        if (hasErrors) {
          return Promise.reject(errors);
        }
      },
    },
  }
);

export type PrimaryFacilityFormMachineService = ActorRefFrom<typeof PrimaryFacilityFormMachine>;

export function usePrimaryFacilityForm(
  service: PrimaryFacilityFormMachineService
) {
  let [state, send] = useActor(service);

  return {
    ...state?.context ?? PrimaryFacilityFormMachine.context,
    setValue(name: string, value: string) {
      return send({
        type: "SET_VALUE",
        name: name,
        value: value,
      } as SetValueEvent);
    },
    addCustomFacility() {
      return send({
        type: "ADD_CUSTOM_FACILITY",
      } as AddCustomFacilityEvent);
    },
    onChange(event: React.ChangeEvent<HTMLInputElement>) {
      return send({
        type: "SET_VALUE",
        name: event.target.name,
        value: event.target.value,
      } as SetValueEvent);
    },
  };
}
