import React from "react";
import TextField from "@mui/material/TextField";

import { noop } from "utils/noop";
import { useMachine } from "@xstate/react";
import { Department } from "types/facility";
import { Machine, assign } from "xstate";
import { DepartmentTypeSelectField } from "./DepartmentTypeSelectField";
import { DepartmentSubtypeSelectField } from "./DepartmentSubtypeSelectField";

interface DepartmentInputProps {
  value?: Department;
  onChange?: Callback;
  nameFieldProps?: AnyObject;
  typeFieldProps?: AnyObject;
  subTypeFieldProps?: AnyObject;
  [key: string]: any;
}

export function DepartmentInput({
  nameFieldProps = {},
  typeFieldProps = {},
  subTypeFieldProps = {},
  ...props
}: DepartmentInputProps) {
  const { inputs } = useDepartmentInput(props);
  return (
    <div>
      <DepartmentTypeSelectField
        required
        fullWidth
        {...inputs.type}
        error={!!typeFieldProps.helperText}
        {...typeFieldProps}
      />
      <DepartmentSubtypeSelectField
        fullWidth
        {...inputs.subType}
        {...subTypeFieldProps}
      />
      <TextField
        name="name"
        id="departmentName"
        label="Department name"
        required
        fullWidth
        {...inputs.name}
        error={!!nameFieldProps.helperText}
        {...nameFieldProps}
      />
    </div>
  );
}

interface InputMachineContext {
  name: string | null;
  type: number | null;
  subType: number | null;
}

const InputMachine = Machine<InputMachineContext>(
  {
    initial: "idle",
    context: {
      name: null,
      type: null,
      subType: null,
    },
    states: {
      idle: {
        on: {
          SET_VALUE: [{ cond: "isNotSameValue", actions: ["setValue"] }],
          SET_DEPARTMENT_NAME: {
            actions: ["setDepartmentName", "fireOnChange"],
          },
          SET_DEPARTMENT_TYPE: {
            actions: [
              "clearDepartmentSubtype",
              "setDepartmentType",
              "fireOnChange",
            ],
          },
          SET_DEPARTMENT_SUBTYPE: {
            actions: ["setDepartmentSubtype", "fireOnChange"],
          },
        },
      },
    },
  },
  {
    guards: {
      isNotSameValue: (context, event) => {
        const value = event.value;

        if (!value && !context.name && !context.type && !context.subType) {
          return false;
        }

        return !(
          value &&
          context.name === value.name &&
          context.type === value.type &&
          context.subType === value.subType
        );
      },
    },
    actions: {
      setDepartmentName: assign((_, event) => ({
        name: event.departmentName,
      })),
      setDepartmentType: assign((_, event) => ({
        type: event.departmentType,
      })),
      setDepartmentSubtype: assign((_, event) => ({
        subType: event.departmentSubtype,
      })),
      clearDepartmentSubtype: assign((_) => ({
        subType: null,
      })),
      setValue: assign((_, event) => ({
        name: event.value?.name,
        type: event.value?.type,
        subType: event.value?.subType,
      })),
    },
  }
);

function useDepartmentInput({ value, onChange = noop }: DepartmentInputProps) {
  //@ts-ignore
  const [state, send] = useMachine(InputMachine, {
    actions: {
      fireOnChange: (context) => {
        onChange({
          name: context.name,
          type: context.type,
          subType: context.subType,
        });
      },
    },
  });

  send({ type: "SET_VALUE", value });

  return {
    inputs: {
      name: {
        value: state.context.name || "",
        onChange: (event: any) => {
          send({
            type: "SET_DEPARTMENT_NAME",
            departmentName: event.target.value,
          });
        },
      },
      type: {
        value: state.context.type || undefined,
        onChange: (event: any) => {
          send({
            type: "SET_DEPARTMENT_TYPE",
            departmentType: event.target.value,
          });
        },
      },
      subType: {
        type: state.context.type as number,
        value: state.context.subType || undefined,
        disabled: !Boolean(state.context.type),
        onChange: (event: any) => {
          send({
            type: "SET_DEPARTMENT_SUBTYPE",
            departmentSubtype: event.target.value,
          });
        },
      },
    },
  };
}
