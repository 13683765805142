import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Form } from "./Form";
import { noop } from "utils/noop";
import { useForm } from "hooks/useForm";
import { FormField } from "./FormField";
import { AutocompleteField } from "./AutocompleteField";
import { ResearchPhasesField } from "./ResearchPhasesField";
import { TherapeuticAreasField } from "./TherapeuticAreasField";
import { SponsorExperienceField } from "./SponsorExperienceField";
import { ClinicalTrialHoursField } from "./ClinicalTrialHoursField";
import { LanguageCapabilitiesField } from "./LanguageCapabilitiesField";
import { FieldYesNoRadioGroup } from "./FieldYesNoRadioGroup";
import { Characteristics, LovItem } from "types/facility";
import { maxLengthForValidator as maxLength } from "validators/maxLength";
import { url } from "validators/url";

const FieldContents = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridRowGap: theme.spacing(1),
}));

export interface DepartmentCharacteristicsFormProps {
  open?: boolean;
  title?: string;
  entry?: Characteristics;
  submit: (value: Characteristics) => Promise<any>;
  onCancel?: Callback;
  onError?: Callback;
  onSuccess?: Callback;
  therapeuticAreas: LovItem[];
  facilityTypes: LovItem[];
  isForFacility?: boolean;
  "data-testid"?: string;
}

export function DepartmentCharacteristicsForm({
  open,
  title,
  entry = {} as Characteristics,
  onError = console.error,
  onCancel = noop,
  onSuccess = noop,
  isForFacility = false,
  submit: doSubmit,
  therapeuticAreas,
  facilityTypes,
  ...otherProps
}: DepartmentCharacteristicsFormProps) {
  const otherTypeValue = facilityTypes.find((t) =>
    t.name.toLowerCase().startsWith("other")
  )!.code;
  const { id, fields, submit, reset, submitting, error } = useForm({
    id: "department-characteristics-form",
    fields: {
      type: {
        name: "type",
        label: "Please let us know how you would describe your place of work?",
        value: entry.type ? entry.type.toString() : entry.type,
      },
      url: {
        name: "url",
        label: `Please add your ${
          isForFacility ? "facility" : "department"
        } website if you have one:`,
        value: entry.url,
        validate: (maxLength(500), url),
      },
      typeOther: {
        name: "typeOther",
        label: "Please specify",
        value: entry.typeOther,
        validate: maxLength(127),
      },
      dedicatedResearchFacility: {
        name: "dedicatedResearchFacility",
        label: `Is your ${
          isForFacility ? "facility" : "department"
        } a dedicated research facility, i.e. no patient care outside of clinical trials?`,
        value: entry.dedicatedResearchFacility,
        "aria-label": "dedicated research facility",
      },
      govAffiliation: {
        name: "govAffiliation",
        label: `Is your ${
          isForFacility ? "facility" : "department"
        } affiliated with a government agency or part of a government funded health service?`,
        value: entry.govAffiliation,
        "aria-label": "government affiliation",
      },
      therapeuticAreas: {
        name: "therapeuticAreas",
        value: entry.therapeuticAreas,
      },
      phases: {
        name: "phases",
        value: entry.phases,
      },
      experience: {
        name: "experience",
        value: entry.experience,
      },
      hours: {
        name: "hours",
        value: entry.hours,
      },
      languageCapabilities: {
        name: "languageCapabilities",
        value: entry.languageCapabilities,
      },
    },
    submit: (values: AnyObject) => {
      const type =
        typeof values.type === "string" && values.type.length
          ? parseInt(values.type)
          : null;
      const typeOther = values.typeOther === "" ? null : values.typeOther;
      const dedicatedResearchFacility =
        values.dedicatedResearchFacility === ""
          ? null
          : values.dedicatedResearchFacility;
      const govAffiliation =
        values.govAffiliation === "" ? null : values.govAffiliation;
      const url = values.url === "" ? null : values.url;
      const result = {
        ...values,
        url,
        type,
        typeOther,
        dedicatedResearchFacility,
        govAffiliation,
      } as Characteristics;
      return doSubmit(result);
    },
    onReset: onCancel,
    onError,
    onSuccess,
  });

  const facilityTypeOptions = facilityTypes.map((t) => ({
    value: t.code,
    label: t.name,
  }));

  return (
    <Form
      id={id}
      open={open}
      title={title}
      onSubmit={submit}
      aria-label="department characteristics form"
      submitting={submitting}
      onCancel={reset}
      error={error}
      {...otherProps}
    >
      <FormField label={`${isForFacility ? "Facility" : "Department"} details`}>
        <FieldContents>
          <AutocompleteField {...fields.type} options={facilityTypeOptions} />

          {fields.type.value === otherTypeValue && (
            <TextField {...fields.typeOther} />
          )}

          <TextField {...fields.url} />

          <FieldYesNoRadioGroup {...fields.dedicatedResearchFacility} />
          <FieldYesNoRadioGroup {...fields.govAffiliation} />
        </FieldContents>
      </FormField>
      <TherapeuticAreasField
        form={id}
        areas={therapeuticAreas}
        {...fields.therapeuticAreas}
      />
      <ResearchPhasesField form={id} {...fields.phases} />
      <SponsorExperienceField form={id} {...fields.experience} />
      <ClinicalTrialHoursField form={id} {...fields.hours} />
      <LanguageCapabilitiesField form={id} {...fields.languageCapabilities} />
    </Form>
  );
}
