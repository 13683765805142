import React from "react";
import { TextField } from "@mui/material";
import { FormDialog } from "./FormDialog";
import { email as isEmail } from "validators/email";
import { required } from "validators/required";
import { noop, asyncNoop } from "utils/noop";
import { useForm, SubmitFn } from "hooks/useForm";
import {
  ChangeReasonFormDialog,
  useChangeReasonFormDialog,
} from "./ChangeReasonFormDialog";
import { validators } from "utils/forms";

interface Props {
  title: string;
  open: boolean;
  testId?: string;
  submit: SubmitFn;
  onCancel: Callback;
  onSuccess: Callback;
  "data-testid"?: string;
  submitting?: boolean;
  onRequestSuccess?: Callback;
  onRequestError?: Callback;
  withReason?: boolean;
}

export function DelegateForm({
  open,
  "data-testid": testId = DelegateForm.name,
  submit: doSubmit = asyncNoop,
  onCancel: doCancel = noop,
  onSuccess = noop,
  onRequestSuccess = noop,
  onRequestError = noop,
  withReason = false,
  ...otherProps
}: Props) {
  const reasonForm = useChangeReasonFormDialog({
    doSubmit,
    formHookSubmit,
    withReason,
  });

  const { id, fields, submit, reset, submitting } = useForm({
    id: "delegate-form",
    fields: {
      email: {
        id: "email",
        name: "email",
        label: "Email",
        required: true,
        fullWidth: true,
        validate: validators(required, isEmail),
      },
    },
    onReset: doCancel,
    submit: reasonForm.performSubmit,
    onError: (data: string) => onRequestError(data),
    onSuccess: (_, { data }) => {
      onRequestSuccess(data);
      onSuccess();
    },
  });

  function formHookSubmit() {
    return submit();
  }

  return (
    <>
      <FormDialog
        id={id}
        open={open}
        onCancel={reset}
        onSubmit={reasonForm.handleSubmitButtonClick}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        data-testid={testId}
        showProgressIndicator={submitting}
        {...otherProps}
      >
        <div>
          <TextField data-testid={`${testId}Email`} {...fields.email} />
        </div>
      </FormDialog>
      <ChangeReasonFormDialog {...reasonForm.reasonDialog}/>
    </>
  );
}
