//@ts-nocheck
import { Machine } from "xstate";
import { ROLES } from "../types/user";

interface Context {
  url: string;
  roles?: ROLES;
  token: string | null;
  authorised: boolean;
  refreshTokenTime: number;
  user: any;
  isDelegate?: boolean;
  ssoUser: any;
  showOhfsBanner?: boolean;
  oneHomeUrl?: string;
}

export const AuthenticationMachine = Machine<Context>({
  initial: "authenticating",
  context: {
    url: "",
    roles: undefined,
    token: null,
    refreshTokenTime: 10000,
    authorised: false,
    user: undefined,
    isDelegate: false,
    ssoUser: {},
  },
  states: {
    loggedOut: {
      on: {
        LOGIN: "login",
      },
    },
    login: {
      invoke: {
        src: "login",
        onError: {
          target: "loggedOut",
        },
      },
    },
    authenticating: {
      invoke: {
        id: "authenticate",
        src: "authenticate",
        onError: {
          target: "loggedOut",
        },
        onDone: {
          actions: ["authenticated"],
          target: "authorising",
        },
      },
    },
    authorising: {
      invoke: {
        id: "authorise",
        src: "authorise",
        onError: [
          {
            cond: "hasNoUser",
            target: "registeringSsoNonUser",
          },
          {
            cond: "isAdmin",
            target: "loggedIn",
            actions: ["setUser"],
          },
          {
            target: "unauthorised",
          },
        ],
        onDone: [
          {
            actions: ["setUser"],
            target: "loggedIn",
          },
        ],
      },
    },
    unauthorised: {
      on: {
        LOGIN: "authenticating",
      },
    },
    loggingOut: {
      invoke: {
        id: "logout",
        src: "logout",
        onDone: {
          target: "loggedOut",
        },
        onError: {
          target: "loggedIn",
        },
      },
    },
    loggedIn: {
      on: {
        LOGOUT: "loggingOut",
        TOGGLE_DELEGATE: {
          actions: "setDelegateStatus",
        },
        SET_TOKEN: {
          actions: ["authenticated"],
        },
        HIDE_ONE_HOME_BANNER: {
          actions: "hideOneHomeBanner",
        },
        GO_TO_ONE_HOME: "oneHome"
      },
    },
    oneHome: {
      invoke: {
        src: "requestOneHomeUserToken",
        onDone: {
          target: "loggedIn",
          actions: "redirectToOneHome",
        },
        onError: {
          target: "loggedIn",
        },
      },
    },
    registeringSsoNonUser: {
      entry: "getToRegistrationPage",
      on: {
        LOGIN: "login",
      },
    },
  },
});
