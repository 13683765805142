import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from '@mui/lab/LoadingButton';
import { noop } from "utils/noop";
import { styled } from "@mui/material/styles";
import { FormErrorBar, GqlErrorCodeMessageMap } from "./FormErrorBar";

const PREFIX = "Form";

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  contents: `${PREFIX}-contents`,
  actionButtons: `${PREFIX}-actionButtons`,
  actionBar: `${PREFIX}-actionBar`,
  pushRight: "pushRight",
  pushLeft: "pushLeft",
};

const FormStyled = styled("form")(({ theme }) => ({
  "& > *:not(:first-child)": {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.title}`]: {
    fontSize: 24,
  },

  [`& .${classes.contents}`]: {
    "& > *:not(:first-child)": {
      marginTop: theme.spacing(3),
    },
  },
  [`& .${classes.actionButtons}`]: {
    display: "grid",
    gap: theme.spacing(0.5),
    gridTemplateColumns: "auto 1fr auto",
  },

  [`& .${classes.actionBar}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    marginBottom: theme.spacing(3),
  },
  [`& .${classes.pushRight}`]: {
    justifyContent: "flex-start",
  },
  [`& .${classes.pushLeft}`]: {
    justifyContent: "flex-end",
  },
}));

interface FormProps {
  id: string;
  open?: boolean;
  title?: string;
  error?: Error | null;
  onSubmit?: (...args: any) => any;
  onCancel?: Callback;
  errorBarLabel?: string;
  children: ReactNodes;
  submitting?: boolean;
  noErrorBar?: boolean;
  actionBarLeft?: ReactNodes;
  submitDisabled?: boolean;
  noCancelButton?: boolean;
  noSubmitButton?: boolean;
  noActivityIndicator?: boolean;
  errorCodeMessageMap?: GqlErrorCodeMessageMap;
  "data-testid"?: string;
  "aria-label"?: string;
  pushActionsToRight?: boolean;
}

export function Form({
  id,
  open = true,
  error,
  title,
  children,
  errorBarLabel,
  onSubmit = noop,
  onCancel = noop,
  submitting = false,
  noErrorBar = false,
  actionBarLeft,
  pushActionsToRight = false,
  submitDisabled = false,
  noCancelButton = false,
  noSubmitButton = false,
  noActivityIndicator = false,
  errorCodeMessageMap = {},
  "data-testid": testId = Form.name,
  ...otherProps
}: FormProps) {
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit();
  };

  if (!open) return null;

  return (
    <FormStyled
      id={id}
      data-testid={testId}
      onSubmit={handleSubmit}
      aria-labelledby={title ? "form-title" : undefined}
      aria-label={otherProps["aria-label"]}
      noValidate
      {...otherProps}
    >
      {title && (
        <Typography
          id="form-title"
          variant="h6"
          component="h2"
          className={classes.title}
          data-testid={`${testId}Title`}
        >
          {title}
        </Typography>
      )}

      {!noErrorBar && !!error && (
        <FormErrorBar
          error={error}
          aria-label={errorBarLabel}
          data-testid={`${testId}ErrorBar`}
          gqlCodeMessageMap={errorCodeMessageMap}
        />
      )}

      <div className={classes.contents}>{children}</div>

      {!(noActivityIndicator && noCancelButton && noSubmitButton) && (
        <div className={classes.actionBar}>
          {actionBarLeft}
          <div className={classes.actionButtons}>
            {!noCancelButton && (
              <Button
                onClick={onCancel}
                data-testid={`${testId}CancelButton`}
                children="Cancel"
                variant="outlined"
              />
            )}
            <span/>
            {!noSubmitButton && (
              <LoadingButton
                form={id}
                type="submit"
                loading={submitting}
                loadingPosition="center"
                variant="contained"
                disabled={submitting}
                data-testid={`${testId}SubmitButton`}
                children="Save"
              />
            )}
          </div>
        </div>
      )}
    </FormStyled>
  );
}
