import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { FieldTrueFalseRadioGroup } from "components/FieldTrueFalseRadioGroup";
import WarningIcon from "@mui/icons-material/Warning";
import { noop } from "utils/noop";
import { Facility } from "types/profile";
import { useCreateFacility } from "hooks/useProfile";
import { useLocation } from "wouter";

const PREFIX = "DelegateFacilityMessage";

const classes = {
  dialogContent: `${PREFIX}-dialogContent`,
  dialogActions: `${PREFIX}-dialogActions`,
  dialogActionsLeft: `${PREFIX}-dialogActionsLeft`,
  warningMessage: `${PREFIX}-warningMessage`,
  warningIcon: `${PREFIX}-warningIcon`,
};

const DialogStyled = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogContent}`]: {
    "& > *:not(:first-child)": {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.dialogActions}`]: {
    display: "flex",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },

  [`& .${classes.dialogActionsLeft}`]: {
    flexGrow: 1,
    paddingLeft: "16px",
    position: "relative",
  },

  [`& .${classes.warningMessage}`]: {
    fontSize: "14px",
    color: theme.palette.warning.main,
    marginTop: theme.spacing(2),
  },

  [`& .${classes.warningIcon}`]: {
    fill: theme.palette.warning.main,
    verticalAlign: "-7px",
    marginRight: "5px",
  },
}));

interface DelegateFacilityMessageProps {
  open?: boolean;
  testId?: string;
  onCancel?: Callback;
  confirmationMessage?: string;
  entry: Facility;
  disabled: boolean;
  type: "facility" | "department";
}

export function DelegateFacilityMessage({
  onCancel = noop,
  open = true,
  disabled = true,
  testId = DelegateFacilityMessage.name,
  entry,
  type,
}: DelegateFacilityMessageProps) {
  const [, setLocation] = useLocation();
  const [primaryLocation, setPrimaryLocation] = useState("");
  const entityName =
    type === "facility" ? entry.name : entry.departments[0].name;
  const createFacility = useCreateFacility();
  const redirect = () =>
    setLocation(
      `/facilities/${entry.id}${
        type === "department"
          ? `/department/${
              entry.departments[0].subType || entry.departments[0].type
            }`
          : ""
      }`
    );
  const addEntry = () =>
    createFacility({
      goldenFacilityPk: entry.id,
      departments: type === "department" ? [...entry.departments] : [],
      primaryLocation: primaryLocation === "true",
    }).then(onCancel);
  const addEntryAndRedirect = () =>
    createFacility({
      goldenFacilityPk: entry.id,
      departments: type === "department" ? [...entry.departments] : [],
      primaryLocation: primaryLocation === "true",
    }).then(redirect);

  return (
    <DialogStyled
      open={open}
      onClose={onCancel}
      data-testid={testId}
      aria-labelledby="facility confirmation dialog"
    >
      <DialogTitle id="alert-dialog-title">
        {`Would you like to ${
          disabled ? "be able to" : ""
        } add and edit information for ${entityName}?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {disabled && (
            <Typography>{`You are currently acting as a delegate. For profile management purposes, before you can add and
          edit information for ${entityName} you need to add it to your own profile`}</Typography>
          )}
          <Typography className={classes.warningMessage}>
            <WarningIcon className={classes.warningIcon} />
            Please note you will switch back to your own profile to begin
            editing and any unsaved changes to your delegate profile will be
            lost.
          </Typography>
        </DialogContentText>
          {disabled && (
            <Box sx={{ marginTop:"15px" }}>
              <FieldTrueFalseRadioGroup
                id="primary-location-radio-group"
                aria-label="primary-location-radio-group"
                label="Select this as your primary location for clinical research?(required)"
                value={primaryLocation}
                onChange={(event) => setPrimaryLocation(event.target.value)}
              />
              <Button
                variant="contained"
                onClick={addEntryAndRedirect}
                data-testid={`${testId}addButton`}
                disabled={!primaryLocation}
              >
                Add to my profile and begin editing
              </Button>
              <Button
                onClick={addEntry}
                data-testid={`${testId}addButton`}
                disabled={!primaryLocation}
              >
                Add to my profile, I'll edit later
              </Button>
            </Box>
          )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <div>
          {!disabled && (
            <Button
              variant="contained"
              data-testid={`${testId}redirectButton`}
              onClick={redirect}
            >
              Begin editing
            </Button>
          )}
          <Button
            onClick={onCancel}
            data-testid={`${testId}CancelButton`}
            autoFocus
          >
            Cancel
          </Button>
        </div>
      </DialogActions>
    </DialogStyled>
  );
}
