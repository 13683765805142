import gql from "graphql-tag";
import React from "react";
import { Link } from "wouter";
import { Suspend } from "components/Suspend";
import { useQuery } from "@apollo/client";
import { useProfile } from "hooks/useProfile";
import { ProgressBar } from "../components/ProgressBar";
import { Paper, Typography, Button, Divider, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  Screen,
  ScreenSection,
  ScreenSectionTitle,
} from "../components/Screen";

const CoverImage = styled("div")(({ theme }) => ({
  width: "100%",
  height: 120,
  background: "url(/investigators.png) no-repeat 50%",
  backgroundSize: "cover",
}));

const BottomBar = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr auto auto",
  gridGap: theme.spacing(1),
  marginTop: theme.spacing(4),
}));

const CvText = styled("div")(({ theme }) => ({
  fontSize: "1.1em",
  fontWeight: 400,
}));

export default function CV() {
  const cvExport = useCvExport();
  const profile = useProfile();

  return (
    <Suspend when={profile.loading}>
      <Screen data-testid={CV.name}>
        <ScreenSection>
          <ScreenSectionTitle children="Your CV" />
          <Paper>
            <CoverImage />
            <Box sx={{ p: 3 }}>
              <Typography sx={{ mb: 1 }} variant="h4">
                Turn your profile into an industry standard CV
              </Typography>
              <Typography variant="subtitle2">
                Once your profile is complete, you can download a standard CV
                for sharing with any sponsor company or CRO. This CV template
                was developed in line with TransCelerate guidance on a standard
                CV format. And, we have provided it in a Word file so you can
                edit as required!
              </Typography>
              <Divider sx={{ mb: 3, mt: 3 }} />
              <Typography sx={{ mb: 1 }} variant="h5">
                What next?
              </Typography>
              <CvText>
                Your CV information is <b>{profile.progress.all}%</b> complete
              </CvText>
              <ProgressBar progress={profile.progress.all} />

              <BottomBar>
                <span />
                {profile.progress.all !== 100 ? (
                  <Link href="/">
                    <Button variant="contained" color="secondary">
                      Continue creating your industry standard CV
                    </Button>
                  </Link>
                ) : (
                  <span />
                )}
                <Button
                  variant="contained"
                  color="primary"
                  disabled={cvExport.loading}
                  component={React.forwardRef((props, ref: any) => (
                    <a href={cvExport?.data?.url} download {...props} ref={ref}>
                      Download CV
                    </a>
                  ))}
                />
              </BottomBar>
            </Box>
          </Paper>
        </ScreenSection>
      </Screen>
    </Suspend>
  );
}

export const GET_CV_EXPORT = gql`
  query GetCvExport {
    cvExport {
      url
    }
  }
`;

export function useCvExport() {
  const result = useQuery(GET_CV_EXPORT, { fetchPolicy: "network-only" });
  return { ...result, data: result.data?.cvExport };
}
