import React from "react";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import { IconButton, Typography } from "@mui/material";
import { Form } from "./Form";
import { noop } from "utils/noop";
import { useForm } from "hooks/useForm";
import { Facility } from "types/profile";
import { required } from "validators/required";
import { DialogTrigger } from "./DialogTrigger";
import { isValidDepartments } from "utils/department";
import { CountrySelectField } from "./CountrySelectField";
import { StateAutocompleteField } from "./StateAutocompleteField";
import { FacilityDepartmentField } from "./FacilityDepartmentField";
import { FacilityDefinitionDialog } from "./FacilityDefinitionDialog";
import { FacilityAutocompleteField } from "./FacilityAutocompleteField";

import {
  useChangeReasonFormDialog,
  ChangeReasonFormDialog,
} from "./ChangeReasonFormDialog";

import { FieldTrueFalseRadioGroup } from "./FieldTrueFalseRadioGroup";

import { Link } from "wouter";

const PREFIX = "FacilityCreationForm";

const classes = {
  tooltip: `${PREFIX}-tooltip`,
  helpText: `${PREFIX}-helpText`,
  trialFieldWrapper: `${PREFIX}-trialFieldWrapper`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.tooltip}`]: {
    fontSize: 12,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.black,
  },

  [`& .${classes.helpText}`]: {
    fontStyle: "italic",
    fontSize: 12,
    marginTop: theme.spacing(1),
  },

  [`& .${classes.trialFieldWrapper}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gridColumnGap: theme.spacing(1),
    alignItems: "center",
  },
}));

export interface FacilityCreationFormProps {
  open?: boolean;
  title?: string;
  submit: (values: Facility, adminComment?: string) => Promise<any>;
  onError?: Callback;
  onCancel?: Callback;
  onSuccess?: Callback;
  withReason?: boolean;
  "data-testid"?: string;
}

export function FacilityCreationForm({
  open = false,
  title = "Add a facility",
  onError = console.error,
  onCancel = noop,
  onSuccess = noop,
  withReason = false,
  submit: doSubmit,
  "data-testid": testId = FacilityCreationForm.name,
  ...otherProps
}: FacilityCreationFormProps) {
  const reasonForm = useChangeReasonFormDialog({
    doSubmit: performSubmit,
    formHookSubmit,
    withReason,
  });

  const { id, fields, submit, reset, submitting, error } = useForm({
    id: "facility-creation-form",
    fields: {
      countryCode: {
        name: "countryCode",
        required: true,
        validate: required,
        dependantFields: ["facility", "state"],
      },
      state: {
        name: "state",
        defaultValue: null,
      },
      facility: {
        name: "facility",
        label: "Facility name",
        required: true,
        validate: required,
      },
      hasDepartment: {
        name: "hasDepartment",
        required: true,
        validate: required,
        dependantFields: ["departments"],
        "aria-label": "has department",
      },
      departments: {
        name: "departments",
        defaultValue: [],
        validate(departments) {
          return hasDepartment() ? isValidDepartments(departments.value) : true;
        },
      },
      isPrimaryLocation: {
        name: "isPrimaryLocation",
        required: true,
        validate: required,
        "aria-label": "is primary location",
      },
    },
    submit: reasonForm.performSubmit,
    onReset: onCancel,
    onError,
    onSuccess,
  });

  async function performSubmit(values: AnyObject, adminComment?: string) {
    const packet: any = {
      goldenFacilityPk: values.facility.id,
      primaryLocation: values.isPrimaryLocation === "true",
      departments: [],
    };

    if (values.hasDepartment === "true") {
      packet.departments.push(...values.departments);
    }

    return await doSubmit(packet as any, adminComment);
  }

  function formHookSubmit() {
    return submit();
  }

  function hasDepartment() {
    return fields.hasDepartment.value === "true";
  }

  function isHasDepartmentFieldEnabled() {
    return Boolean(fields.countryCode.value && fields.facility.value);
  }

  return (
    <Root>
      <Form
        id={id}
        open={open}
        title={title}
        error={error}
        onCancel={reset}
        onSubmit={reasonForm.handleSubmitButtonClick}
        submitting={submitting}
        data-testid={`${testId}Form`}
        pushActionsToRight={true}
        {...otherProps}
      >
        <div>
          <CountrySelectField {...fields.countryCode} fullWidth={true} />

          {fields.countryCode.value === "US" && (
            <StateAutocompleteField
              countryCode={fields.countryCode.value}
              fullWidth={true}
              {...fields.state}
            />
          )}
          <div className={classes.trialFieldWrapper}>
            <FacilityAutocompleteField
              aria-label="Enter facility name"
              countryCode={fields.countryCode.value}
              region={fields.state.value}
              fullWidth={true}
              {...fields.facility}
            />

            <DialogTrigger
              dialogCloseProp="onConfirm"
              dialog={FacilityDefinitionDialog}
            >
              <IconButton aria-label="What is a facility?" size="small">
                <InfoIcon color="primary" />
              </IconButton>
            </DialogTrigger>
          </div>
          <Typography variant="subtitle2" className={classes.helpText}>
            Please select an affiliation that most closely matches your Facility
            recognizing that for big institutions the name and address may be
            related to the main hospital, rather than your department.
          </Typography>
          <Typography variant="subtitle2" className={classes.helpText}>
            If you are unable to find your facility please{" "}
            <Link href="/contact-us">contact us</Link>.
          </Typography>
        </div>

        <FieldTrueFalseRadioGroup
          label="Do you work in a department?"
          {...fields.hasDepartment}
          disabled={!isHasDepartmentFieldEnabled()}
        />

        {hasDepartment() && (
          <FacilityDepartmentField
            facility={fields.facility.value}
            {...fields.departments}
          />
        )}

        <FieldTrueFalseRadioGroup
          label="Select this as your primary location for clinical research?"
          {...fields.isPrimaryLocation}
        />
      </Form>
      <ChangeReasonFormDialog {...reasonForm.reasonDialog} />
    </Root>
  );
}
