import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import InfoIcon from "@mui/icons-material/Info";
import { Licence } from "types/profile";
import { maxLengthForValidator as maxLength } from "validators/maxLength";
import { url } from "validators/url";
import { validators } from "utils/forms";
import { isDateValid } from "utils/date";
import { FormDialog } from "./FormDialog";
import { useCoreData } from "hooks/useCoreData";
import { FormDateField } from "./FormDateField";
import { noop, asyncNoop } from "utils/noop";
import { ErrorBar } from "./ErrorBar";
import { CountrySelectField } from "./CountrySelectField";
import { LicenceSelectField } from "./LicenceSelectField";
import { TextFieldWithWarning } from "./TextFieldWithWarning";
import { StateAutocompleteField } from "./StateAutocompleteField";
import { Popover, IconButton, Typography } from "@mui/material";
import { useForm, SubmitFn, Field, Fields } from "hooks/useForm";
import { required, REQUIRED_ERROR_MESSAGE } from "validators/required";

import {
  ChangeReasonFormDialog,
  useChangeReasonFormDialog,
} from "./ChangeReasonFormDialog";

export const NPI_INVALID = `NPI numbers are 10-digits and can't contain letters`;

const defaultLicence = {} as Licence;

interface Props {
  title?: string;
  open?: boolean;
  entry?: Licence;
  testId?: string;
  submit?: SubmitFn;
  onCancel?: Callback;
  onSuccess?: Callback;
  withReason?: boolean;
  isInvestigator?: boolean;
  flagIncompleteFields?: boolean;
  "data-testid"?: string;
}

export function LicenceForm({
  open,
  entry,
  isInvestigator = false,
  title = "Update licence or certification",
  submit: doSubmit = asyncNoop,
  onCancel: doCancel = noop,
  withReason = false,
  onSuccess = noop,
  "data-testid": testId = LicenceForm.name,
  flagIncompleteFields = false,
  ...otherProps
}: Props) {
  const licence = entry || defaultLicence;
  const { hasStates } = useCoreData();
  const [popoverRef, setPopoverRef] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const reasonForm = useChangeReasonFormDialog({
    doSubmit,
    formHookSubmit,
    withReason,
  });

  const { id, fields, submit, reset, submitting, error } = useForm({
    id: "licence-form",
    fields: {
      id: {
        name: "id",
        value: licence.id,
      },
      countryCode: {
        name: "countryCode",
        value: licence.countryCode,
        validate: required,
        required: true,
        dependantFields: ["state"],
      },
      state: {
        name: "state",
        label: "State/Province/County",
        value: licence.state,
        validate: isRequiredState(hasStates),
        required: true,
      },
      npi: {
        name: "npi",
        label: "National Provider Identifier (NPI)",
        value: licence.npi,
        validate: isValidNpi(isInvestigator),
        required: false,
      },
      type: {
        id: "type",
        name: "type",
        label: "Licence/certification type",
        value: licence.type,
        required: true,
        validate: required,
      },
      issuer: {
        label: "Licence or certification issued by",
        name: "issuer",
        value: licence.issuer,
        validate: validators(required, maxLength(127)),
        required: true,
      },
      number: {
        label: "Licence or certification number",
        name: "number",
        value: licence.number,
        validate: validators(required, maxLength(100)),
        required: true,
      },
      issueDate: {
        name: "issueDate",
        label: "Issue date",
        "aria-label": "Issue time",
        value: licence.issueDate,
        validate: validators(required, isDateValid),
        required: true,
        defaultValue: null,
      },
      expirationDate: {
        name: "expirationDate",
        label: "Expiration date",
        "aria-label": "Expiration time",
        value: licence.expirationDate,
        validate: isDateValid,
        required: false,
        defaultValue: null,
      },
      digitalBadgeUrl: {
        name: "digitalBadgeUrl",
        label: "Digital Badge (if issued by your certification body)",
        "aria-label": "Digital Badge Url",
        value: licence.digitalBadgeUrl,
        validate: (maxLength(500), url),
        required: false,
        defaultValue: null,
      },
    },
    submit: reasonForm.performSubmit,
    onReset: doCancel,
    onError: () => {},
    onSuccess,
  });

  function formHookSubmit() {
    return submit();
  }

  const maxIssueDate = new Date();
  const minExpirationDate = fields.issueDate.value || maxIssueDate;

  let errorMessage = error?.message;

  return (
    <>
      <FormDialog
        id={id}
        open={open}
        title={title}
        onCancel={reset}
        onSubmit={reasonForm.handleSubmitButtonClick}
        maxWidth="sm"
        data-testid={testId}
        showProgressIndicator={submitting}
        {...otherProps}
      >
        {errorMessage && <ErrorBar message={errorMessage} />}

        <FormGroup>
          <CountrySelectField
            data-testid={`${testId}CountryCodeField`}
            {...fields.countryCode}
          />

          {fields.countryCode.value === "US" &&
            hasStates(fields.countryCode.value) && (
              <StateAutocompleteField
                data-testid={`${testId}StateField`}
                countryCode={fields.countryCode.value}
                fullWidth
                {...fields.state}
              />
            )}

          {fields.countryCode.value === "US" && isInvestigator && (
            <>
              <TextField
                data-testid={`${testId}NpiField`}
                {...fields.npi}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size="small"
                      onClick={(event) => {
                        //@ts-ignore
                        setPopoverRef(event.target);
                        setPopoverOpen(!popoverOpen);
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  ),
                }}
              />
              {popoverRef !== null && (
                <Popover
                  anchorEl={popoverRef}
                  onClose={() => {
                    setPopoverOpen(false);
                  }}
                  open={popoverOpen}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Typography
                    style={{ padding: 16, width: 400 }}
                    variant="body2"
                  >
                    The NPI is a unique 10-digit identification number issued to
                    health care providers in the United States by the Centers
                    for Medicare and Medicaid Services (CMS).
                  </Typography>
                </Popover>
              )}
            </>
          )}

          <LicenceSelectField
            data-testid={`${testId}TypeField`}
            {...fields.type}
          />

          <TextFieldWithWarning
            data-testid={`${testId}IssuerField`}
            warn={flagIncompleteFields && !fields.issuer.value}
            {...fields.issuer}
          />

          <TextField data-testid={`${testId}NumberField`} {...fields.number} />

          <TextField
            data-testid={`${testId}DigitalBadgeField`}
            {...fields.digitalBadgeUrl}
          />

          <FormGroup row>
            <FormDateField
              maxDate={maxIssueDate}
              data-testid={`${testId}IssueDateField`}
              {...fields.issueDate}
            />

            <FormDateField
              minDate={minExpirationDate}
              data-testid={`${testId}ExpirationDateField`}
              {...fields.expirationDate}
            />
          </FormGroup>
        </FormGroup>
      </FormDialog>
      <ChangeReasonFormDialog {...reasonForm.reasonDialog} />
    </>
  );
}

export function isValidNpi(isInvestigator: boolean) {
  return (field: Field, fields: Fields) => {
    if (fields.countryCode.value === "US" && isInvestigator && field.value) {
      const npiRegex = /^[0-9]{10}$/;
      const isValid = field.value && field.value.match(npiRegex);
      if (!isValid) return NPI_INVALID;
    }
  };
}

function isRequiredState(hasStates: Function) {
  return (field: Field, fields: Fields) => {
    if (
      fields.countryCode.value === "US" &&
      hasStates(fields.countryCode.value) &&
      !field.value
    ) {
      return REQUIRED_ERROR_MESSAGE;
    }
  };
}
