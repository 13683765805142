import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Redirect } from "wouter";

const CONFIRM_OPT_IN_MUTATION = gql`
  mutation confirmOptIn {
    confirmOptIn {
      status
    }
  }
`;

export function ConfirmOptIn() {
  let [confirmOptIn, data] = useMutation(CONFIRM_OPT_IN_MUTATION);

  useEffect(() => {
    confirmOptIn().catch(() => {});
  }, [confirmOptIn]);

  if (data.called && !data.loading) {
    return data.error ? <Redirect to="/" /> : <Redirect to="/?optedIn" />;
  }
  return null;
}
