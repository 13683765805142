import React from "react";
import { FormGroup, Typography, Link as MuiLink } from "@mui/material";

import { useEffect } from "react";
import { FieldInfo } from "./FieldInfo";
import { styled } from "@mui/material/styles";
import { useForm, Field } from "hooks/useForm";
import { asyncNoop, noop } from "utils/noop";
import { FormCheckboxField } from "./FormCheckboxField";
import { Link as WouterLink } from "wouter";
import { PopulationKeyValue, Ethnicity } from "types/facility";

import {
  fieldsToPopulationKeyValueArray,
  toPopulationValuesObj,
} from "utils/department";

const defaultValue = [] as PopulationKeyValue[];

const Root = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridRowGap: theme.spacing(2),
}));

const FormGroupStyled = styled(FormGroup)(() => ({
  display: "grid",
  alignContent: "start",
}));

const CheckboxesWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridRowGap: theme.spacing(4),
  gridColumnGap: theme.spacing(2),
}));

const NorthEastAsianWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridRowGap: theme.spacing(1),
}));

const NorthEastAsianWrapperCheckboxRow = styled(FormGroup)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridRowGap: theme.spacing(1),
}));

interface PatientPopulationEthnicitiesFieldProps {
  value?: PopulationKeyValue[];
  onChange: Callback;
  form: string;
  ethnicities: Ethnicity[];
  [key: string]: any;
}

export function PatientPopulationEthnicitiesField({
  value = defaultValue,
  ethnicities,
  form,
  onChange,
}: PatientPopulationEthnicitiesFieldProps) {
  const values = toPopulationValuesObj(value);
  const { fields, setField } = useForm({
    id: form,
    fields: {
      // Africa
      central_and_west_african: {
        type: "checkbox",
        name: "central_and_west_african",
        label: "Central and West African",
        value: values.central_and_west_african,
      },
      north_african: {
        type: "checkbox",
        name: "north_african",
        label: "North African",
        value: values.north_african,
      },
      southern_and_east_african: {
        type: "checkbox",
        name: "southern_and_east_african",
        label: "Southern and East African",
        value: values.southern_and_east_african,
      },
      // Asia
      central_asian: {
        type: "checkbox",
        name: "central_asian",
        label: "Central Asian",
        value: values.central_asian,
      },
      middle_eastern: {
        type: "checkbox",
        name: "middle_eastern",
        label: "Middle Eastern",
        value: values.middle_eastern,
      },
      north_east_asian: {
        type: "checkbox",
        name: "north_east_asian",
        label: "North East Asian",
        value: values.north_east_asian,
        dependantFields: ["chinese", "japanese", "korean"],
      },
      southern_asian: {
        type: "checkbox",
        name: "southern_asian",
        label: "Southern Asian",
        value: values.southern_asian,
      },
      south_east_asian: {
        type: "checkbox",
        name: "south_east_asian",
        label: "South East Asian",
        value: values.south_east_asian,
      },
      // Europe
      eastern_european: {
        type: "checkbox",
        name: "eastern_european",
        label: "Eastern European",
        value: values.eastern_european,
      },
      northern_european: {
        type: "checkbox",
        name: "northern_european",
        label: "Northern European",
        value: values.northern_european,
      },
      southern_european: {
        type: "checkbox",
        name: "southern_european",
        label: "Southern European",
        value: values.southern_european,
      },
      western_european: {
        type: "checkbox",
        name: "western_european",
        label: "Western European",
        value: values.western_european,
      },
      // Oceania
      australian_peoples: {
        type: "checkbox",
        name: "australian_peoples",
        label: "Australian peoples",
        value: values.australian_peoples,
      },
      new_zealand_peoples: {
        type: "checkbox",
        name: "new_zealand_peoples",
        label: "New Zealand Peoples",
        value: values.new_zealand_peoples,
      },
      pacific_islanders: {
        type: "checkbox",
        name: "pacific_islanders",
        label: "Pacific Islanders",
        value: values.pacific_islanders,
      },
      // The Americas
      caribbean_islanders: {
        type: "checkbox",
        name: "caribbean_islanders",
        label: "Caribbean Islanders",
        value: values.caribbean_islanders,
      },
      central_american: {
        type: "checkbox",
        name: "central_american",
        label: "Central American",
        value: values.central_american,
      },
      north_american: {
        type: "checkbox",
        name: "north_american",
        label: "North American",
        value: values.north_american,
      },
      south_american: {
        type: "checkbox",
        name: "south_american",
        label: "South American",
        value: values.south_american,
      },
      // North east asia
      chinese: {
        type: "checkbox",
        name: "chinese",
        label: "Chinese",
        value: values.chinese || false,
      },
      japanese: {
        type: "checkbox",
        name: "japanese",
        label: "Japanese",
        value: values.japanese || false,
      },
      korean: {
        type: "checkbox",
        name: "korean",
        label: "Korean",
        value: values.korean || false,
      },
    },
    submit: asyncNoop,
    onError: noop,
    onChange: (fields: any) => {
      onChange(fieldsToPopulationKeyValueArray(fields));
    },
  });

  useEffect(() => {
    for (const item of value) {
      setField(item.key, item.value);
    }
  }, [JSON.stringify(value)]); // eslint-disable-line

  function fieldDesc(name: any): any {
    return ethnicities.find((e) => e.value === name)!.subGroups.join(", ");
  }

  return (
    <Root>
      <Typography variant="h6">
        Does your site have access to patients from any of the following
        population groups / geographic backgrounds for enrolment in clinical
        trials? (select all those that make up 15% or more of your population):
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        These data are being collected to support sponsors in ensuring
        representation from diverse populations in their clinical trials, and to
        support enrolment of specific patient groups for targeted trials. Need
        help?{" "}
        <WouterLink href="/contact-us">
          <MuiLink underline="hover">Contact us</MuiLink>
        </WouterLink>
        .
      </Typography>

      <CheckboxesWrapper>
        <FormGroupStyled>
          <Typography variant="subtitle1" color="textSecondary">
            Africa
          </Typography>
          <FieldInfo info={fieldDesc(fields.central_and_west_african.name)}>
            <CheckboxField {...fields.central_and_west_african} />
          </FieldInfo>
          <FieldInfo info={fieldDesc(fields.north_african.name)}>
            <CheckboxField {...fields.north_african} />
          </FieldInfo>
          <FieldInfo info={fieldDesc(fields.southern_and_east_african.name)}>
            <CheckboxField {...fields.southern_and_east_african} />
          </FieldInfo>
        </FormGroupStyled>

        <FormGroupStyled>
          <Typography variant="subtitle1" color="textSecondary">
            Asia
          </Typography>
          <FieldInfo info={fieldDesc(fields.central_asian.name)}>
            <CheckboxField {...fields.central_asian} />
          </FieldInfo>
          <FieldInfo info={fieldDesc(fields.middle_eastern.name)}>
            <CheckboxField {...fields.middle_eastern} />
          </FieldInfo>
          <FieldInfo info={fieldDesc(fields.north_east_asian.name)}>
            <CheckboxField {...fields.north_east_asian} />
          </FieldInfo>
          <FieldInfo info={fieldDesc(fields.southern_asian.name)}>
            <CheckboxField {...fields.southern_asian} />
          </FieldInfo>
          <FieldInfo info={fieldDesc(fields.south_east_asian.name)}>
            <CheckboxField {...fields.south_east_asian} />
          </FieldInfo>
        </FormGroupStyled>

        <FormGroupStyled>
          <Typography variant="subtitle1" color="textSecondary">
            European
          </Typography>
          <FieldInfo info={fieldDesc(fields.eastern_european.name)}>
            <CheckboxField {...fields.eastern_european} />
          </FieldInfo>
          <FieldInfo info={fieldDesc(fields.northern_european.name)}>
            <CheckboxField {...fields.northern_european} />
          </FieldInfo>
          <FieldInfo info={fieldDesc(fields.southern_european.name)}>
            <CheckboxField {...fields.southern_european} />
          </FieldInfo>
          <FieldInfo info={fieldDesc(fields.western_european.name)}>
            <CheckboxField {...fields.western_european} />
          </FieldInfo>
        </FormGroupStyled>

        <FormGroupStyled>
          <Typography variant="subtitle1" color="textSecondary">
            Oceania
          </Typography>
          <FieldInfo info={fieldDesc(fields.australian_peoples.name)}>
            <CheckboxField {...fields.australian_peoples} />
          </FieldInfo>
          <FieldInfo info={fieldDesc(fields.new_zealand_peoples.name)}>
            <CheckboxField {...fields.new_zealand_peoples} />
          </FieldInfo>
          <FieldInfo info={fieldDesc(fields.pacific_islanders.name)}>
            <CheckboxField {...fields.pacific_islanders} />
          </FieldInfo>
        </FormGroupStyled>

        <FormGroupStyled>
          <Typography variant="subtitle1" color="textSecondary">
            The Americas
          </Typography>
          <FieldInfo info={fieldDesc(fields.caribbean_islanders.name)}>
            <CheckboxField {...fields.caribbean_islanders} />
          </FieldInfo>
          <FieldInfo info={fieldDesc(fields.central_american.name)}>
            <CheckboxField {...fields.central_american} />
          </FieldInfo>
          <FieldInfo info={fieldDesc(fields.north_american.name)}>
            <CheckboxField {...fields.north_american} />
          </FieldInfo>
          <FieldInfo info={fieldDesc(fields.south_american.name)}>
            <CheckboxField {...fields.south_american} />
          </FieldInfo>
        </FormGroupStyled>
      </CheckboxesWrapper>

      {fields.north_east_asian.value && (
        <NorthEastAsianWrapper>
          <Typography variant="h6">
            To support regulatory requirements for data generated from local
            patients, please indicate if a significant portion of your
            population are:
          </Typography>

          <NorthEastAsianWrapperCheckboxRow row>
            <CheckboxField {...fields.chinese} />
            <CheckboxField {...fields.japanese} />
            <CheckboxField {...fields.korean} />
          </NorthEastAsianWrapperCheckboxRow>
        </NorthEastAsianWrapper>
      )}
    </Root>
  );
}

function CheckboxField(props: Field) {
  return <FormCheckboxField sx={{ checkbox: { pr: 1 } }} {...props} />;
}
