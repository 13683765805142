import React from "react";

import { Card } from "components/Card";
import { useEditableData } from "hooks/useEditableData";
import { DepartmentAboutForm } from "components/DepartmentAboutForm";
import { DepartmentProfileHookData } from "hooks/useFacilityDepartmentProfile";
import { DepartmentProfile, DepartmentDetails } from "types/facility";
import { CardMachine, useCard, ManagedCardProps } from "hooks/useCard";

export const DepartmentDetailsCardMachine = CardMachine({ validateCard });

interface DepartmentDetailsCardProps extends ManagedCardProps {
  profile: DepartmentProfile;
  updateDepartmentDetails: (details: DepartmentDetails) => Promise<any>;
  "data-testid"?: string;
}

export function DepartmentDetailsCard({
  id = "details-card",
  service,
  profile,
  updateDepartmentDetails,
  "data-testid": testId = DepartmentDetailsCard.name,
  ...otherProps
}: DepartmentDetailsCardProps) {
  const { card } = useCard({ service });
  const { departmentDetails, facilityDetails } = profile;
  const { updateForm } = useEditableData({
    update: updateDepartmentDetails,
  });

  return (
    <Card
      id={id}
      heading={`About: ${departmentDetails.departmentName} - ${facilityDetails.name}`}
      data-testid={testId}
      {...card}
      {...otherProps}
    >
      <DepartmentAboutForm
        {...updateForm}
        entry={profile.departmentDetails}
        open
      />
    </Card>
  );
}

function validateCard(data?: DepartmentProfileHookData) {
  const message = "Please tell us more about your department";
  if (!data) return message;
  const { departmentDetails } = data?.profile;
  const isComplete = Boolean(
    departmentDetails &&
      departmentDetails.departmentName &&
      departmentDetails.type &&
      departmentDetails.address &&
      departmentDetails.countryCode &&
      departmentDetails.postal
  );
  if (!isComplete) {
    return "Please tell us more about your department";
  }
}
