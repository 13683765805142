import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { noop } from "utils/noop";
import { Qualification } from "types/profile";

import {
  TablePane,
  TableHeadRow,
  TableBodyRow,
  TableActionsCell,
  TableEditActionButton,
  TableDeleteActionButton,
  TablePaneFooter,
  TablePaneButton,
} from "./Table";

interface Props {
  testId?: string;
  entries?: Qualification[];
  maxEntries?: number;
  onCreateButtonClick?: Callback;
  onUpdateButtonClick?: Callback;
  onDeleteButtonClick?: Callback;
  "data-testid"?: string;
}

export function EducationTable({
  entries = [],
  onCreateButtonClick = noop,
  onUpdateButtonClick = noop,
  onDeleteButtonClick = noop,
  "data-testid": testId = EducationTable.name,
}: Props) {
  return (
    <TablePane>
      <Table data-testid={testId}>
        <TableHead>
          <TableHeadRow>
            <TableCell>University name</TableCell>
            <TableCell>Degree or certificate</TableCell>
            <TableCell>From</TableCell>
            <TableCell>To</TableCell>
            <TableCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {entries.map((entry) => (
            <TableBodyRow key={entry.id} data-testid={`${testId}BodyRow`}>
              <TableCell>{entry.university}</TableCell>
              <TableCell>{entry.qualification}</TableCell>
              <TableCell>{entry.yearStarted}</TableCell>
              <TableCell>{entry.yearFinished}</TableCell>
              <TableActionsCell>
                <TableEditActionButton
                  onClick={() => onUpdateButtonClick(entry)}
                  data-testid={`${testId}UpdateEntryButton`}
                />
                <TableDeleteActionButton
                  onClick={() => onDeleteButtonClick(entry)}
                  data-testid={`${testId}DeleteEntryButton`}
                />
              </TableActionsCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
      <TablePaneFooter>
        <TablePaneButton
          icon={AddCircleIcon}
          label="Add more qualifications"
          onClick={onCreateButtonClick}
          data-testid={`${testId}CreateEntryButton`}
        />
      </TablePaneFooter>
    </TablePane>
  );
}
