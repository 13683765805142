let siteStaffJobTitles = [
  { label: "I met or exceeded my enrollment target", value: "EnTarget" },
  {
    label: "We had delays in start up (e.g. contracting, ethics, drug supply)",
    value: "Delays",
  },
  {
    label: "The study design changed and/or recruitment was put on hold",
    value: "DesignChanged",
  },
  {
    label:
      "The study timelines/enrollment targets were not achievable (e.g. problems in accessing protocol tests, fewer eligible patients than expected, higher than expected screen fail rate, low patient interest)",
    value: "TimelineUnachievable",
  },
  {
    label: "Our site was recruited as a back-up or rescue site",
    value: "BackupSite",
  },
  {
    label:
      "There were other external factors outside our control that impacted recruitment (e.g. competing studies, publicity)",
    value: "ExternalFactors",
  },
];

export default siteStaffJobTitles;
