import React from "react";

import { Card } from "components/Card";
import { MutatorFn } from "hooks/useCards";
import { DeletionDialog } from "./DeletionDialog";
import { useEditableData } from "hooks/useEditableData";
import { Specialty, Profile } from "types/profile";
import { MedicalSpecialtyForm } from "./MedicalSpecialtyForm";
import { MedicalSpecialtyTable } from "./MedicalSpecialtyTable";
import { CardMachine, ManagedCardProps, useCard } from "hooks/useCard";

export const MedicalSpecialtyCardMachine = CardMachine({ validateCard });

interface MedicalSpecialtyCardProps extends ManagedCardProps {
  userPk?: number;
  isAdmin?: boolean;
  specialties: Specialty[];
  createSpecialty: MutatorFn;
  updateSpecialty: MutatorFn;
  deleteSpecialty: MutatorFn;
}

export function MedicalSpecialtyCard({
  id = "medical-specialty-card",
  userPk,
  service,
  isAdmin = false,
  specialties,
  createSpecialty,
  updateSpecialty,
  deleteSpecialty,
  ...otherProps
}: MedicalSpecialtyCardProps) {
  const withReason = isAdmin;
  const { card } = useCard({ service });
  const { details, creationForm, updateForm, deletionDialog } = useEditableData(
    {
      create: createSpecialty,
      update: updateSpecialty,
      delete: deleteSpecialty,
    }
  );

  return (
    <Card
      id={id}
      heading="Medical Specialty"
      arial-label="medicalSpecialty"
      data-testid={MedicalSpecialtyCard.name}
      {...card}
      {...otherProps}
    >
      <MedicalSpecialtyTable entries={specialties} {...details} />

      {creationForm.open && (
        <MedicalSpecialtyForm
          title="Add medical specialty"
          withReason={withReason}
          data-testid="MedicalSpecialtyCreationForm"
          {...creationForm}
        />
      )}

      {updateForm.open && (
        <MedicalSpecialtyForm
          title="Update medical specialty"
          withReason={withReason}
          data-testid="MedicalSpecialtyUpdateForm"
          {...updateForm}
        />
      )}

      {deletionDialog.open && (
        <DeletionDialog {...deletionDialog} withReason={withReason} />
      )}
    </Card>
  );
}

export function validateCard({ specialties }: Profile) {
  if (!specialties.length) {
    return "Please add a specialty";
  }
}
