import React from "react";
import { Route, Redirect, RouteProps, useLocation } from "wouter";
import { useAuth } from "../hooks/useAuth";

interface AuthRoutesProps {
  children: ReactNodes;
}

export function AuthRoutes({ children }: AuthRoutesProps) {
  const { isLoggedIn, isAuthenticating } = useAuth();
  const [_location] = useLocation();
  const search = new URLSearchParams(window.location.search);

  if (isAuthenticating) {
    return null;
  }

  if (_location.includes("/login") && isLoggedIn) {
    return <Redirect to={search.get("redirect") || "/"} />;
  }

  return <>{children}</>;
}

export function ProtectedRoute(props: RouteProps) {
  const auth = useAuth();

  return !auth.isLoggedIn && !auth.isAuthenticating ? (
    <Redirect to={auth?.loginPagePath ?? "/"} />
  ) : (
    <Route {...props} />
  );
}
