import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import { amber, green } from "@mui/material/colors";
import {
  IconButton,
  Box,
  Snackbar as MuiSnackbar,
  SnackbarContent as MuiSnackbarContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { noop } from "utils/noop";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const SnackbarStyled = styled(MuiSnackbar)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  position: "relative",
  "&.MuiSnackbar-root": {
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    transform: "none",
  },
}));

const SnackbarContentStyled = styled(MuiSnackbarContent)<{
  "color-variant": keyof typeof variantIcon;
}>(({ theme, "color-variant": colorVariant }) => {
  let backgroundColor = "";
  switch (colorVariant) {
    case "success":
      backgroundColor = green[600];
      break;
    case "error":
      backgroundColor = theme.palette.error.dark;
      break;
    case "info":
      backgroundColor = theme.palette.primary.main;
      break;
    case "warning":
      backgroundColor = amber[700];
      break;
    default:
      break;
  }

  return {
    width: "100%",
    backgroundColor,
  };
});

const Message = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "top",
}));

export interface Props {
  message?: string;
  onClose?: () => void;
  variant: keyof typeof variantIcon;
  noActionButton?: boolean;
}

function SnackbarContent({
  message,
  onClose,
  variant,
  noActionButton,
  ...other
}: Props) {
  const Icon = variantIcon[variant];
  const actionButton = noActionButton
    ? []
    : [
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
          size="large">
          <CloseIcon sx={{ fontSize: 20 }} />
        </IconButton>,
      ];

  return (
    <SnackbarContentStyled
      color-variant={variant}
      aria-describedby="client-snackbar"
      message={
        <Message id="client-snackbar">
          <Icon sx={{ fontSize: 20, opacity: 0.9, mr: 1 }} />
          {message}
        </Message>
      }
      action={actionButton}
      {...other}
    />
  );
}

interface SnackbarProps {
  role?: string;
  testId?: string;
  message: string | ReactNodes;
  contentId?: string;
  open: boolean;
  onClose?(): void;
  variant: keyof typeof variantIcon;
  noActionButton?: boolean;
  "data-testid"?: string;
}

export function Snackbar({
  "data-testid": testId = Snackbar.name,
  variant,
  message,
  open,
  onClose = noop,
  contentId = "error-message-content",
  noActionButton,
  ...otherProps
}: SnackbarProps) {
  return (
    <SnackbarStyled open={open} data-testid={testId} {...(otherProps as any)}>
      <Box sx={{ width: 1 }} id={contentId}>
        <SnackbarContent
          variant={variant}
          message={message}
          onClose={onClose}
          noActionButton={noActionButton}
        />
      </Box>
    </SnackbarStyled>
  );
}
