import React from "react";
import { styled } from "@mui/material/styles";
import CheckCircle from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  BoxProps,
} from "@mui/material";
import { ReactNode } from "react";
import clsx from "clsx";

const PREFIX = "Card";

const classes = {
  root: `${PREFIX}-root`,
  heading: `${PREFIX}-heading`,
  subHeading: `${PREFIX}-subHeading`,
  summaryContainer: `${PREFIX}-summaryContainer`,
  successIcon: `${PREFIX}-successIcon`,
  warningIcon: `${PREFIX}-warningIcon`,
  expandIcon: `${PREFIX}-expandIcon`,
  warningMessage: `${PREFIX}-warningMessage`,
  warningBorder: `${PREFIX}-warningBorder`,
  details: `${PREFIX}-details`,
  panel: `${PREFIX}-panel`,
};

const BoxStyled = styled(Box)(({ theme }) => {
  return {
    [`&.${classes.root}`]: {
      width: "100%",
      color: theme.palette.grey[800],
    },
    [`& .${classes.heading}`]: {
      fontSize: "20px",
      color: theme.palette.grey[800],
    },
    [`& .${classes.subHeading}`]: {
      fontSize: "14px",
      opacity: 0.6,
    },
    [`& .${classes.summaryContainer}`]: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      marginRight: theme.spacing(1),
    },
    [`& .${classes.successIcon}`]: {
      fontSize: "1.7rem",
      fill: theme.palette.success.main,
    },
    [`& .${classes.warningIcon}`]: {
      fontSize: "1.7rem",
      fill: theme.palette.warning.main,
    },
    [`& .${classes.expandIcon}`]: {
      fontSize: "1.7rem",
    },
    [`& .${classes.warningMessage}`]: {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.warning.main,
    },
    [`&.${classes.warningBorder}`]: {
      borderLeft: `5px solid ${theme.palette.warning.main}`,
      borderRadius: "4px",
      background: theme.palette.warning.main,
    },
    [`& .${classes.details}`]: {
      width: "100%",
    },
    [`& .${classes.panel}`]: {
      transition: "box-shadow 0.3s ease-in-out ",
    },
  };
});

export interface CardProps extends Omit<BoxProps, "onChange"> {
  id: string;
  heading: string;
  subHeading?: ReactNode;
  expanded?: boolean;
  noExpandIcon?: boolean;
  showExtraWarning?: boolean;
  hideSubHeadingDuringWarning?: boolean;
  showSuccessStatusIcon?: boolean;
  showWarningStatusIcon?: boolean;
  warningMessage?: string;
  onChange?: (event: React.ChangeEvent<{}>, expanded: boolean) => void;
  "data-testid"?: string;
}

export function Card({
  id,
  children,
  onChange,
  onClick,
  heading,
  subHeading,
  expanded,
  showExtraWarning,
  showSuccessStatusIcon,
  showWarningStatusIcon,
  warningMessage,
  noExpandIcon = false,
  hideSubHeadingDuringWarning = false,
  "data-testid": testId = Card.name,
  ...otherProps
}: CardProps) {
  const [open, setOpen] = React.useState(expanded || false);
  const ExpandIcon = noExpandIcon ? () => null : ExpandMoreIcon;
  const handleExpansionChange = (
    event: React.ChangeEvent<{}>,
    expanded: boolean
  ) => {
    setOpen(expanded);
    if (onChange) onChange(event, expanded);
  };

  function showSubHeading() {
    if (!hideSubHeadingDuringWarning && subHeading) {
      return true;
    }

    if (hideSubHeadingDuringWarning && !warningMessage && subHeading) {
      return true;
    }

    return false;
  }

  return (
    <BoxStyled
      id={id}
      className={clsx(
        classes.root,
        showExtraWarning && warningMessage && classes.warningBorder
      )}
      data-testid={testId}
    >
      <Accordion
        className={classes.panel}
        expanded={expanded}
        elevation={open ? 4 : 1}
        aria-label={`${heading} card`}
        onChange={handleExpansionChange}
      >
        <AccordionSummary
          expandIcon={<ExpandIcon className={classes.expandIcon} />}
          aria-controls={`${id}-content`}
          id={`${id}-header`}
          onClick={onClick}
        >
          <div className={classes.summaryContainer}>
            <div>
              <Typography
                variant="h3"
                children={heading}
                data-testid={`${testId}Title`}
              />
              {showSubHeading() && (
                <div className={classes.subHeading} children={subHeading} />
              )}
              {warningMessage && (
                <Typography
                  variant="body1"
                  className={classes.warningMessage}
                  children={warningMessage}
                  data-testid={`${testId}WarningMessage`}
                />
              )}
            </div>
            {showSuccessStatusIcon && (
              <CheckCircle
                className={classes.successIcon}
                data-testid={`${testId}SuccessIcon`}
              />
            )}
            {showWarningStatusIcon && (
              <WarningIcon
                className={classes.warningIcon}
                data-testid={`${testId}WarningIcon`}
              />
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.details}>{children}</div>
        </AccordionDetails>
      </Accordion>
    </BoxStyled>
  );
}
