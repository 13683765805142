import { Training, GQLTraining } from "types/profile";

export function isCompleteTraining(training: Training | GQLTraining) {
  return Boolean(training.completionDate && training.courseProvider);
}

export function hasIncompleteTrainings(trainings: Training[] | GQLTraining[]) {
  return trainings.some((training: Training | GQLTraining) => {
    return !isCompleteTraining(training);
  });
}
