import React from "react";
import { useTypeaheadQueries } from "hooks/useTypeaheadQueries";

import {
  AsyncAutocompleteField,
  AsyncAutocompleteFieldProps,
} from "./AsyncAutocompleteField";

export interface CourseProviderAutocompleteFieldProps
  extends Omit<
    AsyncAutocompleteFieldProps,
    "fetchOptions" | "additionalQueryArgs"
  > {}

export function CourseProviderAutocompleteField({
  name = "courseProvider",
  label = "Course provider",
  onQueryError = console.error,
  ...otherProps
}: CourseProviderAutocompleteFieldProps) {
  const { getCourseProviders } = useTypeaheadQueries();
  return (
    <AsyncAutocompleteField
      name={name}
      label={label}
      fetchOptions={getCourseProviders}
      getOptionLabel={(option) => option}
      getOptionValue={(option) => option}
      {...otherProps}
    />
  );
}
