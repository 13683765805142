import React from "react";
import Table from "@mui/material/Table";
import EditIcon from "@mui/icons-material/Edit";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";

import { noop } from "utils/noop";
import { User } from "types/profile";
import { styled } from "@mui/material/styles";
import { roleLabelOf, RoleType } from "utils/role";
import { formatUserFullName } from "utils/user";

const PREFIX = "ProfileUserDetails";

const classes = {
  root: `${PREFIX}-root`,
  actions: `${PREFIX}-actions`,
};

const TableStyled = styled(Table)(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: "100%",
    overflowX: "auto",
  },

  [`& .${classes.actions}`]: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

interface Props {
  user: User;
  onUpdateButtonClick?: Callback;
}

export function ProfileUserDetails({
  user,
  onUpdateButtonClick = noop,
  ...otherProps
}: Props) {
  const fullName = formatUserFullName(user);
  const isInvestigator = user.role === RoleType.Investigator;
  return (
    <TableStyled data-testid={ProfileUserDetails.name} {...otherProps}>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Role</TableCell>
          {!isInvestigator && <TableCell>Job function</TableCell>}
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell scope="row">{fullName}</TableCell>
          <TableCell>{roleLabelOf(user.role)}</TableCell>
          {!isInvestigator && <TableCell>{user.jobTitle}</TableCell>}
          <TableCell>
            <div className={classes.actions}>
              <IconButton
                aria-label="edit"
                size="small"
                onClick={onUpdateButtonClick}
                data-testid="ProfileUserDataEditButton"
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </TableStyled>
  );
}
