import React from "react";

import {
  AutocompleteField,
  AutocompleteInstanceFieldProps,
} from "./AutocompleteField";

export interface YearSelectFieldProps extends AutocompleteInstanceFieldProps {
  endYear?: number;
  startYear?: number;
  allowPresent?: boolean;
}

export function YearSelectField({
  endYear = currentYear(),
  startYear = 1936,
  allowPresent = false,
  "data-testid": testId = YearSelectField.name,
  ...props
}: YearSelectFieldProps) {
  const years = generateYears(startYear, endYear);
  const options = years.map((y) => ({
    value: y.toString(),
    label: y.toString(),
  }));

  if (allowPresent) {
    options.unshift({ value: "Present", label: "Present" } as any);
  }

  return (
    <AutocompleteField
      name="year"
      label="Year"
      options={options}
      data-testid={testId}
      {...props}
    />
  );
}

function currentYear() {
  return new Date().getFullYear();
}

function generateYears(startYear: number, endYear: number) {
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.unshift(year);
  }
  return years;
}
