import React from "react";
import { TextField, Chip, Autocomplete } from "@mui/material";

export interface ChipListInputProps {
  value: string[];
  label?: string;
  placeholder?: string;
  clearOnBlur?: boolean;
  error?: boolean;
  onChange: (items: string[]) => void;
  id: string;
  name: string;
}

export function ChipListInput(props: ChipListInputProps) {
  const {
    value = [],
    label = "",
    placeholder = "Type and press `Enter`",
    clearOnBlur = false,
    error = false,
    onChange,
    id,
    name,
  } = props;

  return (
    <Autocomplete
      id={id}
      value={value}
      options={[]}
      multiple
      freeSolo
      clearOnBlur={clearOnBlur}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip label={option} {...getTagProps({ index })} />
        ))
      }
      onChange={(event, items) => {
        onChange(items.map((item) => (item as string).trim()));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={error}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}
