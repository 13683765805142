import React from "react";
import clsx from "clsx";
import { SxProps } from "@mui/system";
import { styled } from "@mui/material/styles";

import { Tooltip } from "components/Tooltip";

const PREFIX = "FieldInfo";

const classes = {
  root: `${PREFIX}-root`,
  infoIconContainer: `${PREFIX}-infoIconContainer`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
  },

  [`& .${classes.infoIconContainer}`]: {
    justifySelf: "end",
  },
}));

export interface FieldInfoProps {
  info: string;
  children?: ReactNodes;
  sx?: SxProps;
}

export function FieldInfo({ info, children, sx }: FieldInfoProps) {
  return (
    <Root className={clsx(classes.root)} sx={sx}>
      {children}
      <div className={classes.infoIconContainer}>
        <Tooltip title={info} />
      </div>
    </Root>
  );
}
