import gql from "graphql-tag";
import { useApolloClient } from "@apollo/client";

export function useTypeaheadQueries() {
  const getRegions = useManuallyGetRegions();
  const getFacilities = useManuallyGetFacilityNames();
  const getIndications = useManuallyGetIndications();
  const getUniversities = useManuallyGetUniversities();
  const getQualifications = useManuallyGetQualifications();
  const getCourseProviders = useManuallyGetCourseProviders();
  return {
    getRegions,
    getFacilities,
    getIndications,
    getUniversities,
    getQualifications,
    getCourseProviders,
  };
}

export const GET_UNIVERSITIES = gql`
  query($q: String!, $countryCode: CountryCode!) {
    typeaheadUniversities(q: $q, countryCode: $countryCode) {
      options
      complete
    }
  }
`;

function useManuallyGetUniversities() {
  const client = useApolloClient();
  return async (q: string, countryCode: string) => {
    const result = await client.query({
      query: GET_UNIVERSITIES,
      variables: { q, countryCode },
    });
    return result.data.typeaheadUniversities.options;
  };
}

export const GET_QUALIFICATIONS = gql`
  query($q: String!) {
    typeaheadQualifications(q: $q) {
      options
      complete
    }
  }
`;

function useManuallyGetQualifications() {
  const client = useApolloClient();
  return async (q?: string | null) => {
    const searchString = q || "";
    const result = await client.query({
      query: GET_QUALIFICATIONS,
      variables: { q: searchString },
    });
    return result.data.typeaheadQualifications.options;
  };
}

export const GET_REGIONS = gql`
  query($q: String!, $countryCode: CountryCode!) {
    typeaheadRegions(q: $q, countryCode: $countryCode) {
      options
      complete
    }
  }
`;

function useManuallyGetRegions() {
  const client = useApolloClient();
  return async (q: string, countryCode: string) => {
    const result = await client.query({
      query: GET_REGIONS,
      variables: { q, countryCode },
    });
    return result.data.typeaheadRegions.options;
  };
}

export const GET_FACILITIES = gql`
  query($q: String!, $countryCode: CountryCode, $region: String) {
    typeaheadFacilities(q: $q, countryCode: $countryCode, region: $region) {
      options {
        id: pk
        name
        countryCode
        city
        region
        zipCode
        street
        departments {
          name
          type
          subType
        }
      }
      complete
    }
  }
`;

export function useManuallyGetFacilities() {
  const client = useApolloClient();
  return async (
    q: string,
    countryCode: string,
    region: string | null = null
  ) => {
    const result = await client.query({
      query: GET_FACILITIES,
      variables: { q, countryCode, region },
    });
    return result.data.typeaheadFacilities.options;
  };
}

function useManuallyGetFacilityNames() {
  const getFacilities = useManuallyGetFacilities();
  return async (
    q: string,
    countryCode: string,
    region: string | null = null
  ) => {
    const result = await getFacilities(q, countryCode, region);
    return result.map((o: any) => o.name);
  };
}

export function useManuallyGetFacilityGoldenId() {
  const getFacilities = useManuallyGetFacilities();
  return async (
    facilityName: string,
    countryCode: string,
    region: string | null = null
  ) => {
    const result = await getFacilities(facilityName, countryCode, region);
    return result[0] ? result[0].id : undefined;
  };
}

export const GET_COURSE_PROVIDERS = gql`
  query($q: String!) {
    typeaheadCourseProviders(q: $q) {
      options
      complete
    }
  }
`;

export function useManuallyGetCourseProviders() {
  const client = useApolloClient();
  return async (q: string) => {
    const result = await client.query({
      query: GET_COURSE_PROVIDERS,
      variables: { q },
    });
    return result.data.typeaheadCourseProviders.options;
  };
}

export const GET_INDICATIONS = gql`
  query($q: String!) {
    typeaheadIndications(q: $q) {
      options
      complete
    }
  }
`;

export function useManuallyGetIndications() {
  const client = useApolloClient();
  return async (q: string) => {
    const result = await client.query({
      query: GET_INDICATIONS,
      variables: { q },
    });
    return result.data.typeaheadIndications.options;
  };
}
