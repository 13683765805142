import React from "react";
import { styled } from "@mui/material/styles";
import { Table, TableBody, TableHead } from "@mui/material";

import { Study } from "types/profile";
import { TableHeadRow, TableBodyRow, TableCell } from "./Table";

interface Props {
  testId?: string;
  entries?: Study[];
}

const TableCellWrapped = styled(TableCell)(() => ({
  wordBreak: "break-word",
}));

export function StudyDetailsTable({ entries = [] }: Props) {
  return (
    <Table aria-label="Study details">
      <TableHead>
        <TableHeadRow>
          <TableCell>Phase</TableCell>
          <TableCell>Role</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Primary indication</TableCell>
        </TableHeadRow>
      </TableHead>
      <TableBody>
        {entries.map((entry, i) => (
          <TableBodyRow key={i}>
            <TableCellWrapped>{entry.phase || "N/A"}</TableCellWrapped>
            <TableCellWrapped>{entry.role || "N/A"}</TableCellWrapped>
            <TableCellWrapped>{entry.status || "N/A"}</TableCellWrapped>
            <TableCellWrapped>{entry.type || "N/A"}</TableCellWrapped>
            <TableCellWrapped>
              {(entry.primaryIndication || []).join(", ") || "N/A"}
            </TableCellWrapped>
          </TableBodyRow>
        ))}
      </TableBody>
    </Table>
  );
}
