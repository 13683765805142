import React from "react";
import CheckCircle from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import SendIcon from "@mui/icons-material/SendOutlined";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { DelegateStatusType } from "hooks/useDelegates";

type StatusProps = {
  status: DelegateStatusType;
};

const Root = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  gridColumnGap: 10,
  alignContent: "center",
}));

export function StatusIcon({ status }: StatusProps) {
  let IconComponent;
  switch (status) {
    case "INVITED":
      IconComponent = (
        <SendIcon
          sx={{
            color: "primary.light",
          }}
        />
      );
      break;
    case "DECLINED":
      IconComponent = <ClearIcon sx={{ color: "error.dark" }} />;
      break;
    case "ACTIVE":
      IconComponent = <CheckCircle sx={{ color: "success.main" }} />;
      break;
  }

  return (
    <Root>
      <Box sx={{ display: "inline-grid" }}>{IconComponent}</Box>
      <Box sx={{ alignSelf: "center" }}>{status.toLowerCase()}</Box>
    </Root>
  );
}
