import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Alert from "@mui/material/Alert";
import WarningIcon from "@mui/icons-material/Warning";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { noop } from "utils/noop";
import { Licence } from "types/profile";
import { Country } from "hooks/useCoreData";
import { formattedDate } from "utils/date";
import { isCompleteLicence, hasIncompleteLicences } from "utils/licence";

import {
  TablePane,
  TableHeadRow,
  TableBodyRow,
  TableActionsCell,
  TableEditActionButton,
  TableDeleteActionButton,
  TablePaneFooter,
  TablePaneButton,
} from "./Table";

interface Props {
  testId?: string;
  entries?: Licence[];
  countries: Country[];
  onCreateButtonClick?: Callback;
  onUpdateButtonClick?: Callback;
  onDeleteButtonClick?: Callback;
  "data-testid"?: string;
}

export function LicenceTable({
  countries,
  entries = [],
  "data-testid": testId = LicenceTable.name,
  onCreateButtonClick = noop,
  onUpdateButtonClick = noop,
  onDeleteButtonClick = noop,
}: Props) {
  return (
    <TablePane>
      {hasIncompleteLicences(entries) && (
        <Alert sx={{ mb: 2 }} severity="warning">
          Please provide missing information for the entries indicated below
        </Alert>
      )}
      <Table data-testid={testId}>
        <TableHead>
          <TableHeadRow>
            <TableCell>Country</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Issuer</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>No.</TableCell>
            <TableCell>Issued</TableCell>
            <TableCell>Expires</TableCell>
            <TableCell />
            <TableCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {entries.map((entry) => (
            <TableBodyRow key={entry.id} data-testid={`${testId}BodyRow`}>
              <TableCell>
                {getCountryNameByCode(entry.countryCode, countries) || "-"}
              </TableCell>
              <TableCell>{entry.state || "-"}</TableCell>
              <TableCell>{entry.issuer || "-"}</TableCell>
              <TableCell>{entry.type || "-"}</TableCell>
              <TableCell>{entry.number || "-"}</TableCell>
              <TableCell>{formattedDate(entry.issueDate) || "-"}</TableCell>
              <TableCell>
                {formattedDate(entry.expirationDate) || "-"}
              </TableCell>
              <TableCell>
                {!isCompleteLicence(entry) && (
                  <WarningIcon
                    sx={{ color: "warning.main" }}
                    aria-label="Warning"
                    titleAccess="The data of this experience entry is incomplete"
                  />
                )}
              </TableCell>
              <TableActionsCell>
                <TableEditActionButton
                  onClick={() => onUpdateButtonClick(entry)}
                  data-testid={`${testId}UpdateEntryButton`}
                />
                <TableDeleteActionButton
                  onClick={() => onDeleteButtonClick(entry)}
                  data-testid={`${testId}DeleteEntryButton`}
                />
              </TableActionsCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
      <TablePaneFooter>
        <TablePaneButton
          icon={AddCircleIcon}
          label="Add another professional licence and/or other certification"
          onClick={onCreateButtonClick}
          data-testid={`${testId}CreateEntryButton`}
        />
      </TablePaneFooter>
    </TablePane>
  );
}

export function getCountryNameByCode(
  countryCode: string,
  countries: Country[]
) {
  const country = countries.find((c) => c.code === countryCode);
  if (country) return country.name;
}
