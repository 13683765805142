import React from "react";
import {
  FormRadioButtonGroupField,
  FormRadioButtonGroupFieldProps,
} from "./FormRadioButtonGroupField";

export function FieldYesNoRadioGroup({
  "aria-label": ariaLabel = "yes/no radio button group",
  ...props
}: FormRadioButtonGroupFieldProps) {
  return (
    <FormRadioButtonGroupField
      aria-label={ariaLabel}
      options={[
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
        { value: "n/a", label: "N/A" },
      ]}
      {...props}
    ></FormRadioButtonGroupField>
  );
}
