import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import studyRoles from "fixtures/studyRoles";

import { Form } from "./Form";
import { noop } from "utils/noop";
import { useForm } from "hooks/useForm";
import { required } from "validators/required";
import { getPhaseLabelByValue } from "utils/phase";
import { StudyIdentifier, Study } from "types/profile";
import { StudyCommentSelectField } from "./StudyCommentSelectField";
import { StudyRoleRadioGroupField } from "./StudyRoleRadioGroupField";
import { TableHeadRow, TableBodyRow, TableCell, TableHeadCell } from "./Table";
import {
  useChangeReasonFormDialog,
  ChangeReasonFormDialog,
} from "./ChangeReasonFormDialog";

export interface StudyByNctFormProps {
  open?: boolean;
  title?: string;
  study?: StudyIdentifier & Partial<Study>;
  submit: (study: Study) => Promise<any>;
  onCancel?: Callback;
  onError?: Callback;
  onSuccess?: Callback;
  withReason?: boolean;
  "data-testid"?: string;
}

export function StudyByNctForm({
  open,
  title = "Add a clinical study",
  study = {} as StudyIdentifier & Partial<Study>,
  onCancel = noop,
  onError = console.error,
  onSuccess = noop,
  withReason = false,
  submit: doSubmit,
  "data-testid": testId = StudyByNctForm.name,
  ...otherProps
}: StudyByNctFormProps) {
  const reasonForm = useChangeReasonFormDialog({
    doSubmit,
    formHookSubmit,
    withReason,
  });
  const { id, fields, submit, reset, submitting, error } = useForm({
    id: "study-nct-form",
    fields: {
      id: {
        name: "id",
        value: study.id,
      },
      nctNumber: {
        name: "nctNumber",
        value: study.nctNumber,
      },
      phase: {
        name: "phase",
        value: study.phase,
      },
      primaryIndication: {
        name: "primaryIndication",
        value: study.primaryIndication,
      },
      status: {
        name: "status",
        value: study.status,
      },
      title: {
        name: "title",
        value: study.title,
      },
      type: {
        name: "type",
        value: study.type,
      },
      source: {
        name: "source",
        value: "NCT",
      },
      role: {
        name: "role",
        value: study.role,
        label: "What was your role on this study?",
        required: true,
        validate: required,
      },
      comment: {
        name: "comment",
        value: study.comment,
      },
    },
    submit: reasonForm.performSubmit,
    onReset: onCancel,
    onError,
    onSuccess,
  });

  function formHookSubmit() {
    return submit();
  }

  return (
    <>
      <Form
        id={id}
        open={open}
        title={title}
        data-testid={testId}
        onSubmit={reasonForm.handleSubmitButtonClick}
        submitting={submitting}
        onCancel={reset}
        error={error}
        {...otherProps}
      >
        <Table>
          <TableHead>
            <TableHeadRow>
              <TableHeadCell>{study.nctNumber}</TableHeadCell>
            </TableHeadRow>
          </TableHead>
          <TableBody>
            <TableBodyRow>
              <TableCell variant="body" data-testid={`${testId}TitleCell`}>
                {study.title}
              </TableCell>
            </TableBodyRow>
          </TableBody>
        </Table>

        <Table data-testid={`${testId}DetailsTable`}>
          <TableHead>
            <TableHeadRow data-testid={`${testId}DetailsTableHeadRow`}>
              <TableHeadCell>Phase</TableHeadCell>
              <TableHeadCell>Role</TableHeadCell>
              <TableHeadCell>Status</TableHeadCell>
              <TableHeadCell>Type</TableHeadCell>
              <TableHeadCell>Primary indication</TableHeadCell>
            </TableHeadRow>
          </TableHead>
          <TableBody>
            <TableBodyRow data-testid={`${testId}DetailsTableBodyRow`}>
              <TableCell data-testid={`${testId}PhaseCell`}>
                {getPhaseLabelByValue(study.phase) || "-"}
              </TableCell>
              <TableCell data-testid={`${testId}RoleCell`}>
                {getStudyRoleLabel(study.role)}
              </TableCell>
              <TableCell data-testid={`${testId}StatusCell`}>
                {study.status || "-"}
              </TableCell>
              <TableCell data-testid={`${testId}TypeCell`}>
                {study.type || "-"}
              </TableCell>
              <TableCell data-testid={`${testId}PrimaryIndicationCell`}>
                {formatPrimaryIndication(study.primaryIndication) || "-"}
              </TableCell>
            </TableBodyRow>
          </TableBody>
        </Table>

        <StudyRoleRadioGroupField
          data-testid={`${testId}RoleField`}
          {...fields.role}
        />

        <StudyCommentSelectField {...fields.comment} fullWidth />
      </Form>
      <ChangeReasonFormDialog {...reasonForm.reasonDialog} />
    </>
  );
}

function formatPrimaryIndication(value?: string[] | string) {
  if (Array.isArray(value)) return value.join(", ");
  return value;
}

function getStudyRoleLabel(value?: string) {
  if (!value) return "N/A";
  const role = studyRoles.find((role) => role.value === value);
  if (role) return role.label;
  return "N/A";
}
