import React from "react";
import {
  Container,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import {AppBar} from '../layouts/AppBar'

const ContainerStyled = styled(Container)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridRowGap: theme.spacing(5),
  paddingTop: theme.spacing(15),
}));

const PaperStyled = styled(Paper)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridRowGap: theme.spacing(1),
  padding: theme.spacing(5),
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));


export function MaintenanceScreen() {
  return (
    <div>
      <AppBar showNav={false} />
      <ContainerStyled>
        <PaperStyled>
          <Typography component="h3" variant="h3">
            Under Maintenance
          </Typography>
          <Typography>
            IQVIA Profiles are currently offline as part of a planned
            maintenance cycle. The maintenance cycle is expected to last 3
            hours. We apologise for the inconvenience.
          </Typography>
        </PaperStyled>
      </ContainerStyled>
    </div>
  );
}
