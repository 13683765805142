import React from "react";
import { useContactTypes } from "hooks/useContactTypes";

import {
  AutocompleteField,
  AutocompleteInstanceFieldProps,
} from "./AutocompleteField";

export function ContactTypeSelectField(props: AutocompleteInstanceFieldProps) {
  const { contactTypes } = useContactTypes();

  return (
    <AutocompleteField
      name="type"
      label="Contact type"
      options={contactTypes}
      {...props}
    />
  );
}
