import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Error";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const MessageContent = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(1),
  "& > :not(:first-child)": {
    marginLeft: theme.spacing(0.5),
  },
}));

const Message = styled(Typography)<{
  "color-variant": FieldMessageProps["variant"];
}>(({ theme, "color-variant": colorVariant }) => {
  let color = "";
  switch (colorVariant) {
    case "success":
      color = theme.palette.success.main;
      break;
    case "error":
      color = theme.palette.error.main;
      break;
    case "info":
      color = theme.palette.text.secondary;
      break;
    case "warning":
      color = theme.palette.warning.main;
      break;
    default:
      break;
  }
  return { color };
});

interface FieldMessageProps {
  variant?: "warning" | "error" | "success" | "info";
  message?: ReactNodes;
  children: ReactNodes;
  "data-testid"?: string;
}

export function FieldMessage({
  variant = "info",
  message,
  children,
  "data-testid": testId = FieldMessage.name,
  ...otherProps
}: FieldMessageProps) {
  return (
    <div {...otherProps}>
      <div>{children}</div>
      {message && (
        <MessageContent role="alert" data-testid={testId}>
          {variant === "info" && (
            <InfoIcon
              aria-label="Info icon"
              sx={{
                color: "text.secondary",
              }}
              fontSize="small"
            />
          )}
          {variant === "warning" && (
            <WarningIcon
              aria-label="Warning icon"
              sx={{ color: "warning.main" }}
              fontSize="small"
            />
          )}
          {variant === "success" && (
            <SuccessIcon
              aria-label="Success icon"
              sx={{ color: "success.main" }}
              fontSize="small"
            />
          )}
          {variant === "error" && (
            <ErrorIcon
              aria-label="Error icon"
              sx={{ color: "error.main" }}
              fontSize="small"
            />
          )}
          <Message variant="caption" color-variant={variant}>
            {message}
          </Message>
        </MessageContent>
      )}
    </div>
  );
}
