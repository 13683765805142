import React, { PropsWithChildren } from "react";
import { styled } from "@mui/material/styles";
import {
  Paper,
  Typography,
  Button,
  Fade,
  CircularProgress,
} from "@mui/material";
import { Snackbar } from "../components/Snackbar";
import { ContactUsForm } from "../components/ContactUsForm";
import { useContactUs } from "../hooks/useContactUs";
import { RouteComponentProps, DefaultParams } from "wouter";

const PREFIX = "ContactUs";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  subtitle: `${PREFIX}-subtitle`,
  bottomBar: `${PREFIX}-bottomBar`,
  form: `${PREFIX}-form`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    minHeight: "569px",
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    minHeight: "569px",
  },

  [`& .${classes.subtitle}`]: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },

  [`& .${classes.bottomBar}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    marginTop: theme.spacing(1),
  },

  [`& .${classes.form}`]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

interface Props
  extends Partial<PropsWithChildren<RouteComponentProps<DefaultParams>>> {
  source?: "external" | "internal";
}

export default function ContactUs({ source }: Props) {
  let { services, notification, matches, state } = useContactUs(source);

  return (
    <Root
      className={classes.root}
      data-testid={ContactUs.name}
      data-state={state}
    >
      <Paper className={classes.paper}>
        <Typography variant="h4">Contact Us</Typography>
        <Typography className={classes.subtitle} variant="subtitle2">
          Send us a message below and we will email you back. Or if you would
          rather speak by telephone, please provide your phone number and we
          will call you back within three business days. You can email us
          directly at{" "}
          <a href="mailto:profile@drugdev.com">profile@drugdev.com</a>
        </Typography>
        <Snackbar
          aria-label="contact us notification"
          open={notification.open}
          onClose={notification.close}
          variant={notification.variant as any}
          message={notification.message}
        />
        <ContactUsForm
          className={classes.form as string}
          service={services.form as any}
        />
        <div className={classes.bottomBar}>
          <Button type="submit" form="contact-us-form" variant="contained">
            Send Message
          </Button>
          <span />
          <Fade in={matches("submitting")}>
            <CircularProgress size={30} />
          </Fade>
        </div>
      </Paper>
    </Root>
  );
}
