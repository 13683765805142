import React from "react";
import { styled } from "@mui/material/styles";
import { LinearProgress, linearProgressClasses } from "@mui/material";

export interface ProgressBarProps {
  progress: number;
}

const LinearProgressStyled = styled(LinearProgress)(({ theme }) => ({
  [`&.${linearProgressClasses.root}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[500]}`,
    height: 18,
    background: "white",
  },
  [`& .${linearProgressClasses.bar}`]: {
    background: theme.palette.success.main,
  },
}));

export function ProgressBar({ progress }: ProgressBarProps) {
  return (
    <LinearProgressStyled
      aria-label={`cv progress bar at ${progress}%`}
      variant="determinate"
      value={progress}
    />
  );
}
