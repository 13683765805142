import { Field } from "hooks/useForm";

export const REQUIRED_ERROR_MESSAGE = "This field is required";

export function required(field: Field) {
  if (
    [undefined, null].includes(field.value) ||
    (typeof field.value === "string" && !field.value.trim().length)
  ) {
    return REQUIRED_ERROR_MESSAGE;
  }
}
