import React from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";

import { noop } from "utils/noop";
import { useAuth } from "hooks/useAuth";
import { ProfileContact } from "types/facility";

import {
  TableCell,
  TableBody,
  TableHeadCell,
  TableHeadRow,
  TableBodyRow,
  TableActionsCell,
  TableDeleteActionButton,
} from "./Table";

interface Props {
  entries?: ProfileContact[];
  onDeleteButtonClick?: Callback;
  getContactTypeLabel: (typeCode: number) => string;
}

export function DepartmentContactsTable({
  onDeleteButtonClick = noop,
  entries = [],
  getContactTypeLabel,
}: Props) {
  //@ts-ignore
  const auth = useAuth();

  return (
    <Table aria-label="Department contacts">
      <TableHead>
        <TableHeadRow>
          <TableHeadCell>Type</TableHeadCell>
          <TableHeadCell>Email</TableHeadCell>
          <TableHeadCell>Name</TableHeadCell>
          <TableCell />
        </TableHeadRow>
      </TableHead>
      <TableBody>
        {entries.map((entry, i) => {
          return (
            <TableBodyRow key={i}>
              <TableCell>{getContactTypeLabel(entry.type)}</TableCell>
              <TableCell>{entry.email}</TableCell>
              <TableCell>{formatEntryName(entry)}</TableCell>
              <TableActionsCell>
                {(entry.myself || auth?.isAdmin) && (
                  <TableDeleteActionButton
                    onClick={() => onDeleteButtonClick(entry)}
                  />
                )}
              </TableActionsCell>
            </TableBodyRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

function formatEntryName(entry: ProfileContact) {
  return entry.myself ? `${entry.name} (You)` : entry.name;
}
