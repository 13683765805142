import React from "react";
import licenceTypes from "../fixtures/licenceTypes";

import {
  AutocompleteField,
  AutocompleteInstanceFieldProps,
} from "./AutocompleteField";

export function LicenceSelectField({
  "data-testid": testId = LicenceSelectField.name,
  ...props
}: AutocompleteInstanceFieldProps) {
  return (
    <AutocompleteField
      id="licence"
      label="Licence"
      name="licence"
      data-testid={testId}
      options={licenceTypes}
      {...props}
    />
  );
}
