import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

interface CustomTheme {
  color: {
    main: string;
  };
}

declare module "@mui/material/styles/createTheme" {
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

export default createTheme({
  typography: {
    fontFamily: "Proxima Nova, Nunito Sans, sans-serif",
    subtitle1: {
      color: grey[800],
      fontSize: "0.95rem",
      lineHeight: 1.5,
      fontWeight: 300,
    },
    subtitle2: {
      color: grey[700],
      fontSize: "0.875rem",
    },
    h3: {
      fontSize: "1.4rem",
      color: grey[800],
      fontWeight: 500,
      marginBottom: 4,
    },
    h4: {
      fontSize: "1.65rem",
      color: grey[800],
      fontWeight: 600,
      marginBottom: 8,
    },
    h5: {
      fontSize: "1.1rem",
      fontWeight: 600,
      color: grey[800],
      marginBottom: 8,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          background: #fafafa;
        }
      `,
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: 150,
        },
      },
      defaultProps: {
        margin: "dense",
        variant: "outlined",
        size: "small",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 600,
        },
        containedSecondary: {
          border: "1px solid #d9d9d9",
          "&:hover": {
            backgroundColor: "#ecf3ff",
          },
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          border: `1px solid ${grey[300]}`,
          borderRadius: 3,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none",
        },
        head: {
          fontWeight: 600,
          background: grey[200],
          color: grey[800],
          borderBottom: `1px solid ${grey[300]}`,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorSecondary: {
          "&.Mui-checked": {
            color: grey[600],
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-of-type(even)": {
            backgroundColor: "#f5f5f578",
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        row: {
          justifyContent: "space-between",
          "& > *": {
            flexGrow: 1,
          },
          "& > *:not(:first-child)": {
            marginLeft: 12,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        groupLabel: {
          backgroundColor: "#eee",
        },
      },
    },

  },
  palette: {
    primary: {
      main: "#005FF1",
    },
    secondary: {
      main: "#ffffff",
      contrastText: "rgb(7, 104, 253)",
    },
    warning: {
      main: "#FF9400",
    },
    success: {
      main: "#00C221",
    },
  },
  color: {
    main: "#281B45",
  },
});
