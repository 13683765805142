import React from "react";

import { Card } from "components/Card";
import { CommitteeDetails } from "types/facility";
import { DepartmentCommitteeForm } from "./DepartmentCommitteeForm";
import {
  DepartmentProfileHookData,
  Profile,
} from "hooks/useFacilityDepartmentProfile";
import { ManagedCardProps, useCard, CardMachine } from "hooks/useCard";

interface DepartmentCommitteeCardProps extends ManagedCardProps {
  profile: Profile;
  departmentPk?: number;
  updateCommitteeDetails: (committee: CommitteeDetails) => Promise<any>;
}

export const DepartmentCommitteeCardMachine = CardMachine({ validateCard });

export function DepartmentCommitteeCard({
  id = "committee-card",
  service,
  profile,
  departmentPk,
  updateCommitteeDetails,
  ...otherProps
}: DepartmentCommitteeCardProps) {
  const { card } = useCard({ service });
  return (
    <Card
      id={id}
      heading={formatCardHeading(profile)}
      {...card}
      {...otherProps}
    >
      <DepartmentCommitteeForm
        entry={profile.irb}
        submit={updateCommitteeDetails}
        forFacility={!departmentPk}
      />
    </Card>
  );
}

function formatCardHeading(profile: Profile) {
  if (profile.departmentDetails) {
    return `IRB/ERB/Ethics Committee Details: ${profile.departmentDetails.departmentName} - ${profile.facilityDetails.name}`;
  } else {
    return `IRB/ERB/Ethics Committee Details: ${profile.facilityDetails.name}`;
  }
}

function validateCard(data?: DepartmentProfileHookData) {
  const committee = data?.profile.irb;
  const isComplete = Boolean(
    committee &&
      !(
        Object.values(committee.useCommitteeTypes).every(
          (value) => value !== true
        ) ||
        committee.anyCommittee === null ||
        committee.ownLocalIrb === null ||
        (committee.ownLocalIrb === false &&
          [null, "false"].includes(committee.otherIrb))
      )
  );
  if (!isComplete) {
    return "Please tell us more about IRB/ERB/Ethics Committee";
  }
}
