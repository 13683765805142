import React from "react";
import { useRoute } from "wouter";
import { LinearProgress } from "@mui/material";

import { DelegatesCard } from "components/DelegatesCard";
import { DelegatesForCard } from "components/DelegatesForCard";
import { Screen, ScreenSection, ScreenSectionTitle } from "components/Screen";
import { useDelegates, DelegateType } from "hooks/useDelegates";
import { AppBar } from "layouts/AppBar";
import { Content } from "layouts/Content";

export interface MenuLink {
  link: string;
  title: string;
  key?: number;
  blank?: boolean;
  selected?: boolean;
  ariaLabel?: string;
}

const MenuItems = {
  search: {
    title: "Search",
    link: "/admin",
    selected: false,
    ariaLabel: "Link to admin search",
  },
  profile: {
    title: "Profile",
    link: "",
    selected: false,
    ariaLabel: "Link to user profile",
  },
  delegates: {
    link: "",
    title: "Delegates",
    selected: true,
    ariaLabel: "Link to users delegates",
  },
};

interface DelegatesAdminProps {
  userPk: string;
}

export default function AdminDelegates(params: DelegatesAdminProps) {
  // eslint-disable-next-line
  let [_match, param] = useRoute("/delegates/:id");

  MenuItems.profile.link = `/admin/profile/${param?.id}`;
  MenuItems.delegates.link = `/delegates/${param?.id}`;
  const menuList = Object.values(MenuItems);

  const {
    loading,
    delegates,
    delegatesFor,
    invitationData,
    inviteDelegate,
    onInvitationSuccess,
    onRequestSuccess,
    deleteDelegate,
  } = useDelegates({ isAdmin: true, userPk: params.userPk });

  return (
    <>
      <AppBar showNav={true} menuItems={menuList} />
      <Content>
        <>{loading && <LinearProgress aria-label="loading delegates" />}</>
        <Screen data-testid={AdminDelegates.name}>
          <ScreenSection>
            <ScreenSectionTitle children="Delegates" />
            <DelegatesCard
              delegates={delegates}
              inviteDelegate={(data: any, adminComment: any) =>
                inviteDelegate({
                  email: data.email,
                  delegateType: "DELEGATES" as DelegateType,
                  adminComment,
                })
              }
              deleteDelegate={(data: any, adminComment: any) =>
                deleteDelegate({
                  delegate: data,
                  delegateType: "DELEGATES" as DelegateType,
                  adminComment,
                })
              }
              onRequestSuccess={(data: any) =>
                onRequestSuccess({
                  data,
                  delegateType: "DELEGATES" as DelegateType,
                })
              }
              onInvitationSuccess={(invitationData: any) => {
                onInvitationSuccess(invitationData);
              }}
              isAdmin
            />
            <DelegatesForCard
              delegates={delegatesFor}
              inviteDelegate={(data: any, adminComment: any) =>
                inviteDelegate({
                  email: data.email,
                  delegateType: "DELEGATE_FOR" as DelegateType,
                  adminComment,
                })
              }
              deleteDelegate={(data: any, adminComment: any) =>
                deleteDelegate({
                  delegate: data,
                  delegateType: "DELEGATE_FOR" as DelegateType,
                  adminComment,
                })
              }
              onRequestSuccess={(data: any) =>
                onRequestSuccess({
                  data,
                  delegateType: "DELEGATE_FOR" as DelegateType,
                })
              }
              onInvitationSuccess={(invitationData: any) => {
                onInvitationSuccess(invitationData);
              }}
              invitationData={invitationData}
              isAdmin
            />
          </ScreenSection>
        </Screen>
      </Content>
    </>
  );
}
