import { useApolloClient } from "@apollo/client";
import { createMachine, assign, DoneInvokeEvent } from "xstate";
import { useMachine } from "@xstate/react";
import gql from "graphql-tag";
import { VPD } from "types/data";
import { Options } from "types/form";

interface SpecialtyOptions extends Options {
  group: string;
}

export type InitialQuery = {
  vpds: VPD[];
  roles: {
    options: Options[];
  };
  status: {
    options: Options[];
  };
  countries: Options[];
  specialties: Options[];
  profileAdmins: Options[];
};

export type AdminEvents = DoneInvokeEvent<{
  data: InitialQuery;
}>;

export interface AdminContext {
  vpds: VPD[];
  roles: Options[];
  countries: Options[];
  specialties: SpecialtyOptions[];
  admins: Options[];
}

type AdminState =
  | {
      value: "idle";
      context: AdminContext;
    }
  | { value: "initialising"; context: AdminContext };

const AdminMachine = createMachine<AdminContext, AdminEvents, AdminState>(
  {
    context: {
      vpds: [],
      roles: [],
      countries: [],
      specialties: [],
      admins: [],
    },
    initial: "initialising",
    states: {
      idle: {},
      initialising: {
        invoke: {
          src: "initialise",
          onDone: {
            actions: [
              "setVpds",
              "setRoles",
              "setCountries",
              "setSpecialties",
              "setAdmins",
            ],
            target: "idle",
          },
        },
      },
    },
  },
  {
    actions: {
      setAdmins: assign({
        admins: (_context, event) => event.data.data.profileAdmins,
      }),
      setVpds: assign({
        vpds: (_context, event) => event.data.data.vpds,
      }),
      setRoles: assign({
        roles: (_context, event) => event.data.data.roles.options,
      }),
      setCountries: assign({
        countries: (_context, event) => event.data.data.countries,
      }),
      setSpecialties: assign({
        specialties: (_, event) => {
          const groups: { [key: string]: string } = {};
          for (let specialty of event.data.data.specialties) {
            if (!specialty.value.includes(".")) {
              groups[specialty.value] = specialty.label;
            }
          }
          return event.data.data.specialties.map((specialty) => ({
            ...specialty,
            group: groups[specialty.value.split(".")[0]],
          })) as SpecialtyOptions[];
        },
      }),
    },
  },
);

export const INITIAL_ADMIN_QUERY = gql`
  query AdminInitial {
    vpds {
      label: name
      value: pk
    }
    status: __type(name: "OptInStatusType") {
      options: enumValues {
        label: name @normalise
        value: name
      }
    }
    roles: __type(name: "RoleType") {
      options: enumValues {
        label: name @normalise
        value: name
      }
    }
    countries {
      label: name
      value: code
    }
    specialties {
      label: name
      value: code
    }
    profileAdmins {
      value: pk
      label: email
    }
  }
`;

export function useAdmin() {
  const client = useApolloClient();

  //@ts-ignore
  const [state] = useMachine(AdminMachine, {
    services: {
      initialise(_context, _event) {
        return client.query<InitialQuery>({
          query: INITIAL_ADMIN_QUERY,
          fetchPolicy: "network-only",
        });
      },
    },
  });

  return {
    loading: state.matches("initialising"),
    ...state.context,
  };
}
