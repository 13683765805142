import React from "react";
import enLocale from "date-fns/locale/en-GB";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';

export interface FormDateFieldProps
  extends Pick<
  DatePickerProps<AdapterDateFns>,
    "label" | "maxDate" | "minDate" | "onChange" | "value"
  > {
  id: string;
  name: string;
  "aria-label"?: string;
  required?: boolean;
  helperText?: string;
}

export function FormDateField({
  id,
  required,
  helperText,
  name,
  label,
  "aria-label": ariaLabel,
  ...otherProps
}: FormDateFieldProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale} >
      <DatePicker
        {...otherProps}
        label={label}
        name={name}
        views={['year', 'month', 'day']}
        slotProps={{
          textField: {
            id,
            inputProps:{ "aria-label": ariaLabel },
            required,
            helperText: helperText,
            error: !!helperText
          },
        }}
      />
    </LocalizationProvider>
  );
}
