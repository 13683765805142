import React from "react";
import WarningIcon from "@mui/icons-material/Error";
import {
  Fade,
  Typography,
  CircularProgress,
  Box,
  Link as MuiLink,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

import { Facility } from "types/profile";
import { useMachine } from "@xstate/react";
import { Department } from "types/facility";
import { createMachine, assign } from "xstate";
import { DepartmentInput } from "./DepartmentInput";
import { Link as WouterLink } from "wouter";
import { useGetGoldenDepartments } from "hooks/useProfile";
import { REQUIRED_ERROR_MESSAGE } from "validators/required";
import { getMaxLengthErrorMessage, maxLength } from "validators/maxLength";

import {
  CheckableDepartment,
  GoldenDepartmentsField,
} from "./GoldenDepartmentsField";

const FieldRoot = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridRowGap: theme.spacing(3),
}));

const DepartmentFieldWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridRowGap: theme.spacing(1),
}));

const DepartmentFieldRoot = styled("section")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridRowGap: theme.spacing(2),
}));

interface FacilityDepartmentFieldProps {
  error: any;
  facility: Facility;
  onChange: Callback;
}

export function FacilityDepartmentField(props: FacilityDepartmentFieldProps) {
  const {
    loading,
    departmentField,
    goldenDepartmentsField,
    departmentExistsMessage,
  } = useField(props);

  return (
    <FieldRoot>
      {loading && (
        <Fade in>
          <CircularProgress size={20} />
        </Fade>
      )}

      {goldenDepartmentsField.show && (
        <Fade in>
          <DepartmentFieldRoot>
            <Typography color="textPrimary">
              If your department is listed below, please select it
            </Typography>
            <GoldenDepartmentsField
              aria-label="Existing departments to select from"
              {...goldenDepartmentsField.props}
            />
          </DepartmentFieldRoot>
        </Fade>
      )}

      {departmentField.show && (
        <Fade in>
          <section>
            <Box sx={{ pb: 2 }}>
              <Typography color="textPrimary">
                Please add details of the department you work in
              </Typography>

              <Typography
                sx={{ mt: 1, fontStyle: "italic", color: grey[700] }}
                variant="caption"
              >
                You can add more departments later
              </Typography>
            </Box>

            <DepartmentFieldWrapper>
              <DepartmentInput {...departmentField.props} />

              {departmentExistsMessage.show && (
                <DepartmentExistsMessage
                  departmentName={departmentExistsMessage.departmentName}
                />
              )}
            </DepartmentFieldWrapper>
          </section>
        </Fade>
      )}
    </FieldRoot>
  );
}

const RootMessage = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  gridColumnGap: theme.spacing(1),
}));

interface DepartmentExistsMessageProps {
  departmentName: string;
}

function DepartmentExistsMessage({
  departmentName,
}: DepartmentExistsMessageProps) {
  return (
    <RootMessage>
      <WarningIcon
        aria-label="Warning icon"
        sx={{ color: "warning.main" }}
        fontSize="small"
      />
      <Box sx={{ color: "warning.main" }}>
        We have found that your department type and subtype exists as{" "}
        <Box component="span" sx={{ fontWeight: 600 }}>
          {departmentName}
        </Box>
        . If this is incorrect, please{" "}
        <WouterLink href="/registration/contact-us">
          <MuiLink sx={{ textTransform: "uppercase" }} underline="hover">
            contact us
          </MuiLink>
        </WouterLink>
      </Box>
    </RootMessage>
  );
}

interface FieldMachineContext {
  facility: null | Facility;
  newDepartment: CheckableDepartment | null;
  existingDepartment: CheckableDepartment | null;
  existingDepartments: CheckableDepartment[];
  selectedDepartments: CheckableDepartment[];
  error: Error | null;
}

const FieldMachine = createMachine<FieldMachineContext>(
  {
    initial: "idle",
    context: {
      facility: null,
      newDepartment: null,
      existingDepartment: null,
      existingDepartments: [],
      selectedDepartments: [],
      error: null,
    },
    states: {
      idle: {
        on: {
          SET_FACILITY: [
            {
              cond: "isNotSameFacility",
              target: "loadingExistingDepartments",
              actions: [
                "assignFacility",
                "clearSelectedDepartments",
                "clearNewDepartment",
              ],
            },
          ],
          SET_NEW_DEPARTMENT: [
            {
              cond: "isChangingExistingDepartment",
              actions: [
                "deselectExistingDepartment",
                "clearExistingDepartment",
                "assignNewDepartment",
                "fireOnChange",
              ],
            },
            {
              cond: "isInExistingDepartmentsList",
              actions: [
                "assignExistingDepartment",
                "flagExistingDepartmentAsReadonly",
                "setNewDepartmentToExistingDepartment",
                "selectExistingDepartment",
                "fireOnChange",
              ],
            },
            {
              cond: "isInFacilityDepartmentsList",
              actions: [
                "assignExistingFacilityDepartment",
                "setNewDepartmentToExistingDepartment",
                "fireOnChange",
              ],
            },
            {
              actions: ["assignNewDepartment", "fireOnChange"],
            },
          ],
          ADD_SELECTED_DEPARTMENTS: {
            actions: ["addSelectedDepartments", "fireOnChange"],
          },
        },
      },
      loadingExistingDepartments: {
        invoke: {
          src: "loadExistingDepartments",
          onError: {
            actions: "assignErrorData",
            target: "idle",
          },
          onDone: {
            actions: [
              "assignExistingDepartments",
              "filterOutExistingFacilityDepartments",
            ],
            target: "idle",
          },
        },
      },
    },
  },
  {
    guards: {
      isNotSameFacility: (context, event) => {
        return context.facility?.id !== event.facility.id;
      },
      isChangingExistingDepartment: (context) => {
        return Boolean(context.existingDepartment);
      },
      isInExistingDepartmentsList: (context, event) => {
        const department = event.department;
        for (const xDept of context.existingDepartments) {
          if (
            xDept.type === department.type &&
            xDept.subType === department.subType
          ) {
            return true;
          }
        }
        return false;
      },
      isInFacilityDepartmentsList: (context, event) => {
        const department = event.department;
        for (const fDept of context.facility?.departments || []) {
          if (
            fDept.type === department.type &&
            fDept.subType === department.subType
          ) {
            return true;
          }
        }
        return false;
      },
    },
    actions: {
      assignFacility: assign((_, event) => ({
        facility: event.facility,
      })),
      assignExistingDepartment: assign((context, event) => ({
        existingDepartment: context.existingDepartments.find(
          (xDept) =>
            xDept.type === event.department.type &&
            xDept.subType === event.department.subType
        ),
      })),
      assignExistingFacilityDepartment: assign((context, event) => ({
        existingDepartment: context.facility?.departments.find(
          (xDept) =>
            xDept.type === event.department.type &&
            xDept.subType === event.department.subType
        ),
      })),
      flagExistingDepartmentAsReadonly: assign((context) => {
        const existingDepartment = {
          ...context.existingDepartment,
          readonly: true,
        } as unknown as Department;
        return { existingDepartment };
      }),
      setNewDepartmentToExistingDepartment: assign((context) => ({
        newDepartment: context.existingDepartment,
      })),
      clearSelectedDepartments: assign((_) => ({
        selectedDepartments: [],
      })),
      clearNewDepartment: assign((_) => ({
        newDepartment: null,
      })),
      clearExistingDepartment: assign((_) => ({
        existingDepartment: null,
      })),
      assignNewDepartment: assign((_, event) => ({
        newDepartment: event.department,
      })),
      assignExistingDepartments: assign((_, event) => ({
        existingDepartments: event.data,
      })),
      filterOutExistingFacilityDepartments: assign((context) => ({
        existingDepartments: context.existingDepartments.filter((xDept) => {
          return !listHasDepartment(context.facility?.departments || [], xDept);
        }),
      })),
      assignErrorData: assign((_, event) => ({
        error: event.data,
      })),
      addSelectedDepartments: assign((_, event) => ({
        selectedDepartments: event.departments,
      })),
      selectExistingDepartment: assign((context) => {
        const selectedDepartments = ([] as Department[]).concat(
          context.selectedDepartments
        );

        if (
          context.existingDepartment &&
          !selectedDepartments.includes(context.existingDepartment)
        ) {
          selectedDepartments.push(context.existingDepartment);
        }

        return { selectedDepartments };
      }),
      deselectExistingDepartment: assign((context) => {
        const xDept = context.existingDepartment!;
        const selectedDepartments = context.selectedDepartments.filter(
          (sDept) => {
            return !isSameDepartment(sDept, xDept);
          }
        );
        return { selectedDepartments };
      }),
    },
  }
);

function isSameDepartment(deptOne: Department, deptTwo: Department) {
  return (
    deptOne.name === deptTwo.name &&
    deptOne.type === deptTwo.type &&
    deptOne.subType === deptTwo.subType
  );
}

function listHasDepartment(list: Department[] = [], department: Department) {
  for (const dept of list) {
    if (isSameDepartment(dept, department)) {
      return true;
    }
  }
  return false;
}

function setTypeFieldHelperText(
  type: number | undefined,
  error: boolean | undefined
) {
  return !type && error ? REQUIRED_ERROR_MESSAGE : "";
}

function setNameFieldHelperText(
  name: string | undefined,
  error: boolean | undefined,
  isNameFieldEnabled: boolean
) {
  const MAX_NAME_LENGTH = 255;

  if (isNameFieldEnabled && error) {
    if (!name || name.trim() === "") {
      return REQUIRED_ERROR_MESSAGE;
    } else if (!maxLength(MAX_NAME_LENGTH)(name)) {
      return getMaxLengthErrorMessage(MAX_NAME_LENGTH);
    }
  }

  return "";
}

function useField({ facility, onChange, error }: FacilityDepartmentFieldProps) {
  const fetchGoldenDepartments = useGetGoldenDepartments();
  //@ts-ignore
  const [state, send] = useMachine(FieldMachine, {
    actions: {
      fireOnChange: (context) => {
        const newDept = context.newDepartment;
        const departments = ([] as Department[])
          .concat(context.selectedDepartments)
          .map((dept) => ({
            name: dept.name,
            type: dept.type,
            subType: dept.subType,
            primaryLocation: false,
          }));

        if (newDept && !listHasDepartment(departments, newDept)) {
          departments.push({
            name: newDept.name,
            type: newDept.type,
            subType: newDept.subType,
            primaryLocation: false,
          });
        }

        onChange(departments);
      },
    },
    services: {
      loadExistingDepartments: () => fetchGoldenDepartments(facility.id),
    },
  });

  send({ type: "SET_FACILITY", facility });

  const loading = state.matches("loadingExistingDepartments");
  const isNameFieldEnabled = !Boolean(state.context.existingDepartment);

  return {
    loading,
    goldenDepartmentsField: {
      show:
        !loading &&
        !state.context.error &&
        Boolean(state.context.existingDepartments.length),
      props: {
        value: state.context.selectedDepartments,
        departments: state.context.existingDepartments,
        onChange: (departments: Department[]) => {
          send({ type: "ADD_SELECTED_DEPARTMENTS", departments });
        },
      },
    },
    departmentField: {
      show: !loading,
      props: {
        value: state.context.newDepartment || undefined,
        onChange: (department: Department) => {
          send({ type: "SET_NEW_DEPARTMENT", department });
        },
        typeFieldProps: {
          helperText: setTypeFieldHelperText(
            state.context.newDepartment?.type,
            error
          ),
        },
        nameFieldProps: {
          InputProps: {
            disabled: !isNameFieldEnabled,
          },
          helperText: setNameFieldHelperText(
            state.context.newDepartment?.name,
            error,
            isNameFieldEnabled
          ),
        },
      },
    },
    departmentExistsMessage: {
      show: Boolean(state.context.existingDepartment),
      departmentName: state.context.existingDepartment?.name as string,
    },
  };
}
