//@ts-nocheck
import React from "react";
import { Box, Table, TableCell, TableHead } from "@mui/material";

import { omit } from "rambda";
import { useEffect } from "react";
import { useMachine } from "@xstate/react";
import { Department } from "types/facility";
import { createMachine, assign } from "xstate";
import { FormCheckboxField } from "components/FormCheckboxField";
import { getDepartmentType, getDepartmentSubtypeName } from "utils/department";
import { TableHeadRow, TableBodyRow, TableBody, TableHeadCell } from "./Table";

interface GoldenDepartmentsFieldProps {
  departments: Department[];
  onChange: (departments: Department[]) => void;
  value?: Department[];
}

export function GoldenDepartmentsField(props: GoldenDepartmentsFieldProps) {
  const state = useField(props);

  const handleCheckboxChange = (value: any, department: Department) => {
    const checked = value.target.checked;
    state.setDepartmentChecked(department, checked);
  };

  return (
    <Table aria-label="Existing departments to select from">
      <TableHead>
        <TableHeadRow>
          <TableHeadCell>Department name</TableHeadCell>
          <TableHeadCell>Type</TableHeadCell>
          <TableHeadCell>Subtype</TableHeadCell>
        </TableHeadRow>
      </TableHead>
      <TableBody>
        {state.departments.map((dept: CheckableDepartment, i: number) => (
          <TableBodyRow key={i}>
            <TableCell>
              <Box sx={{ ml: "-4px" }}>
                <FormCheckboxField
                  label={dept.name!}
                  checked={dept.checked}
                  readonly={dept.readonly}
                  sx={{ checkbox: { pr: 1 }, label: { fontSize: 14 } }}
                  onChange={(event) => handleCheckboxChange(event, dept)}
                />
              </Box>
            </TableCell>
            <TableCell>{getDepartmentType(dept.type!).name}</TableCell>
            <TableCell>
              {getDepartmentSubtypeName(dept.type!, dept.subType!)}
            </TableCell>
          </TableBodyRow>
        ))}
      </TableBody>
    </Table>
  );
}

export interface CheckableDepartment extends Department {
  checked?: boolean;
  readonly?: boolean;
}

interface Context {
  departments: CheckableDepartment[];
  selectedDepartments: Department[];
}

const FieldMachine = createMachine<Context>(
  {
    initial: "idle",
    context: {
      departments: [],
      selectedDepartments: [],
    },
    states: {
      idle: {
        entry: "applySelectedDepartments",
        on: {
          SELECT_DEPARTMENT: {
            actions: ["setDepartmentChecked", "dispatchOnChange"],
          },
          SET_SELECTED_DEPARTMENTS: {
            actions: ["assignSelectedDepartments", "applySelectedDepartments"],
          },
        },
      },
    },
  },
  {
    actions: {
      assignSelectedDepartments: assign((context, event) => ({
        //@ts-ignore
        selectedDepartments: event.departments,
      })),
      setDepartmentChecked: assign((context, event) => ({
        departments: context.departments.map((dept) => ({
          ...dept,
          //@ts-ignore
          checked: isSameDepartment(dept, event.department)
            ? event.checked
            : dept.checked,
        })),
      })),
      applySelectedDepartments: assign((context, event) => ({
        departments: context.departments.map((department) => {
          const preSelectedDepartment = (
            context.selectedDepartments || []
          ).find((dept) => isSameDepartment(dept, department));
          return {
            ...department,
            checked: preSelectedDepartment ? true : false,
            readonly: preSelectedDepartment
              ? preSelectedDepartment.readonly || false
              : false,
          };
        }),
      })),
    },
  }
);

export function isSameDepartment(deptOne: Department, deptTwo: Department) {
  return (
    deptOne.name === deptTwo.name &&
    deptOne.type === deptTwo.type &&
    deptOne.subType === deptTwo.subType
  );
}

function useField({
  departments,
  onChange,
  value,
}: GoldenDepartmentsFieldProps) {
  const [state, send] = useMachine(
    FieldMachine.withContext({
      departments: departments as CheckableDepartment[],
      // NOTE: The empty array is being defaulted here to handle a
      // situation where the value is something like an empty string,
      // which is what could happen if the this components is being used in
      // conjunction with a useForm component
      selectedDepartments: value || [],
    }),
    {
      actions: {
        dispatchOnChange(context) {
          const departments = context.departments
            .filter((dept) => Boolean(dept.checked))
            .map((department) => ({
              ...omit(["checked", "readonly"], department as any),
            })) as unknown as Department[];
          onChange(departments);
        },
      },
    }
  );

  useEffect(() => {
    send({ type: "SET_SELECTED_DEPARTMENTS", departments: value });
  }, [value, send]);

  return {
    departments: state.context.departments,
    setDepartmentChecked: (department: Department, checked: boolean) => {
      send({ type: "SELECT_DEPARTMENT", department, checked });
    },
  };
}
