import React from "react";
import { styled } from "@mui/material/styles";
import EmailIcon from "@mui/icons-material/Email";
import { Button, Card, Typography, Link, Alert } from "@mui/material";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { createMachine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import { InvestigatorDataBankTerms } from "./InvestigatorDataBankTerms";

const PREFIX = "OptInConfirmation";

const classes = {
  card: `${PREFIX}-card`,
  title: `${PREFIX}-title`,
  paragraph: `${PREFIX}-paragraph`,
  termsLink: `${PREFIX}-termsLink`,
  terms: `${PREFIX}-terms`,
};

const CardStyled = styled(Card)(({ theme }) => ({
  [`&.${classes.card}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.title}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridGap: theme.spacing(1),
    alignItems: "center",
  },

  [`& .${classes.paragraph}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.termsLink}`]: {
    cursor: "pointer",
  },

  [`& .${classes.terms}`]: {
    height: 200,
    overflowY: "scroll",
    background: theme.palette.grey[100],
    borderRadius: 3,
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(2),
  },
}));

const SEND_OPT_IN_EMAIL_MUTATION = gql`
  mutation optin {
    optIn {
      status
    }
  }
`;

interface OptInConfirmationMachineContext {
  termsOpen: boolean;
}

let OptInConfirmationMachine = createMachine<OptInConfirmationMachineContext>(
  {
    initial: "idle",
    context: {
      termsOpen: false,
    },
    states: {
      idle: {
        on: {
          SEND_EMAIL: "sending",
          TOGGLE_TERMS: {
            actions: "toggleTerms",
          },
        },
      },
      sending: {
        invoke: {
          src: "sendOptInEmail",
          onDone: "success",
          onError: "failure",
        },
      },
      success: {
        on: {
          SEND_EMAIL: "sending",
          TOGGLE_TERMS: {
            actions: "toggleTerms",
          },
        },
      },
      failure: {
        on: {
          SEND_EMAIL: "sending",
          TOGGLE_TERMS: {
            actions: "toggleTerms",
          },
        },
      },
    },
  },
  {
    actions: {
      toggleTerms: assign({
        termsOpen: (context, _event) => !context.termsOpen,
      }),
    },
  }
);

interface Props {
  email: string;
}

export function OptInConfirmation({ email }: Props) {
  let [sendOptInEmail] = useMutation(SEND_OPT_IN_EMAIL_MUTATION);
  //@ts-ignore
  let [state, send] = useMachine(OptInConfirmationMachine, {
    services: {
      sendOptInEmail(_context, _event) {
        return sendOptInEmail();
      },
    },
  });

  return (
    <CardStyled className={classes.card}>
      <Typography className={classes.title} variant="h5">
        <EmailIcon fontSize="large" />
        We've sent you an Email
      </Typography>

      <Typography className={classes.paragraph} variant="body1">
        {`
            We've sent a confirmation email to ${email} containing instructions on
            how to complete the Investigator Databank opt-in process. Please check
            your inbox (and spam folder if it's not there). If you have not received
            it, then click the button below and we will resend it to you.
           `}
        <Link
          className={classes.termsLink}
          onClick={() => send("TOGGLE_TERMS")}
          underline="hover"
        >
          {state.context.termsOpen
            ? "Hide terms"
            : "Show me the opt-in terms I previously agreed to"}
        </Link>
      </Typography>
      {state.matches("failure") && (
        <Alert className={classes.paragraph} severity="error">
          Something went wrong
        </Alert>
      )}
      {state.matches("success") && (
        <Alert className={classes.paragraph} severity="success">
          Email has been sent, please check your inbox.
        </Alert>
      )}
      {state.context.termsOpen && (
        <Typography className={classes.terms} variant="body1">
          {InvestigatorDataBankTerms}
        </Typography>
      )}
      <Button variant="contained" onClick={() => send("SEND_EMAIL")}>
        Resend Confirmation Email
      </Button>
    </CardStyled>
  );
}
