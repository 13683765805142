//@ts-nocheck
import React from "react";
import { styled } from "@mui/material/styles";
import {
  Paper,
  Button,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";

import { ChipListInput } from "./ChipListInput";
import { useForm } from "../hooks/useForm";
import { useAdmin } from "../hooks/useAdmin";
import { propOr } from "rambda";
import { noop } from "utils/noop";

const PREFIX = "AdminSearchForm";

const classes = {
  paper: `${PREFIX}-paper`,
  formRow: `${PREFIX}-formRow`,
  actionBar: `${PREFIX}-actionBar`,
  divider: `${PREFIX}-divider`,
};

const PaperStyled = styled(Paper)(({ theme }) => ({
  [`&.${classes.paper}`]: {
    padding: theme.spacing(3),
  },

  [`& .${classes.formRow}`]: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridGap: theme.spacing(1),
  },

  [`& .${classes.actionBar}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    justifyItems: "left",
    marginTop: theme.spacing(2),
    gridGap: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    margin: `${theme.spacing(2)} 0`,
  },
}));

const getLabel = propOr("", "label");
interface Props {
  onSubmit: (values: any) => void;
}

export function AdminSearchForm({ onSubmit }: Props) {
  const { vpds, roles, countries, specialties, loading } = useAdmin();

  const { fields, reset, submit } = useForm({
    fields: {
      vpd: {
        name: "vpd",
        label: "VPD",
        fullWidth: true,
        id: "vpd",
      },
      memberIds: {
        name: "memberIds",
        label: "Member ID(s)",
        defaultValue: [],
        id: "member-ids",
      },
      facilityGoldenIds: {
        label: "Facility Golden ID(s)",
        name: "facilityGoldenIds",
        defaultValue: [],
        id: "facility-ids",
      },
      personGoldenIds: {
        label: "Person Golden ID(s)",
        name: "personGoldenIds",
        defaultValue: [],
        id: "person-golden-ids",
      },
      names: {
        label: "Name(s)",
        name: "names",
        defaultValue: [],
        id: "names",
      },
      emails: {
        label: "Emails(s)",
        name: "emails",
        defaultValue: [],
        id: "emails",
      },
      ssoUid: {
        label: "SSO UID(s)",
        name: "ssoUid",
        defaultValue: [],
        id: "ssoUid",
      },
      role: {
        label: "Role",
        name: "role",
        fullWidth: true,
        id: "role",
      },
      specialties: {
        label: "Specialties",
        name: "specialties",
        fullWidth: true,
        id: "specialties",
      },
      countries: {
        label: "Countries",
        name: "countries",
        fullWidth: true,
        id: "countries",
      },
    },
    onSuccess: onSubmit,
    onError: console.log,
    //@ts-ignore
    submit: noop,
  });

  function onSubmitForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    submit();
  }

  return (
    <PaperStyled className={classes.paper}>
      <Typography variant="h5">Search</Typography>
      <form
        onSubmit={onSubmitForm}
        aria-label="Admin search form"
        aria-busy={loading}
      >
        <div className={classes.formRow}>
          <div>
            <Autocomplete
              id={fields.vpd.id}
              options={vpds}
              getOptionLabel={getLabel}
              onChange={(_, value) => {
                fields.vpd.onChange({ target: { value } });
              }}
              value={fields.vpd.value || null}
              renderInput={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  label={fields.vpd.label}
                  margin="dense"
                  name={fields.vpd.name}
                />
              )}
            />
            <ChipListInput {...fields.memberIds} />
            <ChipListInput {...fields.facilityGoldenIds} />
            <ChipListInput {...fields.personGoldenIds} />
          </div>
          <div>
            <ChipListInput {...fields.names} />
            <ChipListInput {...fields.emails} />
            <ChipListInput {...fields.ssoUid} />
          </div>
          <div>
            <Autocomplete
              id={fields.role.id}
              options={roles}
              getOptionLabel={getLabel}
              onChange={(_, value) => {
                fields.role.onChange({ target: { value } });
              }}
              value={fields.role.value || null}
              renderInput={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  label={fields.role.label}
                  name={fields.role.name}
                  margin="dense"
                />
              )}
            />
          </div>
          <div>
            <Autocomplete
              id={fields.specialties.id}
              options={specialties}
              groupBy={(option) => option.group}
              multiple
              onChange={(_, value) => {
                fields.specialties.onChange({ target: { value } });
              }}
              value={fields.specialties.value || []}
              renderInput={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  label={fields.specialties.label}
                  name={fields.specialties.name}
                  margin="dense"
                />
              )}
            />
            <Autocomplete
              id={fields.countries.id}
              options={countries}
              multiple
              getOptionLabel={(option) => option.label}
              onChange={(_, value) => {
                fields.countries.onChange({ target: { value } });
              }}
              value={fields.countries.value || []}
              renderInput={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  label={fields.countries.label}
                  name={fields.countries.name}
                  margin="dense"
                />
              )}
            />
          </div>
        </div>
        <div className={classes.actionBar}>
          <Button
            variant="contained"
            type="submit"
            aria-label="adminSearchButton"
          >
            Search
          </Button>
          <Button variant="outlined" onClick={reset} aria-label="resetButton">
            Reset
          </Button>
        </div>
      </form>
    </PaperStyled>
  );
}
