import React from "react";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { FormControlLabel, Radio } from "@mui/material";
import { FormFieldset } from "./FormFieldset";

type InputChangeHandler = (
  event: React.ChangeEvent<HTMLInputElement>,
  value: string
) => void;

interface RadioOptions {
  label: string;
  value: string;
  key?: string;
}

export interface FormRadioButtonGroupFieldProps {
  id: string;
  name?: string;
  label?: string;
  value?: string;
  options?: RadioOptions[];
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  onChange?: InputChangeHandler | undefined;
  helperText?: string;
  "aria-label"?: string;
}

export function FormRadioButtonGroupField({
  id,
  name,
  label,
  value,
  options = [],
  error,
  required,
  disabled,
  onChange,
  helperText,
  "aria-label": ariaLabel,
}: FormRadioButtonGroupFieldProps) {
  return (
    <FormFieldset
      error={error}
      required={required}
      legend={label!}
      disabled={disabled}
      aria-hidden
    >
      <FormControl
        id={id}
        required={required}
        component="fieldset"
        error={error}
        disabled={disabled}
      >
        <FormLabel
          component="legend"
          required={required}
          disabled={disabled}
          hidden
        >
          {label}
        </FormLabel>
        <RadioGroup
          aria-label={ariaLabel}
          name={name}
          value={value}
          onChange={onChange}
          row
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.key || option.label}
              value={option.value}
              control={
                <Radio
                  color="primary"
                  required={required}
                  checked={option.value === value}
                />
              }
              label={option.label}
            />
          ))}
        </RadioGroup>
        <FormHelperText id={`${id}-helper-text`} error={error}>
          {helperText}
        </FormHelperText>
      </FormControl>
    </FormFieldset>
  );
}
