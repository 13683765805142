import gql from "graphql-tag";

import { useMutation } from "@apollo/client";

import {
  GET_FACILITY_PROFILE,
  GET_DEPARTMENT_PROFILE,
} from "./useFacilityDepartmentProfile";

import {
  ProfileContact,
  FacilityContact,
  CommitteeDetails,
  FacilityEquipment,
  PatientPopulation,
  Characteristics,
} from "types/facility";

export const DELETE_FACILITY_CONTACT = gql`
  mutation DeleteFacilityContact(
    $adminComment: String
    $departmentPk: Int = 0
    $facilityContact: IFacilityContact!
    $goldenFacilityPk: Int!
  ) {
    deleteFacilityContact(
      adminComment: $adminComment
      departmentPk: $departmentPk
      facilityContact: $facilityContact
      goldenFacilityPk: $goldenFacilityPk
    ) {
      status
    }
  }
`;

export const DELETE_SELF_FACILITY_CONTACT = gql`
  mutation DeleteSelfFacilityContact(
    $departmentPk: Int = 0
    $facilityContact: IFacilityContact!
    $goldenFacilityPk: Int!
  ) {
    deleteSelfFacilityContact(
      departmentPk: $departmentPk
      facilityContact: $facilityContact
      goldenFacilityPk: $goldenFacilityPk
    ) {
      status
    }
  }
`;

export const CREATE_FACILITY_CONTACT = gql`
  mutation CreateFacilityContact(
    $departmentPk: Int
    $facilityContact: IFacilityContact!
    $goldenFacilityPk: Int!
  ) {
    createFacilityContact(
      departmentPk: $departmentPk
      facilityContact: $facilityContact
      goldenFacilityPk: $goldenFacilityPk
    ) {
      emailRecipient
    }
  }
`;

export const CREATE_SELF_FACILITY_CONTACT = gql`
  mutation CreateSelfFacilityContact(
    $departmentPk: Int
    $facilityContact: IFacilityContact!
    $goldenFacilityPk: Int!
  ) {
    createSelfFacilityContact(
      departmentPk: $departmentPk
      facilityContact: $facilityContact
      goldenFacilityPk: $goldenFacilityPk
    ) {
      email
    }
  }
`;

export const UPDATE_FACILITY_COMMITTEE_DETAILS = gql`
  mutation UpdateFacilityCommitteeDetails(
    $committeeDetails: IFacilityCommitteeDetails!
    $departmentPk: Int = 0
    $goldenFacilityPk: Int!
  ) {
    updateFacilityCommitteeDetails(
      committeeDetails: $committeeDetails
      departmentPk: $departmentPk
      goldenFacilityPk: $goldenFacilityPk
    ) {
      goldenFacilityPk
      irb {
        anyCommittee
        ownLocalIrb
        otherIrb
        useCommitteeTypes {
          usesLocal
          usesCentral
          usesCentralAsLocal
          usesSponsorSupplied
        }
      }
      departmentPk
    }
  }
`;

export const UPDATE_FACILITY_EQUIPMENT = gql`
  mutation UpdateFacilityEquipment(
    $departmentPk: Int = 0
    $equipment: IFacilityEquipment!
    $goldenFacilityPk: Int!
  ) {
    updateFacilityEquipment(
      departmentPk: $departmentPk
      equipment: $equipment
      goldenFacilityPk: $goldenFacilityPk
    ) {
      goldenFacilityPk
      equipment {
        diagnostics {
          ct
          mri
          pet
          xray
          dxa
          flro
          mra
          mrs
          mammo
          nmed
          ultrasound
          ecg
          echo
          spirometry
          other
        }
        treatment {
          inPatientAdmission
          isInfusionCapable
          pgxCollectionResearch
          pkPdCapability
          pkPdOutsideHours
        }
        equipmentDetails {
          labSamplesCentrifuge
          refrigeratedCentrifuge
          refrigeratorForSamples
          sopRoutineMaintenance
          treatMedicalEmergencies
          freezers {
            minus20ToMinus30
            minus70
            minus135
          }
        }
      }
      departmentPk
    }
  }
`;

export const UPDATE_FACILITY_PATIENT_POPULATION = gql`
  mutation UpdateFacilityPatientPopulation(
    $ageGroups: [IPopulationKeyValue]!
    $departmentPk: Int = 0
    $ethnicities: [IPopulationKeyValue]!
    $goldenFacilityPk: Int!
  ) {
    updateFacilityPatientPopulation(
      ageGroups: $ageGroups
      departmentPk: $departmentPk
      ethnicities: $ethnicities
      goldenFacilityPk: $goldenFacilityPk
    ) {
      goldenFacilityPk
      patientPopulation {
        ethnicities {
          key
          value
        }
        ageGroups {
          key
          value
        }
      }
      departmentPk
    }
  }
`;

export const UPDATE_FACILITY_CHARACTERISTICS = gql`
  mutation UpdateFacilityCharacteristics(
    $characteristics: IFacilityCharacteristics!
    $departmentPk: Int = 0
    $goldenFacilityPk: Int!
  ) {
    updateFacilityCharacteristics(
      characteristics: $characteristics
      departmentPk: $departmentPk
      goldenFacilityPk: $goldenFacilityPk
    ) {
      goldenFacilityPk
      characteristics {
        dedicatedResearchFacility
        govAffiliation
        url
        languageCapabilities {
          hasSufficientEnglish
          translatorSupportStudyConduct
        }
        experience {
          academic
          government
          industry
          investigatorInitiated
          other
        }
        hours {
          all
          evenings
          weekends
        }
        phases {
          phaseZero
          phaseOne
          phaseOneOrTwo
          phaseTwo
          phaseTwoOrThree
          phaseThree
          phaseThreeOrFour
          phaseFour
        }
        therapeuticAreas {
          code
          value
        }
        type
        typeOther
      }
      departmentPk
    }
  }
`;

export function useFacilityMutations(
  goldenFacilityPk: number,
  departmentPk: number = 0
) {
  const deleteContact = useDeleteContact(goldenFacilityPk, departmentPk);
  const createContact = useCreateContact(goldenFacilityPk, departmentPk);
  const createSelfContact = useCreateSelfContact(
    goldenFacilityPk,
    departmentPk
  );
  const updateCommitteeDetails = useUpdateCommitteeDetails(
    goldenFacilityPk,
    departmentPk
  );
  const updateEquipmentDetails = useUpdateEquipmentDetails(
    goldenFacilityPk,
    departmentPk
  );
  const updatePatientPopulation = useUpdatePatientPopulation(
    goldenFacilityPk,
    departmentPk
  );
  const updateCharacteristics = useUpdateCharacteristics(
    goldenFacilityPk,
    departmentPk
  );

  return {
    deleteContact,
    createContact,
    createSelfContact,
    updateCharacteristics,
    updateCommitteeDetails,
    updateEquipmentDetails,
    updatePatientPopulation,
  };
}

export function useDeleteContact(
  goldenFacilityPk: number,
  departmentPk: number = 0
) {
  const refetchQueries = createRefetchQueries(goldenFacilityPk, departmentPk);

  const [updateDeleteContact] = useMutation(DELETE_FACILITY_CONTACT, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const [updateDeleteSelfContact] = useMutation(DELETE_SELF_FACILITY_CONTACT, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  return async (contact: ProfileContact, adminComment?: string) => {
    const params = {
      variables: {
        facilityContact: {
          type: contact.type,
          email: contact.email,
        },
        adminComment,
        goldenFacilityPk,
        departmentPk,
      },
    };
    const result = contact.myself
      ? await updateDeleteSelfContact(params)
      : await updateDeleteContact(params);
    return result.data.status;
  };
}

export function useCreateContact(
  goldenFacilityPk: number,
  departmentPk: number = 0
) {
  const [createFacilityContact] = useMutation(CREATE_FACILITY_CONTACT, {
    refetchQueries: createRefetchQueries(goldenFacilityPk, departmentPk),
    awaitRefetchQueries: true,
  });

  return async (facilityContact: FacilityContact) => {
    const result = await createFacilityContact({
      variables: {
        facilityContact,
        goldenFacilityPk,
        departmentPk,
      },
    });
    return result.data.createFacilityContact.emailRecipient;
  };
}

export function useCreateSelfContact(
  goldenFacilityPk: number,
  departmentPk: number = 0
) {
  const [createSelfFacilityContact] = useMutation(
    CREATE_SELF_FACILITY_CONTACT,
    {
      refetchQueries: createRefetchQueries(goldenFacilityPk, departmentPk),
      awaitRefetchQueries: true,
    }
  );

  return async (facilityContact: FacilityContact) => {
    const result = await createSelfFacilityContact({
      variables: {
        facilityContact,
        goldenFacilityPk,
        departmentPk,
      },
    });
    return result.data.createSelfFacilityContact.email;
  };
}

export function useUpdateCommitteeDetails(
  goldenFacilityPk: number,
  departmentPk: number = 0
) {
  const [createFacilityContact] = useMutation(
    UPDATE_FACILITY_COMMITTEE_DETAILS,
    {
      refetchQueries: createRefetchQueries(goldenFacilityPk, departmentPk),
      awaitRefetchQueries: true,
    }
  );

  return async (committeeDetails: CommitteeDetails) => {
    const result = await createFacilityContact({
      variables: {
        committeeDetails,
        goldenFacilityPk,
        departmentPk,
      },
    });
    return result.data.updateFacilityCommitteeDetails;
  };
}

export function useUpdateEquipmentDetails(
  goldenFacilityPk: number,
  departmentPk: number = 0
) {
  const [updateFacilityEquipment] = useMutation(UPDATE_FACILITY_EQUIPMENT, {
    refetchQueries: createRefetchQueries(goldenFacilityPk, departmentPk),
    awaitRefetchQueries: true,
  });

  return async (equipment: FacilityEquipment) => {
    const result = await updateFacilityEquipment({
      variables: {
        equipment,
        goldenFacilityPk,
        departmentPk,
      },
    });
    return result.data.updateFacilityEquipment;
  };
}

export function useUpdatePatientPopulation(
  goldenFacilityPk: number,
  departmentPk: number = 0
) {
  const [updateFacilityEquipment] = useMutation(
    UPDATE_FACILITY_PATIENT_POPULATION,
    {
      refetchQueries: createRefetchQueries(goldenFacilityPk, departmentPk),
      awaitRefetchQueries: true,
    }
  );

  return async (population: PatientPopulation) => {
    const result = await updateFacilityEquipment({
      variables: {
        ...population,
        goldenFacilityPk,
        departmentPk,
      },
    });
    return result.data.updateFacilityPatientPopulation;
  };
}

export function useUpdateCharacteristics(
  goldenFacilityPk: number,
  departmentPk: number = 0
) {
  const [updateCharacteristics] = useMutation(UPDATE_FACILITY_CHARACTERISTICS, {
    refetchQueries: createRefetchQueries(goldenFacilityPk, departmentPk),
    awaitRefetchQueries: true,
  });

  return async (characteristics: Characteristics) => {
    const result = await updateCharacteristics({
      variables: {
        characteristics,
        goldenFacilityPk,
        departmentPk,
      },
    });
    return result.data.updateFacilityCharacteristics;
  };
}

export function createRefetchQueries(
  goldenFacilityPk: number,
  departmentPk: number = 0
) {
  const refetchQueries: any = [
    {
      query: GET_FACILITY_PROFILE,
      variables: { goldenFacilityPk },
    },
  ];

  if (departmentPk) {
    refetchQueries.push({
      query: GET_DEPARTMENT_PROFILE,
      variables: { goldenFacilityPk, departmentPk },
    });
  }
  return refetchQueries;
}
