import { Field } from "hooks/useForm";

export function getMaxLengthErrorMessage(length: number, type = "character") {
  return `Should not be more than ${length} ${type}(s) long`;
}

export function maxLengthForValidator(length: number, type = "character") {
  return (field: Field) => {
    if (field.value && field.value.length > length) {
      return getMaxLengthErrorMessage(length, type);
    }
  };
}

export function maxLength(length: number): (value: string | []) => boolean {
  //@ts-ignore
  return value => value && value.length <= length;
}
