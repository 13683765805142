import React from "react";
import { styled } from "@mui/material/styles";

import { Footer } from "./Footer";

const OuterContainer = styled("main")(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  flex: '1 0 auto'
}));

const InnerContainer = styled("div")(({ theme }) => ({
  flex: '1 0 auto',
  maxWidth: "1000px",
  marginTop: theme.spacing(12),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  display: "grid",
  gridTemplateRows: "auto 1fr",
  gridGap: theme.spacing(2),
  width: '100%',

  '&.full-width': {
    maxWidth: "none",
    marginTop: theme.spacing(10),
  }
}));

interface IContent {
  fullWidth?: boolean
  children: JSX.Element | JSX.Element[]
}

export function Content({ fullWidth = false, children }: IContent) {
  return (
    <>
      <OuterContainer>
        <InnerContainer  className={fullWidth ? 'full-width' : ''}>
          {children}
        </InnerContainer>
      </OuterContainer>
      <Footer />
    </>
  );
}
