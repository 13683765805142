import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { noop } from "utils/noop";
import { Specialty } from "types/profile";

import {
  TablePane,
  TableHeadRow,
  TableBodyRow,
  TableActionsCell,
  TableEditActionButton,
  TableDeleteActionButton,
  TablePaneFooter,
  TablePaneButton,
} from "./Table";

interface Props {
  testId?: string;
  entries?: Specialty[];
  maxEntries?: number;
  onCreateButtonClick?: Callback;
  onUpdateButtonClick?: Callback;
  onDeleteButtonClick?: Callback;
  "data-testid"?: string;
}

export function MedicalSpecialtyTable({
  entries = [],
  maxEntries = 5,
  onCreateButtonClick = noop,
  onUpdateButtonClick = noop,
  onDeleteButtonClick = noop,
  "data-testid": testId = MedicalSpecialtyTable.name,
}: Props) {
  const maxEntriesReached = entries.length >= maxEntries;
  const addSpecialtyLabel = entries.length > 0 ? 'Add another specialty' : 'Add specialty'
  return (
    <TablePane>
      <Table data-testid={testId} data-testmaxentries={maxEntries}>
        <TableHead>
          <TableHeadRow>
            <TableCell>Specialty</TableCell>
            <TableCell>Subspecialty</TableCell>
            <TableCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {entries.map((entry, i) => (
            <TableBodyRow key={i} data-testid={`${testId}BodyRow`}>
              <TableCell>{entry.specialty}</TableCell>
              <TableCell>{entry.subspecialty}</TableCell>
              <TableActionsCell>
                <TableEditActionButton
                  onClick={() => onUpdateButtonClick(entry)}
                  data-testid={`${testId}UpdateEntryButton`}
                />
                <TableDeleteActionButton
                  onClick={() => onDeleteButtonClick(entry)}
                  data-testid={`${testId}DeleteEntryButton`}
                />
              </TableActionsCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
      <TablePaneFooter>
        {!maxEntriesReached ? (
          <TablePaneButton
            icon={AddCircleIcon}
            label={addSpecialtyLabel}
            onClick={onCreateButtonClick}
            data-testid={`${testId}CreateEntryButton`}
          />
        ) : (
          <Typography
            variant="subtitle2"
            data-testid={`${testId}MaxReachedNotification`}
          >
            You can add up to 5 medical specialties where you have the most
            experience, the priority is specialties in which you have specialist
            training or board certification. You can add all of your research
            experience in the Clinical Studies section.
          </Typography>
        )}
      </TablePaneFooter>
    </TablePane>
  );
}
